import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'

const AddUserForm = () => {
   const [passwordError, setPasswordError] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
    roles:'',
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleRoleChange = (e) => {
    const roleValue = e.target.value
    console.log("the role value is",roleValue)
    setFormData({...formData,roles:roleValue})
    // const updatedRoles = e.target.checked
    //   ? [...formData.roles, roleValue]
    //   : formData.roles.filter((role) => role !== roleValue)
    // setFormData({ ...formData, roles: updatedRoles })
  }


  const handleSubmit = (e) => {
    e.preventDefault()
     if (formData.password !== formData.password_confirmation) {
       setPasswordError("Passwords do not match")
       return // Stop form submission if passwords don't match
     } else {
       setPasswordError('')
     }
    // Perform logic to submit form data, including formData
   console.log('Form submitted with data:', formData)
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      axios
        .post(
          base_url+'admin/addUser',
          { body: formData },
          config
        )
        .then((res) => console.log('the res is', res))
        .catch((err) => console.log('the error is', err))
    } catch (error) {
      console.log('catch error is', error)
    }
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Add User</h3>
                  <ul className="breadcrumb mt-3">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Add</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm">
                        <form
                          method="POST"
                          action="http://clc-london.uk/admin/users"
                          className="needs-validation"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <input
                            type="hidden"
                            name="_token"
                            value="X90I0NPAgthq4dcz6ArwbaXK1S7CKB4MEOxnPLCS"
                          />

                          <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom01">
                                Username:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder=""
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom02">Email:</label>
                              <input
                                type="email"
                                className="form-control"
                                id="validationCustom02"
                                placeholder=""
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom03">Phone</label>
                              <input
                                type="number"
                                className="form-control"
                                id="validationCustom03"
                                placeholder=""
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <label htmlFor="validationCustom04">
                                Password
                              </label>
                              <input
                                type="password"
                                className={`form-control ${
                                  passwordError ? 'is-invalid' : ''
                                }`}
                                id="validationCustom04"
                                placeholder=""
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                            <div className="col-md-3 mb-3">
                              <label htmlFor="validationCustom05">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                className={`form-control ${
                                  passwordError ? 'is-invalid' : ''
                                }`}
                                id="validationCustom05"
                                placeholder=""
                                name="password_confirmation"
                                value={formData.password_confirmation}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                              <div className="valid-feedback">Looks good.</div>
                            </div>
                          </div>
                          <div className="card-header">
                            <h5 className="card-title mb-2 font-weight-bold">
                              Roles
                            </h5>
                          </div>
                          <div className="form-row mt-3 mb-3">
                            <div className="col-lg-3 box">
                              <input
                                className="larger"
                                type="checkbox"
                                id="checkbox1"
                                name="roles[]"
                                value="admin"
                                onChange={handleRoleChange}
                                checked={formData.roles.includes('admin')}
                              />
                              <label className="mt-1 ml-1" htmlFor="checkbox1">
                                admin
                              </label>
                            </div>
                            <div className="col-lg-3 box">
                              <input
                                className="larger"
                                type="checkbox"
                                id="checkbox2"
                                name="roles[]"
                                value="writer"
                                onChange={handleRoleChange}
                                checked={formData.roles.includes('writer')}
                              />
                              <label className="mt-1 ml-1" htmlFor="checkbox2">
                                writer
                              </label>
                            </div>
                          </div>

                          <button className="btn btn-primary" type="submit">
                            Submit form
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2020.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default AddUserForm
