import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, RESET_PASSWORD_FAILED,RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS,VERIFY_OTP_REQUEST,VERIFY_OTP_FAILED,VERIFY_OTP_SUCCESS, CHANGE_PASSWORD_REQUEST,CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILED } from "../constants/userConstants";


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true };
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}
export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true }
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, otpInfo: action.payload }
    case RESET_PASSWORD_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const verifyOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      return { loading: true }
    case VERIFY_OTP_SUCCESS:
      return { loading: false, otpstatus: action.payload }
    case VERIFY_OTP_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { loading: true }
    case CHANGE_PASSWORD_SUCCESS:
      return { loading: false, otpstatus: action.payload }
    case CHANGE_PASSWORD_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}