import React from 'react'
import { CKEditor } from 'ckeditor4-react'
import { useSelector } from 'react-redux'

const EmailTemplate = () => {
  const handleSubmit = (event) => {
    event.preventDefault()
    // Handle form submission logic here
    const form = event.target
    const formData = new FormData(form)
    const approveTemplate = formData.get('approve')
    const declineTemplate = formData.get('reject')
    const documentsRequiredTemplate = formData.get('revision')

    // Perform the necessary actions with the templates, e.g., save to backend
    console.log('Approve Template:', approveTemplate)
    console.log('Decline Template:', declineTemplate)
    console.log('Documents Required Template:', documentsRequiredTemplate)
  }
 const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Email Template</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Email Templates</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-2 font-weight-bold">
                    Templates
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <form
                        className="needs-validation"
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        <input
                          type="hidden"
                          name="_token"
                          value="DxDjgKuqc6rfRi32Cw8pFWH2LPYDeI0UPxCQSQpP"
                        />
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="validationCustom01">
                              Approve Template
                            </label>
                            <CKEditor
                              data="&lt;ul&gt;&lt;li&gt;&lt;h1&gt;&lt;em&gt;&lt;strong&gt;Comment &lt;/strong&gt;&lt;/em&gt;has&lt;/h1&gt;&lt;/li&gt;&lt;/ul&gt;"
                              name="approve"
                              className="form-control editor"
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              This field is required.
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="validationCustom01">
                              Decline Template
                            </label>
                            <CKEditor
                              data="&lt;h1&gt;&lt;em&gt;&lt;strong&gt;Comment 2nd is &lt;samp&gt;Decline&lt;/samp&gt;&lt;/strong&gt;&lt;/em&gt;&lt;/h1&gt;"
                              name="reject"
                              className="form-control editor2"
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              This field is required.
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="validationCustom01">
                              Documents Required
                            </label>
                            <CKEditor
                              data="&lt;h1&gt;&lt;em&gt;&lt;strong&gt;Comment of &lt;/strong&gt;&lt;/em&gt;revision&lt;/h1&gt;"
                              name="revision"
                              className="form-control editor3"
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              This field is required.
                            </div>
                          </div>
                        </div>

                        <button className="btn btn-primary" type="submit">
                          Save changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <p>Copyright © 2022.</p>
        </footer>
      </div>
    </div>
  )
}

export default EmailTemplate
