import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { base_url } from '../../../utils/Api'

const EditCommencement = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    sessionname: '',
  })
   const { _id } = useParams()
   useEffect(() => {
     getSessionNameById()
   }, [_id])
  const getSessionNameById = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+`admin/SessionName/${_id}`, config)
      .then((res) => {
        console.log('the res is', res)

        setFormData({
          sessionname: res?.data?.data?.session_Name,
        })
        //setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    // Perform form submission logic here (e.g., send data to the server)
    // Replace the console.log with your actual API call or desired action.
    console.log("fjdksfslf")
    console.log("th id iisissi",_id)
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      axios
        .put(
          base_url+`admin/updateName/${_id}`,
          formData,
          config
        )
        .then((res) => console.log('resp is', res))
        .catch((err) => console.log('error is'.err))
    
    console.log(formData)
  }
 const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Edit Commencement</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/admin/commencement">
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Edit Commencement</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit} // Call handleSubmit on form submission
                    noValidate
                  >
                    <input
                      type="hidden"
                      name="_token"
                      value="fNxMDchsc00FU6wuPoVJ1UeOBotL1EjnkDxHZJGb"
                    />
                    <input type="hidden" name="_method" value="PATCH" />
                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom01">Session Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder=""
                          name="sessionname"
                          value={formData.sessionname} // Set input value from state
                          onChange={handleChange} // Call handleChange on input change
                          // required
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required.
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary" type="submit">
                      Edit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCommencement
