import PermissionTable from './PermissionTable'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { base_url } from '../../../utils/Api'
// import TablePagination from './TablePagination'

const RolesTable = () => {
  const [PerPageEntry, setPerPageEntry] = useState(10)
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState([])
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)

  const getAllList = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const res = await axios.get(base_url + 'admin/getRoleNames', config)
      const responseData = res?.data?.data

      setTotalEntries(responseData.length)
      setTotalLength(responseData.length)

      const filteredData = responseData.filter(
        (item, index) =>
          item?.Role_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          index.toString().includes(searchQuery)
      )

      setFilteredTotalEntries(filteredData.length)
      setData(filteredData)
    } catch (err) {
      console.log('error is---', err)
    }
  }
  useEffect(() => {
    getAllList()
  }, [searchQuery])

  const rolesData = [
    {
      roleName: 'admin',
      permissions: [
        'Course access',
        'Course edit',
        'Course create',
        'Course delete',
        'Role access',
        'Role edit',
        'Role create',
        'Role delete',
        'User access',
        'User edit',
        'User create',
        'User delete',
        'Permission access',
        'Permission edit',
        'Permission create',
        'Permission delete',
        'Application access',
        'fee management',
      ],
      roleId: 1,
    },
    {
      roleName: 'writer',
      permissions: [],
      roleId: 2,
    },
  ]

  const handleEditRole = (roleId) => {
    // Perform logic to handle edit role
    console.log(`Editing role with ID ${roleId}`)
  }

  const handleDeleteRole = (roleId) => {
    // Perform logic to handle delete role
    console.log(`Deleting role with ID ${roleId}`)
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-warpper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Roles</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Roles</li>
                  </ul>
                </div>
                <div className="col-auto text-right float-right ml-auto">
                  <Link to="/admin/roles/create" className="btn btn-primary">
                    <i className="fas fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 ">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <p style={{ display: 'none' }}>1</p>
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 mb-1">
                            <div class="col-sm-12 col-md-6 mb-1">
                              <div
                                class="dataTables_length"
                                id="DataTables_Table_0_length"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <label>Show </label>
                                <select
                                  name="DataTables_Table_0_length"
                                  aria-controls="DataTables_Table_0"
                                  class="custom-select custom-select-sm form-control form-control-sm"
                                  style={{
                                    width: 60,
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>{' '}
                                entries
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div
                              id="DataTables_Table_0_filter"
                              className="dataTables_filter"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="DataTables_Table_0"
                                style={{ width: 200, marginLeft: 8 }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card card-table">
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table className="table table-hover table-center mb-0 datatable">
                                    <thead>
                                      <tr>
                                        <th>Role Name</th>
                                        <th>Permissions</th>
                                        <th className="text-right">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* {data.map((item, index) => (
                                      <tr key={index + 1}>
                                        <td>{item?.Role_Name}</td>
                                        <td>
                                          {item?.permissions.map(
                                            (permission, index) => (
                                              <span
                                                key={index + 1}
                                                className="bg-dark text-white rounded px-2"
                                                style={{ fontSize: '13px' }}
                                              >
                                                {permission.label}
                                              </span>
                                            )
                                          )}
                                        </td>
                                        <td className="text-right">
                                          <div className="actions">
                                            <a
                                              href={`/admin/roles/${item._Id}/edit`}
                                              className="btn btn-sm bg-success-light mr-2"
                                              onClick={() =>
                                                handleEditRole(item.roleId)
                                              }
                                            >
                                              <i className="fas fa-pen"></i>
                                            </a>

                                            <button
                                              className="btn btn-sm bg-danger-light"
                                              onClick={() =>
                                                handleDeleteRole(item.roleId)
                                              }
                                            >
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))} */}
                                      {data.length > 0 ? (
                                        data.map((item, index) => (
                                          <tr key={index}>
                                            <td>{item.Role_Name}</td>{' '}
                                            {/* Replace with actual role name or data */}
                                            <td>
                                              {item.Permission.map(
                                                (permission, permissionIndex) =>
                                                  permission.checked && (
                                                    <span
                                                      key={permissionIndex + 1}
                                                      // key={index + 1}
                                                      className="bg-dark text-white rounded px-2"
                                                      style={{
                                                        fontSize: '13px',
                                                      }}
                                                    >
                                                      {permission.label}
                                                    </span>
                                                  )
                                              )}
                                            </td>
                                            <td className="text-right">
                                              <div className="actions">
                                                <Link
                                                  to={`/admin/roles/${item._id}/edit`}
                                                  className="btn btn-sm bg-success-light mr-2"
                                                  onClick={() =>
                                                    handleEditRole(item.roleId)
                                                  }
                                                >
                                                  <i className="fas fa-pen"></i>
                                                </Link>

                                                <button
                                                  className="btn btn-sm bg-danger-light"
                                                  onClick={() =>
                                                    handleDeleteRole(
                                                      item.roleId
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="3">Loading data...</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 1 of 1 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="DataTables_Table_0_paginate"
                            >
                              <ul className="pagination justify-content-end">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="DataTables_Table_0_previous"
                                >
                                  <a
                                    href="#"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="DataTables_Table_0_next"
                                >
                                  <a
                                    href="#"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2022.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default RolesTable
