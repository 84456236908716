import React, { useEffect, useState } from 'react'
import './StudentDetail.css'

import img3 from '../../../assets/images/uploadimg.png'
import { useSelector } from 'react-redux'
import axios from 'axios'
import generic_file_icon from '../../../assets/images/generic_file.png'
import Pdf_file_icon from '../../../assets/images/PDF_file_icon.png'
import word_icon from '../../../assets/images/word_icon.png'
import { base_url } from '../../../utils/Api'

const StudentDetails = () => {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedFilesD, setSelectedFilesD] = useState([])
  const [selectedFilesDAdd, setSelectedFilesDAdd] = useState([])
  const [filePreviews, setFilePreviews] = useState([])

  // for student detail
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [myfile, setmyfile] = useState()

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    setmyfile(files)
    console.log('files', files)

    setSelectedFiles([...selectedFiles, ...event.target.files])
    // const previews = files.map(file => URL.createObjectURL(file));
    const previews = files.map((file) => {
      return {
        file: file,
        preview: file.type.includes('image') ? URL.createObjectURL(file) : null,
      }
    })
    setFilePreviews([...filePreviews, ...previews])
  }
  console.log('fdfjdkfjdf')

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('subject', subject)
    formData.append('description', description)

    for (const file of selectedFiles) {
      formData.append('allfiles', file)
    }
    console.log('form data is', formData.get('allfiles'))

    try {
      await axios.post(base_url + 'student/studentDetail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log('Profile data and images uploaded successfully')
    } catch (error) {
      console.error('Error uploading profile', error)
    }
  }

  useEffect(() => {
    return () => {
      // Clean up object URLs
      filePreviews.forEach((preview) => URL.revokeObjectURL(preview))
    }
  }, [filePreviews])
  // for student detail

  const deleteFile = (index, f) => {
    if (f && f.length > 0) {
      setData([...data, f[index]])
      console.log(data)
    }
    // ... rest of your code ...
  }

  const handleFileSelect = (e, target) => {
    const storedFiles = []
    const files = e.target.files
    const filesArr = Array.from(files)
    const device = e.target.dataset.device

    // ... rest of your code ...

    if (target === 'selectedFilesD') {
      setSelectedFilesD(filesArr)
    } else if (target === 'selectedFilesDAdd') {
      setSelectedFilesDAdd(filesArr)
    }

    console.log(filesArr)
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h3 className="page-title">Student Details</h3>
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={() => setShowModal(true)}
                  >
                    {' '}
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ marginRight: '5px' }}
                    >
                      {' '}
                    </i>{' '}
                    Add New
                  </button>
                  <div
                    className="modal-overlay modal"
                    style={{
                      display: showModal ? 'flex' : 'none',
                    }}
                  >
                    <div
                      className=""
                      id="exampleModalCenter"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                      // style={{
                      //   display: showModal ? 'flex' : 'none',
                      //   height: 400,
                      // }}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        // style={{ height: 100 }}
                        role="document"
                      >
                        <div
                          className="modal-content"
                          style={{ maxHeight: 750, overflowY: 'auto' }}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Modal title
                            </h5>
                            <button
                              // style={{ backgroundColor: 'none !important' }}
                              type="button"
                              className="cross"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShowModal(false)}
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form
                              // action="http://clc-london.uk/admin/storestudentmemoform"
                              encType="multipart/form-data"
                              // method="post"
                              onSubmit={handleSubmit}
                            >
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="8OKiBuIe3HTa4JYknYIxmFCvgwEefjXv4jEUQvYM"
                              />
                              <input
                                type="text"
                                defaultValue={1}
                                name="said"
                                hidden
                              />
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Subject
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  name="subject"
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">
                                  Description
                                </label>
                                <textarea
                                  rows={5}
                                  type="text"
                                  className="form-control"
                                  name="description"
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  defaultValue={' '}
                                />
                              </div>
                              <div className="form-row">
                                <div className="file-upload">
                                  <div className="image-upload-wrap">
                                    <div className="drag-text">
                                      <img
                                        src={img3}
                                        alt="uploadimage"
                                        style={{
                                          marginTop: '40px',
                                          width: '160px',
                                          height: '100px',
                                        }}
                                      />
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '5px',
                                        }}
                                      >
                                        <h3
                                          style={{
                                            padding: '15px 15px 0px 15px',
                                          }}
                                        >
                                          Drag And Drop Files OR Choose your
                                          Files
                                        </h3>
                                        <h6>
                                          Only .pdf, .png, .jpg, .jpeg, .docx
                                          Allowed
                                        </h6>
                                      </div>
                                      <input
                                        multiple
                                        name="allfiles"
                                        type="file"
                                        className="file-upload-btn"
                                        id="filesadd"
                                        accept="image/png, image/jpeg, image/jpg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf,application/vnd.ms-excel"
                                        // multiple={(e) =>
                                        //   handleFileSelect(e, 'selectedFilesD')
                                        // }
                                        onChange={handleFileChange}
                                      />
                                    </div>
                                    <output
                                      id="selectedFilesDadd"
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '40px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {filePreviews.map((previewObj, index) => (
                                        <div key={index}>
                                          {/* {console.log("file preview is",preview)}
                                        <img key={index} src={preview} alt={`Preview ${index}`} style={{ maxWidth: '100px', maxHeight: '100px' }} /> */}
                                          {previewObj.preview ? (
                                            <img
                                              src={previewObj.preview}
                                              alt={`Preview ${index}`}
                                              style={{
                                                maxWidth: '100px',
                                                maxHeight: '100px',
                                              }}
                                            />
                                          ) : (
                                            <div>
                                              {/* Display an icon or thumbnail based on file type */}
                                              {previewObj.file.type.includes(
                                                'pdf'
                                              ) ? (
                                                <img
                                                  src={Pdf_file_icon}
                                                  alt={`PDF Preview ${index}`}
                                                  style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                  }}
                                                />
                                              ) : previewObj.file.type.includes(
                                                  'word'
                                                ) ? (
                                                <img
                                                  src={word_icon}
                                                  alt={`Word Preview ${index}`}
                                                  style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={generic_file_icon}
                                                  alt={`File Preview ${index}`}
                                                  style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                  }}
                                                />
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </output>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal"
                                  onClick={() => setShowModal(false)}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="attachmentModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            Modal title
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div
                          className="modal-body"
                          id="tblattachmentinfo"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          {/* ... Your attachment info content ... */}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Student Details</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <p style={{ display: 'none' }}>1</p>
                    <table
                      className="table table-hover table-center mb-0 datatable"
                      id="empTable"
                    >
                      <thead>
                        <tr>
                          <th>SR #.</th>
                          <th>Subject</th>
                          <th>Description</th>
                          <th>Attachments</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright © 2022</p>
        </footer>
      </div>
    </div>
  )
}

export default StudentDetails
