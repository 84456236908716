import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { base_url } from '../../utils/Api'

const Studentletters = (props) => {
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [currentUser, setCurrentUser] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [PerPageEntry, setPerPageEntry] = useState(10)
  const { lettersData } = props

  const [data, setData] = useState([])
  const [courses, setCourses] = useState([])
  const [showData, setShowData] = useState([])

  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const usertoken = localStorage.getItem('UserToken')
  useEffect(() => {
    checkUser()
  }, [])
  const checkUser = async () => {
    try {
      axios
        .post(
          base_url + 'user/userData',
          {},
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === '201') {
            // setRole(response?.data?.UserData?.type)
            console.log('the respsdsdsd is👩👩👩', response)
            setCurrentUser(response?.data?.UserData?.email)
            // localStorage.setItem('role', response?.data?.UserData?.type)
          }
        })
        .catch((error) => {
          console.error('the error is', error)
        })
    } catch (error) {
      console.error(error)
    }
  }
  const handleNextClick = () => {
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const getAllCourses = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        setCourses(res?.data?.data)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    //  getAllList()
    getAllCourses()
  }, [])
  const getAllForms = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/applications', config)
      .then((res) => {
        let applicationsdata = res?.data?.applications
        const filteredData = applicationsdata.filter(
          (eachapplication) =>
            eachapplication.Personal_Details.Email == currentUser
        )
        console.log('teh sidfjs', filteredData)
        setData(filteredData)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    if (currentUser) {
      getAllForms()
    }
  }, [currentUser])

  console.log('the student data isssssss', data)
  console.log('the course data isssssss', courses)
  const gatherData = () => {
    let arr = []
    data.forEach((item) => {
      if (item?.Course?.course_name) {
        courses.forEach((course_item) => {
          if (item?.Course?.course_name === course_item.course_Name) {
            arr.push({
              Student_Name: item?.Personal_Details?.First_Name_In_Passport,
              DOB: item?.Personal_Details?.Date_Of_Birth,
              City: item?.Personal_Details?.City,
              status: item?.status,
              _id: item?._id,
              Course_Name: course_item?.course_Name,
              Start_Date: course_item?.start_Date,
              Fee: course_item?.fee,
              End_Date: course_item?.end_Date,
              Installment:
                course_item?.allow_Installment.length > 0 ? 'Installments' : '',
            })
          }
        })
      }
    })
    setShowData(arr)
    // setTotalEntries(data.length)
    setTotalLength(data.length)
    const filteredData = arr.filter(
      (item, index) =>
        item.Course_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        index.toString().includes(searchQuery)
    )

    if (filteredData) {
      setTotalEntries(filteredData.length)
    }
    setShowData(filteredData)
    setFilteredTotalEntries(filteredData.length)
  }
  useEffect(() => {
    gatherData()
  }, [data, courses, searchQuery])
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-wrappe">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Student Letters</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Student Letters</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 ">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <p style={{ display: 'none' }}>1</p>
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div class="col-sm-12 col-md-6">
                              <div
                                class="dataTables_length"
                                id="DataTables_Table_0_length"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <label>Show </label>
                                <select
                                  name="DataTables_Table_0_length"
                                  aria-controls="DataTables_Table_0"
                                  class="custom-select custom-select-sm form-control form-control-sm"
                                  style={{
                                    width: 60,
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                  value={PerPageEntry}
                                  onChange={(e) =>
                                    setPerPageEntry(parseInt(e.target.value))
                                  }
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>{' '}
                                entries
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div
                              id="DataTables_Table_0_filter"
                              className="dataTables_filter"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="DataTables_Table_0"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ width: 200, marginLeft: 8 }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card card-table">
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table className="table table-hover table-center mb-0 datatable">
                                    <thead>
                                      <tr>
                                        <th>Sr.No</th>
                                        <th>Student Name</th>
                                        <th>DOB</th>
                                        <th>Course Name</th>
                                        <th>Start Date</th>
                                        <th>Application status</th>
                                        <th>Course Completion Status</th>
                                        <th className="text-right">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {showData.length > 0 ? (
                                        showData?.map((student, index) => (
                                          // <tr key={student.id}></tr>
                                          <tr role="row" className="odd">
                                            <td>{index + 1}</td>
                                            <td>{student?.Student_Name}</td>
                                            <td>{student?.DOB}</td>
                                            <td>{student.Course_Name}</td>
                                            <td>{student.Start_Date}</td>
                                            {/* <td>
                                            {'Diploma in Web development'}
                                          </td> */}
                                            {/* <td>{'2023 - 06 - 06'}</td> */}
                                            {/* <td>{student.applicationStatus}</td>
                                        <td>{student.courseCompetition}</td> */}
                                            <td>{student.status}</td>
                                            <td>{'In Progress'}</td>
                                            <td className="text-right">
                                              <div className="actions ">
                                                <Link
                                                  // href={`/admin/student-letter-report/${student.id}`}
                                                  // className="btn btn-sm bg-success-light mr-2"

                                                  to={`/student/student-letters/${student._id}`}
                                                  className="btn btn-sm bg-success-light mr-2"
                                                >
                                                  View
                                                </Link>
                                                {/* Add more action buttons as needed */}
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="10"
                                            style={{ textAlign: 'center' }}
                                          >
                                            {searchQuery
                                              ? 'No matching records found'
                                              : 'Loading...'}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              {filteredTotalEntries === 0
                                ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                                : `Showing ${Math.min(
                                    startIndex + 1,
                                    endIndex
                                  )} to ${Math.min(
                                    endIndex,
                                    filteredTotalEntries
                                  )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="DataTables_Table_0_paginate"
                            >
                              <ul className="pagination justify-content-end">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="DataTables_Table_0_previous"
                                >
                                  <a
                                    href="#"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                    onClick={handlePreviousClick}
                                  >
                                    Previous
                                  </a>
                                </li>

                                {totalEntries > 0
                                  ? Array.from({
                                      length: Math.max(
                                        1,
                                        Math.ceil(totalEntries / PerPageEntry)
                                      ),
                                    }).map((_, index) => (
                                      <li
                                        key={index}
                                        className={`paginate_button page-item ${
                                          currentPage === index + 1
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <button
                                          onClick={() =>
                                            setCurrentPage(index + 1)
                                          }
                                          className="page-link"
                                        >
                                          {index + 1}
                                        </button>
                                      </li>
                                    ))
                                  : null}
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="DataTables_Table_0_next"
                                >
                                  <Link
                                    href="#"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                    onClick={handleNextClick}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © {new Date().getFullYear()}.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Studentletters
