import axios from 'axios'
import React, { useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'
import '../../style.css'

const Addcourselevel = () => {
  const [courseName, setCourseName] = useState('')
      useEffect(() => {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.getElementsByClassName('needs-validation')

        // Loop over them and prevent submission
        const validation = Array.prototype.filter.call(forms, (form) => {
          form.addEventListener('submit', (event) => {
            if (form.checkValidity() === false) {
              event.preventDefault()
              event.stopPropagation()
            }
            form.classList.add('was-validated')
          })
        })
      }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    // You can handle form submission logic here.
    // For example, you can make an API call to submit the form data to the server.
    console.log('Form submitted with session name: ', courseName)
    try {
     const config = {
       headers: {
         'Content-type': 'application/json',
       },
     }
     axios
       .post(
         base_url+'admin/addCourseName',
         {courseName},
         config
       )
       .then((res) => console.log('the res is', res))
       .catch((err) => console.log('the error is', err))
    } catch (error) {
      console.log("catch error is",error)
    }
    
   
  }
 const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" style={{ minHeight: '845px' }}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Add Course</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Add course level</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom01">Course Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder=""
                          name="course_name"
                          value={courseName}
                          onChange={(e) => setCourseName(e.target.value)}
                          required
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required.
                        </div>
                      </div>
                    </div>

                    <div>
                      <button className="btn btn-primary" type="submit">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addcourselevel
