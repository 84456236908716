import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'bootstrap/js/dist/tooltip'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'
// import '../../../../node_modules/bootstrap/js'
import { useNavigate } from 'react-router-dom'

const EditCourseForm = () => {
  const [sessionData, setSessionData] = useState([])
  const [courseNmae, setcourseNmae] = useState([])
  const navigate = useNavigate()
  // const [courseData, setCourseData] = useState({
  //   installment: [{ installment_Date: '' }],
  // })
  const getSessionNames = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const res = await axios.get(base_url + 'admin/getSessionNames', config)
      const data = res?.data?.data
      // console.log('datas', data)
      return data // Return the data
    } catch (error) {
      console.error('Error fetching session names:', error)
      throw error // Propagate the error
    }
  }
  useEffect(() => {
    // console.log('data', getSessionNames)
    getSessionNames()
      .then((data) => {
        setSessionData(data)
        console.log('data', data)
      })
      .catch((error) => {
        // Handle the error here if needed
      })
  }, [])
  const getCourseNames = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const res = await axios.get(base_url + 'admin/getCoursesNames', config)
      const data = res?.data?.data
      return data // Return the data
    } catch (error) {
      console.error('Error fetching session names:', error)
      throw error // Propagate the error
    }
  }
  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation')

    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
        }
        form.classList.add('was-validated')
      })
    })
  }, [])

  useEffect(() => {
    // console.log('data', getSessionNames)

    getCourseNames()
      .then((data) => {
        setcourseNmae(data)
        console.log('data', data)
      })
      .catch((error) => {
        // Handle the error here if needed
      })
  }, [])
  const [courseData, setCourseData] = useState({
    coursename: '',
    startdate: '',
    enddate: '',
    fee: '',
    courselevel: '',
    commencement: '',
    age: '',
    studyMode: '',
    isinstallment: false,
    installment: [{ installment_Date: '', installment_No: '' }],
  })
  const { _id } = useParams()
  const [installment, setInstallment] = useState([])
  const [data, setData] = useState({})
  // courseData?.installment?.map((item)=>console.log(Object.keys(item)))
  console.log('the fldsfjlkdfjldsk', courseData.installment['installment_Date'])

  const handleInstallmentChange = (index, event) => {
    const newValue = event.target.value

    // Create a new copy of the installment array
    const newInstallment = [...courseData.installment]
    newInstallment[index].installment_Date = newValue
    newInstallment[index].installment_No = index + 1

    // Update the courseData state with the new installment array
    setCourseData({
      ...courseData,
      installment: newInstallment,
    })
  }

  const editInstallmentRow = () => {
    // setInstallment([...installment, { date: '' }])
    setCourseData({
      ...courseData,
      installment: [
        ...courseData.installment,
        { installment_Date: '', installment_No: '' },
      ],
    })
  }
  console.log('course dta is----👌👌👌', courseData.installment)
  const deleteInstallmentRow = (index) => {
    courseData.installment.pop()
    let array = []
    courseData.installment.map((item) => array.push(item))
    setCourseData({
      ...courseData,
      installment: array,
    })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    console.log('the name is', event.target.name)
    setCourseData({
      ...courseData,
      [name]: value,
    })
  }

  const handleStudyModeChange = (event) => {
    console.log('the value isss', event.target.value)
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    )
    setCourseData({
      ...courseData,
      studyMode: selectedOptions,
    })
  }
  // const [courseName, setCourseName] = useState(false)
  // const [startdate, setStartDate] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    // Handle form submission logic here
    // console.log('Form submitted with course data: ', courseData)
    if (courseData.coursename === '') {
      return
    } else if (courseData.startdate === '') {
      return
    } else if (courseData.enddate === '') {
      return
    } else if (courseData.fee === '') {
      return
    } else if (courseData.courselevel === '') {
      return
    } else if (courseData.session_Name === '') {
      return
    } else if (courseData.age === '') {
      return
    } else if (courseData.studyMode === '') {
      return
    }
    {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      axios
        .put(base_url + `admin/updateCourse/${_id}`, courseData, config)
        .then((res) => {
          console.log('resp is', res)
          navigate('/admin/courses')
        })
        .catch((err) => console.log('error is'.err))
    }
  }

  useEffect(() => {
    getAppFormById()
  }, [_id])
  const getAppFormById = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/course/${_id}`, config)
      .then((res) => {
        console.log('the res is', res)
        //  const St_Date = moment(
        //    res?.data?.data?.start_Date,
        //    'DD/MM/YYYY'
        //  ).format('YYYY-MM-DD')
        //  const En_Date = moment(res?.data?.data?.end_Date, 'DD/MM/YYYY').format('DD/MM/YYYY')
        //  console.log("the end date is",En_Date)
        // let allow_inst = res?.data?.data?.allow_Installment
        setCourseData({
          coursename: res?.data?.data?.course_Name,
          startdate: res?.data?.data?.start_Date,
          enddate: res?.data?.data?.end_Date,
          fee: res?.data?.data?.fee,
          courselevel: res?.data?.data?.course_Level,
          commencement: res?.data?.data?.session_Name,
          age: res?.data?.data?.age,
          studyMode: res?.data?.data?.study_Mode,
          isinstallment: res?.data?.data?.isinstallment,
          installment: res?.data?.data?.allow_Installment,
        })
        //setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" style={{ minHeight: '845px' }}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Edit Course</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Edit Course</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom01">Course Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder=""
                          name="coursename"
                          value={courseData.coursename}
                          onChange={handleInputChange}
                          // required
                        />
                        {/* {courseData.coursename && <div >Looks good!</div>} */}
                        {courseData.coursename === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Start Date</label>
                        <input
                          type="date"
                          className="form-control date"
                          id="validationCustom02"
                          name="startdate"
                          value={courseData.startdate}
                          onChange={handleInputChange}
                          required
                        />
                        {/* {courseData.startdate} */}
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.startdate === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">End Date</label>
                        <input
                          type="date"
                          className="form-control date"
                          id="validationCustom02"
                          value={courseData.enddate}
                          name="enddate"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.enddate === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Fee</label>
                        <div className="input-group-prepend input-group mb-2">
                          <div className="input-group-text">£</div>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            placeholder=""
                            value={courseData.fee}
                            name="fee"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.fee === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Course Level</label>
                        <select
                          className="form-control"
                          name="courselevel"
                          value={courseData.courselevel}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          {courseNmae.map((res) => (
                            <option value={res.course_Name}>
                              {res.course_Name}
                            </option>
                          ))}
                          {/* <option value="">Select</option>
                          <option value="Under-Graduate">Under-Graduate</option>
                          <option value="foundation">foundation</option> */}
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.courselevel === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Session Name</label>
                        <select
                          className="form-control"
                          name="commencement"
                          value={courseData.commencement}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          {sessionData.map((res) => (
                            <option value={res.session_Name}>
                              {res.session_Name}
                            </option>
                          ))}
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.commencement === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Age</label>
                        <div className="input-group-prepend input-group mb-2">
                          <div className="input-group-text">OVER</div>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            placeholder=""
                            value={courseData.age}
                            name="age"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.age === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                      {/* not handle */}
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Study Mode</label>
                        <select
                          className="form-control"
                          name="studyMode"
                          value={courseData.studyMode}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          <option value="Full-Time">Full-Time</option>
                          <option value="Part-Time">Part-Time</option>
                          <option value="Blended-Learning">
                            Blended-Learning
                          </option>
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        {courseData.studyMode === '' && (
                          <div>This field is required.</div>
                        )}
                      </div>
                      {/* not handle  */}
                    </div>

                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <input
                            type="checkbox"
                            name="isinstallment"
                            aria-label="Checkbox for following text input"
                            id="myCheck"
                            checked={courseData.isinstallment}
                            onClick={() =>
                              setCourseData({
                                ...courseData,
                                isinstallment: !courseData.isinstallment,
                              })
                            }
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Text input with checkbox"
                        value="Allow Installments"
                        style={{ fontWeight: 'bold' }}
                        readOnly
                      />
                    </div>

                    {courseData.isinstallment ? (
                      <div
                        id="wrapdiv"
                        // style={{
                        //   display: courseData.isinstallment ? 'block' : 'none',
                        // }}
                      >
                        <table
                          align="center"
                          cellSpacing={2}
                          cellPadding={5}
                          id="data_table"
                          border={1}
                          style={{ marginBottom: 20 }}
                        >
                          <tr>
                            <th>Installment No:</th>
                            <th>Installment Date</th>
                          </tr>
                          {courseData?.installment?.map((item, index) => (
                            <tr>
                              <td>
                                <input type="text" value={index + 1} readOnly />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  // name="startdate"
                                  // id={`installment[${index}].installment_Date`}
                                  name={`installment[${index}].installment_Date`}
                                  value={item?.installment_Date}
                                  // onChange={handleInstallmentChange}
                                  onChange={(event) =>
                                    handleInstallmentChange(index, event)
                                  }
                                  required
                                />
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <td>
                              <input
                                type="button"
                                // className="add"
                                onClick={editInstallmentRow}
                                value="Add Row"
                              />
                            </td>
                            <td>
                              <input
                                type="button"
                                // className="delete"
                                onClick={() =>
                                  deleteInstallmentRow(installment.length)
                                }
                                value="Delete Row"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="insinputval"
                                id="insvalue"
                                hidden
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div>
                      <button className="btn btn-primary" type="submit">
                        Edit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCourseForm
// import React from 'react'
// import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'

// const EditCourseForm = () => {
//   const validationSchema = Yup.object().shape({
//     coursename: Yup.string().required('Course Name is required.'),
//     startdate: Yup.date().required('Start Date is required.'),
//     enddate: Yup.date().required('End Date is required.'),
//     fee: Yup.number().required('Fee is required.'),
//     courselevel: Yup.string().required('Course Level is required.'),
//     commencement: Yup.string().required('Session Name is required.'),
//     age: Yup.number().required('Age is required.'),
//     studyMode: Yup.array().min(1, 'Study Mode is required.'),
//   })

//   const handleSubmit = (values) => {
//     // Handle form submission logic here
//     console.log('Form submitted with course data: ', values)
//   }

//   return (
//     <div className="page-wrapper">
//       <div className="content container-fluid">
//         <div className="page-header">
//           <div className="row align-items-center">
//             <div className="col">
//               <h3 className="page-title">Edit Course</h3>
//               <ul className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <a href="http://clc-london.uk/dashboard">Dashboard</a>
//                 </li>
//                 <li className="breadcrumb-item active">Edit Course</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-sm-12">
//             <div className="card">
//               <div className="card-body">
//                 <Formik
//                   initialValues={{
//                     coursename: '',
//                     startdate: '',
//                     enddate: '',
//                     fee: '',
//                     courselevel: '',
//                     commencement: '',
//                     age: '',
//                     studyMode: [],
//                     isinstallment: false,
//                   }}
//                   validationSchema={validationSchema}
//                   onSubmit={handleSubmit}
//                 >
//                   {({ isSubmitting }) => (
//                     <Form className="needs-validation" noValidate>
//                       <div className="form-row">
//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="coursename">Course Name</label>
//                           <Field
//                             type="text"
//                             className="form-control"
//                             id="coursename"
//                             placeholder=""
//                             name="coursename"
//                             required
//                           />
//                           <ErrorMessage
//                             name="coursename"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>

//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="startdate">Start Date</label>
//                           <Field
//                             type="date"
//                             className="form-control"
//                             id="startdate"
//                             name="startdate"
//                             required
//                           />
//                           <ErrorMessage
//                             name="startdate"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>

//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="enddate">End Date</label>
//                           <Field
//                             type="date"
//                             className="form-control"
//                             id="enddate"
//                             name="enddate"
//                             required
//                           />
//                           <ErrorMessage
//                             name="enddate"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>
//                       </div>

//                       <div className="form-row">
//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="fee">Fee</label>
//                           <div className="input-group-prepend input-group mb-2">
//                             <div className="input-group-text">£</div>
//                             <Field
//                               type="number"
//                               className="form-control"
//                               id="fee"
//                               placeholder=""
//                               name="fee"
//                               required
//                             />
//                           </div>
//                           <ErrorMessage
//                             name="fee"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>

//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="courselevel">Course Level</label>
//                           <Field
//                             as="select"
//                             className="form-control"
//                             name="courselevel"
//                             required
//                           >
//                             <option value="">Select</option>
//                             <option value="Under-Graduate">
//                               Under-Graduate
//                             </option>
//                             <option value="foundation">foundation</option>
//                           </Field>
//                           <ErrorMessage
//                             name="courselevel"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>

//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="commencement">Session Name</label>
//                           <Field
//                             as="select"
//                             className="form-control"
//                             name="commencement"
//                             required
//                           >
//                             <option value="">Select</option>
//                             <option value="fall-2023">fall-2023</option>
//                             <option value="fall-2024">fall-2024</option>
//                           </Field>
//                           <ErrorMessage
//                             name="commencement"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>
//                       </div>

//                       <div className="form-row">
//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="age">Age</label>
//                           <div className="input-group-prepend input-group mb-2">
//                             <div className="input-group-text">OVER</div>
//                             <Field
//                               type="number"
//                               className="form-control"
//                               id="age"
//                               placeholder=""
//                               name="age"
//                               required
//                             />
//                           </div>
//                           <ErrorMessage
//                             name="age"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>

//                         <div className="col-md-4 mb-3">
//                           <label htmlFor="studyMode">Study Mode</label>
//                           <Field
//                             as="select"
//                             className="form-control"
//                             name="studyMode"
//                             // multiple
//                             required
//                           >
//                             <option value="Under-Graduate">Full time</option>
//                             <option value="foundation">Part time</option>
//                             <option value="foundation">Blended Learning</option>
//                           </Field>
//                           <ErrorMessage
//                             name="studyMode"
//                             component="div"
//                             className="invalid-feedback"
//                           />
//                         </div>

//                         <div className="input-group mb-3">
//                           <div className="input-group-prepend">
//                             <div className="input-group-text">
//                               <Field
//                                 type="checkbox"
//                                 name="isinstallment"
//                                 aria-label="Checkbox for following text input"
//                               />
//                             </div>
//                           </div>
//                           <input
//                             type="text"
//                             className="form-control"
//                             aria-label="Text input with checkbox"
//                             value="Allow Installments"
//                             style={{ fontWeight: 'bold' }}
//                             readOnly
//                           />
//                         </div>

//                         {/* Add the installment table and buttons here */}
//                         {/* ... */}

//                         <div>
//                           <button
//                             className="btn btn-primary"
//                             type="submit"
//                             disabled={isSubmitting}
//                           >
//                             Edit
//                           </button>
//                         </div>
//                       </div>
//                     </Form>
//                   )}
//                 </Formik>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default EditCourseForm
// import React from 'react'
// import { Formik, Field, ErrorMessage, Form, FieldArray } from 'formik'
// import * as Yup from 'yup'
// import axios from 'axios'

// const EditCourseForm = () => {
//   const validationSchema = Yup.object().shape({
//     coursename: Yup.string().required('Course Name is required'),
//     startdate: Yup.date().required('Start Date is required'),
//     enddate: Yup.date().required('End Date is required'),
//     fee: Yup.number().required('Fee is required'),
//     courselevel: Yup.string().required('Course Level is required'),
//     commencement: Yup.string().required('Session Name is required'),
//     age: Yup.number().required('Age is required'),
//     // Add more validation for other fields if needed
//     installments: Yup.array().of(
//       Yup.object().shape({
//         insdate: Yup.date().required('Installment Date is required'),
//       })
//     ),
//   })

//   const initialValues = {
//     coursename: '',
//     startdate: '',
//     enddate: '',
//     fee: '',
//     courselevel: '',
//     commencement: '',
//     age: '',
//     mos: [],
//     installments: [{ insno: 0, insdate: '' }],
//   }

//   const handleSubmit = (values) => {
//     // Send data to update API
//     axios
//       .patch('your-api-endpoint-for-updating', values)
//       .then((response) => {
//         // Handle success
//         console.log('Course updated successfully')
//       })
//       .catch((error) => {
//         console.error('Error updating course:', error)
//       })
//   }

//   return (
//     <div className="page-wrapper">
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={handleSubmit}
//       >
//         {({ values, handleChange }) => (
//           <Form className="needs-validation">
//             <div className="form-row">
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="coursename">Course Name</label>
//                 <Field
//                   type="text"
//                   id="coursename"
//                   name="coursename"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="coursename"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="startdate">Start Date</label>
//                 <Field
//                   type="date"
//                   id="startdate"
//                   name="startdate"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="startdate"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="enddate">End Date</label>
//                 <Field
//                   type="date"
//                   id="enddate"
//                   name="enddate"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="enddate"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//             </div>
//             <div className="form-row">
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="fee">Fee</label>
//                 <div className="input-group-prepend input-group mb-2">
//                   <div className="input-group-text">£</div>
//                   <Field
//                     type="number"
//                     id="fee"
//                     name="fee"
//                     className="form-control"
//                   />
//                 </div>
//                 <ErrorMessage
//                   name="fee"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="courselevel">Course Level</label>
//                 <Field
//                   as="select"
//                   id="courselevel"
//                   name="courselevel"
//                   className="form-control"
//                 >
//                   <option value="">Select</option>
//                   <option value="Under-Graduate">Under-Graduate</option>
//                   <option value="foundation">Foundation</option>
//                 </Field>
//                 <ErrorMessage
//                   name="courselevel"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="commencement">Session Name</label>
//                 <Field
//                   as="select"
//                   id="commencement"
//                   name="commencement"
//                   className="form-control"
//                 >
//                   <option value="">Select</option>
//                   <option value="fall-2023">Fall 2023</option>
//                   <option value="fall-2024">Fall 2024</option>
//                 </Field>
//                 <ErrorMessage
//                   name="commencement"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//             </div>
//             <div className="form-row">
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="age">Age</label>
//                 <Field
//                   type="number"
//                   id="age"
//                   name="age"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="age"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//               <div className="col-md-4 mb-3">
//                 <label htmlFor="mos">Study Mode</label>
//                 <Field
//                   as="select"
//                   multiple
//                   id="mos"
//                   name="mos"
//                   className="form-control"
//                 >
//                   <option value="Full Time">Full Time</option>
//                   <option value="Part Time">Part Time</option>
//                   <option value="Blended Learning">Blended Learning</option>
//                 </Field>
//                 <ErrorMessage
//                   name="mos"
//                   component="div"
//                   className="invalid-feedback"
//                 />
//               </div>
//             </div>
//             <FieldArray name="installments">
//               {({ push, remove }) => (
//                 <div id="wrapdiv">
//                   <table
//                     align="center"
//                     cellSpacing={2}
//                     cellPadding={5}
//                     id="data_table"
//                     border={1}
//                     style={{ marginBottom: 20 }}
//                   >
//                     <tbody>
//                       {values.installments.map((_, index) => (
//                         <tr key={index}>
//                           <td>{index}</td>
//                           <td>
//                             <Field
//                               type="date"
//                               name={`installments.${index}.insdate`}
//                               className="form-control"
//                             />
//                             <ErrorMessage
//                               name={`installments.${index}.insdate`}
//                               component="div"
//                               className="invalid-feedback"
//                             />
//                           </td>
//                           <td>
//                             {index > 0 && (
//                               <button
//                                 type="button"
//                                 onClick={() => remove(index)}
//                               >
//                                 Delete
//                               </button>
//                             )}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                   <button
//                     type="button"
//                     onClick={() =>
//                       push({ insno: values.installments.length, insdate: '' })
//                     }
//                   >
//                     Add Row
//                   </button>
//                 </div>
//               )}
//             </FieldArray>
//             <button type="submit">Edit</button>
//           </Form>
//         )}
//       </Formik>
//     </div>
//   )
// }

// export default EditCourseForm
