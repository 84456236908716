import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { base_url } from '../../../utils/Api'

const UsersTable = () => {
  const [PerPageEntry, setPerPageEntry] = useState(10)
   const [load, setLoad] = useState(false)
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [data, setData] = useState([])
  const handleEditUser = (userId) => {
    // Handle logic for editing user with ID: userId
    console.log(`Edit user with ID: ${userId}`)
  }

  // const handleDeleteUser = (userId) => {
  //   // Handle logic for deleting user with ID: userId
  //   console.log(`Delete user with ID: ${userId}`)
  // }
  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    console.log('the current page is', currentPage)
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const [getUser, setUser] = useState([])
const getAllUsers = async () => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }

  try {
    const res = await axios.get(base_url + 'admin/getUser', config)
    console.log('the res is❤❤❤❤❤', res)

    const originalData = Object.values(res?.data?.data || [])

    console.log('Original Data:', originalData)

    const filteredData = originalData.filter((item) =>
      ['Name', 'Email', 'Phone'].some((field) =>
        item[field]?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )

    console.log('Filtered Data:', filteredData)

    setTotalLength(originalData.length)
    setTotalEntries(filteredData.length)
    setFilteredTotalEntries(filteredData.length)
    setUser(filteredData)
  } catch (err) {
    console.log('Error:', err)
  }
}
  useEffect(() => {
    getAllUsers()
  }, [data, searchQuery])
 const handleDeleteUser = async (_id) => {
   const confirmDelete = window.confirm(
     'Are you sure you want to delete this item?'
   )
   console.log("the id is",_id)
   if (confirmDelete) {
     try {
       const response = await axios.delete(`${base_url}user/deleteuser/${_id}`)
       console.log("the res ismalik",response)
       if (response.data.status === '201') {
         setLoad(!load)
         alert('User removed successfully')
       } else {
         alert('Failed to remove user')
       }
     } catch (error) {
       console.error('Error deleting User:', error)
       alert('An error occurred while deleting the User')
     }
   } else {
     alert('Deletion canceled')
   }
 }


    useEffect(() => {
      getAllUsers()
    }, [load])
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper" style={{ minHeight: '870px' }}>
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Users</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Users</li>
                  </ul>
                </div>
                <div className="col-auto text-right float-right ml-auto">
                  <Link to="#" className="btn btn-outline-primary mr-2 ms-2">
                    <i className="fas fa-download"></i> Download
                  </Link>
                  <Link
                    to="/admin/users/create"
                    className="btn btn-primary ms-2"
                  >
                    <i className="fas fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 mb-2">
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_0_length"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <label>Show </label>
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                                value={PerPageEntry}
                                onChange={(e) =>
                                  setPerPageEntry(parseInt(e.target.value))
                                }
                                style={{
                                  width: 60,
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div
                              id="DataTables_Table_0_filter"
                              className="dataTables_filter"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              <label>Search: </label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="DataTables_Table_0"
                                style={{ width: 200, marginLeft: 8 }}
                                value={searchQuery} // Bind the searchQuery to the input value
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              className="table table-hover table-center mb-0 datatable dataTable no-footer"
                              id="DataTables_Table_0"
                              role="grid"
                              aria-describedby="DataTables_Table_0_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="sorting_asc"
                                    tabIndex="0"
                                    aria-controls="DataTables_Table_0"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="User Name: activate to sort column descending"
                                    style={{ width: '488.854px' }}
                                  >
                                    User Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="DataTables_Table_0"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Role: activate to sort column ascending"
                                    style={{ width: '271.545px' }}
                                  >
                                    Role
                                  </th>
                                  <th
                                    className="text-right sorting"
                                    tabIndex="0"
                                    aria-controls="DataTables_Table_0"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Action: activate to sort column ascending"
                                    style={{ width: '452.969px' }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getUser
                                  .slice(startIndex, endIndex)
                                  .map((eachUser) => (
                                    <tr key={eachUser._id} role="row">
                                      <td>
                                        {Array.isArray(eachUser.username) ? (
                                          eachUser.username.map((eachRole) => (
                                            <span
                                              className="bg-dark text-white rounded px-2"
                                              style={{
                                                fontSize: '13px',
                                                marginRight: '5px',
                                              }}
                                            >
                                              {eachRole}
                                            </span>
                                          ))
                                        ) : (
                                          <span>{eachUser.username}</span>
                                        )}
                                      </td>
                                      <td>
                                        {Array.isArray(eachUser.Roles) ? (
                                          eachUser.Roles.map((eachRole) => (
                                            <span
                                              className="bg-dark text-white rounded px-2"
                                              style={{
                                                fontSize: '13px',
                                                marginRight: '5px',
                                              }}
                                            >
                                              {eachRole}
                                            </span>
                                          ))
                                        ) : (
                                          <span>{eachUser.Roles}</span>
                                        )}
                                      </td>

                                      <td className="text-right">
                                        <div className="actions">
                                          <Link
                                            to={`/admin/users/${eachUser._id}/edit`}
                                            className="btn btn-sm bg-success-light mr-2"
                                            style={{
                                              width: 30,
                                              marginLeft: 10,
                                              marginRight: 10,
                                            }}
                                          >
                                            <i className="fas fa-pen"></i>
                                          </Link>
                                          <a
                                            href="#"
                                            className="btn btn-sm bg-danger-light"
                                          >
                                            <form
                                              method="POST"
                                              action="admin/users/1"
                                              onsubmit="return confirm('Are you sure?');"
                                            >
                                              <input
                                                type="hidden"
                                                name="_token"
                                                value="fRDytL5B4HUIZGSAP9PXGrkDiz9B8ETyDiF5Ea4l"
                                              />
                                              <input
                                                type="hidden"
                                                name="_method"
                                                value="DELETE"
                                              />
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleDeleteUser(eachUser._id)
                                                }
                                                style={{
                                                  border: 'none',
                                                  padding: '0%',
                                                  backgroundColor: 'none',
                                                }}
                                              >
                                                <i className="fas fa-trash  btn-sm bg-danger-light"></i>
                                              </button>
                                            </form>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              {filteredTotalEntries === 0
                                ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                                : `Showing ${Math.min(
                                    startIndex + 1,
                                    endIndex
                                  )} to ${Math.min(
                                    endIndex,
                                    filteredTotalEntries
                                  )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="DataTables_Table_0_paginate"
                            >
                              <ul className="pagination justify-content-end">
                                <li
                                  className={`paginate_button page-item previous ${
                                    currentPage === 1 ? 'disabled' : ''
                                  }`}
                                  id="DataTables_Table_0_previous"
                                >
                                  <a
                                    href="#"
                                    // aria-controls="DataTables_Table_0"
                                    // data-dt-idx="0"
                                    // tabIndex="0"
                                    onClick={handlePreviousClick}
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                {totalEntries > 0
                                  ? Array.from({
                                      length: Math.max(
                                        1,
                                        Math.ceil(totalEntries / PerPageEntry)
                                      ),
                                    }).map((_, index) => (
                                      <li
                                        key={index}
                                        className={`paginate_button page-item ${
                                          currentPage === index + 1
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <button
                                          onClick={() =>
                                            setCurrentPage(index + 1)
                                          }
                                          className="page-link"
                                        >
                                          {index + 1}
                                        </button>
                                      </li>
                                    ))
                                  : null}
                                <li
                                  className={`paginate_button page-item next ${
                                    currentPage ===
                                    Math.ceil(totalEntries / PerPageEntry)
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  id="DataTables_Table_0_next"
                                >
                                  <a
                                    href="#"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    onClick={handleNextClick}
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2022.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default UsersTable
