import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import './EditApplication.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import img3 from '../../assets/images/uploadimg.png'
import { boolean } from 'yup'
import './EditApplication.css'
import { useSelector } from 'react-redux'
import '../style.css'
import { countries } from 'countries-list'
import generic_file_icon from '../../assets/images/generic_file.png'
import Pdf_file_icon from '../../assets/images/PDF_file_icon.png'
import word_icon from '../../assets/images/word_icon.png'
import { base_url } from '../../utils/Api'
import { base_url1 } from '../../utils/Api'
const countryOptions = [{ value: 'Korea', label: 'Korea' }]

const EditApplication = () => {
  // for progress bar
  const [myfile, setmyfile] = useState()
  const [valid, setValid] = useState(true)
  const [filePreviews, setFilePreviews] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [courseLabel, setCourseLabel] = useState('')
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState({
    family_name: '',
    first_name: '',
    previous_family_name: '',
    gender: '',
    home_address: '',
    post_code: '',
    home_tel_no: '',
    mobile: '',
    email: '',
    country_birth: '',
    nationality: '',
    // entry_date_uk: '',
    course: {
      // foundation_courses: [],
      // undergraduate_courses: [],
      course_name: '',
      mode_study: [],
      commencement: '',
      course_duration: '',
      fee_pay: '',
    },
    reference_percentage_table: {
      Intellectual_Academic_ability: [],
      English_communication: {
        oral: [],
        written: [],
      },
      Ability_to_work_hard: [],
      Perseverance: [],
      Leadership: [],
      Creativity: [],
      Concern_for_others: [],
    },
    academic_qualification: [
      {
        dateAwarded: '',
        board_institute: '',
        subject: '',
        qualification: '',
        results_achieved: '',
      },
    ],
    academic_qualification_examinations: [
      {
        dateAwarded: '',
        board_institute: '',
        subject: '',
        qualification: '',
        results_achieved: '',
      },
    ],
    english_your_first_language: '',
    english_language_proficiency: '',
    language_attend_course: '',
    Work_Experience_sub: [
      {
        date_from: '',
        fT_PT: '',
        post_held: '',
        rOrganisation_nam: '',
        brief_description: '',
      },
    ],
    Work_Experience: [
      {
        Date_from: '',
        Date_to: '',
        FT_PT: '',
        Post_held: '',
        ROrganisation_nam: '',
        Brief_description: '',
      },
    ],
    refer_name: '',
    refer_sign: '',
    refer_date: '',
    marketing_inf: '',
    equal_oppertunity_ethnic_origin: '',
    equal_oppertunity_Religion: '',
    app_sign: '',
    equal_oppertunity_date: '',
    reference_applicant_capacity: '',
    reference_applicant_opinion: '',
    reference_any_other_info: '',

    // // For Registry user validation
    registry_use_only: '',
    further_information: {
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: '',
      q7: '',
    },
  })
  // const countryOptions = Object.keys(countries).map((countryCode) => {
  //   const country = countries[countryCode]
  //   return {
  //     value: countryCode,
  //     label: country.name,
  //   }
  // })
  //label: country.name.charAt(0).toUpperCase() + country.name.slice(1),
  const countryOptions = Object.keys(countries)
    .map((countryCode) => {
      const country = countries[countryCode]
      return {
        value: countryCode,
        label: country.name,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    // const forms = document.getElementsByClassName('needs-validation')
    const forms = document.getElementById(`step${currentPage}-form`)
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        console.log('the validity is', form.checkValidity())
        setValid(form.checkValidity())
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
        }
        form.classList.add('was-validated')
      })
    })
    console.log('the validation is 👨👨👨👨👨👨👨', validation)
  }, [error])
  const { _id } = useParams()
  const [data, setData] = useState({
    family_name: '',
    first_name: '',
    title: '',
    previous_family_name: '',
    dob: '',
    gender: '',
    home_address: '',
    city: '',
    post_code: '',
    home_tel_no: '',
    mobile: '',
    email: '',
    country_birth: '',
    nationality: '',
    // entry_date_uk: '',
    course: {
      // foundation_courses: [],
      // undergraduate_courses: [],
      course_name: '',
      mode_study: [],
      commencement: '',
      course_duration: '',
      fee_pay: '',
    },
    reference_percentage_table: {
      Intellectual_Academic_ability: [],
      English_communication: {
        oral: [],
        written: [],
      },
      Ability_to_work_hard: [],
      Perseverance: [],
      Leadership: [],
      Creativity: [],
      Concern_for_others: [],
    },
    academic_qualification: [
      {
        dateAwarded: '',
        board_institute: '',
        subject: '',
        qualification: '',
        results_achieved: '',
      },
    ],
    academic_qualification_examinations: [
      {
        dateAwarded: '',
        board_institute: '',
        subject: '',
        qualification: '',
        results_achieved: '',
      },
    ],
    english_your_first_language: false,
    english_language_proficiency: '',
    language_attend_course: '',
    Work_Experience_sub: [
      {
        date_from: '',
        fT_PT: '',
        post_held: '',
        rOrganisation_nam: '',
        brief_description: '',
      },
    ],
    Work_Experience: [
      {
        Date_from: '',
        Date_to: '',
        FT_PT: '',
        Post_held: '',
        ROrganisation_nam: '',
        Brief_description: '',
      },
    ],
    refer_name: '',
    refer_sign: '',
    refer_date: '',
    marketing_inf: [{ label: '', value: '', checked: false }],
    equal_oppertunity_ethnic_origin: '',
    equal_oppertunity_Religion: '',
    app_sign: '',
    equal_oppertunity_date: '',
    reference_applicant_capacity: '',
    reference_applicant_opinion: '',
    reference_any_other_info: '',
    registry_use_only: '',
    further_information: {
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: '',
      q7: '',
    },
  })
  const [activeSteps, setActiveSteps] = useState([0])
  const [page, setPage] = useState('')
  const [state, setState] = useState({
    value: 0,
  })
  const steps = [
    { label: 'First' },
    { label: 'Second' },
    { label: 'Third' },
    { label: 'Fourth' },
    { label: 'Fifth' },
    { label: 'Sixth' },
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = 6 // Total number of pages in the slider

  const handlePageChange = (increment) => {
    const nextPage = currentPage + increment
    if (nextPage >= 0 && nextPage <= totalPages) {
      setCurrentPage(nextPage)
    }
  }

  const handleNext = (e) => {
    // handleSubmit()
    e.preventDefault()
    let errtxt = {
      family_name: '',
      first_name: '',
      dob: '',
      title: '',
      previous_family_name: '',
      gender: '',
      home_address: '',
      post_code: '',
      home_tel_no: '',
      mobile: '',
      email: '',
      country_birth: '',
      nationality: '',
      // entry_date_uk: '',
      course: {
        // foundation_courses: [],
        // undergraduate_courses: [],
        course_name: '',
        mode_study: [],
        commencement: '',
        course_duration: '',
        fee_pay: '',
      },
      reference_percentage_table: '',
      academic_qualification: [
        {
          dateAwarded: '',
          board_institute: '',
          subject: '',
          qualification: '',
          results_achieved: '',
        },
      ],
      academic_qualification_examinations: [
        {
          dateAwarded: '',
          board_institute: '',
          subject: '',
          qualification: '',
          results_achieved: '',
        },
      ],
      english_your_first_language: '',
      english_language_proficiency: '',
      language_attend_course: '',
      Work_Experience_sub: [
        {
          date_from: '',
          fT_PT: '',
          post_held: '',
          rOrganisation_nam: '',
          brief_description: '',
        },
      ],
      Work_Experience: [
        {
          Date_from: '',
          Date_to: '',
          FT_PT: '',
          Post_held: '',
          ROrganisation_nam: '',
          Brief_description: '',
        },
      ],
      refer_name: '',
      refer_sign: '',
      refer_date: '',
      marketing_inf: '',
      equal_oppertunity_ethnic_origin: '',
      equal_oppertunity_Religion: '',
      app_sign: '',
      equal_oppertunity_date: '',
      reference_applicant_capacity: '',
      reference_applicant_opinion: '',
      reference_any_other_info: '',

      // // For Registry user validation
      registry_use_only: '',
      further_information: {
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q6: '',
        q7: '',
      },
    }
    let errorr = false
    if (currentPage == 1) {
      if (data.family_name === '') {
        errtxt.family_name = 'please fil it'
        // setErrorText({ ...errorText, ['family_name']: 'please fil it' })

        errorr = true
      }
      if (data.first_name === '') {
        errtxt.first_name = 'please fil it'
        // setErrorText({ ...errorText, ['first_name']: 'please fil it' })
        errorr = true
      }
      if (data.previous_family_name === '') {
        errtxt.previous_family_name = 'please fil it'
        // setErrorText({
        //   ...errorText,
        //   ['previous_family_name']: 'please fil it',
        // })
        errorr = true
      }
      if (data.gender === '') {
        errtxt.gender = 'please fil it'
        // setErrorText({ ...errorText, ['gender']: 'please fil it' })
        errorr = true
      }
      if (data.home_address === '') {
        errtxt.home_address = 'please fil it'
        // setErrorText({ ...errorText, ['home_address']: 'please fil it' })
        errorr = true
      }
      if (data.post_code === '') {
        errtxt.post_code = 'please fil it'
        // setErrorText({ ...errorText, ['post_code']: 'please fil it' })
        errorr = true
      }
      if (data.home_tel_no === '') {
        errtxt.home_tel_no = 'please fil it'
        // setErrorText({ ...errorText, ['home_tel_no']: 'please fil it' })
        errorr = true
      }
      if (data.mobile === '') {
        errtxt.mobile = 'please fil it'
        // setErrorText({ ...errorText, ['mobile']: 'please fil it' })
        errorr = true
      }
      if (data.email === '') {
        errtxt.email = 'please fil it'
        // setErrorText({ ...errorText, ['email']: 'please fil it' })
        errorr = true
      }

      if (data.dob === '') {
        errtxt.dob = 'please fil it'
        // setErrorText({ ...errorText, ['dob']: 'please fil it' })
        errorr = true
      }
      if (data.title === '') {
        errtxt.title = 'please fil it'
        // setErrorText({ ...errorText, ['title']: 'please fil it' })
        errorr = true
      }
      if (data.city === '') {
        errtxt.city = 'please fil it'
        // setErrorText({ ...errorText, ['title']: 'please fil it' })
        errorr = true
      }
      if (data.country_birth === '') {
        errtxt.country_birth = 'please fil it'
        // setErrorText({ ...errorText, ['country_birth']: 'please fil it' })
        errorr = true
      }
      if (data.nationality === '') {
        errtxt.nationality = 'please fil it'
        // setErrorText({ ...errorText, ['nationality']: 'please fil it' })
        errorr = true
      }
      if (data.entry_date_uk === '') {
        errtxt.entry_date_uk = 'please fil it'
        // setErrorText({ ...errorText, ['entry_date_uk']: 'please fil it' })
        errorr = true
      }
      setErrorText(errtxt)
    }
    if (currentPage == 2) {
      if (data.course.commencement === '') {
        errtxt.course.commencement = 'please fil it'
        // setErrorText({ ...errorText, ['commencement']: 'please fil it' })
        errorr = true
      }
      if (data.course.course_duration === '') {
        errtxt.course.course_duration = 'please fil it'
        // setErrorText({ ...errorText, ['commencement']: 'please fil it' })
        errorr = true
      }
      if (data.course.course_name === '') {
        errtxt.course.course_name = 'please fil it'
        // setErrorText({ ...errorText, ['commencement']: 'please fil it' })
        errorr = true
      }
      if (data.course.mode_study === '') {
        errtxt.course.mode_study = 'please fil it'
        // setErrorText({ ...errorText, ['commencement']: 'please fil it' })
        errorr = true
      }
      if (data.course.fee_pay === '') {
        errtxt.course.fee_pay = 'please fil it'
        // setErrorText({ ...errorText, ['commencement']: 'please fil it' })
        errorr = true
      }
      setErrorText(errtxt)
    }

    if (currentPage === 3) {
      // Check academic_qualification_examinations array
      data.academic_qualification_examinations?.map((item) => {
        if (
          item.dateAwarded === '' ||
          item.board_institute === '' ||
          item.qualification === '' ||
          item.results_achieved === '' ||
          item.subject === ''
        ) {
          errorr = true
        }
      })

      // Check academic_qualification array
      data.academic_qualification?.map((item) => {
        if (
          item.board_institute === '' ||
          item.dateAwarded === '' ||
          item.qualification === '' ||
          item.results_achieved === '' ||
          item.subject === ''
        ) {
          errorr = true
        }
      })

      // Update errtxt based on errorr
      // const errtxt = errorr ? 'Validation failed. Please fill in all fields.' : '';
      setErrorText(errtxt)

      // Proceed to the next step only if there are no validation errors
      if (!errorr) {
        // Your logic to proceed to the next step
      }

      setErrorText(errtxt)
    }
    if (currentPage === 4) {
      data.Work_Experience?.map((item) => {
        if (
          item.Date_from === '' ||
          item.Date_to === '' ||
          item.FT_PT === '' ||
          item.Post_held === '' ||
          item.ROrganisation_nam === '' ||
          item.Brief_description === ''
        ) {
          errorr = true
        }
      })

      data.Work_Experience_sub?.map((item) => {
        if (
          item.date_from === '' ||
          item.Date_to === '' ||
          item.fT_PT === '' ||
          item.post_held === '' ||
          item.rOrganisation_nam === '' ||
          item.brief_description === ''
        ) {
          errorr = true
        }
      })

      setErrorText(errtxt)
    }
    if (currentPage === 5) {
      if (data.further_information.q1 === '') {
        errtxt.further_information.q1 = 'please fil it'
        // setErrorText({ ...errorText, ['q1']: 'please fil it' })
        errorr = true
      }
      if (data.further_information.q2 === '') {
        errtxt.further_information.q2 = 'please fil it'
        // setErrorText({ ...errorText, ['q2']: 'please fil it' })
        errorr = true
      }
      if (data.further_information.q3 === '') {
        errtxt.further_information.q3 = 'please fil it'
        // setErrorText({ ...errorText, ['q3']: 'please fil it' })
        errorr = true
      }
      if (data.further_information.q4 === '') {
        errtxt.further_information.q4 = 'please fil it'
        // setErrorText({ ...errorText, ['q4']: 'please fil it' })
        errorr = true
      }
      if (data.further_information.q5 === '') {
        errtxt.further_information.q5 = 'please fil it'
        // setErrorText({ ...errorText, ['q5']: 'please fil it' })
        errorr = true
      }
      if (data.further_information.q6 === '') {
        errtxt.further_information.q6 = 'please fil it'
        // setErrorText({ ...errorText, ['q6']: 'please fil it' })
        errorr = true
      }
      if (data.further_information.q7 === '') {
        errtxt.further_information.q7 = 'please fil it'
        // setErrorText({ ...errorText, ['q7']: 'please fil it' })
        errorr = true
      }
      if (data.reference_applicant_capacity === '') {
        errtxt.reference_applicant_capacity = 'please fil it'

        errorr = true
      }
      if (data.reference_applicant_opinion === '') {
        errtxt.reference_applicant_opinion = 'please fil it'

        errorr = true
      }
      if (data.refer_name === '') {
        errtxt.refer_name = 'please fil it'
        // setErrorText({ ...errorText, ['refer_name']: 'please fil it' })
        errorr = true
      } else if (data.refer_sign === '') {
        errtxt.refer_sign = 'please fil it'
        // setErrorText({ ...errorText, ['refer_sign']: 'please fil it' })
        errorr = true
      } else if (data.refer_date === '') {
        errtxt.refer_date = 'please fil it'
        // setErrorText({ ...errorText, ['refer_date']: 'please fil it' })
        errorr = true
      }

      setErrorText(errtxt)
    }
    console.log('error', errorr)

    if (!errorr) {
      handlePageChange(1)
      setActiveSteps((prevActiveSteps) =>
        prevActiveSteps[prevActiveSteps.length - 1] < steps.length - 1
          ? [
              ...prevActiveSteps,
              prevActiveSteps[prevActiveSteps.length - 1] + 1,
            ]
          : prevActiveSteps
      )
      setState({ ...state, value: state.value + 1 })
    }
  }

  const handlePrevious = () => {
    handlePageChange(-1)
    setActiveSteps((prevActiveSteps) =>
      prevActiveSteps.length > 1
        ? prevActiveSteps.slice(0, prevActiveSteps.length - 1)
        : prevActiveSteps
    )
    setState({ ...state, value: state.value - 1 })
  }
  const getFoundationCourse = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        console.log('the resp is------------', res)
        let underCourse = []
        let foundCourse = []
        res?.data?.data?.map((item) => {
          console.log('item is matching is', item)
          if (item?.course_Name === data?.course?.course_name) {
            setCourseLabel(item.course_Level)
          }
        })
      })
      .catch((err) => console.log('error is---', err))
  }

  useEffect(() => {
    getAppFormById()
    getFoundationCourse()
  }, [_id])
  const getAppFormById = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        console.log('res is--- 🤣🤣🤣', res)
        setData({
          files: res?.data?.applications?.files,
          family_name:
            res?.data?.applications?.Personal_Details?.Family_Name_In_Passport,
          first_name:
            res?.data?.applications?.Personal_Details?.First_Name_In_Passport,
          title: res?.data?.applications?.Personal_Details?.Title,
          // previous_family_name:
          // res?.data?.applications?.Personal_Details?.Pervious_Family_Name,
          dob: res?.data?.applications?.Personal_Details?.Date_Of_Birth,
          gender: res?.data?.applications?.Personal_Details?.Gender,
          home_address:
            res?.data?.applications?.Personal_Details?.Home_Permanent_Address,
          city: res?.data?.applications?.Personal_Details?.City,
          post_code: res?.data?.applications?.Personal_Details?.Post_Code,
          home_tel_no: res?.data?.applications?.Personal_Details?.Home_Tel_No,
          mobile: res?.data?.applications?.Personal_Details?.Modile_No,
          email: res?.data?.applications?.Personal_Details?.Email,
          country_birth:
            res?.data?.applications?.Personal_Details?.Coutry_Of_Birth,
          nationality: res?.data?.applications?.Personal_Details?.Nationality,
          // _date_uk:
          //   res?entry.data?.applications?.Personal_Details?.Entrty_Date_UK,
          course: res?.data?.applications?.Course,
          reference_percentage_table:
            res?.data?.applications?.Reference?.Reference_Tabel,
          academic_qualification:
            res?.data?.applications?.Academic_Qua_Exam_Pending,
          academic_qualification_examinations:
            res?.data?.applications?.Academic_Qua_Exam_Achieved,
          english_your_first_language: false,
          english_language_proficiency:
            res?.data?.applications?.English_Language
              ?.English_Language_Proficiency,
          language_attend_course:
            res?.data?.applications?.English_Language
              ?.English_Language_Attend_Course,
          Work_Experience_sub:
            res?.data?.applications?.Work_Experience?.currently_employed,
          Work_Experience:
            res?.data?.applications?.Work_Experience?.work_experience,
          refer_name: res?.data?.applications?.Reference?.Refrees_Name,
          refer_sign: res?.data?.applications?.Reference?.Refrees_Sign,
          refer_date: res?.data?.applications?.Reference?.Date,
          marketing_inf: res?.data?.applications?.Marketing_Information,
          equal_oppertunity_ethnic_origin:
            res?.data?.applications?.Equal_Oppertunity_Monitoring
              ?.Ethnic_Origin[0],
          equal_oppertunity_Religion:
            res?.data?.applications?.Equal_Oppertunity_Monitoring?.Religion[0],
          app_sign:
            res?.data?.applications?.Equal_Oppertunity_Monitoring?.Declaration
              ?.Applicant_Signature,
          equal_oppertunity_date:
            res?.data?.applications?.Equal_Oppertunity_Monitoring?.Declaration
              ?.Date,
          reference_applicant_capacity:
            res?.data?.applications?.Reference?.Note_To_Refree
              ?.Applicant_Capacity,
          reference_applicant_opinion:
            res?.data?.applications?.Reference?.Note_To_Refree
              ?.Applicant_Opinion,
          reference_any_other_info:
            res?.data?.applications?.Reference?.Any_Other_Info,

          // // For Registry user validation
          registry_use_only: res?.data?.applications?.For_Register_Use_Only,
          further_information: {
            q1: res?.data?.applications?.Further_Information?.q1,
            q2: res?.data?.applications?.Further_Information?.q2,
            q3: res?.data?.applications?.Further_Information?.q3,
            q4: res?.data?.applications?.Further_Information?.q4,
            q5: res?.data?.applications?.Further_Information?.q5,
            q6: res?.data?.applications?.Further_Information?.q6,
            q7: res?.data?.applications?.Further_Information?.q7,
          },
        })
      })
      .catch((err) => console.log('error is---', err))
  }
  console.log('the data is😉😉😉', data)
  console.log('the fjsklfjsdklfjsdklf👨‍🦱👨‍🦱👨‍🦱👨‍🦱👨‍🦱', errorText)

  // for subform valdation

  const handleInputChange = (event) => {
    const { name, value } = event.target
    console.log('the name is', event.target.value)
    setData({
      ...data,
      [name]: value,
    })
    setData((prevData) => ({
      ...prevData,
      course: {
        ...prevData.course,
        [name]: value,
      },
      further_information: {
        ...prevData.further_information,
        [name]: value,
      },
    }))
  }

  const handleArrayChange = (type, index, field, value) => {
    console.log('type is', type)
    if (type === 'achieved') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.academic_qualification_examinations[index][field] = value
        return newData
      })
    } else if (type === 'pending') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.academic_qualification[index][field] = value
        return newData
      })
    } else if (type === 'work_exp') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.Work_Experience[index][field] = value
        return newData
      })
    } else if (type === 'sub_work_exp') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.Work_Experience_sub[index][field] = value
        return newData
      })
    }
  }

  const handleSelectedValue = (category, subcategory, value) => {
    setData((prevData) => {
      const newData = { ...prevData }

      if (subcategory) {
        newData.reference_percentage_table[category][subcategory] = value
      } else {
        newData.reference_percentage_table[category] = value
      }

      return newData
    })
  }

  const handleRemoveItem = (index, type) => {
    if (type === 'achieved') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.academic_qualification_examinations.splice(index, 1)
        return newData
      })
    } else if (type === 'pending') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.academic_qualification.splice(index, 1)
        return newData
      })
    } else if (type === 'work_exp') {
      setData((prevData) => {
        const newData = { ...prevData }
        newData.Work_Experience.splice(index, 1)
        return newData
      })
    }
  }

  const handleCheckboxChange = (index) => {
    setData((prevData) => {
      const newData = { ...prevData }
      newData.marketing_inf[index].checked =
        !newData.marketing_inf[index].checked
      return newData
    })
  }

  const addRow = (type) => {
    if (type === 'achieved') {
      setData({
        ...data,
        academic_qualification_examinations: [
          ...data.academic_qualification_examinations,
          {
            board_institute: '',
            dateAwarded: '',
            qualification: '',
            results_achieved: '',
            subject: '',
          },
        ],
      })
    } else if (type === 'pending') {
      setData({
        ...data,
        academic_qualification: [
          ...data.academic_qualification,
          {
            Brief_description: 'f',
            Date_from: '',
            Date_to: '',
            FT_PT: '',
            Post_held: '',
            ROrganisation_nam: '',
          },
        ],
      })
    }
    if (type === 'work_exp') {
      setData({
        ...data,
        Work_Experience: [
          ...data.Work_Experience,
          {
            board_institute: '',
            dateAwarded: '',
            qualification: '',
            subject: '',
          },
        ],
      })
    }
  }
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    setmyfile(files)
    console.log('files', files)

    setSelectedFiles([...selectedFiles, ...event.target.files])
    // const previews = files.map((file) => URL.createObjectURL(file))
    // setFilePreviews([...filePreviews, ...previews])
    const previews = files.map((file) => {
      return {
        file: file,
        preview: file.type.includes('image') ? URL.createObjectURL(file) : null,
      }
    })
    setFilePreviews([...filePreviews, ...previews])
  }
  const formData = new FormData()
  const update_data = {
    Personal_Details: {
      Family_Name_In_Passport: data.family_name,
      First_Name_In_Passport: data.first_name,
      Title: data.title,
      // Pervious_Family_Name: data.family_name,
      Date_Of_Birth: data.dob,
      Gender: data.gender,
      Home_Permanent_Address: data.home_address,
      City: data.city,
      Post_Code: data.post_code,
      Home_Tel_No: data.home_tel_no,
      Modile_No: data.mobile,
      Email: data.email,
      Coutry_Of_Birth: data.country_birth,
      Nationality: data.nationality,
      // Entrty_Date_UK: data.entry_date_uk,
    },
    Course: data.course,
    Academic_Qua_Exam_Achieved: data.academic_qualification_examinations,
    Academic_Qua_Exam_Pending: data.academic_qualification,
    English_Language: {
      English_Your_First_Language: data.english_your_first_language
        ? 'Yes'
        : 'No',
      English_Language_Attend_Course: data.language_attend_course,
      English_Language_Proficiency: data.english_language_proficiency,
    },
    Work_Experience: {
      work_experience: data.Work_Experience,
      currently_employed: data.Work_Experience_sub,
    },
    Further_Information: data.further_information,
    Reference: {
      Note_To_Refree: {
        Applicant_Capacity: data.reference_applicant_capacity,
        Applicant_Opinion: data.reference_applicant_opinion,
      },
      Reference_Tabel: data.reference_percentage_table,
      Any_Other_Info: data.reference_any_other_info,
      Refrees_Name: data.refer_name,
      Refrees_Sign: data.refer_sign,
      Date: data.refer_date,
    },
    Marketing_Information: data.marketing_inf,
    Equal_Oppertunity_Monitoring: {
      Ethnic_Origin: data.equal_oppertunity_ethnic_origin,
      Religion: data.equal_oppertunity_Religion,
      Declaration: {
        Applicant_Signature: data.app_sign,
        Date: data.equal_oppertunity_date,
      },
    },
  }
  const handleUpdate = async () => {
    for (const file of selectedFiles) {
      console.log('the fiels are', file)
      formData.append('allfiles', file)
    }
    formData.append('data', JSON.stringify(data))
    try {
      if (update_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        await axios
          // .put(`http://clc-london.uk/api/admin/updateApplication/${_id}`, update_data, config)
          .put(base_url + `admin/updateApplication/${_id}`, formData, config)
          .then((res) => {
            console.log('res is--- 🤣🤣🤣', res)
          })
          .catch((err) => console.log('error is---', err))
      }
    } catch (error) {}
  }

  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* progressbar */}
            <div className="stepper-wrapper">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`stepper-item ${
                    activeSteps.includes(index)
                      ? 'active'
                      : index < activeSteps[0]
                      ? 'completed'
                      : ''
                  }`}
                >
                  <div
                    className={`step ${
                      activeSteps.includes(index) ? 'active-circle' : ''
                    }`}
                  >
                    {index + 1}
                  </div>
                  <div className="step-name">{step.label}</div>
                </div>
              ))}
            </div>
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">
                    Edit Application For Admission{' '}
                    <small className="fs-5" style={{ fontSize: '15px' }}>
                      (Higher Education Courses)
                    </small>
                  </h3>
                  <small className="fs-5" style={{ fontSize: '15px' }}>
                    (Please read the collage prospectus and consult the website
                    before completing this form)
                  </small>
                </div>
              </div>
            </div>
            <form
              id={`step${currentPage}-form`}
              className="needs-validation"
              // onSubmit= {valid && handleSubmit}
              noValidate
              // encType="multipart/form-data"
            >
              {state.value === 0 && currentPage === 1 && (
                <div>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            <input
                              type="file"
                              className="form-control"
                              style={{
                                width: '0px',
                                height: '0px',
                                padding: '0px',
                              }}
                            />
                            <h5 className="card-title mb-2 font-weight-bold">
                              Personal Details
                            </h5>
                          </div>
                          {/*{currentPage === 1 &&
                          <form className="needs-validation" onSubmit={handleSubFormSubmit} noValidate> */}
                          <div className="card-body">
                            <div className="form-row">
                              <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom01">
                                  Family name as appears in passport:
                                </label>
                                <input
                                  type="text"
                                  name="family_name"
                                  className={`form-control ${
                                    errorText.family_name &&
                                    (!data?.family_name
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  id="validationCustom01"
                                  placeholder
                                  value={data?.family_name}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                {/* /* {data?.family_name ? (
                                  <></>
                                // ) : ( */}
                                {/* <div> */}
                                {/* This field is required.  */}
                                {/* {errorText.family_name}  */}
                                {/* </div> */}
                                {/* )} */}
                              </div>
                              <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom02">
                                  First name as appears in passport:
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  className={`form-control ${
                                    errorText.first_name &&
                                    (!data?.first_name
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  id="validationCustom02"
                                  placeholder
                                  value={data?.first_name}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                                {/* {data.first_name === '' && ( */}
                                {/* {data?.first_name ? (
                                  <></>
                                ) : (
                                  <div>{errorText.first_name}</div>
                                )} */}
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-lg-5">
                                <label className="col-lg- col-form-label">
                                  Select title(Mr/Mrs/Miss/Ms/etc){' '}
                                </label>
                                <div className="col-lg-  ">
                                  <select
                                    className={`form-control ${
                                      errorText.title &&
                                      (!data?.title ? 'is-invalid' : 'is-valid')
                                    }`}
                                    name="title"
                                    value={data.title}
                                    onChange={handleInputChange}
                                  >
                                    <option selected hidden>
                                      {/* Mr */}
                                    </option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Ms">Ms</option>
                                  </select>
                                </div>
                              </div>

                              <div className="form-group col-lg-5 ">
                                <label className="col-form-label">
                                  Date of Birth:{' '}
                                </label>
                                <div className>
                                  <input
                                    type="date"
                                    name="dob"
                                    id="dateofbirth"
                                    className={`form-control ${
                                      errorText.dob &&
                                      (!data?.dob ? 'is-invalid' : 'is-valid')
                                    }`}
                                    value={data.dob}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                </div>
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                {/* {data?.dob ? <></> : <div>{errorText.dob}</div>} */}
                                <div className="valid-feedback">
                                  Looks good.
                                </div>
                              </div>
                              <div
                                className="form-group col-lg-2"
                                style={{ width: '100px' }}
                              >
                                <label className="col-lg- col-form-label">
                                  Gender{' '}
                                </label>
                                <div className="col-lg-  ">
                                  <select
                                    className={`form-control ${
                                      errorText.gender &&
                                      (!data?.gender
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    name="gender"
                                    value={data.gender}
                                    onChange={handleInputChange}
                                  >
                                    <option selected hidden></option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom03">
                                  Home/Permanent address
                                </label>
                                <input
                                  type="text"
                                  name="home_address"
                                  className={`form-control ${
                                    errorText.home_address &&
                                    (!data?.home_address
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  id="validationCustom03"
                                  placeholder
                                  value={data.home_address}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  required
                                />
                                {errorText.home_address &&
                                  !data?.home_address && (
                                    <div className="invalid-feedback">
                                      This field is required.
                                    </div>
                                  )}
                                {errorText.home_address &&
                                  data?.home_address && (
                                    <div className="valid-feedback">
                                      Looks good.
                                    </div>
                                  )}
                              </div>
                              <div className="col-md-3 mb-3">
                                <label htmlFor="validationCustom04">City</label>
                                <input
                                  type="text"
                                  name="city"
                                  className={`form-control ${
                                    errorText.city &&
                                    (!data?.city ? 'is-invalid' : 'is-valid')
                                  }`}
                                  id="validationCustom04"
                                  placeholder
                                  value={data?.city}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                <div className="valid-feedback">
                                  Looks good.
                                </div>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label htmlFor="validationCustom05">
                                  Postal Code
                                </label>
                                <input
                                  type="text"
                                  name="post_code"
                                  className={`form-control ${
                                    errorText.post_code &&
                                    (!data?.post_code
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  id="validationCustom05"
                                  placeholder
                                  value={data?.post_code}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                <div className="valid-feedback">
                                  Looks good.
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-3 mb-3">
                                <label htmlFor="validationCustom03">
                                  Home Tel no:
                                </label>
                                <input
                                  type="number"
                                  name="home_tel_no"
                                  className={`form-control ${
                                    errorText.home_tel_no &&
                                    (!data?.home_tel_no
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  id="validationCustom03"
                                  placeholder
                                  value={data?.home_tel_no}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                {/* {data?.dob ? <></> : <div>{errorText.dob}</div>} */}
                                <div className="valid-feedback">
                                  Looks good.
                                </div>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label htmlFor="validationCustom04">
                                  Mobile:
                                </label>
                                <input
                                  type="number"
                                  name="mobile"
                                  className={`form-control ${
                                    errorText.mobile &&
                                    (!data?.mobile ? 'is-invalid' : 'is-valid')
                                  }`}
                                  id="validationCustom04"
                                  placeholder
                                  value={data?.mobile}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                                <div className="valid-feedback">
                                  Looks good.
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom05">
                                  Email:
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  className="form-control "
                                  id="validationCustom05"
                                  placeholder
                                  value={data?.email}
                                  onChange={handleInputChange}
                                  defaultValue=""
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom06">
                                  Country of Birth:
                                </label>
                                <select
                                  className={`form-control ${
                                    errorText.country_birth &&
                                    (!data?.country_birth
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  name="country_birth"
                                  value={data?.country_birth}
                                  onChange={handleInputChange}
                                >
                                  <option value={data?.country_birth}  selected>{data?.country_birth}</option>
                                  {countryOptions.map((option) => (
                                    <option
                                      style={{ textTransform: 'capitalize' }}
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom06">
                                  Nationality:
                                </label>
                                <select
                                  className={`form-control ${
                                    errorText.nationality &&
                                    (!data?.nationality
                                      ? 'is-invalid'
                                      : 'is-valid')
                                  }`}
                                  name="nationality"
                                  value={data?.nationality}
                                  onChange={handleInputChange}
                                >
                                  <option value={data?.nationality}   selected>{data?.nationality}</option>
                                  {countryOptions?.map((option) => (
                                    <option
                                      style={{ textTransform: 'capitalize' }}
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {state.value === 1 && currentPage === 2 && !error && (
                <div className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-2 font-weight-bold">
                            Course:
                            <small
                              className="fs-5"
                              style={{ fontSize: '15px' }}
                            >
                              (Please indicate the code you wish to apply)
                            </small>
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm">
                              <div className="form-row">
                                <div className="col-md-4 mb-3">
                                  <label htmlFor="validationCustom05">
                                    {courseLabel && courseLabel}
                                  </label>
                                  <input
                                    type="text"
                                    name="undergraduate_courses"
                                    defaultValue=""
                                    value={data?.course?.course_name}
                                    className="form-control"
                                    onChange={(e) => {
                                      const newValue =
                                        e.target.value.split(', ') // Split back into an array
                                      handleInputChange({
                                        target: {
                                          name: 'undergraduate_courses',
                                          value: newValue,
                                        },
                                      })
                                    }}
                                    placeholder
                                    readOnly
                                  />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label htmlFor="validationCustom05">
                                    Course Level
                                  </label>
                                  <input
                                    type="text"
                                    name="course_duration"
                                    value={data?.course?.course_duration}
                                    defaultValue=""
                                    className="form-control"
                                    placeholder
                                    onChange={(e) => {
                                      handleInputChange({
                                        target: {
                                          name: 'course_duration',
                                          value: e.target.value,
                                        },
                                      })
                                    }}
                                    readOnly
                                  />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label htmlFor="validationCustom05">
                                    Commencement
                                  </label>
                                  <input
                                    type="text"
                                    name="commencement"
                                    value={data?.course?.commencement}
                                    defaultValue=""
                                    className="form-control"
                                    placeholder
                                    onChange={(e) => {
                                      handleInputChange({
                                        target: {
                                          name: 'commencement',
                                          value: e.target.value,
                                        },
                                      })
                                    }}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-lg-4">
                                  <label className="col-lg- col-form-label">
                                    Mode of studies:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      name="mode_study"
                                      className={`form-control ${
                                        errorText.course.mode_study &&
                                        (!data?.course.mode_study
                                          ? 'is-invalid'
                                          : 'is-valid')
                                      }`}
                                      value={data?.course.mode_study}
                                      onChange={(e) => {
                                        handleInputChange({
                                          target: {
                                            name: 'mode_study',
                                            value: e.target.value,
                                          },
                                        })
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Full-time">
                                        Full Time
                                      </option>
                                      <option value="Part-time">
                                        Part Time
                                      </option>
                                    </select>
                                    <div className="invalid-feedback">
                                      This field is required.
                                    </div>

                                    <div className="valid-feedback">
                                      Looks good.
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group col-lg-4">
                                  <label className="col-lg- col-form-label">
                                    Who will pay your fees?:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className={`form-control ${
                                        errorText.course.fee_pay &&
                                        (!data?.course.fee_pay
                                          ? 'is-invalid'
                                          : 'is-valid')
                                      }`}
                                      name="fee_pay"
                                      value={data?.course?.fee_pay}
                                      onChange={(e) => {
                                        handleInputChange({
                                          target: {
                                            name: 'fee_pay',
                                            value: e.target.value,
                                          },
                                        })
                                      }}
                                    >
                                      <option value="">Select</option>
                                      <option value="Parents">Parents</option>
                                      <option value="Family member">
                                        Family member
                                      </option>
                                      <option value="Employer">Employer</option>
                                      <option value="Self">Self</option>
                                      <option value="SLC Funded">
                                        SLC Funded
                                      </option>
                                    </select>
                                    <div className="invalid-feedback">
                                      This field is required.
                                    </div>

                                    <div className="valid-feedback">
                                      Looks good.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* page 2nd end */}
              {/* page 3rd start */}
              {state.value === 2 && currentPage === 3 && !error && (
                <div className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-2 font-weight-bold">
                            Academic Qualification - examination achived
                          </h5>
                          <small className="fs-5" style={{ fontSize: '15px' }}>
                            Please list all qualifications taken,whatever the
                            result, in chronological order, Documentary evidance
                            of known result should be submitted with this form.
                            Only certified photocopies are acceptable
                          </small>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm">
                              <div className="myTable">
                                <div className="form-row">
                                  <div className="form-group col-lg-2">
                                    <label className="col-lg- col-form-label">
                                      Exams Date
                                    </label>
                                  </div>
                                  <div className="form-group col-lg-3">
                                    <label className="col-lg- col-form-label">
                                      Examing Board/Institution:
                                    </label>
                                  </div>
                                  <div className="form-group col-lg-2 ">
                                    <label className=" col-form-label">
                                      Subjects:{' '}
                                    </label>
                                  </div>
                                  <div className="form-group col-lg-2 ">
                                    <label className=" col-form-label">
                                      Qualification
                                    </label>
                                  </div>
                                  <div className="form-group col-lg-2 ">
                                    <label className=" col-form-label">
                                      Result achived
                                    </label>
                                  </div>
                                </div>
                                <div id="cell"> </div>
                                <div
                                  className="col-auto"
                                  style={{ padding: '1px' }}
                                >
                                  <input
                                    type="hidden"
                                    name="eanumberinput"
                                    defaultValue=""
                                    id="eanoinput"
                                    className="form-control"
                                    placeholder="Phone #"
                                  />
                                </div>
                                <table id="emptbl1" className="tbl1">
                                  <tbody>
                                    {data?.academic_qualification_examinations?.map(
                                      (item, index) => (
                                        <tr>
                                          <td
                                            id="colt0"
                                            className="col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="date"
                                                name="dateAwarded"
                                                value={item.dateAwarded}
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification_examinations &&
                                                  (!item.dateAwarded
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'achieved',
                                                    index,
                                                    'dateAwarded',
                                                    e.target.value
                                                  )
                                                }
                                                // id="inputPassword2"
                                              />
                                            </div>
                                            <div className="invalid-feedback">
                                              This field is required.
                                            </div>

                                            <div className="valid-feedback">
                                              Looks good.
                                            </div>
                                          </td>
                                          <td
                                            id="colt1"
                                            className="col-lg-3 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="board_institute"
                                                value={item.board_institute}
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification_examinations &&
                                                  (!item.board_institute
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'achieved',
                                                    index,
                                                    'board_institute',
                                                    e.target.value
                                                  )
                                                }
                                                placeholder
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="colt2"
                                            className="col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="subject"
                                                value={item.subject}
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification_examinations &&
                                                  (!item.subject
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'achieved',
                                                    index,
                                                    'subject',
                                                    e.target.value
                                                  )
                                                }
                                                id="inputPassword2"
                                                placeholder
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="colt3"
                                            className="col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="qualification"
                                                value={item.qualification}
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification_examinations &&
                                                  (!item.qualification
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'achieved',
                                                    index,
                                                    'qualification',
                                                    e.target.value
                                                  )
                                                }
                                                id="inputPassword2"
                                                placeholder
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="colt4"
                                            className="col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="results_achieved"
                                                value={item.results_achieved}
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification_examinations &&
                                                  (!item.results_achieved
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'achieved',
                                                    index,
                                                    'results_achieved',
                                                    e.target.value
                                                  )
                                                }
                                                placeholder
                                              />
                                            </div>
                                          </td>
                                          <td id="colt6">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleRemoveItem(
                                                  index,
                                                  'achieved'
                                                )
                                              }
                                              className="btn btn-danger "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-trash"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path
                                                  fillRule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                />
                                              </svg>
                                            </button>
                                          </td>
                                          {/* <td id="colt5">
                                            <button
                                              type="button"
                                              // onclick="eadeleteRows(this)"

                                              className="btn btn-danger "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-trash"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path
                                                  fillRule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                />
                                              </svg>
                                            </button>
                                          </td> */}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                                <div
                                  id="eaadd"
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '10px',
                                    marginBottom: '30px',
                                  }}
                                >
                                  {' '}
                                  <button
                                    type="button"
                                    // value="Add Row"
                                    style={{ width: '70%' }}
                                    onClick={() => addRow('achieved')}
                                    className="btn btn-primary "
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-plus-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>
                                  </button>{' '}
                                </div>
                              </div>
                              <h5 className="card-title mb-2 font-weight-bold">
                                Academic Qualification - examination Pending
                              </h5>
                              <small
                                className="fs-5"
                                style={{ fontSize: '15px' }}
                              >
                                Complete the section only if you are awaiting
                                the results of any examination taken recently
                              </small>
                              <hr />
                              <div className="mynewTable">
                                <div className="form-row">
                                  <div className="form-group col-lg-2">
                                    <label className="col-lg- col-form-label">
                                      Exams Date
                                    </label>
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="col-lg- col-form-label">
                                      Examing Board/Institution:
                                    </label>
                                  </div>
                                  <div
                                    className="form-group col-lg-2"
                                    style={{ width: '22.333333%' }}
                                  >
                                    <label className=" col-form-label">
                                      Subjects:{' '}
                                    </label>
                                  </div>
                                  <div className="form-group col-lg-2 ">
                                    <label className=" col-form-label">
                                      Qualification
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className="col-auto"
                                  style={{ padding: '1px' }}
                                >
                                  <input
                                    type="hidden"
                                    name="epnumberinput"
                                    defaultValue={0}
                                    id="epnoinput"
                                    className="form-control"
                                    placeholder="Phone #"
                                  />
                                </div>
                                <table id="emptbl2" className="tbl2">
                                  <tbody>
                                    {data?.academic_qualification?.map(
                                      (item, index) => (
                                        <tr>
                                          <td
                                            id="cold0"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="date"
                                                name="dateAwarded"
                                                defaultValue=""
                                                className={`form-control ${
                                                  errorText.academic_qualification &&
                                                  (!item.dateAwarded
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                value={item.dateAwarded}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'pending',
                                                    index,
                                                    'dateAwarded',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>{' '}
                                          </td>
                                          <td
                                            id="cold1"
                                            className=" col-lg-4 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="board_institute"
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification &&
                                                  (!item.board_institute
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                value={item.board_institute}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'pending',
                                                    index,
                                                    'board_institute',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="cold2"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="subject"
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification &&
                                                  (!item.subject
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                value={item.subject}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'pending',
                                                    index,
                                                    'subject',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="cold3"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto padding-0"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="qualification"
                                                defaultValue=""
                                                className={`form-control  ${
                                                  errorText.academic_qualification &&
                                                  (!item.qualification
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                value={item.qualification}
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'pending',
                                                    index,
                                                    'qualification',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td id="cold5">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleRemoveItem(
                                                  index,
                                                  'pending'
                                                )
                                              }
                                              className="btn btn-danger "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-trash"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path
                                                  fillRule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                />
                                              </svg>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    )}

                                    <tr>
                                      <td id="cold4">
                                        <button
                                          type="button"
                                          onclick="epdeleteRows(this)"
                                          className="btn btn-danger "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  id="eaadd"
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '10px',
                                    marginBottom: '30px',
                                  }}
                                >
                                  {' '}
                                  <button
                                    type="button"
                                    value="Add Row"
                                    onClick={() => addRow('pending')}
                                    className="btn btn-primary "
                                    style={{ width: '70%' }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-plus-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>
                                  </button>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* page 3rd end */}
              {/* page 4th start */}
              {state.value === 3 && currentPage === 4 && !error && (
                <div className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-2 font-weight-bold">
                            English Language
                          </h5>
                          <small className="fs-5" style={{ fontSize: '15px' }}>
                            You must submit proof of your English language
                            prficiency with this application.
                          </small>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm">
                              <div style={{ display: 'flex' }}>
                                <div className="form-group col-lg-4">
                                  <label className="col-lg-6 col-form-label">
                                    Is English your first language?
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="flang"
                                      value={
                                        data?.english_your_first_language ===
                                        'true'
                                          ? 'Yes'
                                          : 'No'
                                      }
                                      onChange={(e) =>
                                        handleInputChange({
                                          target: {
                                            name: 'english_your_first_language',
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                    >
                                      <option selected hidden>
                                        {/* no */}
                                      </option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-4">
                                  <label className="col-lg- col-form-label">
                                    {' '}
                                    Have you attended any courses in English
                                    Language?
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="courseineng"
                                      value={data?.language_attend_course}
                                      onChange={(e) =>
                                        handleInputChange({
                                          target: {
                                            name: 'language_attend_course',
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                    >
                                      <option>
                                        {/* {' '}
                                      yes{' '} */}
                                      </option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-4">
                                  <label className="col-lg- col-form-label">
                                    {' '}
                                    Have you taken any English Language
                                    Proficiency test?
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="proftest"
                                      value={data?.english_language_proficiency}
                                      onChange={(e) =>
                                        handleInputChange({
                                          target: {
                                            name: 'english_language_proficiency',
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                    >
                                      <option>
                                        {/* {' '}
                                      yes{' '} */}
                                      </option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className>
                                <h5 className="card-title mb-2 font-weight-bold">
                                  Work Experience
                                  <small
                                    className="fs-5"
                                    style={{ fontSize: '15px' }}
                                  >
                                    (if any)
                                  </small>
                                </h5>
                                <small style={{ fontSize: '15px' }}>
                                  Please give details of work experience,
                                  training and employment.
                                </small>
                                <hr />
                              </div>
                              <div>
                                <table>
                                  <tbody>
                                    <tr style={{ width: '100%' }}>
                                      <td className=" col-lg-2 padding-0">
                                        <div className="col-auto padding-0">
                                          <label className="col-lg- col-form-label">
                                            {' '}
                                            Date From
                                            <br />
                                            <small style={{ fontSize: '12px' }}>
                                              (dd/mm/yy)
                                            </small>
                                          </label>
                                        </div>{' '}
                                      </td>
                                      <td className=" col-lg-2 padding-0">
                                        <div className="col-auto padding-0">
                                          <label className="col-lg- col-form-label">
                                            Date to
                                            <br />
                                            <small style={{ fontSize: '12px' }}>
                                              (dd/mm/yy)
                                            </small>
                                          </label>
                                        </div>
                                      </td>
                                      <td className=" col-lg-2 padding-0">
                                        <div
                                          className="col-auto padding-0
                    "
                                        >
                                          <label className=" col-form-label">
                                            FT/PT{' '}
                                          </label>
                                        </div>
                                      </td>
                                      <td className=" col-lg-2 padding-0">
                                        <div className="col-auto padding-0">
                                          <label className=" col-form-label">
                                            Post held
                                          </label>
                                        </div>
                                      </td>
                                      <td className=" col-lg-2 padding-0">
                                        <div
                                          className="col-auto padding-0
                    "
                                        >
                                          <label className=" col-form-label">
                                            Organisation name and address
                                          </label>
                                        </div>
                                      </td>
                                      <td className=" col-lg-2 padding-0">
                                        <div className="col-auto padding-0">
                                          <label className=" col-form-label">
                                            Brief description of duties
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  className="col-auto"
                                  style={{ padding: '1px' }}
                                >
                                  <input
                                    type="hidden"
                                    name="numberinput"
                                    defaultValue={0}
                                    id="noinput"
                                    placeholder="Phone #"
                                  />
                                </div>
                                <table id="emptbl" className="tbl3">
                                  <tbody>
                                    {data?.Work_Experience?.map(
                                      (item, index) => (
                                        <tr>
                                          <td
                                            id="col0"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="date"
                                                name="Date_from"
                                                value={item.Date_from}
                                                defaultValue=""
                                                // className="form-control date require"
                                                className={`form-control  ${
                                                  errorText.Work_Experience &&
                                                  (!item.Date_from
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'work_exp',
                                                    index,
                                                    'Date_from',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>{' '}
                                          </td>
                                          <td
                                            id="col1"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="date"
                                                name="Date_to"
                                                value={item.Date_to}
                                                defaultValue=""
                                                // className="form-control date require"
                                                className={`form-control  ${
                                                  errorText.Work_Experience &&
                                                  (!item.Date_to
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'work_exp',
                                                    index,
                                                    'Date_to',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="col2"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="FT_PT"
                                                value={item.FT_PT}
                                                defaultValue=""
                                                // className="form-control"
                                                className={`form-control  ${
                                                  errorText.Work_Experience &&
                                                  (!item.FT_PT
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'work_exp',
                                                    index,
                                                    'FT_PT',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="col3"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="Post_held"
                                                value={item.Post_held}
                                                defaultValue=""
                                                // className="form-control"
                                                className={`form-control  ${
                                                  errorText.Work_Experience &&
                                                  (!item.Date_from
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'work_exp',
                                                    index,
                                                    'Post_held',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="col4"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="ROrganisation_nam"
                                                value={item.ROrganisation_nam}
                                                defaultValue=""
                                                // className="form-control"
                                                className={`form-control  ${
                                                  errorText.Work_Experience &&
                                                  (!item.ROrganisation_nam
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'work_exp',
                                                    index,
                                                    'ROrganisation_nam',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            id="col5"
                                            className=" col-lg-2 padding-0"
                                          >
                                            <div
                                              className="col-auto"
                                              style={{ padding: '1px' }}
                                            >
                                              <input
                                                type="text"
                                                name="Brief_description"
                                                value={item.Brief_description}
                                                defaultValue=""
                                                // className="form-control"
                                                className={`form-control  ${
                                                  errorText.Work_Experience &&
                                                  (!item.Brief_description
                                                    ? 'is-invalid'
                                                    : 'is-valid')
                                                }`}
                                                id="inputPassword2"
                                                placeholder
                                                onChange={(e) =>
                                                  handleArrayChange(
                                                    'work_exp',
                                                    index,
                                                    'Brief_description',
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td id="col7">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleRemoveItem(
                                                  index,
                                                  'work_exp'
                                                )
                                              }
                                              className="btn btn-danger "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-trash"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path
                                                  fillRule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                />
                                              </svg>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    )}

                                    <tr>
                                      <td id="col6">
                                        <button
                                          type="button"
                                          onclick="deleteRows(this)"
                                          className="btn btn-danger "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  id="add"
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '10px',
                                    marginBottom: '30px',
                                  }}
                                >
                                  {' '}
                                  <button
                                    type="button"
                                    value="Add Row"
                                    onClick={() => addRow('work_exp')}
                                    style={{ width: '70%' }}
                                    className="btn btn-primary "
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-plus-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>
                                  </button>{' '}
                                </div>
                              </div>
                              <div>
                                <label htmlFor>
                                  If curently employed,current employer details:
                                </label>
                              </div>

                              <div className="form-row">
                                {data?.Work_Experience_sub?.map(
                                  (item, index) => (
                                    <div key={index} className="col-lg-12 mb-3">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <label>
                                            Date From
                                            <small style={{ fontSize: '12px' }}>
                                              (dd/mm/yy)
                                            </small>
                                          </label>
                                          <input
                                            type="date"
                                            name="date_from"
                                            value={item.date_from}
                                            className={`form-control ${
                                              errorText.Work_Experience_sub &&
                                              !item.date_from
                                                ? 'is-invalid'
                                                : 'is-valid'
                                            }`}
                                            onChange={(e) =>
                                              handleArrayChange(
                                                'sub_work_exp',
                                                index,
                                                'date_from',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="col-md-3">
                                          <label>FT/PT</label>
                                          <input
                                            type="text"
                                            name="fT_PT"
                                            value={item.fT_PT}
                                            className={`form-control ${
                                              errorText.Work_Experience_sub &&
                                              !item.fT_PT
                                                ? 'is-invalid'
                                                : 'is-valid'
                                            }`}
                                            onChange={(e) =>
                                              handleArrayChange(
                                                'sub_work_exp',
                                                index,
                                                'fT_PT',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="col-md-3">
                                          <label>Post held</label>
                                          <input
                                            type="text"
                                            name="post_held"
                                            value={item.post_held}
                                            className={`form-control ${
                                              errorText.Work_Experience_sub &&
                                              !item.post_held
                                                ? 'is-invalid'
                                                : 'is-valid'
                                            }`}
                                            onChange={(e) =>
                                              handleArrayChange(
                                                'sub_work_exp',
                                                index,
                                                'post_held',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="col-md-3">
                                          <label>Organisation name</label>
                                          <input
                                            type="text"
                                            name="rOrganisation_nam"
                                            value={item.rOrganisation_nam}
                                            className={`form-control ${
                                              errorText.Work_Experience_sub &&
                                              !item.rOrganisation_nam
                                                ? 'is-invalid'
                                                : 'is-valid'
                                            }`}
                                            onChange={(e) =>
                                              handleArrayChange(
                                                'sub_work_exp',
                                                index,
                                                'rOrganisation_nam',
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>

                              <div className="form-row">
                                <div className="col-lg-12">
                                  <label className="col-form-label">
                                    Brief description of duties
                                  </label>
                                </div>

                                {/* Textarea for Brief description of duties */}
                                <div className="col-lg-12">
                                  {data?.Work_Experience_sub?.map(
                                    (item, index) => (
                                      <textarea
                                        rows={4}
                                        type="text"
                                        className={`form-control  ${
                                          errorText.Work_Experience_sub &&
                                          !item.brief_description
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        }`}
                                        name="brief_description"
                                        style={{ width: '50vw' }}
                                        onChange={(e) =>
                                          handleArrayChange(
                                            'sub_work_exp',
                                            index,
                                            'brief_description',
                                            e.target.value
                                          )
                                        }
                                        placeholder=""
                                        value={item?.brief_description}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* page 4th end */}
              {/* page 5th  START */}
              {state.value === 4 && currentPage === 5 && !error && (
                <div className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-2 font-weight-bold">
                            Further Information
                          </h5>
                          <small style={{ fontSize: '15px' }}>
                            Please use this section to tell us about yourself
                            and your reasons for wanting to study this course.
                          </small>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm">
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    Please give reasons for choosing this
                                    programme of study?:
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q1"
                                    className={`form-control ${
                                      errorText.further_information?.q1 &&
                                      (!data?.further_information?.q1
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q1}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    What are your future education plans?
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q2"
                                    className={`form-control ${
                                      errorText.further_information?.q2 &&
                                      (!data?.further_information?.q2
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q2}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    What kind of employment do you intend to
                                    seek on completion of your studies?
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q3"
                                    className={`form-control ${
                                      errorText.further_information?.q3 &&
                                      (!data?.further_information?.q3
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q3}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    Please write brief statement about your
                                    interests and hobbies.
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q4"
                                    className={`form-control ${
                                      errorText.further_information?.q4 &&
                                      (!data?.further_information?.q4
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q4}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    To help us provide assistance wherever
                                    possible please state briefly if you have
                                    any special needs requiring support or
                                    facilities.{' '}
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q5"
                                    className={`form-control ${
                                      errorText.further_information?.q5 &&
                                      (!data?.further_information?.q5
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q5}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    Are you currently on any state benifits? If
                                    so, please explain type of benifits:
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q6"
                                    className={`form-control ${
                                      errorText.further_information?.q6 &&
                                      (!data?.further_information?.q6
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q6}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    Do you have any criminal record within or
                                    outside the UK? If yes, please state:{' '}
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="q7"
                                    className={`form-control ${
                                      errorText.further_information?.q7 &&
                                      (!data?.further_information?.q7
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.further_information?.q7}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className>
                                <h5 className="card-title mb-2 font-weight-bold">
                                  Reference:
                                </h5>
                                <small style={{ fontSize: '15px' }}>
                                  This section must be completed by your present
                                  or past teacher, employer or a person who
                                  knows you well, academically and/or socially.{' '}
                                  <br />
                                  Incomplete application forms will not be
                                  processed. An interview with the applicant
                                  will be required.{' '}
                                </small>
                                <br />
                                <small
                                  className="font-weight-bold"
                                  style={{
                                    fontSize: '15px',
                                    marginTop: '10px',
                                  }}
                                >
                                  Note to Referee:
                                </small>
                                <br />
                                <small style={{ fontSize: '15px' }}>
                                  As many potential candidates apply, selection
                                  is extremely competitive. Your comments will
                                  provide us with important information in
                                  assessing this application.
                                </small>
                                <hr />
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    How long have you known the applicant and in
                                    what capacity?{' '}
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="reference_applicant_capacity"
                                    className={`form-control ${
                                      errorText.reference_applicant_capacity &&
                                      (!data?.reference_applicant_capacity
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    value={data?.reference_applicant_capacity}
                                    onChange={handleInputChange}
                                    placeholder
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="validationCustom01">
                                    What is your opinion of the Applicants
                                    suitability for the course chosen?
                                  </label>
                                  <textarea
                                    rows={4}
                                    type="text"
                                    name="reference_applicant_opinion"
                                    className={`form-control ${
                                      errorText.reference_applicant_opinion &&
                                      (!data?.reference_applicant_opinion
                                        ? 'is-invalid'
                                        : 'is-valid')
                                    }`}
                                    id="validationCustom01"
                                    placeholder
                                    value={data?.reference_applicant_opinion}
                                    onChange={handleInputChange}
                                    defaultValue=""
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <div className="invalid-feedback">
                                    THis field is required.
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Intellectual / Academic ability:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="Intellectual_Academic_ability"
                                      value={
                                        data?.reference_percentage_table
                                          .Intellectual_Academic_ability
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'Intellectual_Academic_ability',
                                          null,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Ability to work hard:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="Ability_to_work_hard"
                                      value={
                                        data?.reference_percentage_table
                                          .Ability_to_work_hard
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'Ability_to_work_hard',
                                          null,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Perseverance:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="Perseverance"
                                      value={
                                        data?.reference_percentage_table
                                          .Perseverance
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'Perseverance',
                                          null,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Leadership:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="Leadership"
                                      value={
                                        data?.reference_percentage_table
                                          .Leadership
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'Leadership',
                                          null,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Creativity:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="Creativity"
                                      value={
                                        data?.reference_percentage_table
                                          .Creativity
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'Creativity',
                                          null,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Concern for others:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="Concern_for_others"
                                      value={
                                        data?.reference_percentage_table
                                          .Concern_for_others
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'Concern_for_others',
                                          null,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Fluent and logical communication in English
                                    oral:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="oral"
                                      value={
                                        data?.reference_percentage_table
                                          .English_communication?.oral
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'English_communication',
                                          'oral',
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-3">
                                  <label className="col-lg- col-form-label">
                                    Fluent and logical communication in English
                                    written:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="written"
                                      value={
                                        data?.reference_percentage_table
                                          .English_communication?.written
                                      }
                                      onChange={(e) =>
                                        handleSelectedValue(
                                          'English_communication',
                                          'written',
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Outstanding">
                                        Outstanding
                                      </option>
                                      <option value="Excellent 90-94%">
                                        Excellent 90-94%
                                      </option>
                                      <option value="Very Good 75-89%">
                                        Very Good 75-89%
                                      </option>
                                      <option value="Good 60-74%">
                                        Good 60-74%
                                      </option>
                                      <option value="Averege">Averege</option>
                                      <option value="Below averege">
                                        Below averege
                                      </option>
                                      <option value="Not know">Not know</option>
                                    </select>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    marginTop: '20px',
                                  }}
                                >
                                  <div>
                                    <p>
                                      Any other Information that you feel is
                                      relevant
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                    }}
                                  >
                                    <div className="form-row">
                                      <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom01">
                                          Referee's Name
                                        </label>
                                        <input
                                          type="text"
                                          name="refer_name"
                                          className={`form-control ${
                                            errorText.refer_name &&
                                            (!data?.refer_name
                                              ? 'is-invalid'
                                              : 'is-valid')
                                          }`}
                                          id="validationCustom01"
                                          placeholder
                                          value={data?.refer_name}
                                          onChange={handleInputChange}
                                          defaultValue=""
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                        <div className="invalid-feedback">
                                          THis field is required.
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-row">
                                      <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom01">
                                          Referee's Signature
                                        </label>
                                        <input
                                          type="text"
                                          name="refer_sign"
                                          className={`form-control ${
                                            errorText.refer_name &&
                                            (!data?.refer_sign
                                              ? 'is-invalid'
                                              : 'is-valid')
                                          }`}
                                          id="validationCustom01"
                                          placeholder
                                          value={data?.refer_sign}
                                          onChange={handleInputChange}
                                          defaultValue=" "
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                        <div className="invalid-feedback">
                                          THis field is required.
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-row">
                                      <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom01">
                                          Date
                                        </label>
                                        <input
                                          type="date"
                                          name="refer_date"
                                          value={data?.refer_date}
                                          defaultValue=""
                                          className={`form-control ${
                                            errorText.refer_date &&
                                            (!data?.refer_date
                                              ? 'is-invalid'
                                              : 'is-valid')
                                          }`}
                                          onChange={handleInputChange}
                                          id="inputPassword2"
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                        <div className="invalid-feedback">
                                          THis field is required.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Page 5th End */}
              {/* page 6th start */}
              {state.value === 5 && currentPage === 6 && !error && (
                <div className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-2 font-weight-bold">
                            Marketing Information:
                            <br />
                            <small>
                              it would be helpful if you could tell us about
                              where you heard about the City of London College
                              by ticking the appropriate box.
                            </small>
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm">
                              <div className="col-md-12 row mb-3">
                                {data?.marketing_inf?.map((item, index) => (
                                  <div className="col-lg-4 box">
                                    <input
                                      className="larger"
                                      name="techno[]"
                                      value={item.value}
                                      defaultValue=""
                                      type="checkbox"
                                      id="checkbox1"
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      checked={item.checked}
                                    />
                                    <label className="mt-1 ml-1" htmlFor>
                                      {item.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              <h5 className="card-title mb-2 font-weight-bold">
                                Equal opportunities monitoring:
                                <br />
                                <small>
                                  Your response will not influence the outcome
                                  of your application.You do not have to answer
                                  this question if you do not wish.
                                </small>
                              </h5>
                              <br />
                              <div className="form-row">
                                <div className="form-group col-lg-6">
                                  <label className="col-lg- col-form-label">
                                    Ethnic origin:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      name="equal_oppertunity_ethnic_origin"
                                      className="form-control require"
                                      value={
                                        data?.equal_oppertunity_ethnic_origin
                                      }
                                      onChange={handleInputChange}
                                    >
                                      <option value="White">White</option>
                                      <option value="Asian or Asian British - Pakistani">
                                        Asian or Asian British - Pakistani
                                      </option>
                                      <option value="Mixed - white and Asian">
                                        Mixed - white and Asian
                                      </option>
                                      <option value="Gypsy or traveller">
                                        Gypsy or traveller
                                      </option>
                                      <option value="Asian or Asian British - Bangladeshi">
                                        Asian or Asian British - Bangladeshi
                                      </option>
                                      <option value="Other mixed background">
                                        Other mixed background
                                      </option>
                                      <option value="Black or black British - Caribbean">
                                        Black or black British - Caribbean
                                      </option>
                                      <option value="Chinese">Chinese</option>
                                      <option value="Arab">Arab</option>
                                      <option value="Black or black British - African">
                                        Black or black British - African
                                      </option>
                                      <option value="Other Asian background">
                                        Other Asian background
                                      </option>
                                      <option value="Other ethnic background">
                                        Other ethnic background
                                      </option>
                                      <option value="Other black background">
                                        Other black background{' '}
                                      </option>
                                      <option value="Mixed - white and back Caribbean">
                                        Mixed - white and back Caribbean
                                      </option>
                                      <option value="Prefer not to say">
                                        Prefer not to say
                                      </option>
                                      <option value="Asian or Asian British - Indian">
                                        Asian or Asian British - Indian
                                      </option>
                                      <option value="Mixed - white and black African">
                                        Mixed - white and black African
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group col-lg-6">
                                  <label className="col-lg- col-form-label">
                                    Religion:
                                  </label>
                                  <div className="col-lg-  ">
                                    <select
                                      className="form-control require"
                                      name="equal_oppertunity_Religion"
                                      value={data?.equal_oppertunity_Religion}
                                      onChange={handleInputChange}
                                    >
                                      <option value="Christian">
                                        Christian
                                      </option>
                                      <option value="Muslim">Muslim</option>
                                      <option value="Buddhist">Buddhist</option>
                                      <option value="Sikh">Sikh</option>
                                      <option value="Any other religion or belief">
                                        Any other religion or belief
                                      </option>
                                      <option value="Hindu">Hindu</option>
                                      <option value="Chinese">Chinese</option>
                                      <option value="baha'i faith">
                                        baha'i faith
                                      </option>
                                      <option value="Prefer not to say">
                                        Prefer not to say
                                      </option>
                                      <option value="Jewish">Jewish</option>
                                      <option value="Pagan">Pagan</option>
                                      <option value="Other">Other</option>
                                      <option value="No religion">
                                        No religion
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div id="filearray"></div>
                              <table
                                className="table"
                                style={{ marginTop: '20px' }}
                              >
                                <tbody>
                                  {data?.files?.map((file) => (
                                    <tr>
                                      {console.log('the file si', file)}
                                      <td
                                        style={{
                                          width: '100%',
                                          gap: '40px',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                        id="fil0"
                                      >
                                        {file.filePath.includes('pdf') ? (
                                          <img
                                            className="img-thumbnail img-fluid"
                                            style={{
                                              height: '70px',
                                              width: '70px',
                                              objectFit: 'cover',
                                            }}
                                            src={Pdf_file_icon}
                                          />
                                        ) : file.filePath.includes('word') ? (
                                          <img
                                            className="img-thumbnail img-fluid"
                                            style={{
                                              height: '70px',
                                              width: '70px',
                                              objectFit: 'cover',
                                            }}
                                            src={word_icon}
                                          />
                                        ) : (
                                          <img
                                            className="img-thumbnail img-fluid"
                                            style={{
                                              height: '70px',
                                              width: '70px',
                                              objectFit: 'cover',
                                            }}
                                            src={
                                              base_url1 +
                                              `files/${file.fileName}`
                                            }
                                          />
                                        )}
                                        <h6 className="card-header">
                                          {file.fileName}
                                        </h6>
                                        <button
                                          type="button"
                                          style={{
                                            backgroundColor: 'rgb(167, 2, 2)',
                                          }}
                                          onclick='deleteFile(0, [{"id":1,"attachments":"222-1673691506.png","applicationid":1,"created_at":"2023-01-14T18:18:26.000000Z","updated_at":"2023-01-14T18:18:26.000000Z"},{"id":2,"attachments":"333-1673691506.png","applicationid":1,"created_at":"2023-01-14T18:18:26.000000Z","updated_at":"2023-01-14T18:18:26.000000Z"},{"id":3,"attachments":"hee-1673691506.pdf","applicationid":1,"created_at":"2023-01-14T18:18:26.000000Z","updated_at":"2023-01-14T18:18:26.000000Z"}])'
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div className="form-row">
                                <div className="file-upload">
                                  <div className="image-upload-wrap">
                                    <div className="drag-text">
                                      <img
                                        src={img3}
                                        alt="uploadimage"
                                        style={{
                                          marginTop: '40px',
                                          width: '160px',
                                          height: '100px',
                                        }}
                                      />
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '5px',
                                        }}
                                      >
                                        <h3
                                          style={{
                                            padding: '15px 15px 0px 15px',
                                          }}
                                        >
                                          Drag And Drop Files OR Choose your
                                          Files
                                        </h3>
                                        <h6>
                                          Only .pdf, .png, .jpg, .jpeg, .docx
                                          Allowed
                                        </h6>
                                      </div>
                                      <input
                                        name="allfiles"
                                        type="file"
                                        className="file-upload-btn"
                                        id="allfiles"
                                        accept="image/png, image/jpeg, image/jpg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf "
                                        multiple
                                        onChange={handleFileChange}
                                      />
                                    </div>
                                    <output
                                      id="selectedFilesD"
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '40px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {filePreviews.map((previewObj, index) => (
                                        <div key={index}>
                                          {/* {console.log("file preview is",preview)}
                         <img key={index} src={preview} alt={`Preview ${index}`} style={{ maxWidth: '100px', maxHeight: '100px' }} /> */}
                                          {previewObj.preview ? (
                                            <img
                                              src={previewObj.preview}
                                              alt={`Preview ${index}`}
                                              style={{
                                                maxWidth: '100px',
                                                maxHeight: '100px',
                                              }}
                                            />
                                          ) : (
                                            <div>
                                              {/* Display an icon or thumbnail based on file type */}
                                              {previewObj.file.type.includes(
                                                'pdf'
                                              ) ? (
                                                <img
                                                  src={Pdf_file_icon}
                                                  alt={`PDF Preview ${index}`}
                                                  style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                  }}
                                                />
                                              ) : previewObj.file.type.includes(
                                                  'word'
                                                ) ? (
                                                <img
                                                  src={word_icon}
                                                  alt={`Word Preview ${index}`}
                                                  style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={generic_file_icon}
                                                  alt={`File Preview ${index}`}
                                                  style={{
                                                    maxWidth: '100px',
                                                    maxHeight: '100px',
                                                  }}
                                                />
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </output>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* page 6th end */}
            </form>
            <div style={{ overflow: 'auto', marginBottom: '50px' }}>
              <div style={{ float: 'right' }}>
                <button
                  className="btn btn-primary"
                  type="button"
                  id="prevBtn"
                  hidden={activeSteps.length === 1 ? true : false}
                  onClick={handlePrevious}
                >
                  Previous
                </button>
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  id="nextBtn"
                  hidden={
                    activeSteps[activeSteps.length - 1] === steps.length - 1
                      ? true
                      : false
                  }
                  onClick={handleNext}
                >
                  Next
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  id="submitBtn"
                  hidden={activeSteps.length !== 6 ? true : false}
                  onClick={() => handleUpdate()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2020.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default EditApplication
