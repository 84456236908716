import React, { useState } from 'react'
// import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
//import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

function CheckForm() {

     const [formData, setFormData] = useState({
       name: '',
       email: '',
       message: '',
     })

     const handleInputChange = (event) => {
       const { name, value } = event.target
       setFormData({
         ...formData,
         [name]: value,
       })
     }

     const handleSubmit = (event) => {
       event.preventDefault()
       // Handle form submission logic here
       console.log('Form submitted:', formData)
     }
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Contact Us</h4>
              <form
                className="needs-validation"
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Your Name"
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter your name.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="name@example.com"
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Your message"
                    required
                  ></textarea>
                  <div className="invalid-feedback">
                    Please enter a message.
                  </div>
                </div>
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckForm