import React, { useEffect, useState ,useRef} from 'react'
import './Student.css'
import { Link, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import axios from 'axios'
import colllage from '../../assets/images/collage1.jpg'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import generic_file_icon from '../../assets/images/generic_file.png'
import Pdf_file_icon from '../../assets/images/PDF_file_icon.png'
import word_icon from '../../assets/images/word_icon.png'
import { CKEditor } from 'ckeditor4-react'
// for generting pdf importing
import jsPDF from 'jspdf'
import { PDFDocument, rgb, degrees } from 'pdf-lib'
import { base_url } from '../../utils/Api'
import { base_url1 } from '../../utils/Api'

import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import {
  Interviewed,
  commencement,
  course_duration,
  equal_oppertunity,
  foundation_course,
  further_info,
  marketing_info,
  mode_of_study,
  pay_fees,
  reference,
  tabel_achieved_data,
  tabel_pending_data,
  tabel_work_experience,
  undergraduate_course,
} from '../../config/AppFormData'

const Language_proficiency_test = [
  {
    text: 'Have you taken any English Language Proficiency test?',
    value: 'Yes',
    label: 'Yes.',
  },
  { text: 'If YES please attach evidence', value: 'No', label: 'No.' },
]
const Language_attend_course = [
  {
    text: 'Have you attended any courses in English Language?',
    value: 'Yes',
    label: 'Yes.',
  },
  { text: 'If YES please attach evidence', value: 'No', label: 'No.' },
]
const Studentview = () => {
  const [courses, setCourses] = useState({
    foundationCourse: [],
    underGraduateCourse: [],
  })
  const [modal, setModal] = useState(false)
  const [comment, setComment] = useState('')
  const [editorContent, setEditorContent] = useState('');
   const handleEditorChange = (event) => {
    const newContent = event.editor.getData();
    setEditorContent(newContent);
  };
  
  // const printDiv = (elementId) => {
  //   const elementToPrint = document.getElementById(elementId)
  //   if (elementToPrint) {
  //     const printContents = elementToPrint.innerHTML
  //     const originalContents = document.body.innerHTML

  //     document.body.innerHTML = printContents
  //     window.print()
  //     document.body.innerHTML = originalContents
  //     window.location.reload();
      
  //   }
  // }
   const componentRef = useRef();
 const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle: 'Visitor Pass',
  onAfterPrint: () => console.log('Printed PDF successfully!'),
 });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [data, setData] = useState({})
  const [status, setStatus] = useState('')
  const { _id } = useParams()
  const handleSubmit = (status) => {
    setStatus(status)
    setModal(true)
    // Perform form submission logic here, e.g., sending data to the server
    // console.log('Form submitted with permission name:', permissionName)
    // const config = {
    //   headers: {
    //     'Content-type': 'application/json',
    //   },
    // }
    // axios
    //   .put(
    //     `http://clc-london.uk/api/admin/applicationview/${_id}`,
    //     { status,comment },
    //     config
    //   )
    //   .then((res) => console.log('resp is', res))
    //   .catch((err) => console.log('error is'.err))
  }
  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState)
  }


  const [load, setLoad] = useState(false)

  const getAppFormById = async () => {
    console.log('hfjkdfhjkdfskdfks')
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        console.log('the rep is', res)
        setStatus(res?.data?.applications?.status)
        // setData(res.data.applications)
        setData(res?.data?.applications)
        setLoad(true)
      })
      .catch((err) => console.log('error is---', err))
  }
  console.log('the data is', data)
  function calculateAge(dateOfBirth) {
    if (!dateOfBirth) {
      return
    }

    const birthDate = new Date(dateOfBirth)
    const today = new Date()

    let age = today.getFullYear() - birthDate.getFullYear()

    const monthDifference = today.getMonth() - birthDate.getMonth()
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--
    }
    return age
  }

  const [courseLabel, setCourseLabel] = useState('')
  const getFoundationCourse = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        console.log(
          'the resp is------------👩👩',
          res.data.data.find(
            (course) =>
              course.course_Name === data &&
              data.Course &&
              data.Course.course_name
          )
        )
        let underCourse = []
        let foundCourse = []
        console.log(
          'the courser name is',
          data && data.Course && data.Course.course_name
        )
        res.data.data.forEach((item) => {
          console.log('item is matching is', item)
          if (item.course_Name === data?.Course?.course_name) {
            console.log('the courser label is', item.course_Level)
            setCourseLabel(item.course_Level)
          }
        })
        // res.data.dat?.map((item) => {
        //   console.log('item is matching is', item)
        //   if(item && item.course_Name===data && data.Course && data.Course.course_name){
        //     setCourseLabel(item.course_Level)
        //   }
        //   // if (item.course_Level === 'Undergraduate Course') {
        //   //   underCourse.push({item:item.course_Name,checked:false})
        //   // } else {
        //   //   foundCourse.push({item:item.course_Name,checked:false})
        //   // }
        // })
        // setCourses({
        //   underGraduateCourse: underCourse,
        //   foundationCourse: foundCourse,
        // })
      })
      .catch((err) => console.log('error is---', err))
  }

  useEffect(() => {
    getAppFormById()
  }, [_id])
  useEffect(() => {
    getFoundationCourse()
  }, [load])
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  // Generating pdf
  function download(data, filename, type) {
    const blob = new Blob([data], { type: type })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }
  const generatePDF = async () => {
    // const pdf = new jsPDF();
    try {
      const mergedPdfDoc = await PDFDocument.create()
      const pdfDoc = await PDFDocument.create()

      for (const file of data.files) {
        if (file.fileName.toLowerCase().endsWith('.pdf')) {
          const pdfBytes = await fetch(
            base_url1 + `files/${file.fileName}`
          ).then((res) => res.arrayBuffer())
          const pdf = await PDFDocument.load(pdfBytes)

          const pages = await pdfDoc.copyPages(pdf, pdf.getPageIndices())
          pages.forEach((page) => pdfDoc.addPage(page))
        } else if (file.fileName.includes('jpeg')) {
          const imageBytes = await fetch(
            base_url1 + `files/${file.fileName}`
          ).then((res) => res.arrayBuffer())
          const jpgImage = await pdfDoc.embedJpg(imageBytes)
          const jpgDims = jpgImage.scale(0.5)
          const page = pdfDoc.addPage()
          page.drawImage(jpgImage, {
            x: page.getWidth() / 2 - jpgDims.width / 2,
            y: page.getHeight() / 2 - jpgDims.height / 2 + 250,
            width: jpgDims.width,
            height: jpgDims.height,
          })
          await pdfDoc.save()
        } else if (file.fileName.includes('png')) {
          const imageBytes = await fetch(
            base_url1 + `files/${file.fileName}`
          ).then((res) => res.arrayBuffer())
          const pngImage = await pdfDoc.embedPng(imageBytes)
          const pngDims = pngImage.scale(0.5)
          const page = pdfDoc.addPage()
          page.drawImage(pngImage, {
            x: page.getWidth() / 2 - pngDims.width / 2 + 75,
            y: page.getHeight() / 2 - pngDims.height + 250,
            width: pngDims.width,
            height: pngDims.height,
          })
          await pdfDoc.save()
        }
      }

      const pdfBytes = await pdfDoc.save()
      download(pdfBytes, 'combined.pdf', 'application/pdf')
    } catch (error) {
      console.error('Error merging PDF files:', error)
    }
  }
  console.log("the comment ismalik",comment)
  // for sending email based on status
  const SendEmail = async (email) => {
    console.log("the email is",email,editorContent)
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    axios
      .put(
        base_url + `admin/applicationview/${_id}`,
        { status, editorContent },
        config
      )
      .then((res) => {
        if (res.data.status === '201') {
          setModal(!modal)
        }
      })
      .catch((err) => console.log('error is'.err))
  }

  const closeDropdown = () => {
    setIsDropdownOpen(false)
  }
  // Function to handle dropdown item click
  const handleDropdownItemClick = (status) => {
    toggleDropdown() // Close the dropdown when a
    closeDropdown()
    handleSubmit(status)
    setModal(true)
    // Perform the action based on the selected status
  }

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper" id="elementId">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Applications</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Application view</li>
                  </ul>
                </div>
                <div className="col-auto d-flex text-right float-right ml-auto ">
                  <Link
                    // to=""
                    className="btn btn-outline-primary  "
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                     onClick={handlePrint}
                  >
                    <i className="fas fa-download" /> Download
                  </Link>
                  <div
                    className="dropdown show "
                    style={{ marginRight: '10px' }}
                  >
                    <button
                      className="btn btn-secondary dropdown-toggle "
                      type="button"
                      // id={dropdownButtonId}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={isDropdownOpen}
                      onClick={toggleDropdown}
                     disabled={data?.status==="Approve"?true:false}
                    >
                      {status}
                    </button>
                    {/* Conditionally render the dropdown menu based on the isDropdownOpen state */}
                    {isDropdownOpen && (
                      <div
                        className="dropdown-menu show"
                        aria-labelledby="dropdownMenuButton"
                        style={{
                          position: 'absolute',
                          transform: 'translate3d(0px, 38px, 0px)',
                          top: '0px',
                          left: '0px',
                          willChange: 'transform',
                        }}
                        x-placement="bottom-start"
                      >
                        <a
                          onClick={() => handleDropdownItemClick('Approve')}
                          className="dropdown-item approveBtn"
                        >
                          Approve
                        </a>
                        <a
                          onClick={() => handleDropdownItemClick('Reject')}
                          className="dropdown-item rejectBtn"
                        >
                          Reject
                        </a>
                        <a
                          onClick={() => handleDropdownItemClick('Revision')}
                          className="dropdown-item revisionBtn"
                        >
                          Revision
                        </a>
                        {/* Add onClick event handler to the "Pending" button */}
                      </div>
                    )}
                  </div>
                  {/* for model view */}
                  <Modal
                    isOpen={modal}
                    style={{ maxWidth: '50%', width: 'auto' }}
                  >
                    <ModalHeader>Application Comments </ModalHeader>
                    <ModalBody>
                      <CKEditor
                        data="Your Approve email template content goes here."
                        // name="approve"
                        className="form-control editor"
                        // onChange={(event, editor) => setComment(editor.getData())}
                        // onChange={(event, editor) => console.log("the comment data is",event)}
                        onChange={handleEditorChange}
                        // readOnly
                      />
                    </ModalBody>

                    <ModalFooter>
                      <button
                        type="button"
                        className="btn btn-secondary modalClose"
                        onClick={() => {
                          setModal(false)
                          closeDropdown() // Close the dropdown when the modal is closed
                        }}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary "
                        id="applicationStatus"
                        data-status={3}
                        onClick={() => {
                          SendEmail(data?.Personal_Details?.Email)
                          setModal(false)
                          closeDropdown()
                        }}
                      >
                        Save changes
                      </button>
                    </ModalFooter>
                  </Modal>

                  {/* modal revision */}
                  {/* {status === 'Reject' && ( */}
                  <div
                    className="modal fade"
                    id="approveId"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content modalwh">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Application Comments
                          </h5>
                          <i className="fa fa-close modalClose" />
                          <a href="#" className="btn mr-2 modalClose">
                            <i className="fas fa-xmark" />
                          </a>
                        </div>
                        <div className="modal-body">
                          <div className="form-group text-left">
                            <label htmlFor="comments" className="text-left">
                              Comments
                            </label>
                            <textarea
                              className="ckeditor form-control editor"
                              name="comments"
                              id="revisioncomments"
                              rows={3}
                              defaultValue={
                                '<h1><em><strong>Comment of </strong></em>revision</h1>'
                              }
                            />
                            <input
                              type="hidden"
                              id="said"
                              name="said"
                              defaultValue={1}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary modalClose"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary "
                            id="applicationStatus"
                            data-status={3}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                  {/* modal */}
                  {/* modal approve */}
                  <div
                    className="modal fade"
                    id="approveModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content modalwh">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Application Comments
                          </h5>
                          <i className="fa fa-close modalClose" />
                          <a href="#" className="btn mr-2 modalClose">
                            <i className="fas fa-xmark" />
                          </a>
                        </div>
                        <div className="modal-body">
                          <div className="form-group text-left">
                            <label htmlFor="comments" className="text-left">
                              Comments
                            </label>
                            <textarea
                              className="ckeditor form-control editor1"
                              name="comments"
                              id="approvecomments"
                              rows={3}
                              defaultValue={
                                '<ul>\n\t<li>\n\t<h1><em><strong>Comment </strong></em>has</h1>\n\t</li>\n</ul>'
                              }
                            />
                            <input
                              type="hidden"
                              id="said"
                              name="said"
                              defaultValue={1}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary modalClose"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary "
                            id="approveStatus"
                            data-status={1}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal */}
                  {/* modal reject */}
                  <div
                    className="modal fade"
                    id="rejectModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content modalwh">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Application Comments
                          </h5>
                          <i className="fa fa-close modalClose" />
                          <a href="#" className="btn mr-2 modalClose">
                            <i className="fas fa-xmark" />
                          </a>
                        </div>
                        <div className="modal-body">
                          <div className="form-group text-left">
                            <label htmlFor="comments" className="text-left">
                              Comments
                            </label>
                            <textarea
                              className="ckeditor form-control editor2"
                              name="comments"
                              id="rejectcomments"
                              rows={3}
                              defaultValue={
                                '<h1><em><strong>Comment 2nd is <samp>Decline</samp></strong></em></h1>'
                              }
                            />
                            <input
                              type="hidden"
                              id="said"
                              name="said"
                              defaultValue={1}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary modalClose"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary "
                            id="rejectStatus"
                            data-status={2}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal */}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="messages" />
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">PERSONAL DETAILS</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Family Name
                          </div>
                          {data?.Personal_Details?.Family_Name_In_Passport}
                          <input
                            id
                            defaultValue="shahid"
                            name="FamilyName"
                            hidden
                          />
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            First Name
                          </div>
                          {data?.Personal_Details?.First_Name_In_Passport}
                          <input
                            id
                            defaultValue="rasool"
                            name="FirstName"
                            hidden
                          />
                        </div>
                      </li>
                      {/* <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Previous Name
                          </div>
                          {data?.Personal_Details?.Pervious_Family_Name}
                          <input
                            id
                            defaultValue="rasool"
                            name="PreviousName"
                            hidden
                          />
                        </div>
                      </li> */}
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            DOB
                          </div>
                          {data?.Personal_Details?.Date_Of_Birth}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Email
                          </div>
                          {data?.Personal_Details?.Email}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Entry Date to Uk
                          </div>
                          {data?.Personal_Details?.Entrty_Date_UK}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Home/Parmanent Address
                          </div>
                          {data?.Personal_Details?.Home_Permanent_Address}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Home Tel Number
                          </div>
                          {data?.Personal_Details?.Home_Tel_No}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Title
                          </div>
                          {data?.Personal_Details?.Title}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Gender
                          </div>
                          {data?.Personal_Details?.Gender}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            City
                          </div>
                          {data?.Personal_Details?.City}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Postal Code
                          </div>
                          {data?.Personal_Details?.Post_Code}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Mobile
                          </div>
                          {data?.Personal_Details?.Modile_No}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Country of birth
                          </div>
                          {data?.Personal_Details?.Coutry_Of_Birth}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Nationality
                          </div>
                          {data?.Personal_Details?.Nationality}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div
                          className="ms-2 me-auto"
                          style={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Age
                          </div>
                          <span
                            style={{
                              backgroundColor: 'rgb(19, 214, 19)',
                              padding: '5px 10px',
                              borderRadius: '5px',
                            }}
                          >
                            {calculateAge(
                              data?.Personal_Details?.Date_Of_Birth
                            )}
                          </span>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">COURSE DETAILS</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Course Level
                          </div>
                          {courseLabel}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            {courseLabel}
                          </div>
                          {data?.Course?.course_name}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Mode Of study
                          </div>
                          {data?.Course?.mode_study}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Commencment
                          </div>
                          {data?.Course?.commencement}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Who will pay your fees
                          </div>
                          {data?.Course?.fee_pay}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* aqachieve examinations achieved */}
            {/* aqachieve examinations achieved */}
            {/* aqachieve examinations pending */}
            {/* aqachieve examinations pending */}
            <div className="card" style={{ width: '100%' }}>
              <h5 className="card-title p-2">
                Academic qualifications — examinations achieved
              </h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exams Date</th>
                    <th scope="col">Examing Board/Institution</th>
                    <th scope="col">Subjects</th>
                    <th scope="col">Qualification</th>
                    <th scope="col">Result achived</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.Academic_Qua_Exam_Achieved?.map((exam, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{exam.dateAwarded}</td>
                      <td>{exam.board_institute}</td>
                      <td>{exam.subject}</td>
                      <td>{exam.qualification}</td>
                      <td>{exam.results_achieved}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <h5 className="card-title p-2">
                Academic qualifications — examinations Pending
              </h5>
              <p style={{ display: 'none' }}> 1 </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exams Date</th>
                    <th scope="col">Examing Board/Institution</th>
                    <th scope="col">Subjects</th>
                    <th scope="col">Qualification</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.Academic_Qua_Exam_Pending?.map((exam, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{exam.dateAwarded}</td>
                      <td>{exam.board_institute}</td>
                      <td>{exam.subject}</td>
                      <td>{exam.qualification}</td>
                      <td>{exam.results_achieved}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">English Language</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Is english your first language
                          </div>
                          {data?.English_Language
                            ?.English_Your_First_Language === true
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Have you attended any courses in English Language?
                          </div>
                          {
                            data?.English_Language
                              ?.English_Language_Attend_Course
                          }
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Have you taken any English Language Proficiency
                            test?
                          </div>
                          {data?.English_Language?.English_Language_Proficiency}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="card" style={{ width: '100%' }}>
              <h5 className="card-title p-2">Work Experience</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date from</th>
                    <th scope="col">Date to</th>
                    <th scope="col"> FT/PT</th>
                    <th scope="col">Post held</th>
                    <th scope="col">Organisation name and address</th>
                    <th scope="col"> Brief description of duties</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.Work_Experience?.work_experience?.map(
                    (experience, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{experience.Date_from}</td>
                        <td>{experience.Date_to}</td>
                        <td>{experience.FT_PT}</td>
                        <td>{experience.Post_held}</td>
                        <td>{experience.ROrganisation_nam}</td>
                        <td>{experience.Brief_description}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">
                  If currently employed, current employer details:
                </h5>
                <div className="row">
                  {data?.Work_Experience?.currently_employed?.map((item) => (
                    <>
                      <div className="col-md-6">
                        <ol className="list-group list-group-numbered">
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Date from
                              </div>
                              {item?.date_from}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                FT/PT
                              </div>
                              {item?.fT_PT}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Organization Address
                              </div>
                              {item?.rOrganisation_nam}
                            </div>
                          </li>
                        </ol>
                      </div>
                      <div className="col-md-6">
                        <ol className="list-group list-group-numbered">
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Post held
                              </div>
                              {item?.post_held}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Organisation name and address
                              </div>
                              {item?.rOrganisation_nam}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Brief description of duties
                              </div>
                              {item?.brief_description}
                            </div>
                          </li>
                        </ol>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">Further Information</h5>
                <div className="row">
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Please give reasons for choosing this programme of
                            study.
                          </div>
                          {data?.Further_Information?.q1}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            What are your future education plans?
                          </div>
                          {data?.Further_Information?.q2}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            What kind of employment do you intend to seek on
                            completion of your studies?
                          </div>
                          {data?.Further_Information?.q3}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Please write brief statement about your interests
                            and hobbies.
                          </div>
                          {data?.Further_Information?.q4}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            To help us provide assistance wherever possible
                            please state briefly if you have any special needs
                            requiring support or facilities.
                          </div>
                          {data?.Further_Information?.q5}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Are you currently on any state benefits?
                          </div>
                          {data?.Further_Information?.q6}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Do you have any criminal record within or outside
                            the UK?
                          </div>
                          {data?.Further_Information?.q7}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%', marginBottom: 0 }}>
              <div className="card-body">
                <h5 className="card-title">Reference</h5>
                <div className="row">
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            How long have you known the applicant and in what
                            capacity?
                          </div>
                          {data?.Reference?.Note_To_Refree?.Applicant_Capacity}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            What is your opinion of the Applicants suitability
                            for the course chosen?
                          </div>
                          {data?.Reference?.Note_To_Refree?.Applicant_Opinion}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%', marginBottom: 0 }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Intellectual / Academic ability
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.Intellectual_Academic_ability
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Fluent and logical communication in English oral
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.English_communication?.oral
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Fluent and logical communication in English written
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.English_communication?.written
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Concern for others
                          </div>
                          {data?.Reference?.Reference_Tabel?.Concern_for_others}
                        </div>
                      </li>
                    </ol>
                  </div>

                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Ability to work hard
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.Ability_to_work_hard
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Perseverance
                          </div>
                          {data?.Reference?.Reference_Tabel?.Perseverance}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Leadership
                          </div>
                          {data?.Reference?.Reference_Tabel?.Leadership}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Creativity
                          </div>
                          {data?.Reference?.Reference_Tabel?.Creativity}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Referee's name
                          </div>
                          {data?.Reference?.Refrees_Name}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Referee's signatures
                          </div>
                          {data?.Reference?.Refrees_Sign}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Date
                          </div>
                          {data?.Reference?.Date}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">Marketing Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      {data?.Marketing_Information?.map(
                        (item) =>
                          item.checked && (
                            <li className="list-group-item d-flex justify-content-between align-items-start ">
                              <div className="ms-2 me-auto">
                                <div
                                  className="fw-bold"
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '19px',
                                  }}
                                ></div>
                                <span>{item.value}</span>
                              </div>
                            </li>
                          )
                      )}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title"> Equal opportunities monitoring</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Ethnic Origen
                          </div>
                          {data?.Equal_Oppertunity_Monitoring?.Ethnic_Origin}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Religion
                          </div>
                          {data?.Equal_Oppertunity_Monitoring?.Religion}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Applicant Signature
                          </div>
                          {
                            data?.Equal_Oppertunity_Monitoring?.Declaration
                              ?.Applicant_Signature
                          }
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Date
                          </div>
                          {
                            data?.Equal_Oppertunity_Monitoring?.Declaration
                              ?.Date
                          }
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="card-body">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-between">
                        <button class="btn btn-primary mt-3" type="submit">Revision</button>
                        <button class="btn btn-danger mt-3" type="submit">Reject</button>
                        <button class="btn btn-success mt-3" type="submit">Approve</button>
                    </div>
                </div>
            </div> */}
            <div className="card" style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px 10px',
                }}
              >
                <h5 className="card-title" style={{ margin: '10px' }}>
                  Student Files
                </h5>
                <div style={{ display: 'flex', gap: '4px' }}>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      Rearrange Files
                    </button>
                    {/* Modal */}
                    <div
                      className="modal fade"
                      id="exampleModalCenter"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Rearrange Files
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <ul className="sortlist">
                              <li
                                className="card item"
                                draggable="true"
                                style={{
                                  borderRadius: '0px',
                                  width: '100%',
                                  margin: '10px 5px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <img
                                  className="img-thumbnail img-fluid"
                                  style={{
                                    height: '60px',
                                    width: '60px',
                                    objectFit: 'cover',
                                  }}
                                  src="http://clc-london.uk/assets/images/222-1673691506.png"
                                />
                                <h6>222-1673691506.png</h6>
                              </li>
                              <li
                                className="card item"
                                draggable="true"
                                style={{
                                  borderRadius: '0px',
                                  width: '100%',
                                  margin: '10px 5px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <img
                                  className="img-thumbnail img-fluid"
                                  style={{
                                    height: '60px',
                                    width: '60px',
                                    objectFit: 'cover',
                                  }}
                                  src="http://clc-london.uk/assets/images/333-1673691506.png"
                                />
                                <h6>333-1673691506.png</h6>
                              </li>
                              <li
                                className="card item"
                                draggable="true"
                                style={{
                                  borderRadius: '0px',
                                  width: '100%',
                                  margin: '10px 5px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <img
                                  className="img-thumbnail img-fluid"
                                  style={{ height: '60px', width: '60px' }}
                                  src="/assets/pdf.png"
                                />
                                <h6>hee-1673691506.pdf</h6>
                              </li>
                            </ul>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <form
                              action="http://clc-london.uk/arrange-files/1"
                              method="post"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="5k5OaCompVDVfYavgJGq0sxmub21N4y1NZ3dF27y"
                              />{' '}
                              <div
                                id="arr_div"
                                style={{
                                  display: 'none',
                                  flexDirection: 'column',
                                }}
                              />
                              <button
                                style={{ display: 'none' }}
                                id="savebtn"
                                type="submit"
                                className="btn btn-primary"
                              >
                                Save changes
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <form
                      action="http://clc-london.uk/generate-pdf/1"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="5k5OaCompVDVfYavgJGq0sxmub21N4y1NZ3dF27y"
                      />{' '} */}
                    <button onClick={generatePDF} className="btn btn-success">
                      Generate PDF
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
              {data?.files?.map((file) => (
                <div
                  className="card"
                  style={{ borderRadius: '0px', margin: '10px 5px' }}
                >
                  <h6 className="card-header">{file.fileName}</h6>
                  <div
                    className="card-body"
                    style={{
                      borderRadius: '0px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {file.filePath.includes('pdf') ? (
                      <img
                        className="img-thumbnail img-fluid"
                        style={{
                          height: '70px',
                          width: '70px',
                          objectFit: 'cover',
                        }}
                        src={Pdf_file_icon}
                      />
                    ) : file.filePath.includes('word') ? (
                      <img
                        className="img-thumbnail img-fluid"
                        style={{
                          height: '70px',
                          width: '70px',
                          objectFit: 'cover',
                        }}
                        src={word_icon}
                      />
                    ) : (
                      // file.filePath.includes('file')?
                      // <img
                      //   className="img-thumbnail img-fluid"
                      //   style={{
                      //     height: '70px',
                      //     width: '70px',
                      //     objectFit: 'cover',
                      //   }}
                      //   src={generic_file_icon}
                      // />
                      // :
                      <img
                        className="img-thumbnail img-fluid"
                        style={{
                          height: '70px',
                          width: '70px',
                          objectFit: 'cover',
                        }}
                        // src={`/static/${file.filePath}`} alt="File"
                        src={base_url1 + `files/${file.fileName}`}
                      />
                    )}
                    <a
                      target="_blank"
                      href={base_url1 + `files/${file.fileName}`}
                      download={file.fileName}
                      className="btn btn-primary"
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div  style={{ display: 'none' }}>
              <div  ref={componentRef}className="main">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div
                        className=""
                        style={{
                          width: '300px',
                          height: '300px',
                          border: '1px solid black',
                          display: 'flex',
                          alignItems: 'center',
                          margin: '10px',
                          justifyContent: 'center',
                          overflow: 'hidden',
                          position: 'relative',
                        }}
                      >
                        <div
                          className="text-center"
                          style={{
                            position: 'absolute',
                            fontSize: '20px',
                          }}
                        >
                          Attach <b>ONE</b> passport size photograph here
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    </div>

                    <div className="text-center d-flex justify-content-center align-items-center card border-0">
                      <img
                        src={colllage}
                        width="300px"
                        height="200px"
                        alt="college Logo"
                      />
                      <div className="card-body">
                        <p className="card-text" style={{ maxWidth: '28rem' }}>
                          3 Boyd Street, Aldgate East, London, E1 1FQ, United
                          Kingdom Tel: +44 (0)20 7247 2177 Email:
                          info@clc-london.ac.uk
                        </p>
                        <h5 className="card-title">
                          <Link to="#">www.clc-london.ac.uk</Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <h1 className="mb-0">
                        APPLICATION FOR ADMISSION
                        <strong className="title_subtext">
                          (Higher Education Courses)
                        </strong>
                      </h1>
                      <strong className="paragraph_text">
                        (Please read the College prospectus and consult the
                        website before completing this form)
                        <br />
                        Please complete this form in BLOCK capitals using black
                        ink
                      </strong>
                    </div>
                    <div>
                      <form
                      //   onSubmit={(e) => {
                      //     e.preventDefault()
                      //     .handleSubmit()
                      //     return false
                      //   }}
                      >
                        <h3>1. Personal details</h3>
                        <div className="row mb-3">
                          <label className="col-12 col-form-label label_text">
                            Family name as appears in passport:
                          </label>
                          <div className="col-12">
                            <input
                              type="text"
                              className="form-control"
                              name="family_name"
                              id="family_name"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-12 col-form-label label_text">
                            First name(s) as appears in passport
                          </label>
                          <div className="col-12">
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              id="first_name"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-12 col-form-label label_text">
                            Title (Mr/Mrs/Miss/Ms/etc):
                          </label>
                          <div className="col-12">
                            <select
                              name="title"
                              id="title"
                              className="form-select"
                            ></select>
                          </div>

                          <div className="col-12">
                            <label className=" col-form-label label_text  pe-0">
                              Previous family name (if applicable):
                            </label>
                          </div>
                          <div className="col-sm-12 ps-0">
                            <input
                              type="text"
                              name="optional_family_name"
                              id="optional_family_name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6">
                            <div className="row d-flex align-items-center">
                              <div className="col-6">
                                <label className="label_text">
                                  Date of birth:
                                </label>
                              </div>
                              <div className="col-6">
                                <input
                                  type="date"
                                  name="dob"
                                  id="dob"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-1"></div> */}
                          <div className="col-2">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <label className="col-form-label label_text">
                                  Gender:
                                </label>
                              </div>
                              <div className="d-flex ">
                                <div>
                                  <input
                                    className="form-check-input"
                                    name=""
                                    type="checkbox"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  Male
                                </label>
                                <div className="ms-5">
                                  <input
                                    className="form-check-input ms-2"
                                    name=""
                                    type="checkbox"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text ">
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-5">
                            <label className="col-form-label label_text">
                              Home/permanent address:
                            </label>
                            <input
                              type="text"
                              name="home_address"
                              id="home_address"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="col-form-label label_text">
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-3">
                            <label className="col-form-label label_text">
                              Postcode:
                            </label>
                            <input
                              type="text"
                              name="post_code"
                              id="post_code"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-3">
                            <label className="col-form-label label_text">
                              Home Tel no.:
                            </label>
                            <input
                              type="number"
                              name="home_tel_no"
                              id="home_tel_no"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-3">
                            <label className="col-form-label label_text">
                              Mobile:
                            </label>
                            <input
                              type="number"
                              name="mobile"
                              id="mobile"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="col-form-label label_text">
                              Email:
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-3">
                            <label className="col-form-label label_text">
                              Country of birth
                            </label>
                            <select
                              name="country_birth"
                              id="country_birth"
                              className="form-select"
                            >
                              <option defaultValue>
                                Select Country of Birth
                              </option>
                              {/* {.map((option) => ( */}
                              <option
                              // style={{ textTransform: 'capitalize' }}
                              // key={option.value}
                              // value={option.value}
                              >
                                {/* {option.label} */}
                              </option>
                              {/* ))} */}
                            </select>
                          </div>
                          <div className="col-md-3">
                            <label className="col-form-label label_text">
                              Nationality:
                            </label>
                            <select
                              name="nationality"
                              id="title"
                              className="form-select"
                            >
                              <option defaultValue>
                                Select your Nationlaity
                              </option>
                              {/* {.map((option) => ( */}
                              <option></option>
                              {/* ))} */}
                            </select>
                          </div>
                          <div className="col-md-3">
                            <label className="col-form-label label_text">
                              Entry date to UK:
                            </label>
                            <input
                              type="date"
                              name="entry_date_uk"
                              id="entry_date_uk"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <h3 className="mb-3">
                          2. Course:{' '}
                          <span style={{ fontSize: '16px' }}>
                            <i>
                              (Please indicate the course you wish to apply)
                            </i>
                          </span>
                        </h3>
                        {/* Foundation Course */}

                        <div className="mb-3">
                          <h5>{foundation_course.heading}</h5>

                          <div className="row">
                            {courses?.foundationCourse?.map((checks) => (
                              <div className="d-flex col-3 align-items-center">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="course.s"
                                    className="form-check-input"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  {checks.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Undergraduate Courses */}

                        <div className="mb-3">
                          <h5>{undergraduate_course.heading}</h5>

                          <div className="row">
                            {courses.underGraduateCourse?.map((checks) => (
                              <div className="d-flex col-3 align-items-center">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="course.s"
                                    className="form-check-input"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  {checks.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Mode of Study */}

                        <div className="mb-3">
                          <h5>{mode_of_study.heading}</h5>

                          <div className="row">
                            {mode_of_study.item?.map((checks) => (
                              <div className="d-flex col-3 align-items-center">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="course.mode_study"
                                    className="form-check-input"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  {checks.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/*  */}

                        <div className="mb-3">
                          <h5>
                            {commencement.heading}
                            <span style={{ fontSize: '16px' }}>
                              <i>{commencement.small_text}</i>
                            </span>
                          </h5>

                          <div className="row">
                            {commencement.item?.map((checks) => (
                              <div className="d-flex col-3 align-items-center">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="course."
                                    className="form-check-input"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  {checks.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Course Duration */}

                        <div className="mb-3">
                          <h5>
                            {course_duration.heading}
                            <span style={{ fontSize: '16px' }}>
                              <i>{course_duration.small_text}</i>
                            </span>
                          </h5>

                          <div className="row">
                            {course_duration.item?.map((checks) => (
                              <div className="d-flex col-3 align-items-center">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="course."
                                    className="form-check-input"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  {checks.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Pay Fees */}

                        <div className="mb-3">
                          <h5>
                            {pay_fees.heading}
                            <span style={{ fontSize: '16px' }}>
                              <i>{pay_fees.small_text}</i>
                            </span>
                          </h5>

                          <div className="row">
                            {pay_fees.item?.map((checks) => (
                              <div className="d-flex col-3 align-items-center">
                                <div>
                                  <input
                                    type="checkbox"
                                    name="course.fee_pay"
                                    className="form-check-input"
                                  />
                                </div>
                                <label className="ms-2 form-check-label label_text">
                                  {checks.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div>
                          {tabel_achieved_data?.map((item, index) => {
                            return (
                              <div key={index}>
                                <h3>{item.heading}</h3>
                                <p>{item.paragraph}</p>
                              </div>
                            )
                          })}

                          <table className="table border text-start ">
                            <thead>
                              <tr>
                                {tabel_achieved_data?.map((item) =>
                                  item?.tabel_title?.map((sub_item, index) => (
                                    <th key={index} scope="col">
                                      {sub_item.tabel_heading}
                                    </th>
                                  ))
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          {tabel_pending_data?.map((item, index) => {
                            return (
                              <div key={index}>
                                <h3>{item.heading}</h3>
                                <p>{item.paragraph}</p>
                              </div>
                            )
                          })}

                          <table className="table border text-start ">
                            <thead>
                              <tr>
                                {tabel_pending_data?.map((item) =>
                                  item?.tabel_title?.map((sub_item, index) => (
                                    <th key={index} scope="col">
                                      {sub_item.tabel_heading}
                                    </th>
                                  ))
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <h3>5. English language:</h3>
                          <p>
                            You must submit proof of your English language
                            proficiency with this application
                          </p>
                          <div>
                            <div className="d-flex">
                              <p>
                                Is English your first language? (Please tick box
                                as applicable)
                              </p>
                              <input
                                name="english_your_first_language"
                                className="form-check-input ms-4"
                                type="checkbox"
                              />
                            </div>

                            <div className="d-flex">
                              {Language_attend_course?.map((item, index) => (
                                <div key={index} className="d-flex">
                                  <p>{item.text}</p>
                                  <input
                                    className="form-check-input ms-3"
                                    type="checkbox"
                                    name=""
                                  />
                                  <p className="ms-2">{item.label}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              {Language_attend_course?.map((item, index) => (
                                <div key={index} className="d-flex">
                                  <p>{item.text}</p>
                                  <input
                                    className="form-check-input ms-3"
                                    type="checkbox"
                                    name="english_language_proficiency"
                                  />
                                  <p className="ms-2">{item.label}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          {Language_proficiency_test?.map((item, index) => {
                            return (
                              <div key={index}>
                                <h3>{item.heading}</h3>
                                <p>{item.paragraph}</p>
                              </div>
                            )
                          })}
                          {tabel_work_experience.map((item, index) => {
                            return (
                              <div key={index}>
                                <h3>{item.heading}</h3>
                                <p>{item.paragraph}</p>
                              </div>
                            )
                          })}
                          <table className="table border text-start ">
                            <thead>
                              <tr>
                                {tabel_work_experience?.map((item) =>
                                  item.tabel_title.map((sub_item, index) => (
                                    <th key={index} scope="col">
                                      {sub_item.tabel_heading}
                                    </th>
                                  ))
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {/* {tabel_work_experience?.map((item, index) => ( */}
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <select className="form-select">
                                    <option defaultValue>
                                      Select your job Mode
                                    </option>
                                  </select>
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <select className="form-select">
                                    <option defaultValue>
                                      Select your job Mode
                                    </option>
                                  </select>
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <select className="form-select">
                                    <option defaultValue>
                                      Select your job Mode
                                    </option>
                                  </select>
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <select className="form-select">
                                    <option defaultValue>
                                      Select your job Mode
                                    </option>
                                  </select>
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                              {/* ))} */}
                            </tbody>
                          </table>
                          {tabel_work_experience?.map((item, index) => (
                            <p key={index}>{item.sub_paragraph}</p>
                          ))}
                          <table className="table border text-start ">
                            <thead>
                              <tr>
                                {tabel_work_experience?.map((item) =>
                                  item.sub_tabel_title?.map(
                                    (sub_item, index) => (
                                      <th key={index} scope="col">
                                        {sub_item.tabel_heading}
                                      </th>
                                    )
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="date"
                                  />
                                </td>
                                <td>
                                  <select className="form-select"></select>
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>

                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="table_input form-control"
                                    type="text"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {further_info?.map((data, index) => (
                          <div key={index}>
                            <h3>{data.title}</h3>
                            <p>{data.paragraph}</p>
                            {data.questions?.map((questions, index) => (
                              <div key={index}>
                                <div className="">
                                  <label className="label_text">
                                    {questions?.ques}
                                  </label>
                                </div>
                                <div className="mb-3">
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}

                        {reference?.map((data, index) => (
                          <div key={index}>
                            <h3>{data.title}</h3>
                            <p>{data.paragraph}</p>
                            <h4>{data.sub_title}</h4>
                            <p>{data.sub_paragraph}</p>
                            {/* {data.questions?.map((questions) => ( */}
                            <>
                              <div className="">
                                <label className="label_text">
                                  How long have you known the applicant and in
                                  what capacity?
                                </label>
                              </div>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  name="_applicant_capacity"
                                  className="form-control"
                                />
                              </div>
                              <div className="">
                                <label className="label_text">
                                  What is your opinion of the Applicants
                                  suitability for the course chosen?
                                </label>
                              </div>
                              <div className="mb-3">
                                <input type="text" name="_applicant_opinion" />
                              </div>
                            </>
                            {/* ))} */}
                          </div>
                        ))}
                        <div style={{ marginTop: 40 }}>
                          <p className="label_text">
                            Please tick appropriately – ONE tick per row:
                          </p>
                        </div>
                        <div className="mb-3" style={{ marginTop: 40 }}>
                          <table className="table border">
                            <thead>
                              <tr>
                                {reference?.map((item) =>
                                  item.tabel_title?.map((sub_item, index) => (
                                    <th
                                      key={sub_item.title}
                                      scope="col"
                                      className="text-center"
                                    >
                                      {sub_item.title}
                                    </th>
                                  ))
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {reference?.map((item) =>
                                item.tabel_title_value?.map((sub_item, index) =>
                                  index === 1 ? (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex ">
                                          <div className="d-flex align-items-center">
                                            {sub_item.value}
                                          </div>
                                          <div className="text-center ms-4">
                                            {sub_item.divider?.map(
                                              (text, idx) => (
                                                <div className="d-flex">
                                                  <div key={idx}>
                                                    {text.text}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {/* <div>{sub_item.written?.text}</div> */}
                                          </div>
                                        </div>
                                      </td>
                                      {item.logical_tabel_divider?.map(
                                        (checkbox, idx) => (
                                          <td key={idx} className="text-center">
                                            <div>
                                              <div>
                                                <input
                                                  className="form-check-input "
                                                  type="checkbox"
                                                  value={checkbox.value}
                                                  name="_percentage_table.English_communication.oral"
                                                />
                                              </div>
                                              <div>
                                                <input
                                                  className="form-check-input mb-1 "
                                                  type="checkbox"
                                                  value={checkbox.value}
                                                  name="_percentage_table.English_communication.written"
                                                />
                                              </div>
                                            </div>
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>{sub_item.value}</td>

                                      {sub_item.item?.map((checkbox, idx) => (
                                        <td key={idx} className="text-center">
                                          <input
                                            className="form-check-input "
                                            type="checkbox"
                                            value={checkbox.value}
                                            name={
                                              index === 0
                                                ? '_percentage_table.Intellectual_Academic_ability'
                                                : // : index === 1
                                                //   ? 'course.s'
                                                index === 2
                                                ? '_percentage_table.Ability_to_work_hard'
                                                : index === 3
                                                ? '_percentage_table.Perseverance'
                                                : index === 4
                                                ? '_percentage_table.Leadership'
                                                : index === 5
                                                ? '_percentage_table.Creativity'
                                                : '_percentage_table.Concern_for_others'
                                            }
                                          />
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                )
                              )}
                            </tbody>
                          </table>
                          <div className="">
                            <div>
                              <label className="label_text">
                                Any other information that you feel is relevant.{' '}
                              </label>
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                name="_any_other_info"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row mb-3 ">
                            <div className="col-6 d-flex align-items-center">
                              <label className="col-2  col-form-label label_text">
                                Referee’s name:
                              </label>
                              <div className="col-9">
                                <input
                                  type="text"
                                  name="refer_name"
                                  className="form-control ms-4"
                                />
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-end">
                              <label className="col-2  col-form-label label_text">
                                Referee’s signatures:
                              </label>
                              <div className="col-9 me-4">
                                <input
                                  type="text"
                                  name="refer_sign"
                                  className="form-control ms-4"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row mb-3">
                            <label className="col-1 col-form-label label_text">
                              Date:
                            </label>

                            <div className="col-4 ms-3">
                              <input
                                type="date"
                                className="form-control"
                                name="refer_date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          {marketing_info?.map((value, idx) => (
                            <div key={value.title}>
                              <h3>{value.title}</h3>
                              <p>{value.paragraph}</p>
                              <div className="row">
                                {value.check_boxe?.map((item, idx) => {
                                  return (
                                    <div
                                      key={item.key}
                                      className="d-flex col-3 align-items-center"
                                    >
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="marketing_inf"
                                          value={item.key}
                                        />
                                      </div>
                                      <label className="form-check-label ms-2 label_text">
                                        {item.key}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          {equal_oppertunity?.map((value, idx) => (
                            <div key={idx}>
                              <h3>{value.title}</h3>
                              <p>{value.paragraph}</p>
                              <h5>{value.sub_title_1}</h5>
                              <div className="row mb-3">
                                {value.check_boxes_?.map((item, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      className="d-flex col-3 align-items-center"
                                    >
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="_ethnic_origin"
                                        />
                                      </div>
                                      <label className="form-check-label ms-2 label_text">
                                        {item.key}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <h5>{value.sub_title_2}</h5>
                              <div className="row mb-3">
                                {value.check_boxes_?.map((item, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      className="d-flex col-3 align-items-center"
                                    >
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="_Religion"
                                          value={item.key}
                                        />
                                      </div>
                                      <label className="form-check-label ms-2 label_text">
                                        {item.key}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <h4>{value.declaration_heading}</h4>
                              <p>{value.declaration_paragraph}</p>
                              <div className="row mb-3">
                                <div className="col-2">
                                  <label className="col-form-label label_text">
                                    Applicant’s signature:
                                  </label>
                                </div>
                                <div className="col-5">
                                  <input
                                    type="text"
                                    name="app_sign"
                                    className="form-control"
                                  />
                                </div>

                                <div className="col-1 ">
                                  <label className="ms-5 col-form-label label_text">
                                    Date:
                                  </label>
                                </div>
                                <div className="col-sm-4">
                                  <input
                                    type="date"
                                    name="_date"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <h4>{value.enrolment_procedure_title}</h4>
                              <p>
                                1. Complete the Application for Admission form,
                                and send it with
                              </p>
                              <p className="ms-4">
                                a{')'} certified copies of relevant academic
                                certificates & work related documents
                              </p>
                              <p className="ms-4">b{')'} 1 colour photo</p>
                              <p className="ms-4">
                                c{')'} copy of passport page
                              </p>
                              <p className="ms-4">
                                d{')'} copy of National Insurance number
                                document
                              </p>
                              <p className="ms-4">
                                e{')'} proof of address (driving license or bank
                                statement) send to the{' '}
                                <i>
                                  <b>
                                    Registrar, City of London College, 3 Boyd
                                    Street, London E1 1FQ.
                                  </b>
                                </i>
                              </p>
                              <p>
                                2. When your application has been processed, the
                                College will issue a Letter of Acceptance (LOA).
                              </p>
                            </div>
                          ))}
                        </div>
                        {/* For Register Use Only */}
                        <div className="mt">
                          <h3>FOR REGISTRY USE ONLY:</h3>
                          <div className="row ">
                            <div className="col-md-3">
                              <label className="col-form-label label_text">
                                Student
                              </label>
                              <div className="d-flex">
                                {Interviewed.map((item, index) => (
                                  <div className={'ms-5'}>
                                    <input
                                      className="form-check-input"
                                      name="student_"
                                      type="checkbox"
                                    />
                                    <label className="ms-2">{item.label}</label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <label className="col-form-label label_text">
                                Student by
                              </label>
                              <input
                                type="text"
                                name="registry_std_int_by"
                                id="registry_std_int_on"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-3">
                              <label className="col-form-label label_text">
                                Student on
                              </label>
                              <input
                                type="text"
                                name="registry_std_int_on"
                                id="registry_std_int_on"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="floatingTextarea2">Comments</label>
                            <textarea
                              type="text"
                              name="registry_comment"
                              className="form-control"
                              style={{ height: '80px' }}
                            ></textarea>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <div>
                                <div className="mb-2">
                                  <label className="col-form-label label_text">
                                    Course title on which place offered
                                  </label>
                                  <select
                                    className="form-select"
                                    name="registry_"
                                    id="title"
                                  >
                                    <option defaultValue>
                                      Select Course title
                                    </option>
                                  </select>
                                </div>
                                <div className="d-flex mb-2">
                                  <div>
                                    <label className="col-form-label label_text">
                                      Course Code
                                    </label>
                                    <input
                                      type="number"
                                      name="registry_course_code"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="ms-5">
                                    <label className="col-form-label label_text">
                                      Tuition Fee
                                    </label>
                                    <input
                                      type="number"
                                      name="registry_course_tution_fee"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="me-5">
                                  <label className="col-form-label label_text">
                                    Processed by
                                  </label>
                                  <input
                                    type="text"
                                    name="registry_course_processed_by"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div>
                                <div className="mb-2">
                                  <label className="col-form-label label_text">
                                    Course Level (level 1-6)
                                  </label>
                                  <select
                                    className="form-select"
                                    name="registry_"
                                    id="title"
                                  >
                                    <option defaultValue>Select title</option>
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <label className="col-form-label label_text">
                                    Date Received
                                  </label>
                                  <input
                                    type="date"
                                    name="registry_course_date"
                                    className="form-control"
                                  />
                                </div>
                                <div className="">
                                  <label className="col-form-label label_text">
                                    <b>Allocated SEN:</b>
                                  </label>
                                  <input
                                    type="number"
                                    name="registry_course_sen"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-5 mt-2">
                              <div>
                                <p className="ms-5">
                                  Course Duration{' '}
                                  <span
                                    className=""
                                    style={{ fontSize: '10px' }}
                                  >
                                    (please state START and END date)
                                  </span>
                                </p>
                                <div className="row mb-3">
                                  <div className="col-3">
                                    <label className=" col-form-label label_text ms-5 pe-0">
                                      Start Date:
                                    </label>
                                  </div>
                                  <div className="col-9">
                                    <input
                                      type="date"
                                      name="registry_course_start_date"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-3">
                                    <label className=" col-form-label label_text ms-5 pe-0">
                                      End Date:
                                    </label>
                                  </div>
                                  <div className="col-9">
                                    <input
                                      type="date"
                                      name="registry_course_end_date"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* for model */}
                <div>
                  {/* <Button color="danger" onClick={toggle}>
          Click Me
        </Button> */}
                  {/* <Modal isOpen={modal}>
                    <ModalHeader>Hi! {}</ModalHeader>
                    <ModalBody>
                      Kindly check your email address to confirm that form is
                      submitted or not.
                    </ModalBody>
                    <ModalFooter> */}
                  {/* <Button color="primary" onClick={toggle}>
              Do Something
            </Button>{' '} */}
                  {/* <Button
                        color="secondary"
                        onClick={() => setModal(!modal)}
                      >
                        OK
                      </Button> */}
                  {/* </ModalFooter>
                  </Modal> */}
                </div>
                {/* for model */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Studentview;
