import { useSelector } from 'react-redux'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { base_url } from '../../../utils/Api'
// import { PrintContextConsumer, PrintProvider } from 'react-to-print'

const FeeDetails = () => {
  const { _id } = useParams()
  const { feeManagementStatusData } = useSelector((state) => state.feeDetails)
  const [tableData, setTableData] = useState([])
  const [detail, setDetail] = useState({})
  const [CoursesStatus, setCourseStatus] = useState('')
  const [isPrinting, setIsPrinting] = useState(false)
  const [id, setId] = useState('')
  const [data, setData] = useState({
    amount: '',
    status: '',
    dueDate: '',
    paidDate: '',
    action: 'paid',
  })
  console.log('the display data is', data)

  useEffect(() => {
    getFeesData()
    console.log('first', Array.isArray(feeManagementStatusData))
    if (Array.isArray(feeManagementStatusData)) {
      const item = feeManagementStatusData.find((item) => item._id === _id)
      if (item) {
        setDetail(item)
        localStorage.setItem('detail', JSON.stringify(item))
      }
    } else {
      const dtail = localStorage.getItem('detail')
      console.log('first')
      console.log('detail uouoiuiuoiuoiuio', JSON.parse(dtail))
      setDetail(JSON.parse(dtail))
    }
  }, [feeManagementStatusData, _id])
  const getFeesData = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+`admin/getFeesById/${_id}`, config)
      .then((res) => {
        console.log('teh res is 👌👌👌', res.data.data)
        setTableData(res.data.data)
      })
  }
  const getCourses = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+'admin/courses', config)
      .then((res) => {
        console.log('the resp is------------', res.data.data)
        const filterData = res.data.data.filter(
          (item) => item.course_Name === detail.Course_Name
        )
        console.log('the filterd data is', filterData[0].allow_Installment)
        console.log('the filterd data is', filterData[0].course_status)
        if (filterData) {
          // setTableData(filterData[0].allow_Installment)
          setCourseStatus(filterData[0].course_status)
          setId(filterData[0]._id)
        }
        // setData(res?.data?.data)
        // const data = res?.data?.data
        // setTotalEntries(data.length)
        // setTotalLength(data.length)
        // const filteredData = res?.data?.data.filter(
        //   (item, index) =>
        //     item.course_Name
        //       .toLowerCase()
        //       .includes(searchQuery.toLowerCase()) ||
        //     index.toString().includes(searchQuery)
        // )
        // if (filteredData) {
        //   setTotalEntries(filteredData.length)
        // }
        // setData(filteredData)
        // setFilteredTotalEntries(filteredData.length)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    console.log('jfkldsfjksd')
    getCourses()
    // setData(...data, { data.status: detail.feestatus })
    setData((prevData) => ({
      ...prevData,
      status: detail.feestatus, // Replace "newStatus" with the desired value
    }))
  }, [detail.Course_Name])

  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  const handlePrintClick = (event) => {
    event.preventDefault()
    setIsPrinting(true)
    const printableContent = document.getElementById('print1').innerHTML
    const originalContents = document.body.innerHTML
    document.body.innerHTML = printableContent
    window.print()
    document.body.innerHTML = originalContents // Reset the body content
    setIsPrinting(false)
  }
  console.log('the table data is', tableData)
  const updateCourseStatus = async (status) => {
    setCourseStatus(status)
    console.log('the change si', status)
    // setCourseStatus(status)
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .put(
        base_url+`admin/updateCourseStatuss/${id}`,
        { status },
        config
      )
      .then((res) => {
        console.log('teh res is😒😒', res)
      })
  }

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" id="elementId">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Fee Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Fee Details</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="messages" />
              </div>
            </div>
          </div>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">COURSE DETAILS</h5>
              <div className="row">
                <div className="col-md-12">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      <div
                        className="fw-bold"
                        style={{ fontWeight: 'bold', fontSize: '19px' }}
                      >
                        Course title
                      </div>
                      {detail.Course_Name || ''}
                    </div>
                  </li>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course Start Date
                        </div>
                        {detail.Start_Date || ''}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course End Date
                        </div>
                        {detail.End_Date || ''}
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course Level
                        </div>
                        {detail.course_Level || ''}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course Fee
                        </div>
                        {detail.Fee || ''}
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">PERSONAL DETAILS</h5>
              <div className="row">
                <div className="col-md-12">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      <div
                        className="fw-bold"
                        style={{ fontWeight: 'bold', fontSize: '19px' }}
                      >
                        Name:
                      </div>
                      {detail.Student_Name || ''}
                      <input
                        id
                        defaultValue="shahid"
                        name="FamilyName"
                        hidden
                      />
                    </div>
                  </li>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Previous Family Name:
                        </div>
                        {detail.Family_Name_In_Passport || ''}
                        <input
                          id
                          defaultValue="rasool"
                          name="PreviousName"
                          hidden
                        />
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Home Tel no:
                        </div>
                        {detail.Modile_No}
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Email:
                        </div>
                        {detail.Email}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Mobile:
                        </div>
                        {detail.Modile_No}
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">FEE SECTION</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Application ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Paid Date</th>
                    <th scope="col">Action</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.feeAmount}</td>
                      <td>{detail.feestatus}</td>
                      <td>{item.dueDate}</td>
                      <td>{item.payDate}</td>
                      <td>Paid</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                        >
                          Receipt
                        </button>{' '}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '10px 10px 0px 0px',
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary btn"
                  style={{ padding: '8px 40px' }}
                  onClick={handlePrintClick}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
          <div
            className="card"
            id="print1"
            style={{ width: '100%', display: 'none' }}
          >
            <div className="card-body">
              <h5 className="card-title">Student Name: John Hills</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Application ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Paid Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>90</td>
                    <td>Paid</td>
                    <td>2021-05-13</td>
                    <td>2022-10-27</td>
                    <td>Paid</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <input
            type="text"
            defaultValue={1}
            id="applicationid"
            style={{ display: 'none' }}
          />
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">COURSE COMPLETION STATUS</h5>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className style={{ fontSize: '15px' }}>
                          Application course completion status
                        </div>
                        <select
                          id="stat"
                          name="CoursesStatus"
                          className="form-control form-control-lg coursestatus"
                          style={{ marginTop: '10px', width: '100%' }}
                          value={CoursesStatus}
                          onChange={(e) => updateCourseStatus(e.target.value)}
                        >
                          <option value="In Progress">In Progress</option>
                          <option value="Withdrawn">Withdrawn</option>
                          <option value="Completed Successfully">
                            Completed Successfully
                          </option>
                          <option value="Completed Unsuccessfully">
                            Completed Unsuccessfully
                          </option>
                        </select>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeeDetails
