import React, { useEffect, useState } from 'react'
import axios from 'axios'
import left_img from '../../assets/images/login-flash.jpg'

import first_logo from '../../assets/images/logo.gif'
import second_logo from '../../assets/images/phi.jpg'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './Login.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userAction'
function App() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dsipatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const navigate = useNavigate()
  const { userInfo, error } = userLogin

  console.log('the user info is', error)
  const token = localStorage.getItem('UserToken')
  console.log(token)

  // useEffect(() => {
  //    navigate('/')
  // }, [token])
  // useEffect(() => {
  //   if (userInfo) {
  //     // You can perform other actions based on userInfo, if needed
  //     navigate('/')
  //   }
  //   // Move the toast outside the if block
  //   toast.success('Login successful!')
  // }, [userInfo, navigate])
  // useEffect(() => {
  //   if (userInfo && userInfo.status === '201') {
  //     toast.success('Login successful!')
  //     navigate('/')
  //   }
  // }, [userInfo, navigate])
  useEffect(() => {
    // console.log('first', userInfo)
    if (userInfo && userInfo.status === '201') {
      // Login successful, show success toast
     	setTimeout(() => {
        Swal.fire({
          icon: 'success',
          position: 'top-center',
          title: 'Succcess',
          text: 'Login Successfully!',
          showConfirmButton: true,
          timer: 25000,
        })
        navigate('/')
      })
    } else if (error) {
      // Login failed, show error toast
       Swal.fire({
         icon: 'error',
         position: 'top-center',
         title: 'Error',
         text: 'Invalid login credentials. Please try again.',
         showConfirmButton: true,
       })
    }
  }, [userInfo, error, navigate])
  // const handleLogin = async (e) => {
  //   e.preventDefault()
  //   console.log(email, password)
  //   dsipatch(login(email, password))
  // }
  const handleLogin = async (e) => {
    e.preventDefault()

    try {
      // Attempt to login
      await dsipatch(login(email, password))

      // If successful, the success toast will be shown in the useEffect
    } catch (error) {
      // If there's an error (e.g., incorrect login or password), show an error toast
      toast.error('Invalid login credentials. Please try again.')
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="sign_in_main">
        <div className="container  main_cont d-flex flex-column align-items-center justify-content-center">
          <div className="row ">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5 ">
              <div
                className="card"
                style={{
                  maxWidth: '670px',
                  height: '100%',
                  backgroundColor: '#283993',
                  color: '#ffff',
                }}
              >
                <img src={left_img} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title footer_title">
                    Welcome to Trades Training Centre VLE
                  </h5>
                  <p className="card-text footer_paragraph">
                    Our virtual learning environment helps pupils and teachers
                    to access resources and information from anywhere.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5  ">
              <div className=" py-4 right_panel">
                <div>
                  <img
                    src={first_logo}
                    alt=""
                    style={{
                      // width: '100px',
                      // height: '100px',
                      marginRight: '10px',
                    }}
                  />
                  <img
                    src={second_logo}
                    alt=""
                    style={{ width: '150px', height: '94px' }}
                  />
                </div>
                <div className="mt-4 ">
                  <p className="form_title">
                    <b>Sign In</b> with your username and password.
                  </p>
                </div>
                <form onSubmit={handleLogin} className="form_main mt-0">
                  <div className="mb-1">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label input_label"
                    >
                      User Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Enter Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="formGroupExampleInput2"
                      className="form-label input_label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="formGroupExampleInput2"
                      placeholder="Enter Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div
                    className="form-check"
                    style={{
                      marginBottom: 20,
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label
                      style={{ marginLeft: 5, marginTop: 5 }}
                      className="form-check-label"
                      htmlFor="gridCheck"
                    >
                      <b>Keep me signed in for today</b>
                    </label>
                  </div>
                  <div className="" style={{ color: 'red' }}>
                    <span>{error && error}</span>
                  </div>
                  <div
                    className="mb-3 "
                    style={{ color: 'D1D1D1', borderRadius: '5px' }}
                  >
                    <button type="submit" className="button sign_btns">
                      Sign in
                    </button>
                    <button onClick={()=>{
                      navigate('/resetpassword')
                    }} className="button sign_btns ms-2 ">
                      Reset
                    </button>
                    
                  </div>
                </form>

                <div className="mt-2 mb-3 footer_link">
                  <a href="#">Can't access your account?</a>
                </div>
                <div className="mt-2 form_footer_text">
                  <p>
                    <b>
                      {' '}
                      Don't have a TTC account?{' '}
                      <Link to="/signup">Sign up now</Link>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-5 px-5 footer_border">
          <hr className="mb-2" />
          <div className="d-flex justify-content-between align-items-center text px-3">
            <small>@ 2023 Trades Training Center</small>
            <small>Powered by Smart Move (SM)</small>
          </div>
        </div>
      </div>
    </div>
  )
}
// }

export default App
