import axios from 'axios'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './Editcourse.css'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'

const EditCourseLevel = () => {
   const [isValid, setIsValid] = useState(true)
  // State to manage form data
  const [formData, setFormData] = useState({
    coursename: '',
  })
  const { _id } = useParams()
  useEffect(() => {
    getCourseNameById()
  }, [_id])
  const getCourseNameById = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+`admin/CourseName/${_id}`, config)
      .then((res) => {
        console.log('the res is', res)

        setFormData({
          coursename: res?.data?.data?.course_Name,
        })
        //setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
    const handleSubmit = (e) => {
      e.preventDefault()
      // Perform form submission logic here (e.g., send data to the server)
      // Replace the console.log with your actual API call or desired action.
       if (!formData.coursename) {
         setIsValid(false)
         return
       }
      console.log('fjdksfslf')
      console.log('th id iisissi', _id)
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      axios
        .put(
          base_url+`admin/updatecourseName/${_id}`,
          formData,
          config
        )
        .then((res) => console.log('resp is', res))
        .catch((err) => console.log('error is'.err))

      console.log(formData)
      setIsValid(true)
    }


  // constructor(props) {
  //   super(props)

  //   this.state = {
  //     coursename: '', // Default value for the input field
  //     isValid: true, // Validation flag
  //   }
  // }

  // // async componentDidMount() {
  // //   try {
  // //      const { _id } = this.props
  // //     const response = await axios.get(
  // //       `http://clc-london.uk/api/admin/CourseName/${_id}`

  // //     )
  // //     const initialCourseName = response?.data?.data?.course_Name // Adjust this based on your API response structure
  // //     this.setState({ coursename: initialCourseName })
  // //   } catch (error) {
  // //     console.error('Error fetching initial course name:', error)
  // //   }
  // // }
  // async componentDidMount() {
  //   try {
  //     const { _id } = this.props.match.params // Access _id from route params
  //     console.log("first",_id)
  //     const courseData = await this.getCourseNameById(_id) // Call the function
  //     this.setState({ coursename: courseData.course_Name })
  //   } catch (error) {
  //     console.error('Error fetching initial course name:', error)
  //   }
  // }

  // getCourseNameById = async (_id) => {
  //   try {
  //     const response = await axios.get(
  //       `http://clc-london.uk/api/admin/courseName/${_id}`
  //     )

  //     if (response?.data?.data?.course_Name) {
  //       return response.data.data.course_Name
  //     } else {
  //       console.log('Unexpected API response structure:', response.data)
  //       throw new Error('Unexpected API response structure')
  //     }
  //   } catch (error) {
  //     console.error('Error fetching course data by ID:', error)
  //     throw error
  //   }
  // }

  // handleSubmit = (e) => {
  //   e.preventDefault()

  //   // Perform validation
  //   if (!this.state.coursename) {
  //     this.setState({ isValid: false })
  //     return
  //   }

  //   // Submit form data
  //   const formData = new FormData(e.target)
  //   // Add your code to handle form submission, e.g., sending data to the server

  //   // Reset validation flag
  //   this.setState({ isValid: true })
  // }

  // handleInputChange = (e) => {
  //   const { name, value } = e.target
  //   this.setState({ [name]: value })
  // }

  // render() {
  //   const { coursename, isValid } = this.state
 const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" style={{ minHeight: '844px' }}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Edit Course level</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/admin/foundationcourse">
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Edit Course level</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <input type="hidden" name="_token" value="" />
                    <input type="hidden" name="_method" value="PATCH" />
                    <div className="form-row">
                      <div className="col-md-4 mb-3 ">
                        <label htmlFor="validationCustom01">Course Name</label>
                        <input
                          type="text"
                          className={`form-control ${
                            !isValid ? 'is-invalid' : ''
                          }`}
                          id="validationCustom01"
                          placeholder=""
                          name="coursename"
                          value={formData.coursename}
                          onChange={handleChange}
                          required
                        />
                        <div
                          className={
                            isValid ? 'valid-feedback' : 'invalid-feedback'
                          }
                        >
                          {isValid ? 'Looks good!' : 'This field is required.'}
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary" type="submit">
                      Edit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCourseLevel
