import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userLoginReducer,
  userRegisterReducer,
  userResetPasswordReducer,
  verifyOtpReducer,
  changePasswordReducer,
} from './reducers/userReducers'
import { handlingSidebar } from './reducers/handlingSideBar';
import { feeManagementReducer } from './reducers/feeManagementReducer';
import { feeManagementStatusReducer } from './reducers/feeManagementStatusReducer';

const reducer = combineReducers({
  //this will contain our reducers
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  checkCloseOpen: handlingSidebar,
  feeManagmentDetail: feeManagementReducer,
  feeDetails: feeManagementStatusReducer,
  userResetPasswordReducer: userResetPasswordReducer,
  verifyOtpReducer: verifyOtpReducer,
  changePasswordReducer: changePasswordReducer,
})

// const userInfoFromStorage = localStorage.getItem("UserToken")
// ? JSON.parse(localStorage.getItem("UserToken"))
// : null;

const initialState = {
    // userToken: {userInfo: userInfoFromStorage},
    
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
