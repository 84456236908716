import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom' // Assuming you're using React Router
import { resetpassword, verifyotp, changepassword } from '../../actions/userAction'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import '../../screens/style.css'
import img1 from '../../screens/assets/clc-logo-removebg.png'
const ResetPassword = () => {
  const { resetToken } = useParams()
    const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [sendEmail, setSendEmail] = useState(true)
  const [sendOtp, setSendOtp] = useState(false)
  const [sendpassword, setSendPassword] = useState(false)
  const [notsame, setNotsame] = useState('')
  const [resettoken, setResetToken] = useState('')
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
  useEffect(() => {
    // Perform a backend request to validate the resetToken
    // This is a simplified example, and you would typically send the token to the server for validation

    // In a real-world scenario, you'd make an API request to your server
    // e.g., axios.post('http://clc-london.uk/validate-reset-token', { resetToken })

    // For simplicity, assume the token is valid
    // You might want to handle invalid tokens and expiration in a real application
    // Also, this is a security risk in a real application and should be handled securely
    if (!resetToken) {
      setErrorMessage('Invalid or expired reset token.')
    }
  }, [resetToken])

  const handleResetPassword = async (e) => {
    e.preventDefault()

    try {
      // In a real-world scenario, you would send the resetToken along with the new password to the server
      // e.g., axios.post('http://clc-london.uk/reset-password', { resetToken, password })

      // For simplicity, assume the password reset is successful
      setSuccessMessage(
        'Password reset successful. You can now log in with your new password.'
      )
    } catch (error) {
      setErrorMessage('Error resetting password. Please try again.')
    }
  }
  function sendingEmail() {
    if (email && validateEmail(email)) {
      dispatch(resetpassword(email))
        .then((res) => {
          console.log('reset paswword', res)
          setSendEmail(false)
          setSendOtp(true)
          setSendPassword(false)
          toast.success('Email sent successfully check your otp!')
        })
        .catch((error) => {
          console.log('error', error)
          toast.error('Error sending email. Please try again.')
        })
    } else {
      toast.error('Invalid email format. Please provide a valid email address.')
    }
  }
  function sendingOtp() {
    if(otp){
      dispatch(verifyotp(email,otp))
        .then((res) => {
          console.log('verifyotp', res.resetToken)
          setResetToken(res.resetToken)
          setSendEmail(false)
          setSendOtp(false)
          setSendPassword(true)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    
  }
  function sendingPassword() {
    
    if(password &&confirmPassword){
if (password !== confirmPassword) {
  setNotsame('Passwords are not same')
} else {
  console.log('token', resettoken)
  dispatch(changepassword(email, password, resettoken))
    .then((res) => {
      console.log('changepassword', res)
      setSendEmail(false)
      setSendOtp(false)
      setSendPassword(true)
        navigate('/login')
    })
    .catch((error) => {
      console.log('error', error)
    })
// email, newPassword, resetToken
}
    }
    
    // sendEmail(false)
    // sendOtp(false)
    // sendpassword(false)
  }
  return (
    <div>
      <ToastContainer />
      <div className=" font-sans text-gray-900">
        <div className="min-h-screen d-flex  flex-col sm-justify-center items-center pt-6 sm:pt-0">
          <div>
            <a href="/">
              <img
                className="img-fluid"
                src={img1}
                alt="Logo"
                style={{ width: '150px', height: '100px' }}
              />
            </a>
          </div>
          <div
            style={{ width: '400px' }}
            className="mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg"
          >
            {/* Display Validation Errors */}
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            {successMessage && (
              <div className="text-green-500">{successMessage}</div>
            )}
            <form onSubmit={handleResetPassword}>
              {/* New Password */}
              {sendEmail ? (
                <div>
                  <label
                    className="block font-medium text-sm text-gray-700"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    placeholder="Enter email"
                    className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              ) : (
                <></>
              )}
              {sendOtp ? (
                <div>
                  <label
                    className="block font-medium text-sm text-gray-700"
                    htmlFor="otp"
                  >
                    OTP
                  </label>
                  <input
                    placeholder="Enter otp"
                    className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full"
                    id="otp"
                    type="number"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
              ) : (
                <></>
              )}
              {sendpassword ? (
                <>
                  <div>
                    <label
                      className="block font-medium text-sm text-gray-700"
                      htmlFor="new-password"
                    >
                      New Password
                    </label>
                    <input
                      placeholder="Enter new password"
                      className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full"
                      id="new-password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <p>{notsame}</p>
                  {/* Confirm Password */}
                  <div>
                    <label
                      className="block font-medium text-sm text-gray-700"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password
                    </label>
                    <input
                      placeholder="Enter password to confirm"
                      className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full"
                      id="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {sendEmail ? (
                <div
                  className=" mt-4"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <button
                    onClick={() => sendingEmail()}
                    type="submit"
                    className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
                  >
                    Email Password Reset Link
                  </button>
                </div>
              ) : (
                <></>
              )}
              {sendpassword ? (
                <div className="flex items-center justify-end mt-4">
                  <button
                    onClick={() => sendingPassword()}
                    type="submit"
                    className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
                  >
                    change Password
                  </button>
                </div>
              ) : (
                <></>
              )}
              {sendOtp ? (
                <div className="flex items-center justify-end mt-4">
                  <button
                    onClick={() => sendingOtp()}
                    type="submit"
                    className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
                  >
                    view otp
                  </button>
                </div>
              ) : (
                <></>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
