import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'

const AddPermissions = () => {
  const [permissionName, setPermissionName] = useState('')
  const [isValid, setIsValid] = useState(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      axios
        .post(
          base_url+'admin/addPremissionName',
          { permissionName: permissionName },
          config
        )
        .then((res) => console.log('the res is', res))
        .catch((err) => console.log('the error is', err))
    } catch (error) {
      console.log('catch error is', error)
    }
  }

  const handlePermissionNameChange = (e) => {
    setPermissionName(e.target.value)
    setIsValid(e.target.value !== '') // Set validity based on whether the field is empty or not
  }
const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Add Permissions</h3>
                <ul className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Add Permissions</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <form
                        onSubmit={handleSubmit}
                        className="needs-validation"
                        noValidate
                      >
                        <div className="form-row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="validationCustom01">
                              Permission Name:
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                isValid ? 'is-valid' : 'is-invalid'
                              }`}
                              id="validationCustom01"
                              placeholder=""
                              name="name"
                              value={permissionName}
                              onChange={handlePermissionNameChange}
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              This field is required.
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <p>Copyright © 2020.</p>
        </footer>
      </div>
    </div>
  )
}

export default AddPermissions
