import { Fee_Management_Detail } from "../constants/feeManagementConstant";


export const feeManagementReducer=(state={},action)=>{
    switch (action.type) {
      case Fee_Management_Detail:
        return { feeManagementData: action.payload }
      default:
        return state
    }
}