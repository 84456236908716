import React, { useState, useEffect, useContext, useRef } from 'react'

import 'iconify-icon'
import './SideBar.css'

import '../style.css'
// import './style.css'
import { Link, useNavigate } from 'react-router-dom'
// import { useState } from 'react'
import Courses from '../course/courses/Courses'
import Dashboard from './Dashboard/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { SIDEBAR_HANDLING } from '../../constants/handlingSidebarConstant'
import axios from 'axios'
import { RoleContext } from '../../context/RoleContext'
import { USER_LOGOUT } from '../../constants/userConstants'
import { base_url, base_url1 } from '../../utils/Api'
import image1 from '../assets/dashboardlogo.png'

import image2 from '../assets/7a506246e9e1ea96287ac0e44774f7c9.jpg'
function Sidebar() {
  const type = useContext(RoleContext)
  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  // const handleSubMenuToggle = () => {
  //   setIsSubMenuOpen(!isSubMenuOpen);
  // };
   const [profileImage, setProfileImage] = useState(null)
  const { userInfo } = useSelector((state) => state.userLogin)
  console.log('the user is', userInfo?.user?.firstName)
  
const [response1, setResponse] = useState(null)
  const [show, setShow] = useState(false)
  const [smallScreen, setSmallScreen] = useState(false)
  const [active, setActive] = useState({ component: 'Dashboard' })
  const navigate = useNavigate()
  const dropdownRef = useRef(null)
  console.log('the tpe is ', type)



  const toggleSidebar = () => {
    // console.log(isSidebarOpen)
    setSidebarOpen(!isSidebarOpen)
  }
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dispatch = useDispatch()

  // localStorage.setItem("isSidebarOpen",isSidebarOpen)
  useEffect(()=>{
console.log('userinfo1111', userInfo)
checkUser()
  },[userInfo])
  useEffect(() => {
     console.log('the user1 is', userInfo)
    dispatch({ type: SIDEBAR_HANDLING, payload: isSidebarOpen })
  }, [isSidebarOpen])

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }
  const dropdownStyle = {
    position: 'absolute',
    willChange: 'transform',
    top: '0px',
    left: '0px',
    transform: isDropdownOpen
      ? 'translate3d(-105px, 60px, 0px)'
      : 'translate3d(-9999px, -9999px, 0px)',
    zIndex: '1000',
  }
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const arrowSymbol = isDropdownOpen ? '▲' : '▼'
  const [managesidebar, setManageSidebar] = useState([false, false])

  const handleCourseSubMenuToggle = () => {
    let arr = [...managesidebar]
    arr[0] = !managesidebar[0]
    arr[1] = false
    // console.log(arr)
    setManageSidebar(arr)
    // setIsCourseSubMenuOpen(!isCourseSubMenuOpen)
  }

  const handleRoleSubMenuToggle = () => {
    let arr = [...managesidebar]
    arr[1] = !managesidebar[1]
    arr[0] = false
    // console.log(arr)
    setManageSidebar(arr)

    // setIsRoleSubMenuOpen(!isRoleSubMenuOpen)
  }
  const usertoken = localStorage.getItem('UserToken')
  useEffect(() => {
    console.log()
    
  }, [])
  const checkUser = async () => {
    try {
      axios
        .post(
          base_url + 'user/userData',
          {},
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === '201') {
            console.log("jhhhjhjhjhhj",response.data)
            // setRole(response?.data?.UserData?.type)
            setProfileImage(response?.data?.UserData?.profile)
            setResponse(response?.data.UserData?.type)

          
            localStorage.setItem('role', response?.data?.UserData?.type)
          }
        })
        .catch((error) => {
          console.error('the error is', error)
        })
    } catch (error) {
      console.error(error)
    }
  }
  const testIn = () => {
    // console.log('test In')
  }
  // ------------screensize----------

  // ----------------endscreensize--------
  const arrowStyle = {
    color: '#23a4d8', // Set the arrow color to blue
    marginLeft: '5px', // Apply left margin to the arrow
    padding: '5px', // Apply padding to the arrow
  }
  // const isOpen=1;
  const [isOpen, setIsOpen] = useState(false)

  const handleMenuClick = () => {
    // console.log('first')
    setIsOpen(!isOpen)
  }
  const handleOutsideHover = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  }

  const logouthandler = () => {
    console.log('click')
    dispatch({ type: USER_LOGOUT })
    localStorage.removeItem('UserToken')

    // console.log('toke is', token)
    return navigate('/login')
  }
  // console.log('the role of the adiklsfkds', localStorage.getItem('RoleWork'))
  const role = localStorage.getItem('RoleWork')
  const [permissions, setPermissions] = useState('')
  useEffect(() => {
  
    getAllList()
  }, [role])
  const getAllList = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const res = await axios.get(base_url + 'admin/getRoleNames', config)
      const responseData = res?.data?.data
      // console.log('the response data is', responseData)
      responseData.map((item) => {
        // console.log('the items is', item.Role_Name)
        if (item.Role_Name === role) {
          setPermissions(item.Permission)
        }
      })
    } catch (err) {
      // console.log('error is---', err)
    }
  }
  // console.log('the permisson setting is', permissions)
  const permissionObject =
    permissions &&
    permissions.reduce((obj, item) => {
      obj[item.label] = item.checked
      return obj
    }, {})

  // console.log(permissionObject['Application access'])
  return (
    <div style={{}}>
      {/* <div
        className={isSidebarOpen ? `slide-menu ${isOpen && 'menu-opened'}` : ''}
      > */}
      <div
        className={isSidebarOpen ? `mini-sidebar ${show && 'expand-menu'}` : ''}
      >
        <div className={smallScreen ? 'slide-nav ' : ''}>
          {/* Header Component */}
          <div className="header">
            <div className="header-left">
              <Link to="" className="logo">
                <img src={image1} alt="Logo" className="mt-4" />
              </Link>
              <Link to="" className="logo logo-small">
                <img src={image2} alt="Logo" width="30" height="30" />
              </Link>
            </div>
            <Link to="#" id="toggle_btn" onClick={toggleSidebar}>
              <i
                className={`fas ${
                  isSidebarOpen ? 'fa-align-left' : 'fa-align-right'
                }`}
              ></i>
            </Link>
            <a
              // to=""
              className="mobile_btn"
              id="mobile_btn"
              // onClick={handleMenuClick}
              onClick={() => setSmallScreen(!smallScreen)}
            >
              <i
                // className={`fas ${isSidebarOpen ? 'fa-times' : 'fa-bars'}`}
                className="fas fa-bars"
              ></i>
            </a>
            <ul className="nav user-menu">
              <li
                className="nav-item dropdown "
                ref={dropdownRef}
                onMouseEnter={() => handleDropdownToggle(true)}
                onMouseLeave={handleOutsideHover}
              >
                <div
                  // to=""
                  className={`dropdown-toggle nav-link ${
                    isDropdownOpen ? 'down' : 'up'
                  }`}
                  aria-expanded="true"
                  onClick={handleDropdownToggle}
                >
                  <span className="avatar avatar-sm">
                    <img
                      className="rounded-circle"
                      src={
                        response1 === 'user'
                          ? base_url1 + `files/${profileImage}`
                          : image2
                      }
                      width="31"
                      alt="Ryan Taylor"
                    />
                  </span>
                  <span style={arrowStyle}>{arrowSymbol}</span>
                  {/* {isDropdownOpen ? (
                    <span style={arrowStyle}>▲</span>
                  ) : (
                    <span style={arrowStyle}>▼</span>
                  )} */}
                </div>
                {isDropdownOpen && (
                  <div
                    className="dropdown-menu show "
                    style={dropdownStyle}
                    x-placement="bottom-start"
                  >
                    <div className="user-header">
                      <div className="avatar avatar-sm">
                        <img
                          src={
                            response1 === 'user'
                              ? base_url1 + `files/${profileImage}`
                              : image2
                          }
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="user-text">
                        <h6>
                          {userInfo?.UserData?.firstName ||
                            userInfo?.UserData?.name}
                        </h6>
                      </div>
                    </div>
                    {/* <form method="POST" action="http://clc-london.uk/logout"> */}
                    <input
                      type="hidden"
                      name="_token"
                      value="2sQKI8l28csYzIoDDd3DastnZZq9sNZn7HmzVYJK"
                    />
                    <Link
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      to=""
                      onClick={() => logouthandler()}
                      // onClick={(e) => {
                      //   e.preventDefault()
                      //   e.target.closest('form').submit()
                      // }}
                    >
                      Log Out
                    </Link>
                    {/* </form> */}
                  </div>
                )}
              </li>
            </ul>
          </div>
          {/* Side Bar Component */}

          <div
            className="sidebar"
            id="sidebar"

            // style={{ display: isSidebarOpen ? 'block' : 'none' }}
            // style={{ backgroundColor: 'red' }}
          >
            <div
              className="slimScrollDiv"
              style={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '729px',
              }}
            >
              <div
                className="sidebar-inner slimscroll "
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  height: '729px',
                }}
              >
                <div
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                  id="sidebar-menu"
                  className="sidebar-menu"
                >
                  {/* -----------screeen------------ */}

                  <ul>
                    <li className="submenudash submenu active">
                      {/* <Link to="/"> */}
                      <Link onClick={() => setSmallScreen(false)} to="/">
                        <i className="fas fa-user-graduate"></i>{' '}
                        <span>Dashboard</span>
                      </Link>
                      {/* </Link> */}
                    </li>

                    {type === 'admin' ? (
                      <>
                        <li className={`submenu ${managesidebar[0] ? '' : ''}`}>
                          <Link onClick={handleCourseSubMenuToggle}>
                            <i className="fas fa-user-graduate"></i>{' '}
                            <span>Course</span>{' '}
                            <span
                              className={`menu-arrow ${
                                managesidebar[0] ? 'active' : ''
                              }`}
                            ></span>
                          </Link>
                          <ul
                            style={{
                              display: managesidebar[0] ? 'block' : 'none',
                            }}
                          >
                            <li>
                              {/* <Link to="http://clc-london.uk/admin/courses">Courses</Link> */}
                              <Link
                                onClick={() => {
                                  let arr = [...managesidebar]
                                  arr[1] = false
                                  arr[0] = false
                                  // console.log(arr)
                                  setManageSidebar(arr)
                                  setSmallScreen(false)
                                }}
                                to="/admin/courses"
                              >
                                Courses
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  let arr = [...managesidebar]
                                  arr[1] = false
                                  arr[0] = false
                                  // console.log(arr)
                                  setManageSidebar(arr)
                                  setSmallScreen(false)
                                }}
                                to="/admin/foundationcourse"
                              >
                                Course Level
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  let arr = [...managesidebar]
                                  arr[1] = false
                                  arr[0] = false
                                  // console.log(arr)
                                  setManageSidebar(arr)
                                  setSmallScreen(false)
                                }}
                                to="/admin/commencement"
                              >
                                Commencement
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/studentslist"
                          >
                            <i className="fas fa-user"></i>
                            <span>Students</span>
                          </Link>
                        </li>

                        <li>
                          {/* {permissionObject['Application access'] ? ( */}
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/applications"
                          >
                            <i className="fas fa-file"></i>{' '}
                            <span>Application</span>
                          </Link>
                          {/* ) : ( */}
                          {/* <Link onClick={() => setSmallScreen(false)} to="">
                          <i className="fas fa-file"></i>{' '}
                          <span>Application</span>
                        </Link>
                      )} */}
                        </li>

                        <li>
                          {/* {permissionObject['fee management'] ? ( */}
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/fee-management"
                          >
                            <i className="fas fa-dollar-sign"></i>{' '}
                            <span>Fee Management</span>
                          </Link>
                          {/* ) : (
                            <Link onClick={() => setSmallScreen(false)} to="">
                              <i className="fas fa-dollar-sign"></i>{' '}
                              <span>Fee Management</span>
                            </Link>
                          )} */}
                        </li>

                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/fee-status"
                          >
                            <i className="fas fa-dollar-sign"></i>{' '}
                            <span>Fee Status</span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/student-letters"
                          >
                            <i className="fas fa-envelope"></i>{' '}
                            <span>Student Letter</span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/template"
                          >
                            <i className="fas fa-envelope"></i>{' '}
                            <span>Email Template</span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/admin/editprofile"
                          >
                            <i className="fas fa-user"></i> <span>Profile</span>
                          </Link>
                        </li>

                        <li className={`submenu ${managesidebar[1] ? '' : ''}`}>
                          <Link to="#" onClick={handleRoleSubMenuToggle}>
                            <i className="fas fa-user-graduate"></i>{' '}
                            <span>Role and permissions</span>{' '}
                            <span
                              className={`menu-arrow ${
                                managesidebar[1] ? 'active' : ''
                              }`}
                            ></span>
                          </Link>
                          <ul
                            style={{
                              display: managesidebar[1] ? 'block' : 'none',
                            }}
                          >
                            <li>
                              <Link
                                onClick={() => {
                                  let arr = [...managesidebar]
                                  arr[1] = false
                                  arr[0] = false
                                  // console.log(arr)
                                  setManageSidebar(arr)
                                  setSmallScreen(false)
                                }}
                                to="/admin/roles"
                              >
                                Roles
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  let arr = [...managesidebar]
                                  arr[1] = false
                                  arr[0] = false
                                  // console.log(arr)
                                  setManageSidebar(arr)
                                  setSmallScreen(false)
                                }}
                                to="/admin/permissions"
                              >
                                Permissions
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  let arr = [...managesidebar]
                                  arr[1] = false
                                  arr[0] = false
                                  // console.log(arr)
                                  setManageSidebar(arr)
                                  setSmallScreen(false)
                                }}
                                to="/admin/users"
                              >
                                Users
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          {/* <Link to="http://clc-london.uk/admin/courses">Courses</Link> */}
                          <Link
                            // onClick={() => {
                            //   let arr = [...managesidebar]
                            //   arr[1] = false
                            //   arr[0] = false
                            //   // console.log(arr)
                            //   setManageSidebar(arr)
                            //   setSmallScreen(false)
                            // }}
                            to="/student/courses"
                            onClick={() => setSmallScreen(false)}
                          >
                            <i className="fas fa-user-graduate"></i>{' '}
                            <span>Course</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/user/student-form"
                          >
                            <i className="fas fa-user-graduate"></i>{' '}
                            <span> Application Form</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/student/application"
                            onClick={() => setSmallScreen(false)}
                          >
                            <i className="fas fa-user"></i>{' '}
                            <span> Student Application view</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/Student/fee-management"
                          >
                            <i className="fas fa-dollar-sign"></i>{' '}
                            <span>Fee Managements</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/student/fee"
                          >
                            <i className="fas fa-dollar-sign"></i>{' '}
                            <span>Fee Status</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/student/profile"
                          >
                            <i className="fas fa-user"></i> <span>Profile</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setSmallScreen(false)}
                            to="/student/student-letters"
                          >
                            <i className="fas fa-envelope"></i>{' '}
                            <span>Student Letter</span>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div
                className="slimScrollBar"
                style={{
                  background: 'rgb(204, 204, 204)',
                  width: '7px',
                  position: 'absolute',
                  top: '0px',
                  opacity: '0.4',
                  display: 'none',
                  borderRadius: '7px',
                  zIndex: '99',
                  right: '1px',
                  height: '729px',
                }}
              ></div>
              <div
                className="slimScrollRail"
                style={{
                  width: '7px',
                  height: '100%',
                  position: 'absolute',
                  top: '0px',
                  display: 'none',
                  borderRadius: '7px',
                  background: 'rgb(51, 51, 51)',
                  opacity: '0.2',
                  zIndex: '90',
                  right: '1px',
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* Dashboard Component */}
        {/* {mainPanel.componentName === 'Dashboard' && ( */}
        {/* {active.component === 'Dashboard' && (
          <div>
            <Dashboard />
          </div>
        )} */}
        {/* )} */}
        {/* Course Level
        {mainPanel.componentName === 'Course_Level' && <Courses />} */}
        <div
          className={smallScreen ? 'sidebar-overlay opened' : 'sidebar-overlay'}
        ></div>
      </div>
      //{' '}
    </div>
  )
}
export default Sidebar
