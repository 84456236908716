import React from 'react'

import { useSelector } from 'react-redux'
import '../../../screens/style.css'
function Dashboarduser() {
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Welcome </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright &copy; {new Date().getFullYear()}.</p>
        </footer>
      </div>
    </div>
  )
}
export default Dashboarduser
