export const data = [
  {
    heading: 'Foundation Courses:',
    item: [
      { value: 'Business', label: 'Business' },
      { value: 'Law', label: 'Law' },
    ],
  },
  {
    heading: 'Undergraduate Courses:',
    item: [
      { label: 'HNC L4 in Business' },
      { label: 'HND L5 in Business' },
      { label: 'HNC L4 in Computing' },
      { label: 'HND L5 in Computing' },
      { label: 'VTCT L4 in Education & Training' },
      { label: 'VTCT L5 in Education & Training' },
      { label: 'BTEC L5 in Education & Training' },
      { label: 'HND L5 in Hospitality' },
    ],
  },
  {
    heading: 'Mode of study:',
    item: [
      { label: 'Full-time' },
      { label: 'Part-time' },
      { label: 'Part-time (Evening)' },
      { label: 'One-Day Release' },
    ],
  },
  {
    heading: 'Commencement: ',
    small_text:
      '(Please indicate the date you wish to commence your course of studies by ticking one of the following)',
    item: [
      { label: 'September 2022' },
      { label: 'January 2023' },
      { label: 'March/April 2023 ' },
      { label: 'July 2023' },
    ],
  },
  {
    heading: 'Course Duration: ',
    small_text:
      '(Please indicate the duration of your intended course by ticking one of the following):',
    item: [
      { label: '1 term' },
      { label: '2 terms' },
      { label: '3 terms (1 year)' },
    ],
  },
  {
    heading: 'Who will pay your fees? ',
    small_text: '(Please tick as appropriate & attach evidence):',
    item: [
      { label: 'Parents' },
      { label: 'Family member' },
      { label: 'Employer' },
      { label: 'Self' },
      { label: 'Others please specify' },
    ],
  },
]

export const foundation_course = {
  heading: 'Foundation Courses:',
  item: [
    { value: 'Business', label: 'Business' },
    { value: 'Law', label: 'Law' },
  ],
}

export const undergraduate_course = {
  heading: 'Undergraduate Courses:',
  item: [
    { label: 'HNC L4 in Business' },
    { label: 'HND L5 in Business' },
    { label: 'HNC L4 in Computing' },
    { label: 'HND L5 in Computing' },
    { label: 'VTCT L4 in Education & Training' },
    { label: 'VTCT L5 in Education & Training' },
    { label: 'BTEC L5 in Education & Training' },
    { label: 'HND L5 in Hospitality' },
  ],
}

export const mode_of_study = {
  heading: 'Mode of study:',
  item: [
    { label: 'Full-time' },
    { label: 'Part-time' },
    { label: 'Part-time (Evening)' },
    { label: 'One-Day Release' },
  ],
}

export const commencement = {
  heading: 'Commencement: ',
  small_text:
    '(Please indicate the date you wish to commence your course of studies by ticking one of the following)',
  item: [
    { label: 'September 2024' },
    { label: 'January 2024' },
    { label: 'March/April 2024 ' },
    { label: 'July 2024' },
  ],
}

export const course_duration = {
  heading: 'Course Duration: ',
  small_text:
    '(Please indicate the duration of your intended course by ticking one of the following):',
  item: [
    { label: '1 term' },
    { label: '2 terms' },
    { label: '3 terms (1 year)' },
  ],
}

export const pay_fees = {
  heading: 'Who will pay your fees? ',
  small_text: '(Please tick as appropriate & attach evidence):',
  item: [
    { label: 'Parents' },
    { label: 'Family member' },
    { label: 'Employer' },
    { label: 'Self' },
    { label: 'Others please specify' },
  ],
}

export const tabel_achieved_data = [
  {
    heading: '3. Academic qualifications – examinations achieved:',
    paragraph:
      'Please list all qualifications taken, whatever the result, in chronological order, Documentary evidence of known results should be submitted with this form. Only certified photocopies are acceptable',
    tabel_title: [
      { tabel_heading: 'Date Awarded(mm/yyyy)' },
      { tabel_heading: 'Examining Board/Institution' },
      { tabel_heading: 'Subjects' },
      { tabel_heading: 'Qualification' },
      { tabel_heading: 'Results achieved' },
    ],
    title_value: [0, 1, 3, 4],
  },
]

export const tabel_pending_data = [
  {
    heading: '4. Academic qualifications – examinations pending:',
    paragraph:
      'Complete this section only if you are awaiting the results of any examinations taken recently.',
    tabel_title: [
      { tabel_heading: 'Exams Date(mm/yyyy)' },
      { tabel_heading: 'Examining Board/Institution' },
      { tabel_heading: 'Subjects' },
      { tabel_heading: 'Qualification' },
      // { tabel_heading: 'Results achieved' },
    ],
    title_value: [0, 1, 2, 3],
  },
]
export const Interviewed = [
  { value: 'Yes', label: 'YES' },
  { value: 'No', label: 'NO' },
]

export const tabel_work_experience = [
  {
    heading: '6. Work Experience (if any):',
    paragraph: 'Please give details of work experience, training and employmen',
    tabel_title: [
      { tabel_heading: 'Date from (mm/yyyy)' },
      { tabel_heading: 'Date to (mm/yyyy)' },
      { tabel_heading: 'FT/PT*' },
      { tabel_heading: 'Post held' },
      { tabel_heading: 'ROrganisation name and address' },
      { tabel_heading: 'Brief description of duties' },
    ],
    title_value: [0, 1, 2, 3],
    drop_down_value: [
      { option: 'Select Job Mode' },
      { option: 'Full Time' },
      { option: 'Part Time' },
    ],
    sub_paragraph: 'If currently employed, current employer details:',
    sub_tabel_title: [
      { tabel_heading: 'Date from (mm/yyyy)' },
      { tabel_heading: 'FT/PT*' },
      { tabel_heading: 'Post held' },
      { tabel_heading: 'ROrganisation name and address' },
      { tabel_heading: 'Brief description of duties' },
    ],
    sub_title_value: [0],
  },
]

export const further_info = [
  {
    title: '7. Further Information:',
    paragraph:
      'Please use this section to tell us about yourself and your reasons for wanting to study this course',
    questions: [
      { ques: 'Please give reasons for choosing this programme of study' },
      { ques: 'What are your future education plans?' },
      {
        ques: 'What kind of employment do you intend to seek on completion of your studies?',
      },
      {
        ques: 'Please write brief statement about your interests and hobbies.',
      },
      {
        ques: 'To help us provide assistance wherever possible please state briefly if you have any special needs requiring support or facilities',
      },
      {
        ques: 'Are you currently on any state benefits? If so, please explain type of benefits:',
      },
      {
        ques: 'Do you have any criminal record within or outside the UK? If yes, please state:',
      },
    ],
  },
]

export const reference = [
  {
    title: '8. Reference:',
    paragraph:
      'This section must be completed by your present or past teacher, employer or a person who knows you well, academically and/or socially.Incomplete application forms will not be processed. An interview with the applicant will be required. ',
    sub_title: 'Note to Referee:',
    sub_paragraph:
      'As many potential candidates apply, selection is extremely competitive. Your comments will provide us with important information in assessing this application. ',
    questions: [
      { ques: 'How long have you known the applicant and in what capacity?' },
      {
        ques: 'What is your opinion of the Applicants suitability for the course chosen?',
      },
    ],
    tabel_top_text: 'Please tick appropriately – ONE tick per row:',
    tabel_title: [
      { title: '' },
      { title: 'Outstanding' },
      { title: 'Excellent 90-94%' },
      { title: 'Very Good 75-89%' },
      { title: 'Good 60-74%' },
      { title: 'Average' },
      { title: 'Below Average' },
      { title: 'Not know' },
    ],
    logical_tabel_divider: [
      { value: 'Outstanding', checked: false },
      { value: 'Excellent 90-94%', checked: false },
      { value: 'Very Good 75-89%', checked: false },
      { value: 'Good 60-74%', checked: false },
      { value: 'Average', checked: false },
      { value: 'Below Average', checked: false },
      { value: 'Not know', checked: false },
    ],
    tabel_title_value: [
      // {
      //   logical_tabel_divider: [
      //     { value: 'Outstanding' },
      //     { value: 'Excellent 90-94%' },
      //     { value: 'Very Good 75-89%' },
      //     { value: 'Good 60-74%' },
      //     { value: 'Average' },
      //     { value: 'Below Average' },
      //     { value: 'Not know' },
      //   ]
      // },
      {
        value: 'Intellectual / Academic ability',
        item: [
          { value: 'Outstanding', checked: false },
          { value: 'Excellent 90-94%', checked: false },
          { value: 'Very Good 75-89%', checked: false },
          { value: 'Good 60-74%', checked: false },
          { value: 'Average', checked: false },
          { value: 'Below Average', checked: false },
          { value: 'Not know', checked: false },
        ],
      },
      {
        value: 'Fluent and logical communication in English',
        divider: [
          {
            text: 'oral',
            item: [
              { value: 'Outstanding', checked: false },
              { value: 'Excellent 90-94%', checked: false },
              { value: 'Very Good 75-89%', checked: false },
              { value: 'Good 60-74%', checked: false },
              { value: 'Average', checked: false },
              { value: 'Below Average', checked: false },
              { value: 'Not know', checked: false },
            ],
          },
          {
            text: 'written',
            item: [
              { value: 'Outstanding', checked: false },
              { value: 'Excellent 90-94%', checked: false },
              { value: 'Very Good 75-89%', checked: false },
              { value: 'Good 60-74%', checked: false },
              { value: 'Average', checked: false },
              { value: 'Below Average', checked: false },
              { value: 'Not know', checked: false },
            ],
          },
        ],
        // oral: {
        //   text: 'oral',
        //   item: [
        //     { value: 'Outstanding' },
        //     { value: 'Excellent 90-94%' },
        //     { value: 'Very Good 75-89%' },
        //     { value: 'Good 60-74%' },
        //     { value: 'Average' },
        //     { value: 'Below Average' },
        //     { value: 'Not know' },
        //   ],
        // },
        // written: {
        //   text: 'written',
        //   item: [
        //     { value: 'Outstanding' },
        //     { value: 'Excellent 90-94%' },
        //     { value: 'Very Good 75-89%' },
        //     { value: 'Good 60-74%' },
        //     { value: 'Average' },
        //     { value: 'Below Average' },
        //     { value: 'Not know' },
        //   ],
        // },
      },
      {
        value: 'Ability to work hard',
        item: [
          { value: 'Outstanding', checked: false },
          { value: 'Excellent 90-94%', checked: false },
          { value: 'Very Good 75-89%', checked: false },
          { value: 'Good 60-74%', checked: false },
          { value: 'Average', checked: false },
          { value: 'Below Average', checked: false },
          { value: 'Not know', checked: false },
        ],
      },
      {
        value: 'Perseverance',
        item: [
          { value: 'Outstanding', checked: false },
          { value: 'Excellent 90-94%', checked: false },
          { value: 'Very Good 75-89%', checked: false },
          { value: 'Good 60-74%', checked: false },
          { value: 'Average', checked: false },
          { value: 'Below Average', checked: false },
          { value: 'Not know', checked: false },
        ],
      },
      {
        value: 'Leadership',
        item: [
          { value: 'Outstanding', checked: false },
          { value: 'Excellent 90-94%', checked: false },
          { value: 'Very Good 75-89%', checked: false },
          { value: 'Good 60-74%', checked: false },
          { value: 'Average', checked: false },
          { value: 'Below Average', checked: false },
          { value: 'Not know', checked: false },
        ],
      },
      {
        value: 'Creativity',
        item: [
          { value: 'Outstanding', checked: false },
          { value: 'Excellent 90-94%', checked: false },
          { value: 'Very Good 75-89%', checked: false },
          { value: 'Good 60-74%', checked: false },
          { value: 'Average', checked: false },
          { value: 'Below Average', checked: false },
          { value: 'Not know', checked: false },
        ],
      },
      {
        value: 'Concern for others',
        item: [
          { value: 'Outstanding', checked: false },
          { value: 'Excellent 90-94%', checked: false },
          { value: 'Very Good 75-89%', checked: false },
          { value: 'Good 60-74%', checked: false },
          { value: 'Average', checked: false },
          { value: 'Below Average', checked: false },
          { value: 'Not know', checked: false },
        ],
      },
    ],
  },
]

export const marketing_info = [
  {
    title: '9. Marketing Information:',
    paragraph:
      'It would be helpful if you could kindly tell us where you heard about the City of London College by ticking the appropriate box',
    check_boxes: [
      { key: 'Newspaper/Magazine' },
      { key: 'Name of Newspaper/Magazine' },
      { key: 'Relative/Friend' },
      { key: 'Internet' },
      { key: 'Social Media' },
      { key: 'Other sources (please state)' },
    ],
  },
]
export const equal_oppertunity = [
  {
    title: '10. Equal opportunities monitoring:',
    paragraph:
      'Your response will not influence the outcome of your application. You do not have to answer this question if you do not wish.',
    sub_title_1: 'Ethnic origin',
    check_boxes_1: [
      { key: 'White' },
      { key: 'Asian or Asian British - Pakistani' },
      { key: 'Mixed – white and Asian' },
      { key: 'Gypsy or traveller' },
      { key: 'Asian or Asian British - Bangladeshi' },
      { key: 'Other mixed background' },
      { key: 'Black or black British - Caribbean' },
      { key: 'Chinese' },
      { key: 'Arab' },
      { key: 'Black or black British - African' },
      { key: 'Other Asian background' },
      { key: 'Other ethnic background' },
      { key: 'Other black background' },
      { key: 'Mixed – white and black Caribbean' },
      { key: 'Prefer not to say' },
      { key: 'Asian or Asian British - Indian' },
      { key: 'Mixed – white and black African' },
    ],
    sub_title_2: 'Religion',
    check_boxes_2: [
      { key: 'Christian' },
      { key: 'Muslim' },
      { key: 'No religion' },
      { key: 'Buddhist' },
      { key: 'Sikh' },
      { key: 'Any other religion or belief' },
      { key: 'Hindu' },
      { key: 'Bahá’í Faith' },
      { key: 'Prefer not to say' },
      { key: 'Jewish' },
      { key: 'Pagan' },
      { key: 'Other' },
    ],
    declaration_heading: 'Declaration:',
    declaration_paragraph:
      'I confirm that, to the best of my knowledge, the information in this form is correct. I have read the College prospectus/brochure and the College website. I understand and agree to abide by the conditions and regulations set out there, which I accept as a condition of this application:',

    enrolment_procedure_title: 'Enrolment Procedure:',
    enrolment_procedure_1:
      '1. Complete the Application for Admission form, and send it with',
    enrolemnt_procedure_1_ques: [
      {
        ques: 'a) certified copies of relevant academic certificates & work related documents',
      },
      {
        ques: 'b) 1 colour photo',
      },
      {
        ques: 'c) copy of passport page',
      },
      {
        ques: 'd) copy of National Insurance number document',
      },
      {
        ques: 'e) proof of address (driving license or bank statement) send to the Registrar, City of London College, 3 Boyd Street, London E1 1FQ. ',
      },
    ],
    enrolement_procedure_2:
      '2. When your application has been processed, the College will issue a Letter of Acceptance (LOA).',
  },
]
