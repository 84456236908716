
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

// ------------------------ADMIN ROUTES--------------------
const AdminRoute = ({ auth, children }) => {
    const userLogin = useSelector((state) => state.userLogin)
    if (!auth) {
        return <Navigate to="/login" />
    } else {
        if (userLogin?.userInfo?.UserData?.type === "admin") {
            return children
        }
    }
};
// ------------------------------USER ROUTES------------------------
const UserRoute = ({ auth, children }) => {
    const userLogin = useSelector((state) => state.userLogin)
    if (!auth) {
        return <Navigate to="/login" />
    } else {
        if (userLogin?.userInfo?.UserData?.type === "user") {
            return children
        }
    }
};
export {
    AdminRoute,
    UserRoute,
}


