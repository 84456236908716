import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { base_url } from '../../../utils/Api'
import './courses.css'



const AddCourseForm = () => {
  const [courseData, setCourseData] = useState({
    coursename: '',
    startdate: '',
    enddate: '',
    fee: '',
    courselevel: '',
    commencement: '',
    age: '',
    studyMode: '',
    isinstallment: false,
    installment: [{ installment_Date: '', installment_No: '' }],
    course_status: 'In Progress',
  })
  const [installment, setInstallment] = useState([])
  const [sessionData, setSessionData] = useState([])
   const [courseNmae, setcourseNmae] = useState([])
  const [validationErrors, setValidationErrors] = useState({
    coursename: '',
    startdate: '',
    enddate: '',
    fee: '',
    courselevel: '',
    commencement: '',
    age: '',
    studyMode: '',
    
  })
  const getSessionNames = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const res = await axios.get(
       base_url+'admin/getSessionNames',
        config
      )
      const data = res?.data?.data
      // console.log('datas', data)
      return data // Return the data
    } catch (error) {
      console.error('Error fetching session names:', error)
      throw error // Propagate the error
    }
  } 
  useEffect(() => {
    // console.log('data', getSessionNames)
    getSessionNames()
      .then((data) => {
        setSessionData(data)
        console.log('data', data)
      })
      .catch((error) => {
        // Handle the error here if needed
      })
  }, [])
    const getCourseNames = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
          },
        }
        const res = await axios.get(
         base_url+ 'admin/getCoursesNames',
          config
        )
        const data = res?.data?.data
        return data // Return the data
      } catch (error) {
        console.error('Error fetching session names:', error)
        throw error // Propagate the error
      }
    }
    useEffect(() => {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.getElementsByClassName('needs-validation')

      // Loop over them and prevent submission
      const validation = Array.prototype.filter.call(forms, (form) => {
        form.addEventListener('submit', (event) => {
          if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        })
      })
    }, [])

    useEffect(() => {
      // console.log('data', getSessionNames)
      
      getCourseNames()
        .then((data) => {
          setcourseNmae(data)
          console.log('data', data)
        })
        .catch((error) => {
          // Handle the error here if needed
        })
    }, [])
  const handleInstallmentChange = (index, event) => {
    const newValue = event.target.value

    // Create a new copy of the installment array
    const newInstallment = [...courseData.installment]
    newInstallment[index].installment_Date = newValue
    newInstallment[index].installment_No = index + 1

    // Update the courseData state with the new installment array
    setCourseData({
      ...courseData,
      installment: newInstallment,
    })
  }

  const addInstallmentRow = () => {
    setInstallment([...installment, { date: '' }])
  }

  const deleteInstallmentRow = (index) => {
    courseData.installment.pop()
    let array = []
    courseData.installment.map((item) => array.push(item))
    setCourseData({
      ...courseData,
      installment: array,
    })
  }

 
  const handleInputChange = (event) => {
    const { name, value } = event.target

    if (name.startsWith('insdate[')) {
      const index = parseInt(name.replace('insdate[', '').replace(']', ''), 10)
      const updatedInstallment = [...installment]
      updatedInstallment[index] = { date: value }
      setInstallment(updatedInstallment)

      const newErrors = { ...validationErrors }
      if (value.trim() === '') {
        newErrors.installment = 'All installment dates are required.'
      } else {
        newErrors.installment = ''
      }
      setValidationErrors(newErrors)
    } else {
      setCourseData({
        ...courseData,
        [name]: value,
      })

      setValidationErrors({
        ...validationErrors,
        [name]: value.trim() === '' ? 'This field is required.' : '',
      })
    }
  }

  const editInstallmentRow = () => {
    // setInstallment([...installment, { date: '' }])
    setCourseData({
      ...courseData,
      installment: [
        ...courseData.installment,
        { installment_Date: '', installment_No: '' },
      ],
    })
  }



  const handleSubmit = (event) => {
    event.preventDefault()
     
    console.log("the data is🤦‍♂️",courseData)
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    axios
      .post(
        base_url+'admin/course/addCourse',
        courseData,
        config
      )
      .then((res) => {
        console.log('the res is', res)
        
          // Show a success toast
          toast.success('Course successfully added!')
            // position: toast.POSITION.TOP_CENTER,
          
        })
      .catch((err) => {
        console.log('the error is', err)
        // Show an error toast if the request fails
        toast.error('Failed to add course. Please try again.')
          // position: toast.POSITION.TOP_CENTER)
      })

  
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <ToastContainer />

      <div className="page-wrapper" style={{ minHeight: '845px' }}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Add Course</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Add Course</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  {/* <form
                  className="needs-validation"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Course Name</label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationErrors.coursename && 'is-invalid'
                        }`}
                        id="validationCustom01"
                        placeholder=""
                        name="coursename"
                        value={courseData.coursename}
                        onChange={handleInputChange}
                        required
                      />
                      {validationErrors.coursename && (
                        <div className="invalid-feedback">
                          {validationErrors.coursename}
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom02">Start Date</label>
                      <input
                        type="date"
                        className={`form-control date ${
                          validationErrors.startdate && 'is-invalid'
                        }`}
                        id="validationCustom02"
                        value={courseData.startdate}
                        name="startdate"
                        onChange={handleInputChange}
                        required
                      />
                      {validationErrors.startdate && (
                        <div className="invalid-feedback">
                          {validationErrors.startdate}
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom03">End Date</label>
                      <input
                        type="date"
                        className={`form-control date ${
                          validationErrors.enddate && 'is-invalid'
                        }`}
                        id="validationCustom03"
                        value={courseData.enddate}
                        name="enddate"
                        onChange={handleInputChange}
                        required
                      />
                      {validationErrors.enddate && (
                        <div className="invalid-feedback">
                          {validationErrors.enddate}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom04">Fee</label>
                      <div className="input-group-prepend input-group mb-2">
                        <div className="input-group-text">£</div>
                        <input
                          type="number"
                          className={`form-control ${
                            validationErrors.fee && 'is-invalid'
                          }`}
                          id="validationCustom04"
                          placeholder=""
                          value={courseData.fee}
                          name="fee"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      {validationErrors.fee && (
                        <div className="invalid-feedback">
                          {validationErrors.fee}
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom05">Course Level</label>
                      <select
                        className={`form-control ${
                          validationErrors.courselevel && 'is-invalid'
                        }`}
                        name="courselevel"
                        value={courseData.courselevel}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Under-Graduate">Under-Graduate</option>
                        <option value="foundation">foundation</option>
                      </select>
                      {validationErrors.courselevel && (
                        <div className="invalid-feedback">
                          {validationErrors.courselevel}
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom06">Session Name</label>
                      <select
                        className={`form-control ${
                          validationErrors.commencement && 'is-invalid'
                        }`}
                        name="commencement"
                        value={courseData.commencement}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="fall-2023">fall-2023</option>
                        <option value="fall-2024">fall-2024</option>
                      </select>
                      {validationErrors.commencement && (
                        <div className="invalid-feedback">
                          {validationErrors.commencement}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom07">Age</label>
                      <div className="input-group-prepend input-group mb-2">
                        <div className="input-group-text">OVER</div>
                        <input
                          type="number"
                          className={`form-control ${
                            validationErrors.age && 'is-invalid'
                          }`}
                          id="validationCustom07"
                          placeholder=""
                          value={courseData.age}
                          name="age"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      {validationErrors.age && (
                        <div className="invalid-feedback">
                          {validationErrors.age}
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom08">Study Mode</label>
                      <select
                        className={`form-control ${
                          validationErrors.studyMode && 'is-invalid'
                        }`}
                        name="studyMode"
                        value={courseData.studyMode}
                        onChange={handleStudyModeChange}
                        required
                        // multiple
                      >
                        <option value="full-time">Full time</option>
                        <option value="part-time">Part time</option>
                        <option value="blended-learning">
                          Blended Learning
                        </option>
                      </select>
                      {validationErrors.studyMode && (
                        <div className="invalid-feedback">
                          {validationErrors.studyMode}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <input
                          type="checkbox"
                          name="isinstallment"
                          aria-label="Checkbox for following text input"
                          id="myCheck"
                          checked={courseData.isinstallment}
                          onClick={() =>
                            setCourseData({
                              ...courseData,
                              isinstallment: !courseData.isinstallment,
                            })
                          }
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Text input with checkbox"
                      value="Allow Installments"
                      style={{ fontWeight: 'bold' }}
                      readOnly
                    />
                  </div>

                  <div
                    id="wrapdiv"
                    style={{
                      display: courseData.isinstallment ? 'block' : 'none',
                    }}
                  >
                    <table
                      align="center"
                      cellSpacing={2}
                      cellPadding={5}
                      id="data_table"
                      border={1}
                      style={{ marginBottom: 20 }}
                    >
                      <thead>
                        <tr>
                          <th>Installment No:</th>
                          <th>Installment Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {installment.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                value={index + 1}
                                name="insno[]"
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                name="insdate[]"
                                required
                                value={item.date}
                                onChange={handleInputChange}
                                // onChange={(e) =>
                                //   handleInstallmentChange(index, e.target.value)
                                // }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <input
                              type="button"
                              onClick={addInstallmentRow}
                              value="Add Row"
                            />
                          </td>
                          <td>
                            <input
                              type="button"
                              onClick={() =>
                                deleteInstallmentRow(installment.length - 1)
                              }
                              value="Delete Row"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div>
                    <button className="btn btn-primary" type="submit">
                      Add
                    </button>
                  </div>
                </form> */}
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom01">Course Name</label>
                        <input
                          type="text"
                          className="form-control coursename"
                          id="validationCustom01"
                          placeholder=""
                          name="coursename"
                          value={courseData.coursename}
                          onChange={handleInputChange}
                          required
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                        {courseData.coursename === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.coursename}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Start Date</label>
                        <input
                          type="date"
                          className="form-control date"
                          id="validationCustom02"
                          name="startdate"
                          value={courseData.startdate}
                          onChange={handleInputChange}
                          required
                        />
                        {/* {courseData.startdate} */}
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                        {courseData.startdate === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.startdate}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">End Date</label>
                        <input
                          type="date"
                          className="form-control date"
                          id="validationCustom02"
                          value={courseData.enddate}
                          name="enddate"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required
                        </div>

                        {courseData.enddate === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.enddate}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Fee</label>
                        <div className="input-group-prepend input-group mb-2">
                          <div className="input-group-text">£</div>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            placeholder=""
                            value={courseData.fee}
                            name="fee"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required
                        </div>

                        {courseData.fee === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.fee}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Course Level</label>
                        <select
                          className="form-control"
                          name="courselevel"
                          value={courseData.courselevel}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          {courseNmae.map((res) => (
                            <option value={res.course_Name}>
                              {res.course_Name}
                            </option>
                          ))}
                          {/* <option value="Undergraduate Course">Undergraduate Course</option>
                          <option value="Foundation Course">Foundation Course</option> */}
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required
                        </div>

                        {courseData.courselevel === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.courselevel}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Session Name</label>
                        <select
                          className="form-control"
                          name="commencement"
                          value={courseData.commencement}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          {sessionData.map((res) => (
                            <option value={res.session_Name}>
                              {res.session_Name}
                            </option>
                          ))}
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required{' '}
                        </div>
                        {courseData.commencement === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.commencement}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Age</label>
                        <div className="input-group-prepend input-group mb-2">
                          <div className="input-group-text">OVER</div>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            placeholder=""
                            value={courseData.age}
                            name="age"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required{' '}
                        </div>
                        {courseData.age === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.age}
                          </div>
                        )}
                      </div>
                      {/* not handle */}
                      <div className="col-md-4 mb-3">
                        <label htmlFor="validationCustom02">Study Mode</label>
                        <select
                          // multiple
                          className="form-control"
                          name="studyMode"
                          value={courseData.studyMode}
                          onChange={handleInputChange}
                          placeholder="Select"
                          required
                        >
                          <option value="">Select</option>
                          <option value="Full-Time">Full-Time</option>
                          <option value="Part-Time">Part-Time</option>
                          <option value="Blended-Learning">
                            Blended-Learning
                          </option>
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          This field is required{' '}
                        </div>

                        {courseData.studyMode === '' && (
                          <div
                            style={{
                              color: '#dc3545',
                              fontSize: '.875em',
                              marginTop: '0.25rem',
                            }}
                          >
                            {validationErrors.studyMode}
                          </div>
                        )}
                      </div>
                      {/* not handle  */}
                    </div>

                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <input
                            type="checkbox"
                            name="isinstallment"
                            aria-label="Checkbox for following text input"
                            id="myCheck"
                            checked={courseData.isinstallment}
                            onClick={() =>
                              setCourseData({
                                ...courseData,
                                isinstallment: !courseData.isinstallment,
                              })
                            }
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Text input with checkbox"
                        value="Allow Installments"
                        style={{ fontWeight: 'bold' }}
                        readOnly
                      />
                    </div>

                    <div
                      id="wrapdiv"
                      style={{
                        display: courseData.isinstallment ? 'block' : 'none',
                      }}
                    >
                      <table
                        align="center"
                        cellSpacing={2}
                        cellPadding={5}
                        id="data_table"
                        border={1}
                        style={{ marginBottom: 20 }}
                      >
                        <tr>
                          <th>Installment No:</th>
                          <th>Installment Date</th>
                        </tr>
                        {courseData?.installment?.map((item, index) => (
                          <tr>
                            <td>
                              <input type="text" value={index + 1} readOnly />
                            </td>
                            <td>
                              <input
                                type="date"
                                // name="startdate"
                                // id={`installment[${index}].installment_Date`}
                                name={`installment[${index}].installment_Date`}
                                value={item?.installment_Date}
                                // onChange={handleInstallmentChange}
                                onChange={(event) =>
                                  handleInstallmentChange(index, event)
                                }
                                // required
                              />
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td>
                            <input
                              type="button"
                              // className="add"
                              onClick={editInstallmentRow}
                              value="Add Row"
                            />
                          </td>
                          <td>
                            <input
                              type="button"
                              // className="delete"
                              onClick={() =>
                                deleteInstallmentRow(installment.length)
                              }
                              value="Delete Row"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="insinputval"
                              id="insvalue"
                              hidden
                            />
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div>
                      <button className="btn btn-primary" type="submit">
                        ADD
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCourseForm
