import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { base_url } from '../../../utils/Api'
import '../../style.css'
import './courses.css'

const Courses = () => {
  const [data, setData] = useState([])
  const [PerPageEntry, setPerPageEntry] = useState(10)
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [load, setLoad] = useState(false)

  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  const getAllList = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        // console.log('the resp is------------', res)
        const data = res?.data?.data
        setTotalEntries(data.length)
        setTotalLength(data.length)
        const filteredData = data.filter(
          (item, index) =>
            item.course_Name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            index.toString().includes(searchQuery)
        )
        if (filteredData) {
          setTotalEntries(filteredData.length)
        }
        setData(filteredData)
        setFilteredTotalEntries(filteredData.length)
      })
      .catch((err) => console.log('error is---', err))
  }

  useEffect(() => {
    getAllList()
  }, [searchQuery])

  const renderTable = () => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan="10" style={{ textAlign: 'center' }}>
            {searchQuery ? 'No matching records found' : 'Loading...'}
          </td>
        </tr>
      )
    }

    return data?.map((item, index) => (
      <tr
        key={item._id}
        role="row"
        className={index % 2 === 0 ? 'even' : 'odd'}
      >
        {/* Render table rows as before */}
      </tr>
    ))
  }
  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleDelete = async (_id) => {
    // Show a confirmation dialog
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this item?'
    )

    if (confirmDelete) {
      try {
        // Make an API request to delete the item
        const response = await axios.delete(
          base_url + `admin/Course/delete/${_id}`
        )

        if (response.data.data === 'Course Removed') {
          // If the course was successfully removed, update the state or perform any necessary actions
          alert('Course removed successfully')
          setLoad(!load) // Provide feedback to the user
        } else {
          // Handle the case where the API response indicates an error
          alert('Failed to remove course')
        }
      } catch (error) {
        // Handle any errors that occur during the DELETE request
        console.error('Error deleting course:', error)
        alert('An error occurred while deleting the course')
      }
    } else {
      // The user canceled the deletion, no further action needed
      // You can provide feedback to the user if necessary
      alert('Deletion canceled')
    }
  }
  useEffect(() => {
    getAllList()
  }, [load])

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Courses</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Courses</li>
                </ul>
              </div>
              <div className="col-auto text-right ml-auto">
                <Link to="/admin/courses/create" className="btn btn-primary">
                  <i className="fas fa-plus"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <p style={{ display: 'none' }}>1</p>
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="col-sm-12 col-md-6">
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_0_length"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <label>Show </label>
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                style={{
                                  width: 60,
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                                value={PerPageEntry}
                                onChange={(e) =>
                                  setPerPageEntry(parseInt(e.target.value))
                                }
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              <label>entries</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="DataTables_Table_0_filter"
                            className="dataTables_filter "
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                              marginBottom: 10,
                            }}
                          >
                            <label>Search:</label>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="DataTables_Table_0"
                              value={searchQuery} // Bind the searchQuery to the input value
                              onChange={(e) => setSearchQuery(e.target.value)}
                              style={{ width: 200, marginLeft: 8 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-hover table-center mb-0 datatable dataTable no-footer"
                            id="DataTables_Table_0"
                            role="grid"
                            aria-describedby="DataTables_Table_0_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="ID: activate to sort column ascending"
                                  style={{ width: '15.9219px' }}
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Name: activate to sort column ascending"
                                  style={{ width: '222.047px' }}
                                >
                                  Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Start Date: activate to sort column ascending"
                                  style={{ width: '81.6875px' }}
                                >
                                  Start Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="End Date: activate to sort column ascending"
                                  style={{ width: '73px' }}
                                >
                                  End Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Course Level: activate to sort column ascending"
                                  style={{ width: '116.922px' }}
                                >
                                  Course Level
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Session Name: activate to sort column ascending"
                                  style={{ width: '115.359px' }}
                                >
                                  Session Name
                                </th>
                                <th
                                  className="sorting_desc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Age: activate to sort column ascending"
                                  style={{ width: '57.1562px' }}
                                  aria-sort="descending"
                                >
                                  Age
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Study Mode: activate to sort column ascending"
                                  style={{ width: '136.547px' }}
                                >
                                  Study Mode
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Fee: activate to sort column ascending"
                                  style={{ width: '28.2344px' }}
                                >
                                  Fee
                                </th>
                                <th
                                  className="text-right sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Action: activate to sort column ascending"
                                  style={{ width: '70.25px' }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item, index) => (
                                <tr key={item._id}role="row" className="odd">
                                  <td className="">
                                    {' '}
                                    {parseInt(startIndex) + index + 1}
                                  </td>
                                  <td>{item.course_Name}</td>
                                  <td>{item.start_Date}</td>
                                  <td>{item.end_Date}</td>
                                  <td>{item.course_Level}</td>
                                  <td>{item.session_Name}</td>
                                  <td className="sorting_1">{item.age}</td>
                                  <td>{item.study_Mode}</td>
                                  <td>{item.fee}</td>
                                  <td className="text-right">
                                    <div className="actions d-flex">
                                      <Link
                                        to={`/admin/course/${item._id}`}
                                        className="btn btn-sm bg-success-light mr-2"
                                        style={{
                                          width: 30,
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        <i className="fas fa-pen"></i>
                                      </Link>
                                      {/* <form
                                        method="POST"
                                        // action="http://clc-london.uk/admin/courses/1"
                                        onSubmit={() => {
                                          const confirmDelete =
                                            window.confirm('Are you sure?')
                                          return confirmDelete
                                        }}
                                      >
                                        <input
                                          type="hidden"
                                          name="_token"
                                          value="94Dk5zniKWrcd3OVCNCnxDkOmbTHENDVlxL00sM0"
                                        />
                                        <input
                                          type="hidden"
                                          name="_method"
                                          value="DELETE"
                                        /> */}
                                      <button
                                        className="btn btn-sm bg-danger-light px-2"
                                        type="button"
                                        style={{
                                          border: 'none',
                                          padding: '0',
                                          backgroundColor: 'none',
                                        }}
                                        onClick={() => handleDelete(item._id)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      {/* </form> */}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {filteredTotalEntries === 0
                              ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                              : `Showing ${Math.min(
                                  startIndex + 1,
                                  endIndex
                                )} to ${Math.min(
                                  endIndex,
                                  filteredTotalEntries
                                )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination justify-content-end">
                              <li
                                className={`paginate_button page-item previous ${
                                  currentPage === 1 ? 'disabled' : ''
                                }`}
                              >
                                <button
                                  onClick={handlePreviousClick}
                                  className="page-link"
                                >
                                  Previous
                                </button>
                              </li>
                              {totalEntries > 0
                                ? Array.from({
                                    length: Math.max(
                                      1,
                                      Math.ceil(totalEntries / PerPageEntry)
                                    ),
                                  }).map((_, index) => (
                                    <li
                                      key={index}
                                      className={`paginate_button page-item ${
                                        currentPage === index + 1
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      <button
                                        onClick={() =>
                                          setCurrentPage(index + 1)
                                        }
                                        className="page-link"
                                      >
                                        {index + 1}
                                      </button>
                                    </li>
                                  ))
                                : null}
                              <li
                                className={`paginate_button page-item next ${
                                  currentPage ===
                                  Math.ceil(totalEntries / PerPageEntry)
                                    ? 'disabled'
                                    : ''
                                }`}
                              >
                                <button
                                  onClick={handleNextClick}
                                  className="page-link"
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright © {new Date().getFullYear()}.</p>
        </footer>
      </div>
    </div>
  )
}

export default Courses
