import React, { useEffect, useState } from 'react'
import '../style.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { base_url } from '../../utils/Api'
import '../style.css'

const Applications = () => {
  const [PerPageEntry, setPerPageEntry] = useState(10)
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [courses, setCourses] = useState([])
  const [showData, setShowData] = useState([])

  const [sortOrder, setSortOrder] = useState('asc')
  const [sortBy, setSortBy] = useState('permission')
  const [data, setData] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const showPagination = totalEntries > PerPageEntry

  const renderTable = () => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan="10" style={{ textAlign: 'center' }}>
            {searchQuery ? 'No matching records found' : 'Loading...'}
          </td>
        </tr>
      )
    }

    return data?.map((item, index) => (
      <tr
        key={item._id}
        role="row"
        className={index % 2 === 0 ? 'even' : 'odd'}
      >
        {/* Render table rows as before */}
      </tr>
    ))
  }
  // Sorting the data based on sortBy and sortOrder
  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortBy] ?? ''
    const bValue = b[sortBy] ?? ''

    if (sortOrder === 'asc') {
      return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' })
    } else {
      return bValue.localeCompare(aValue, undefined, { sensitivity: 'base' })
    }
  })

  // -------------------------combine course and form data -------------------------------------
  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    console.log('the current page is', currentPage)
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const getAllCourses = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        setCourses(res?.data?.data)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    //  getAllList()
    getAllCourses()
  }, [])
  const getAllForms = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/applications', config)
      .then((res) => {
        console.log('teh sidfjs', res)
        setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    getAllForms()
  }, [])

  // console.log('the student data isssssss', data)
  // console.log('the course data isssssss', courses)
  const gatherData = () => {
    let arr = []
    data.forEach((item) => {
      if (item?.Course?.course_name) {
        courses.forEach((course_item, index) => {
          //  if (item?.Course?.foundation_courses === course_item.course_Name)
          if (item?.Course?.course_name === course_item.course_Name) {
            arr.push({
              Student_Name: item?.Personal_Details?.First_Name_In_Passport,
              DOB: item?.Personal_Details?.Date_Of_Birth,
              status: item?.status,
              City: item?.Personal_Details?.City,
              _id: item?._id,
              Course_Name: course_item?.course_Name,
              Start_Date: course_item?.start_Date,
              Fee: course_item?.fee,
              End_Date: course_item?.end_Date,
              Installment:
                course_item?.allow_Installment.length > 0 ? 'Installments' : '',
            })
          }
        })
      }
    })
    setShowData(arr)
    // setTotalEntries(data.length)
    setTotalLength(data.length)

    //  const filteredData = arr.filter(
    //    (item, index) =>
    //      item?.Student_Name.toLowerCase().includes(
    //        searchQuery.toLowerCase()
    //      ) || index.toString().includes(searchQuery)
    //  )
    const filteredData = arr.filter((item, index) =>
      ['Student_Name', 'Course_Name', 'DOB', 'Start_Date', 'End_Date'].some(
        (field) =>
          item[field]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          toString(index).includes(searchQuery)
      )
    )

    if (filteredData) {
      setTotalEntries(filteredData.length)
    }
    setShowData(filteredData)
    setFilteredTotalEntries(filteredData.length)
  }
  useEffect(() => {
    gatherData()
  }, [data, courses, searchQuery])
  //  -------------------------end---------------------

  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" style={{ minHeight: '667px' }}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Applications</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Applications</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <p style={{ display: 'none' }}>1</p>
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="col-sm-12 col-md-6">
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_0_length"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <label>Show </label>
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                value={PerPageEntry}
                                onChange={(e) =>
                                  setPerPageEntry(parseInt(e.target.value))
                                }
                                className="custom-select custom-select-sm form-control form-control-sm"
                                style={{
                                  width: 60,
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              <label>entries</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="DataTables_Table_0_filter"
                            className="dataTables_filter "
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                            }}
                          >
                            <label>Search:</label>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="DataTables_Table_0"
                              value={searchQuery} // Bind the searchQuery to the input value
                              onChange={(e) => setSearchQuery(e.target.value)}
                              style={{ width: 200, marginLeft: 8 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table className="table table-hover table-center mb-0 datatable no-footer">
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                  style={{ width: '18.6222px' }}
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-label="Student Name: activate to sort column ascending"
                                  style={{ width: '123.21px' }}
                                >
                                  Student Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-label="Course Name: activate to sort column ascending"
                                  style={{ width: '232.188px' }}
                                >
                                  Course Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-label="Start Date: activate to sort column ascending"
                                  style={{ width: '86.4489px' }}
                                >
                                  Start Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-label="End Date: activate to sort column ascending"
                                  style={{ width: '77.4006px' }}
                                >
                                  End Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-label="Status: activate to sort column ascending"
                                  style={{ width: '55.9375px' }}
                                >
                                  Status
                                </th>
                                <th
                                  className="text-right sorting"
                                  tabIndex="0"
                                  aria-label="Action: activate to sort column ascending"
                                  style={{ width: '121.023px' }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderTable()}
                              {showData
                                .slice(startIndex, endIndex)
                                .map((item, index) => (
                                  <tr key={item._id} role="row" className="odd">
                                    <td className="sorting_1">
                                      {parseInt(startIndex) + index + 1}
                                    </td>
                                    <td>{item?.Student_Name}</td>
                                    <td>
                                      {item?.Course_Name}
                                      {/* {item?.Course?.undergraduate_courses?.map(
                                        (courses, index) => (
                                          <span>
                                            {courses +
                                              (index !==
                                              item?.Course
                                                ?.undergraduate_courses.length -
                                                1
                                                ? ', '
                                                : '')}
                                          </span>
                                        )
                                      )} */}
                                    </td>
                                    <td>{item?.Start_Date}</td>
                                    <td>{item?.End_Date}</td>
                                    <td>
                                      <span
                                        className="badge badge-secondary p-2"
                                        style={{ backgroundColor: '#6c757d' }}
                                      >
                                        {item?.status}
                                      </span>
                                    </td>
                                    <td className="text-right">
                                      <div className="actions">
                                        <Link
                                          to={`/admin/applicationview/${item._id}`}
                                          className="btn btn-sm bg-success-light mr-5"
                                          style={{
                                            width: 50,
                                            marginLeft: 10,
                                            marginRight: 10,
                                          }}
                                        >
                                          View
                                        </Link>
                                        <Link
                                          to={`/student-applicationupdate/${item._id}`}
                                          className="btn btn-sm bg-success-light mr-5"
                                        >
                                          Edit
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div
                            className="dataTables_info "
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {filteredTotalEntries === 0
                              ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                              : `Showing ${Math.min(
                                  startIndex + 1,
                                  endIndex
                                )} to ${Math.min(
                                  endIndex,
                                  filteredTotalEntries
                                )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination justify-content-end">
                              <li
                                className={`paginate_button page-item previous ${
                                  currentPage === 1 ? 'disabled' : ''
                                }`}
                                id="DataTables_Table_0_previous"
                              >
                                <a
                                  href="#"
                                  // aria-controls="DataTables_Table_0"
                                  // data-dt-idx="0"
                                  // tabIndex="0"
                                  onClick={handlePreviousClick}
                                  className="page-link"
                                >
                                  Previous
                                </a>
                              </li>
                              {totalEntries > 0
                                ? Array.from({
                                    length: Math.max(
                                      1,
                                      Math.ceil(totalEntries / PerPageEntry)
                                    ),
                                  }).map((_, index) => (
                                    <li
                                      key={index}
                                      className={`paginate_button page-item ${
                                        currentPage === index + 1
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      <button
                                        onClick={() =>
                                          setCurrentPage(index + 1)
                                        }
                                        className="page-link"
                                      >
                                        {index + 1}
                                      </button>
                                    </li>
                                  ))
                                : null}
                              <li
                                className={`paginate_button page-item next ${
                                  currentPage ===
                                  Math.ceil(totalEntries / PerPageEntry)
                                    ? 'disabled'
                                    : ''
                                }`}
                                id="DataTables_Table_0_next"
                              >
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  onClick={handleNextClick}
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright © 2022</p>
        </footer>
      </div>
    </div>
  )
}

export default Applications
