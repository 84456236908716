// import './SignUp.css'
// import React, { useState } from 'react'
// import axios from 'axios'
// import { useNavigate } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
// import { register } from '../../../actions/userAction'
// const countries = ['USA', 'Canada', 'UK', 'Australia'] // List of countries

// const SignupForm = () => {
//   const [username, setUsername] = useState('')
//   const [password, setPassword] = useState('')
//   const [firstName, setFirstName] = useState('')
//   const [middleName, setMiddleName] = useState('')
//   const [lastName, setLastName] = useState('')
//   const [email, setEmail] = useState('')
//   const [telephone, setTelephone] = useState('')
//   const [selectedCountry, setSelectedCountry] = useState('')
//   const [validationError, setValidationError] = useState(false)
//   const navigate = useNavigate()
//   const dispatch = useDispatch()
//   const userRegister = useSelector((state) => state.userRegister)
//   const { userInfo } = userRegister
//   console.log(userInfo)

//   if (userInfo?.status === '201') {
//     navigate('/login')
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     // Perform validation before submitting
//     if (
//       username.length >= 4 &&
//       password.length >= 6 &&
//       firstName &&
//       lastName &&
//       email &&
//       telephone &&
//       selectedCountry
//     ) {
//       // Validation passed, you can submit the form here
//       console.log('Form submitted')
//       const data = {
//         username,
//         password,
//         firstName,
//         lastName,
//         email,
//         telephone,
//         selectedCountry,
//       }
//       console.log(data)
//       dispatch(register(data))

//       // try {
//       //   const config = {
//       //     headers: {
//       //       'Content-type': 'application/json',
//       //     },
//       //   }
//       //   console.log('apiii posting data')
//       //   const { data } = await axios.post(
//       //     'http://clc-london.uk/api/user/register',
//       //     //'https://student-registration-system.vercel.app/api/user/register',
//       //     {
//       //       username,
//       //       password,
//       //       firstName,
//       //       lastName,
//       //       email,
//       //       telephone,
//       //       selectedCountry,
//       //     },
//       //     config
//       //   )
//       //   console.log(data)
//       //   if (data) {
//       //     navigate('/login')
//       //   }
//       // } catch (error) { console.log(error) }
//     } else {
//       // Validation failed, show error message
//       setValidationError(true)
//     }
//   }

//   return (
//     <div>
//       <div
//         className="card popup-block-head "
//         style={{ display: 'flex', marginBottom: '16px', background: '#283993' }}
//       >
//         <h1>create a accout </h1>
//       </div>
//       <form
//         onSubmit={handleSubmit}
//         style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
//       >
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Username:</label>
//         </div>
//         <div className="">
//           <input
//             type="text"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             style={{ width: '30%' }}
//           />
//           <label style={{ minWidth: '100px', paddingLeft: '5px' }}>
//             (Minimum 4 characters):
//           </label>
//         </div>
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Password</label>
//         </div>
//         <div className="inputi">
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           />
//           <label style={{ minWidth: '100px', paddingLeft: '5px' }}>
//             (Minimum 6 characters):
//           </label>
//         </div>
//         <div style={{ marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>First Name:</label>
//         </div>
//         <div className="inputi">
//           <input
//             type="text"
//             value={firstName}
//             onChange={(e) => setFirstName(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           />
//         </div>
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Middle Name:</label>
//         </div>
//         <div className="inputi">
//           <input
//             type="text"
//             value={middleName}
//             onChange={(e) => setMiddleName(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           />
//         </div>
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Last Name:</label>
//         </div>
//         <div className="inputi">
//           <input
//             type="text"
//             value={lastName}
//             onChange={(e) => setLastName(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           />
//         </div>
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Email Address:</label>
//         </div>
//         <div className="inputi">
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           />
//         </div>
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Telephone:</label>
//         </div>
//         <div className="inputi">
//           <input
//             type="tel"
//             value={telephone}
//             onChange={(e) => setTelephone(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           />
//         </div>
//         <div style={{ display: 'flex', marginBottom: '10px' }}>
//           <label style={{ minWidth: '100px' }}>Country:</label>
//         </div>
//         <div className="inputi">
//           <select
//             value={selectedCountry}
//             onChange={(e) => setSelectedCountry(e.target.value)}
//             style={{ flex: 1, width: '30%' }}
//           >
//             <option value="">Select Country</option>
//             {countries.map((country) => (
//               <option key={country} value={country}>
//                 {country}
//               </option>
//             ))}
//           </select>
//         </div>
//         {validationError && (
//           <div style={{ color: 'red', marginBottom: '10px' }}>
//             Please fill in all the required fields.
//           </div>
//         )}
//         <div style={{ display: 'flex', gap: '2%', marginTop: '15px' }}>
//           <button type="submit">Submit</button>
//           <button type="button" onClick={() => console.log('Form canceled')}>
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//   )
// }

// export default SignupForm
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { countries } from 'countries-list'
import Swal from 'sweetalert2'

import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../../actions/userAction'
import { ToastContainer, toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { base_url } from '../../../utils/Api'
// import { Toast } from 'react-toastify/dist/components'

// const countries = ['USA', 'Canada', 'UK', 'Australia'] // List of countries

const SignupForm = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const countryOptions = Object.keys(countries)
    .map((countryCode) => {
      const country = countries[countryCode]
      return {
        value: countryCode,
        label: country.name.charAt(0).toUpperCase() + country.name.slice(1),
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  const [validationErrors, setValidationErrors] = useState({
    username: '',
    password: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    telephone: '',
    selectedCountry: '',
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRegister = useSelector((state) => state.userRegister)
  const { userInfo } = userRegister

  useEffect(() => {
    if (userInfo?.status === '201') {
      console.log('Navigating back...')
      // navigate('/login')
    }
  }, [userInfo, navigate])

  const showToast = (message, type) => {
    if (type === 'success') {
      toast.success(message)
    } else if (type === 'error') {
      toast.error(message)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Validation logic
    const errors = {}
    if (username.length < 4) {
      errors.username = 'Username must be at least 4 characters'
    }
    if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters'
    }
    if (!firstName) {
      errors.firstName = 'First Name is required'
    }
    if (!lastName) {
      errors.lastName = 'Last Name is required'
    }
    if (!email) {
      errors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email format'
    }
    if (!telephone) {
      errors.telephone = 'Telephone is required'
    }
    if (!selectedCountry) {
      errors.selectedCountry = 'Country is required'
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
       Swal.fire({
         icon: 'success',
         position: 'top-center',
         title: 'Success',
         text: 'Registered Successfully!',
         showConfirmButton: true,
         timer: 2500, // Adjust the timer as needed
       })
      return
    }

    try {
      // Validation passed, submit the form
      const data = {
        username,
        password,
        firstName,
        middleName,
        lastName,
        email,
        telephone,
        selectedCountry,
      }

      // Dispatch the register action
      dispatch(register(data))

      // Reset form fields and errors after successful submission
      setUsername('')
      setPassword('')
      setFirstName('')
      setMiddleName('')
      setLastName('')
      setEmail('')
      setTelephone('')
      setSelectedCountry('')
      setValidationErrors({})

      // Display success toast

      //  navigate('/login')
   Swal.fire({
     icon: 'success',
     position: 'top-center',
     title: 'Success',
     text: 'Registered Successfully!',
     showConfirmButton: true,
     timer: 2500, // Adjust the timer as needed
   }).then(() => {
     setTimeout(() => {
       navigate('/login')
     }, 6000) // Adjust the delay time as needed
   })

      // setTimeout(() => {
      //   navigate('/login')
      // }, 6000)
    } catch (error) {
      // Handle any error that occurred during submission
      Swal.fire({
        icon: 'error',
        position: 'top-center',
        title: 'Error',
        text: 'An error occurred. Please try again.',
        showConfirmButton: true,
      })
     
    }
  }

  return (
    <div>
      <div
        className="card popup-block-head"
        style={{ display: 'flex', marginBottom: '16px', background: '#283993' }}
      >
        <h1>Create an Account</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
      >
        {/* Username Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Username:</label>
        </div>
        <div className="">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ width: '30%' }}
          />
          <label style={{ minWidth: '100px', paddingLeft: '5px' }}>
            (Minimum 4 characters):
          </label>
        </div>
        {validationErrors.username && (
          <div style={{ color: 'red', marginTop: '5px' }}>
            {validationErrors.username}
          </div>
        )}

        {/* Password Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Password:</label>
        </div>
        <div className="inputi">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          />
          <label style={{ minWidth: '100px', paddingLeft: '5px' }}>
            (Minimum 6 characters):
          </label>
        </div>
        {validationErrors.password && (
          <div style={{ color: 'red', marginTop: '5px' }}>
            {validationErrors.password}
          </div>
        )}

        {/* First Name Field */}
        <div style={{ marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>First Name:</label>
        </div>
        <div className="inputi">
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          />
          {validationErrors.firstName && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              {validationErrors.firstName}
            </div>
          )}
        </div>

        {/* Middle Name Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Middle Name:</label>
        </div>
        <div className="inputi">
          <input
            type="text"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          />
        </div>

        {/* Last Name Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Last Name:</label>
        </div>
        <div className="inputi">
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          />
        </div>

        {/* Email Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Email Address:</label>
        </div>
        <div className="inputi">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          />
          {validationErrors.email && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              {validationErrors.email}
            </div>
          )}
        </div>

        {/* Telephone Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Telephone:</label>
        </div>
        <div className="inputi">
          <input
            type="tel"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          />
          {validationErrors.telephone && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              {validationErrors.telephone}
            </div>
          )}
        </div>

        {/* Country Field */}
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label style={{ minWidth: '100px' }}>Country:</label>
        </div>
        <div className="inputi">
          <select
            className="form-select"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            style={{ flex: 1, width: '30%' }}
          >
            <option defaultValue>Select Country</option>
            {countryOptions.map((option) => (
              <option
                style={{ textTransform: 'capitalize' }}
                key={option.value}
                value={option.label}
              >
                {option.label}
              </option>
            ))}
          </select>
          {validationErrors.selectedCountry && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              {validationErrors.selectedCountry}
            </div>
          )}
        </div>

        {/* Submit and Cancel Buttons */}
        <div style={{ display: 'flex', gap: '2%', marginTop: '15px' }}>
          <button type="submit">Submit</button>
          <button type="button" onClick={() => navigate('/login')}>
            Cancel
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

export default SignupForm
