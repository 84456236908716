import React, { useEffect, useState } from 'react'
import '../../screens/application/Student.css'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import colllage from '../../assets/images/collage1.jpg'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import generic_file_icon from '../../assets/images/generic_file.png'
import Pdf_file_icon from '../../assets/images/PDF_file_icon.png'
import word_icon from '../../assets/images/word_icon.png'
import { CKEditor } from 'ckeditor4-react'
// for generting pdf importing
import jsPDF from 'jspdf'
import { PDFDocument, rgb, degrees } from 'pdf-lib'
import { base_url } from '../../utils/Api'
import { base_url1 } from '../../utils/Api'

import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
const StudentviewApllication = () => {
  const [modal, setModal] = useState(false)
  const [comment, setComment] = useState('')
  // console.log("the comment is",comment)
  // const printDiv = () => {
  //   // Replace 'your-div-id' with the ID of the div you want to print
  //   const elementToPrint = document.getElementById('element2')
  //  if (elementToPrint) {
  //    const printContents = elementToPrint.innerHTML
  //    const originalContents = document.body.innerHTML

  //    document.body.innerHTML = printContents
  //    window.print()
  //    document.body.innerHTML = originalContents
  //   //  window.location.reload();
  //  }
  // }
  const printDiv = (elementId) => {
    const elementToPrint = document.getElementById(elementId)
    if (elementToPrint) {
      const printContents = elementToPrint.innerHTML
      const originalContents = document.body.innerHTML

      document.body.innerHTML = printContents
      window.print()
      document.body.innerHTML = originalContents
    }
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [data, setData] = useState({})
  const [status, setStatus] = useState('')
  const { _id } = useParams()
  const handleSubmit = (status) => {
    setStatus(status)
    setModal(true)
    // Perform form submission logic here, e.g., sending data to the server
    // console.log('Form submitted with permission name:', permissionName)
    // const config = {
    //   headers: {
    //     'Content-type': 'application/json',
    //   },
    // }
    // axios
    //   .put(
    //     `http://clc-london.uk/api/admin/applicationview/${_id}`,
    //     { status,comment },
    //     config
    //   )
    //   .then((res) => console.log('resp is', res))
    //   .catch((err) => console.log('error is'.err))
  }
  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState)
  }

  const handlePendingClick = () => {
    // Add your logic for handling the "Pending" button click here
    console.log('Pending button clicked!')
    // Perform any actions or state updates as needed
  }
  const [load, setLoad] = useState(false)

  const getAppFormById = async () => {
    console.log('hfjkdfhjkdfskdfks')
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        console.log('the rep is', res)
        setStatus(res?.data?.applications?.status)
        // setData(res.data.applications)
        setData(res?.data?.applications)
        setLoad(true)
      })
      .catch((err) => console.log('error is---', err))
  }
  console.log('the data is', data)
  function calculateAge(dateOfBirth) {
    if (!dateOfBirth) {
      return
    }

    const birthDate = new Date(dateOfBirth)
    const today = new Date()

    let age = today.getFullYear() - birthDate.getFullYear()

    const monthDifference = today.getMonth() - birthDate.getMonth()
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--
    }
    return age
  }

  const [courseLabel, setCourseLabel] = useState('')
  const getFoundationCourse = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        console.log(
          'the resp is------------👩👩',
          res.data.data.find(
            (course) =>
              course.course_Name === data &&
              data.Course &&
              data.Course.course_name
          )
        )
        let underCourse = []
        let foundCourse = []
        console.log(
          'the courser name is',
          data && data.Course && data.Course.course_name
        )
        res.data.data.forEach((item) => {
          console.log('item is matching is', item)
          if (item.course_Name === data?.Course?.course_name) {
            console.log('the courser label is', item.course_Level)
            setCourseLabel(item.course_Level)
          }
        })
        // res.data.data.map((item) => {
        //   console.log('item is matching is', item)
        //   if(item && item.course_Name===data && data.Course && data.Course.course_name){
        //     setCourseLabel(item.course_Level)
        //   }
        //   // if (item.course_Level === 'Undergraduate Course') {
        //   //   underCourse.push({item:item.course_Name,checked:false})
        //   // } else {
        //   //   foundCourse.push({item:item.course_Name,checked:false})
        //   // }
        // })
        // setCourses({
        //   underGraduateCourse: underCourse,
        //   foundationCourse: foundCourse,
        // })
      })
      .catch((err) => console.log('error is---', err))
  }

  useEffect(() => {
    getAppFormById()
  }, [_id])
  useEffect(() => {
    getFoundationCourse()
  }, [load])
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  // Generating pdf
  function download(data, filename, type) {
    const blob = new Blob([data], { type: type })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }
  const generatePDF = async () => {
    // const pdf = new jsPDF();
    try {
      const mergedPdfDoc = await PDFDocument.create()
      const pdfDoc = await PDFDocument.create()

      for (const file of data.files) {
        if (file.fileName.toLowerCase().endsWith('.pdf')) {
          const pdfBytes = await fetch(
            base_url1 + `files/${file.fileName}`
          ).then((res) => res.arrayBuffer())
          const pdf = await PDFDocument.load(pdfBytes)

          const pages = await pdfDoc.copyPages(pdf, pdf.getPageIndices())
          pages.forEach((page) => pdfDoc.addPage(page))
        } else if (file.fileName.includes('jpeg')) {
          const imageBytes = await fetch(
            base_url1 + `files/${file.fileName}`
          ).then((res) => res.arrayBuffer())
          const jpgImage = await pdfDoc.embedJpg(imageBytes)
          const jpgDims = jpgImage.scale(0.5)
          const page = pdfDoc.addPage()
          page.drawImage(jpgImage, {
            x: page.getWidth() / 2 - jpgDims.width / 2,
            y: page.getHeight() / 2 - jpgDims.height / 2 + 250,
            width: jpgDims.width,
            height: jpgDims.height,
          })
          await pdfDoc.save()
        } else if (file.fileName.includes('png')) {
          const imageBytes = await fetch(
            base_url1 + `files/${file.fileName}`
          ).then((res) => res.arrayBuffer())
          const pngImage = await pdfDoc.embedPng(imageBytes)
          const pngDims = pngImage.scale(0.5)
          const page = pdfDoc.addPage()
          page.drawImage(pngImage, {
            x: page.getWidth() / 2 - pngDims.width / 2 + 75,
            y: page.getHeight() / 2 - pngDims.height + 250,
            width: pngDims.width,
            height: pngDims.height,
          })
          await pdfDoc.save()
        }
      }

      const pdfBytes = await pdfDoc.save()
      download(pdfBytes, 'combined.pdf', 'application/pdf')
    } catch (error) {
      console.error('Error merging PDF files:', error)
    }
  }

  // for sending email based on status
  const SendEmail = async (email) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    axios
      .put(
        base_url + `admin/applicationview/${_id}`,
        { status, comment },
        config
      )
      .then((res) => {
        if (res.data.status === '201') {
          setModal(!modal)
        }
      })
      .catch((err) => console.log('error is'.err))
  }

  const closeDropdown = () => {
    setIsDropdownOpen(false)
  }
  // Function to handle dropdown item click
  const handleDropdownItemClick = (status) => {
    toggleDropdown() // Close the dropdown when a
    closeDropdown()
    handleSubmit(status)
    setModal(true)
    // Perform the action based on the selected status
  }

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper" id="elementId">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Applications</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Application view</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="messages" />
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">PERSONAL DETAILS</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Family Name
                          </div>
                          {data?.Personal_Details?.Family_Name_In_Passport}
                          <input
                            id
                            defaultValue="shahid"
                            name="FamilyName"
                            hidden
                          />
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            First Name
                          </div>
                          {data?.Personal_Details?.First_Name_In_Passport}
                          <input
                            id
                            defaultValue="rasool"
                            name="FirstName"
                            hidden
                          />
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Previous Name
                          </div>
                          {data?.Personal_Details?.Pervious_Family_Name}
                          <input
                            id
                            defaultValue="rasool"
                            name="PreviousName"
                            hidden
                          />
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            DOB
                          </div>
                          {data?.Personal_Details?.Date_Of_Birth}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Email
                          </div>
                          {data?.Personal_Details?.Email}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Entry Date to Uk
                          </div>
                          {data?.Personal_Details?.Entrty_Date_UK}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Home/Parmanent Address
                          </div>
                          {data?.Personal_Details?.Home_Permanent_Address}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Home Tel Number
                          </div>
                          {data?.Personal_Details?.Home_Tel_No}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Title
                          </div>
                          {data?.Personal_Details?.Title}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Gender
                          </div>
                          {data?.Personal_Details?.Gender}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            City
                          </div>
                          {data?.Personal_Details?.City}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Postal Code
                          </div>
                          {data?.Personal_Details?.Post_Code}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Mobile
                          </div>
                          {data?.Personal_Details?.Modile_No}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Country of birth
                          </div>
                          {data?.Personal_Details?.Coutry_Of_Birth}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Nationality
                          </div>
                          {data?.Personal_Details?.Nationality}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div
                          className="ms-2 me-auto"
                          style={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Age
                          </div>
                          <span
                            style={{
                              backgroundColor: 'rgb(19, 214, 19)',
                              padding: '5px 10px',
                              borderRadius: '5px',
                            }}
                          >
                            {calculateAge(
                              data?.Personal_Details?.Date_Of_Birth
                            )}
                          </span>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">COURSE DETAILS</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Course Level
                          </div>
                          {courseLabel}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            {courseLabel}
                          </div>
                          {data?.Course?.course_name}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Mode Of study
                          </div>
                          {data?.Course?.mode_study}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Commencment
                          </div>
                          {data?.Course?.commencement}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Who will pay your fees
                          </div>
                          {data?.Course?.fee_pay}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* aqachieve examinations achieved */}
            {/* aqachieve examinations achieved */}
            {/* aqachieve examinations pending */}
            {/* aqachieve examinations pending */}
            <div className="card" style={{ width: '100%' }}>
              <h5 className="card-title p-2">
                Academic qualifications — examinations achieved
              </h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exams Date</th>
                    <th scope="col">Examing Board/Institution</th>
                    <th scope="col">Subjects</th>
                    <th scope="col">Qualification</th>
                    <th scope="col">Result achived</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.Academic_Qua_Exam_Achieved?.map((exam, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{exam.dateAwarded}</td>
                      <td>{exam.board_institute}</td>
                      <td>{exam.subject}</td>
                      <td>{exam.qualification}</td>
                      <td>{exam.results_achieved}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <h5 className="card-title p-2">
                Academic qualifications — examinations Pending
              </h5>
              <p style={{ display: 'none' }}> 1 </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exams Date</th>
                    <th scope="col">Examing Board/Institution</th>
                    <th scope="col">Subjects</th>
                    <th scope="col">Qualification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>2023-01-13</td>
                    <td>dssd</td>
                    <td>www</td>
                    <td>hamza</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">English Language</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Is english your first language
                          </div>
                          {data?.English_Language
                            ?.English_Your_First_Language === true
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Have you attended any courses in English Language?
                          </div>
                          {
                            data?.English_Language
                              ?.English_Language_Attend_Course
                          }
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Have you taken any English Language Proficiency
                            test?
                          </div>
                          {data?.English_Language?.English_Language_Proficiency}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="card" style={{ width: '100%' }}>
              <h5 className="card-title p-2">Work Experience</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date from</th>
                    <th scope="col">Date to</th>
                    <th scope="col"> FT/PT</th>
                    <th scope="col">Post held</th>
                    <th scope="col">Organisation name and address</th>
                    <th scope="col"> Brief description of duties</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.Work_Experience?.work_experience?.map(
                    (experience, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{experience.Date_from}</td>
                        <td>{experience.Date_to}</td>
                        <td>{experience.FT_PT}</td>
                        <td>{experience.Post_held}</td>
                        <td>{experience.ROrganisation_nam}</td>
                        <td>{experience.Brief_description}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">
                  If currently employed, current employer details:
                </h5>
                <div className="row">
                  {data?.Work_Experience?.currently_employed?.map((item) => (
                    <>
                      <div className="col-md-6">
                        <ol className="list-group list-group-numbered">
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Date from
                              </div>
                              {item?.date_from}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                FT/PT
                              </div>
                              {item?.fT_PT}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Organization Address
                              </div>
                              {item?.rOrganisation_nam}
                            </div>
                          </li>
                        </ol>
                      </div>
                      <div className="col-md-6">
                        <ol className="list-group list-group-numbered">
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Post held
                              </div>
                              {item?.post_held}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Organisation name and address
                              </div>
                              {item?.rOrganisation_nam}
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div
                                className="fw-bold"
                                style={{ fontWeight: 'bold', fontSize: '19px' }}
                              >
                                Brief description of duties
                              </div>
                              {item?.brief_description}
                            </div>
                          </li>
                        </ol>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">Further Information</h5>
                <div className="row">
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Please give reasons for choosing this programme of
                            study.
                          </div>
                          {data?.Further_Information?.q1}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            What are your future education plans?
                          </div>
                          {data?.Further_Information?.q2}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            What kind of employment do you intend to seek on
                            completion of your studies?
                          </div>
                          {data?.Further_Information?.q3}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Please write brief statement about your interests
                            and hobbies.
                          </div>
                          {data?.Further_Information?.q4}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            To help us provide assistance wherever possible
                            please state briefly if you have any special needs
                            requiring support or facilities.
                          </div>
                          {data?.Further_Information?.q5}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Are you currently on any state benefits?
                          </div>
                          {data?.Further_Information?.q6}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Do you have any criminal record within or outside
                            the UK?
                          </div>
                          {data?.Further_Information?.q7}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%', marginBottom: 0 }}>
              <div className="card-body">
                <h5 className="card-title">Reference</h5>
                <div className="row">
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            How long have you known the applicant and in what
                            capacity?
                          </div>
                          {data?.Reference?.Note_To_Refree?.Applicant_Capacity}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-12">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            What is your opinion of the Applicants suitability
                            for the course chosen?
                          </div>
                          {data?.Reference?.Note_To_Refree?.Applicant_Opinion}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%', marginBottom: 0 }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Intellectual / Academic ability
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.Intellectual_Academic_ability
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Fluent and logical communication in English oral
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.English_communication?.oral
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Fluent and logical communication in English written
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.English_communication?.written
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Concern for others
                          </div>
                          {data?.Reference?.Reference_Tabel?.Concern_for_others}
                        </div>
                      </li>
                    </ol>
                  </div>

                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Ability to work hard
                          </div>
                          {
                            data?.Reference?.Reference_Tabel
                              ?.Ability_to_work_hard
                          }
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Perseverance
                          </div>
                          {data?.Reference?.Reference_Tabel?.Perseverance}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Leadership
                          </div>
                          {data?.Reference?.Reference_Tabel?.Leadership}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Creativity
                          </div>
                          {data?.Reference?.Reference_Tabel?.Creativity}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Referee's name
                          </div>
                          {data?.Reference?.Refrees_Name}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Referee's signatures
                          </div>
                          {data?.Reference?.Refrees_Sign}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Date
                          </div>
                          {data?.Reference?.Date}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">Marketing Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      {data?.Marketing_Information?.map(
                        (item) =>
                          item.checked && (
                            <li className="list-group-item d-flex justify-content-between align-items-start ">
                              <div className="ms-2 me-auto">
                                <div
                                  className="fw-bold"
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '19px',
                                  }}
                                ></div>
                                <span>{item.value}</span>
                              </div>
                            </li>
                          )
                      )}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title"> Equal opportunities monitoring</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Ethnic Origen
                          </div>
                          {data?.Equal_Oppertunity_Monitoring?.Ethnic_Origin}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Religion
                          </div>
                          {data?.Equal_Oppertunity_Monitoring?.Religion}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Applicant Signature
                          </div>
                          {
                            data?.Equal_Oppertunity_Monitoring?.Declaration
                              ?.Applicant_Signature
                          }
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Date
                          </div>
                          {
                            data?.Equal_Oppertunity_Monitoring?.Declaration
                              ?.Date
                          }
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="card-body">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-between">
                        <button class="btn btn-primary mt-3" type="submit">Revision</button>
                        <button class="btn btn-danger mt-3" type="submit">Reject</button>
                        <button class="btn btn-success mt-3" type="submit">Approve</button>
                    </div>
                </div>
            </div> */}
            <div className="card" style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px 10px',
                }}
              >
                <h5 className="card-title" style={{ margin: '10px' }}>
                  Student Files
                </h5>
                <div style={{ display: 'flex', gap: '4px' }}>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      Rearrange Files
                    </button>
                    {/* Modal */}
                    <div
                      className="modal fade"
                      id="exampleModalCenter"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Rearrange Files
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <ul className="sortlist">
                              <li
                                className="card item"
                                draggable="true"
                                style={{
                                  borderRadius: '0px',
                                  width: '100%',
                                  margin: '10px 5px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <img
                                  className="img-thumbnail img-fluid"
                                  style={{
                                    height: '60px',
                                    width: '60px',
                                    objectFit: 'cover',
                                  }}
                                  src="http://clc-london.uk/assets/images/222-1673691506.png"
                                />
                                <h6>222-1673691506.png</h6>
                              </li>
                              <li
                                className="card item"
                                draggable="true"
                                style={{
                                  borderRadius: '0px',
                                  width: '100%',
                                  margin: '10px 5px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <img
                                  className="img-thumbnail img-fluid"
                                  style={{
                                    height: '60px',
                                    width: '60px',
                                    objectFit: 'cover',
                                  }}
                                  src="http://clc-london.uk/assets/images/333-1673691506.png"
                                />
                                <h6>333-1673691506.png</h6>
                              </li>
                              <li
                                className="card item"
                                draggable="true"
                                style={{
                                  borderRadius: '0px',
                                  width: '100%',
                                  margin: '10px 5px',
                                  display: 'flex',
                                  gap: '5px',
                                }}
                              >
                                <img
                                  className="img-thumbnail img-fluid"
                                  style={{ height: '60px', width: '60px' }}
                                  src="/assets/pdf.png"
                                />
                                <h6>hee-1673691506.pdf</h6>
                              </li>
                            </ul>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <form
                              action="http://clc-london.uk/arrange-files/1"
                              method="post"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="5k5OaCompVDVfYavgJGq0sxmub21N4y1NZ3dF27y"
                              />{' '}
                              <div
                                id="arr_div"
                                style={{
                                  display: 'none',
                                  flexDirection: 'column',
                                }}
                              />
                              <button
                                style={{ display: 'none' }}
                                id="savebtn"
                                type="submit"
                                className="btn btn-primary"
                              >
                                Save changes
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <form
                      action="http://clc-london.uk/generate-pdf/1"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="5k5OaCompVDVfYavgJGq0sxmub21N4y1NZ3dF27y"
                      />{' '} */}
                    <button onClick={generatePDF} className="btn btn-success">
                      Generate PDF
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
              {data?.files?.map((file) => (
                <div
                  className="card"
                  style={{ borderRadius: '0px', margin: '10px 5px' }}
                >
                  <h6 className="card-header">{file.fileName}</h6>
                  <div
                    className="card-body"
                    style={{
                      borderRadius: '0px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {file.filePath.includes('pdf') ? (
                      <img
                        className="img-thumbnail img-fluid"
                        style={{
                          height: '70px',
                          width: '70px',
                          objectFit: 'cover',
                        }}
                        src={Pdf_file_icon}
                      />
                    ) : file.filePath.includes('word') ? (
                      <img
                        className="img-thumbnail img-fluid"
                        style={{
                          height: '70px',
                          width: '70px',
                          objectFit: 'cover',
                        }}
                        src={word_icon}
                      />
                    ) : (
                      // file.filePath.includes('file')?
                      // <img
                      //   className="img-thumbnail img-fluid"
                      //   style={{
                      //     height: '70px',
                      //     width: '70px',
                      //     objectFit: 'cover',
                      //   }}
                      //   src={generic_file_icon}
                      // />
                      // :
                      <img
                        className="img-thumbnail img-fluid"
                        style={{
                          height: '70px',
                          width: '70px',
                          objectFit: 'cover',
                        }}
                        // src={`/static/${file.filePath}`} alt="File"
                        src={base_url1 + `files/${file.fileName}`}
                      />
                    )}
                    <a
                      target="_blank"
                      href={base_url1 + `files/${file.fileName}`}
                      download={file.fileName}
                      className="btn btn-primary"
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
            {/* <div id="element2" style={{ display: 'none' }}>
              <div
                style={{
                  margin: '2%',
                  padding: '20px 10px',
                  borderTop: '0px',
                  borderBottom: '0px',
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      height: '220px',
                      width: '170px',
                      padding: '10px',
                      border: '1px solid black',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <h5
                      style={{
                        color: 'rgb(16, 16, 148)',
                        textAlign: 'center',
                        fontWeight: 400,
                      }}
                    >
                      Attach ONE passport size photograph here
                    </h5>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '0px',
                      marginTop: '-10px',
                      width: '300px',
                    }}
                  >
                    <img
                      style={{ width: '260px' }}
                      src={colllage}
                      alt="college lgo"
                    />
                    <p
                      style={{
                        fontSize: '10px',
                        color: 'rgb(16, 16, 148)',
                        textAlign: 'center',
                      }}
                    >
                      3 Boyd Street, Aldgate East, London, E1 1FQ, United
                      Kingdom <span style={{ fontWeight: 'bold' }}> Tel: </span>{' '}
                      +44 (0)20 7247 2177{' '}
                      <span style={{ fontWeight: 'bold' }}> Email: </span>{' '}
                      info@clc-london.ac.uk
                    </p>
                    <h6 style={{ color: 'rgb(16, 16, 148)' }}>
                      www.clc-london.ac.uk
                    </h6>
                  </div>
                </div>
                <div style={{ padding: '10px 0px' }}>
                  <h3
                    style={{
                      fontWeight: 'bold',
                      color: 'rgb(12, 12, 122)',
                      marginBottom: '4px',
                      marginTop: '5px',
                    }}
                  >
                    APPLICATION FOR ADMISSION{' '}
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      (Higher Education Courses){' '}
                    </span>
                  </h3>
                  <p
                    style={{
                      fontSize: '13px',
                      color: 'rgb(16, 16, 148)',
                      marginBottom: '7px',
                    }}
                  >
                    (Please read the College prospectus and consult the website
                    before completing this form)
                  </p>
                  <p style={{ fontSize: '13px', color: 'rgb(16, 16, 148)' }}>
                    Please complete this form in BLOCK capitals using black ink.
                  </p>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          1- Personal Details
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Family name as appears in passport:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  defaultValue={
                                    data?.Personal_Details
                                      ?.Family_Name_In_Passport
                                  }
                                  placeholder
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  First name as appears in passport:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" Hills"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Title:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Mr"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Previous family name:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" Beverly"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Date of Birth:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" 2003-06-12"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Gender:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="Male"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Home/permanent address:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" sargodha road, gujarat"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  City:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" gujrat"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Postcode:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue={90210}
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Home Tel no:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  // defaultValue={malik}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Mobile:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  // defaultValue={usman}
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Email:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" student@student.com"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Country of birth:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Korea"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Nationality:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="  Kiribati"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  {' '}
                                  Age:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="  20 Years"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Entry date to UK:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="2023-01-10"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          2- Course{' '}
                          <span
                            style={{
                              fontSize: '12px',
                              fontStyle: 'italic',
                              fontWeight: 400,
                              color: 'rgb(8, 8, 94)',
                            }}
                          >
                            (Please indicate the course you wish to apply){' '}
                          </span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Course Level:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Under-Graduate"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Undergraduate Course:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="Diploma in Web development"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Mode of study:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="  Full Time"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Commencement:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="  fall-2023"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Who will pay your fees:
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="Family member"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          3- Academic qualifications – examinations achieved{' '}
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          Please list all qualifications taken, whatever the
                          result, in chronological order, Documentary evidence
                          of known results should be submitted with this form.
                          Only certified photocopies are acceptable{' '}
                        </p>
                      </div>
                      <div className="card-body">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Date Awarded</th>
                              <th scope="col">Examining Board/Institution</th>
                              <th scope="col">Subjects</th>
                              <th scope="col">Qualification</th>
                              <th scope="col">Results achieved</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>2023-01-05</td>
                              <td>gujrat</td>
                              <td>eng</td>
                              <td>BS</td>
                              <td>yes</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          4. Academic qualifications – examinations pending
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          Complete this section only if you are awaiting the
                          results of any examinations taken recently{' '}
                        </p>
                      </div>
                      <div className="card-body">
                        <p style={{ display: 'none' }}> 1 </p>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Exams Date</th>
                              <th scope="col">Examining Board/Institution</th>
                              <th scope="col">Subjects</th>
                              <th scope="col">Qualification</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>2023-01-13</td>
                              <td>dssd</td>
                              <td>www</td>
                              <td>hamza</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          5. English language{' '}
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          You must submit proof of your English language
                          proficiency with this application
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-3 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Is English your first language?
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" no"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Have you attended any courses in English
                                  Language?
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue="  yes"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-5 mb-4">
                                <label
                                  htmlFor="validationCustom02"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Have you taken any English Language
                                  Proficiency test?
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className="form-control"
                                  id="validationCustom02"
                                  placeholder
                                  defaultValue=" yes"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          6. Work Experience{' '}
                          <span
                            style={{
                              fontSize: '12px',
                              fontStyle: 'italic',
                              fontWeight: 400,
                              color: 'rgb(8, 8, 94)',
                            }}
                          >
                            (if any){' '}
                          </span>
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          Please give details of work experience, training and
                          employment.
                        </p>
                      </div>
                      <div className="card-body">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Date from</th>
                              <th scope="col">Date to</th>
                              <th scope="col">FT/PT*</th>
                              <th scope="col">Post held</th>
                              <th scope="col">Organisation name and address</th>
                              <th scope="col">Brief description of duties</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>2023-01-21</td>
                              <td>2023-01-27</td>
                              <td>wewe</td>
                              <td>e ewew</td>
                              <td>ewe</td>
                              <td>ewewe</td>
                            </tr>
                          </tbody>
                        </table>
                        <p
                          style={{
                            fontSize: '18px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                            padding: '10px 0px',
                          }}
                        >
                          If currently employed, current employer details:
                        </p>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Date from</th>
                              <th scope="col">FT/PT*</th>
                              <th scope="col">Post</th>
                              <th scope="col">Organisation name and address</th>
                              <th scope="col">Brief description of duties</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>2023-01-20</td>
                              <td> ew</td>
                              <td>ewe</td>
                              <td>wew -- ewe</td>
                              <td> we we wewe</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          7. Further Information{' '}
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          Please use this section to tell us about yourself and
                          your reasons for wanting to study this course.
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Please give reasons for choosing this
                                  programme of study:
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={' e we we'}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  What are your future education plans?
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={' wewe wew'}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  What kind of employment do you intend to seek
                                  on completion of your studies?
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={' e we we '}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Please write brief statement about your
                                  interests and hobbies:
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={'wee w e '}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  To help us provide assistance wherever
                                  possible please state briefly if you have any
                                  special needs requiring support or facilities:
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={' we w'}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Are you currently on any state benefits? If
                                  so, please explain type of benefits:
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={' e we we '}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Do you have any criminal record within or
                                  outside the UK?
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={'wewe '}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          8. Reference:{' '}
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          This section must be completed by your present or past
                          teacher, employer or a person who knows you well,
                          academically and/or socially. Incomplete application
                          forms will not be processed. An interview with the
                          applicant will be required.
                        </p>
                      </div>
                      <div className="card-body">
                        <div
                          className="card-header"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            marginBottom: '20px',
                          }}
                        >
                          <h3
                            className="card-title mb-2"
                            style={{
                              fontWeight: 'bold',
                              color: 'rgb(8, 8, 94)',
                              fontSize: '22px',
                            }}
                          >
                            Note to Referee:{' '}
                          </h3>
                          <p
                            style={{
                              fontSize: '12px',
                              fontStyle: 'italic',
                              fontWeight: 400,
                              color: 'rgb(8, 8, 94)',
                            }}
                          >
                            As many potential candidates apply, selection is
                            extremely competitive. Your comments will provide us
                            with important information in assessing this
                            application.{' '}
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  How long have you known the applicant and in
                                  what capacity?
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={'wewe w '}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  How long have you known the applicant and in
                                  what capacity?
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={'ewe we ewe '}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Intellectual / Academic ability
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="  Excellent 90-94%"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Fluent and logical communication in English
                                  (Oral)
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Very Good 75-89%"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Fluent and logical communication in English
                                  (Written)
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Good 60-74%"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Ability to work hard
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Good 60-74%"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Perseverance
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Very Good 75-89%"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-4 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Leadership
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Very Good 75-89%"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Creativity
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="  Very Good 75-89%"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Any other information that you feel is
                                  relevant (optional):
                                </label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  readOnly
                                  defaultValue={' '}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Referee’s name:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="  we"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Date:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="2023-01-04"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  Referee’s signatures:
                                </label>
                                <input
                                  style={{
                                    borderTop: '0px',
                                    borderLeft: '0px',
                                    borderRight: '0px',
                                    borderRadius: '0px',
                                    backgroundColor: 'white',
                                  }}
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" we ew"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          9. Marketing Information{' '}
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          It would be helpful if you could kindly tell us where
                          you heard about the City of London College by ticking
                          the appropriate box
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-12 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  {' '}
                                  Marketing Information:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue=" Newspaper/Magazine,Internet,"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-sm-12">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <h3
                          className="card-title mb-2"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(8, 8, 94)',
                            fontSize: '22px',
                          }}
                        >
                          10. Equal opportunities monitoring:{' '}
                        </h3>
                        <p
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgb(8, 8, 94)',
                          }}
                        >
                          Your response will not influence the outcome of your
                          application. You do not have to answer this question
                          if you do not wish.
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-row">
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  {' '}
                                  Ethnic origin:
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="Other ethnic background"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  htmlFor="validationCustom01"
                                  style={{ color: 'rgb(1, 1, 114)' }}
                                >
                                  {' '}
                                  Religion :
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder
                                  defaultValue="baha'i faith"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    marginTop: '30px',
                    marginBottom: '20px',
                  }}
                >
                  <h3
                    className="card-title mb-2"
                    style={{
                      fontWeight: 'bold',
                      color: 'rgb(8, 8, 94)',
                      fontSize: '22px',
                      marginBottom: '0px',
                    }}
                  >
                    Declaration:{' '}
                  </h3>
                  <p
                    style={{
                      fontSize: '12px',
                      fontStyle: 'italic',
                      fontWeight: 400,
                      color: 'rgb(8, 8, 94)',
                    }}
                  >
                    I confirm that, to the best of my knowledge, the information
                    in this form is correct. I have read the College
                    prospectus/brochure and the College website. I understand
                    and agree to abide by the conditions and regulations set out
                    there, which I accept as a condition of this application
                  </p>
                </div>
                <div className="form-row">
                  <div className="col-md-6 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      Applicant’s signature:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      Date:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <h3
                    className="card-title mb-2"
                    style={{
                      fontWeight: 'bold',
                      color: 'rgb(8, 8, 94)',
                      fontSize: '22px',
                      marginBottom: '0px',
                    }}
                  >
                    Enrolment Procedure:{' '}
                  </h3>
                  <p
                    style={{
                      fontSize: '14px',
                      fontStyle: 'italic',
                      fontWeight: 400,
                      color: 'rgb(8, 8, 94)',
                      marginBottom: '5px',
                    }}
                  >
                    1. Complete the Application for Admission form, and send it
                    with
                  </p>
                  <ol type="a">
                    <li
                      style={{
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        color: 'rgb(8, 8, 94)',
                      }}
                    >
                      {' '}
                      certified copies of relevant academic certificates &amp;
                      work related documents
                    </li>
                    <li
                      style={{
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        color: 'rgb(8, 8, 94)',
                      }}
                    >
                      1 colour photo
                    </li>
                    <li
                      style={{
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        color: 'rgb(8, 8, 94)',
                      }}
                    >
                      copy of passport page
                    </li>
                    <li
                      style={{
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        color: 'rgb(8, 8, 94)',
                      }}
                    >
                      copy of National Insurance number document
                    </li>
                    <li
                      style={{
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        color: 'rgb(8, 8, 94)',
                      }}
                    >
                      proof of address (driving license or bank statement) send
                      to the{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Registrar, City of London College, 3 Boyd Street, London
                        E1 1FQ{' '}
                      </span>
                    </li>
                  </ol>
                  <p
                    style={{
                      fontSize: '14px',
                      fontStyle: 'italic',
                      fontWeight: 400,
                      color: 'rgb(8, 8, 94)',
                    }}
                  >
                    2. When your application has been processed, the College
                    will issue a Letter of Acceptance (LOA).
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    marginTop: '30px',
                    marginBottom: '20px',
                  }}
                >
                  <h3
                    className="card-title mb-2"
                    style={{
                      fontWeight: 'bold',
                      color: 'rgb(8, 8, 94)',
                      fontSize: '22px',
                      marginBottom: '0px',
                    }}
                  >
                    FOR REGISTRY USE ONLY:{' '}
                  </h3>
                </div>
                <div className="form-row">
                  <div className="col-md-2 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Student interviewed:
                    </label>
                    <div style={{ marginTop: '10px' }}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          defaultValue="option1"
                          style={{ width: '30px' }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          defaultValue="option2"
                          style={{ width: '30px' }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Student interviewed by:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-5 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Student interviewed on:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Comments:
                    </label>
                    <textarea
                      rows={2}
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                      defaultValue={' '}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Course title on which place offered:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Course Leve (level 1-6):
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Course Leve (level 1-6):
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      Start Date:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      End Date:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Tuition Fee:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      Date Received:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)' }}
                    >
                      {' '}
                      Processed by:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      htmlFor="validationCustom01"
                      style={{ color: 'rgb(1, 1, 114)', fontWeight: 'bold' }}
                    >
                      {' '}
                      Allocated SEN:
                    </label>
                    <input
                      style={{
                        borderTop: '0px',
                        borderLeft: '0px',
                        borderRight: '0px',
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }}
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="validationCustom01"
                      placeholder
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentviewApllication
