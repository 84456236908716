import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { base_url } from '../../../utils/Api'

const CourseRole = () => {
  const [permissionsData, setPermissionsData] = useState([])
  const [PerPageEntry, setPerPageEntry] = useState(10)
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [load, setLoad] = useState(false)

  const getAllPermissions = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/getAllPermissions`, config)
      .then((res) => {
        const data = res?.data?.data
        setTotalEntries(data.length)
        setTotalLength(data.length)
        const filteredData = data.filter(
          (item, index) =>
            item.Premission_Name.toLowerCase().includes(
              searchQuery.toLowerCase()
            ) || index.toString().includes(searchQuery)
        )
        if (filteredData) {
          setTotalEntries(filteredData.length)
        }
        setPermissionsData(filteredData)
        setFilteredTotalEntries(filteredData.length)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    getAllPermissions()
  }, [searchQuery])

  const users = [
    {
      id: 1,
      name: 'John Doe',
      permissions: [2, 6],
      actions: { edit: true, delete: true },
    },
    {
      id: 2,
      name: 'Jane Smith',
      permissions: [2, 3],
      actions: { edit: true, delete: true },
    },
    {
      id: 3,
      name: 'Michael Johnson',
      permissions: [2, 5],
      actions: { edit: true, delete: true },
    },
    // Add more user data as needed
  ]
  const handleDelete = async (_id) => {
    console.log(_id)
    // Show a confirmation dialog
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this item?'
    )

    if (confirmDelete) {
      try {
        // Make an API request to delete the item
        const response = await axios.delete(
          base_url + `admin/Course/deletePremissionName/${_id}`
        )

        if (response.data.data === 'Name Removed') {
          // If the course was successfully removed, update the state or perform any necessary actions
          setLoad(!load) // Update your state as needed
          alert('courseName removed successfully') // Provide feedback to the user
        } else {
          // Handle the case where the API response indicates an error
          alert('Failed to remove courseName')
        }
      } catch (error) {
        // Handle any errors that occur during the DELETE request
        console.error('Error deleting course:', error)
        alert('An error occurred while deleting the course')
      }
    } else {
      // The user canceled the deletion, no further action needed
      // You can provide feedback to the user if necessary
      alert('Deletion canceled')
    }
  }
  useEffect(() => {
    getAllPermissions()
  }, [load])

  const [currentPage, setCurrentPage] = useState(1)

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  // Calculate the range of entries to display based on the current page
  // const startIndex = (currentPage - 1) * entriesPerPage + 1
  // const endIndex = Math.min(startIndex + entriesPerPage - 1, totalEntries)
  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)
  // pagnation end
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [selectedUserActions, setSelectedUserActions] = useState({})

  const handlePermissionToggle = (permissionId) => {
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.includes(permissionId)
        ? prevPermissions.filter((id) => id !== permissionId)
        : [...prevPermissions, permissionId]
    )
  }

  const handleActionToggle = (userId, action) => {
    setSelectedUserActions((prevActions) => ({
      ...prevActions,
      [userId]: {
        ...prevActions[userId],
        [action]: !prevActions[userId]?.[action],
      },
    }))
  }

  const isPermissionSelected = (permissionId) =>
    selectedPermissions.includes(permissionId)

  // Calculate the range of users to display based on the current page
  // const startIndex = (currentPage - 1) * entriesPerPage
  // const endIndex = startIndex + entriesPerPage

  const displayedUsers = users.slice(startIndex, endIndex)
  // ------------------shooting arrow assending------------
  const [data, setData] = useState([
    // Your data goes here
    // Example:
    { id: 1, permission: 'Permission 1', action: 'Action 1' },
    { id: 2, permission: 'Permission 2', action: 'Action 2' },
    // Add more data as needed
  ])

  const [sortBy, setSortBy] = useState('permission')
  const [sortOrder, setSortOrder] = useState('asc')

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortOrder('asc')
    }
  }

  // Sorting the data based on sortBy and sortOrder
  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortBy]
    const bValue = b[sortBy]

    if (sortOrder === 'asc') {
      return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' })
    } else {
      return bValue.localeCompare(aValue, undefined, { sensitivity: 'base' })
    }
  })
  const renderTable = () => {
    if (permissionsData.length === 0) {
      return (
        <tr>
          <td colSpan="10" style={{ textAlign: 'center' }}>
            {searchQuery ? 'No matching records found' : 'Loading...'}
          </td>
        </tr>
      )
    }

    return data?.map((item, index) => (
      <tr
        key={item._id}
        role="row"
        className={index % 2 === 0 ? 'even' : 'odd'}
      >
        {/* Render table rows as before */}
      </tr>
    ))
  }
  // ------------------shooting arrow assending------------
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Course</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Permissions</li>
                  </ul>
                </div>
                <div className="col-auto text-right float-right ml-auto">
                  <Link
                    to="/admin/permissions/create"
                    className="btn btn-primary"
                  >
                    <i className="fas fa-plus" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6 mb-2">
                            <div
                              class="dataTables_length"
                              id="DataTables_Table_0_length"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <label>Show</label>
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                style={{
                                  width: 60,
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                                value={PerPageEntry}
                                onChange={(e) =>
                                  setPerPageEntry(parseInt(e.target.value))
                                }
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              entries
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <div
                              id="DataTables_Table_0_filter"
                              className="dataTables_filter"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              <label>Search: </label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="DataTables_Table_"
                                style={{ width: 200, marginLeft: 8 }}
                                value={searchQuery} // Bind the searchQuery to the input value
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              className="table table-hover table-center mb-0 datatable dataTable no-footer"
                              id="DataTables_Table_0"
                              role="grid"
                              aria-describedby="DataTables_Table_0_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className={`sorting_${
                                      sortOrder === 'asc' ? 'asc' : 'desc'
                                    }`}
                                    tabIndex="0"
                                    onClick={() => handleSort('permission')}
                                    aria-sort={
                                      sortBy === 'permission'
                                        ? sortOrder
                                        : 'none'
                                    }
                                  >
                                    Permissions
                                  </th>
                                  <th
                                    className={`text-right sorting_${
                                      sortOrder === 'asc' ? 'asc' : 'desc'
                                    }`}
                                    tabIndex="0"
                                    onClick={() => handleSort('action')}
                                    aria-sort={
                                      sortBy === 'action' ? sortOrder : 'none'
                                    }
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {renderTable()}
                                {permissionsData
                                  .slice(startIndex, endIndex)
                                  .map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.Premission_Name}</td>
                                      <td className="text-right">
                                        <div className="actions">
                                          <Link
                                            to={`/admin/permissions/edit/${item._id}`}
                                            className="btn btn-sm bg-success-light mr-2"
                                            style={{
                                              width: 40,
                                              marginLeft: 10,
                                              marginRight: 10,
                                            }}
                                          >
                                            <i className="fas fa-pen" />
                                          </Link>
                                          <a
                                            href="#"
                                            className="btn btn-sm bg-danger-light"
                                          >
                                            {/* <form
                                              method="POST"
                                              action="http://clc-london.uk/admin/permissions/1"
                                              onsubmit="return confirm('Are you sure?');"
                                            >
                                              <input
                                                type="hidden"
                                                name="_token"
                                                defaultValue="X90I0NPAgthq4dcz6ArwbaXK1S7CKB4MEOxnPLCS"
                                              />{' '}
                                              <input
                                                type="hidden"
                                                name="_method"
                                                defaultValue="DELETE"
                                              /> */}
                                            <button
                                              type="submit"
                                              style={{
                                                border: 'none',
                                                padding: '0%',
                                                backgroundColor: 'none',
                                                width: 20,
                                                marginLeft: 10,
                                                marginRight: 10,
                                              }}
                                              onClick={() =>
                                                handleDelete(item._id)
                                              }
                                            >
                                              <i className="fas fa-trash " />
                                            </button>
                                            {/* </form> */}
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              {filteredTotalEntries === 0
                                ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                                : `Showing ${Math.min(
                                    startIndex + 1,
                                    endIndex
                                  )} to ${Math.min(
                                    endIndex,
                                    filteredTotalEntries
                                  )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="DataTables_Table_0_paginate"
                            >
                              <ul className="pagination">
                                <li
                                  className={`paginate_button page-item previous ${
                                    currentPage === 1 ? 'disabled' : ''
                                  }`}
                                >
                                  <button
                                    onClick={handlePreviousClick}
                                    className="page-link"
                                  >
                                    Previous
                                  </button>
                                </li>
                                {/* Render page numbers */}
                                {totalEntries > 0
                                  ? Array.from({
                                      length: Math.max(
                                        1,
                                        Math.ceil(totalEntries / PerPageEntry)
                                      ),
                                    }).map((_, index) => (
                                      <li
                                        key={index}
                                        className={`paginate_button page-item ${
                                          currentPage === index + 1
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <button
                                          onClick={() =>
                                            setCurrentPage(index + 1)
                                          }
                                          className="page-link"
                                        >
                                          {index + 1}
                                        </button>
                                      </li>
                                    ))
                                  : null}
                                <li
                                  className={`paginate_button page-item next ${
                                    currentPage ===
                                    Math.ceil(totalEntries / PerPageEntry)
                                      ? 'disabled'
                                      : ''
                                  }`}
                                >
                                  <button
                                    onClick={handleNextClick}
                                    className="page-link"
                                  >
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2020 Dreamguys.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default CourseRole
