import { FEE_Details } from "../constants/feeMangementstatusconstant";



export const feeManagementStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case FEE_Details:
      return { feeManagementStatusData: action.payload };
    default:
      return state;
  }
}







