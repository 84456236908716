import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { base_url, base_url1 } from '../../../utils/Api'

const EditPermissionsForm = () => {
  const [permissionName, setPermissionName] = useState('')
  const { _id } = useParams()
  console.log('the idddd', _id)

  const getPermissionNameById = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+`admin/getPermissionById/${_id}`, config)
      .then((res) => {
        console.log('the res is❤❤❤❤❤', res)
        setPermissionName(res?.data?.data?.Premission_Name)
        
      })
      .catch((err) => console.log('error is---', err))
  }

  useEffect(() => {
    getPermissionNameById()
  }, [_id])

  const handleSubmit = (event) => {
    event.preventDefault()
    // Perform form submission logic here, e.g., sending data to the server
    console.log('Form submitted with permission name:', permissionName)
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    axios
      .put(
        base_url+`admin/updatePermissionName/${_id}`,
        {permissionName},
        config
      )
      .then((res) => console.log('resp is', res))
      .catch((err) => console.log('error is'.err))
  }

  const handleInputChange = (event) => {
    setPermissionName(event.target.value)
  }
const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Add Permissions</h3>
                <ul className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Edit Permissions</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <form
                        onSubmit={handleSubmit}
                        className="needs-validation"
                        noValidate
                      >
                        <input
                          type="hidden"
                          name="_token"
                          value="X90I0NPAgthq4dcz6ArwbaXK1S7CKB4MEOxnPLCS"
                        />
                        <input type="hidden" name="_method" value="put" />
                        <div className="form-row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="validationCustom01">
                              Permission Name:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              name="name"
                              value={permissionName}
                              onChange={handleInputChange}
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              This field is required.
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright © 2020.</p>
        </footer>
      </div>
    </div>
  )
}

export default EditPermissionsForm
