import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import Login from './components/login/Login.js'
import Signup from './components/registration/signup/SignUp'
import AppForm from './components/student/ApplicationForm/AppForm.js'
import { useDispatch, useSelector } from 'react-redux'
import { USER_LOGIN_SUCCESS } from './constants/userConstants.js'
import { useEffect, useState } from 'react'
import axios from 'axios'
// for admin design
import Courses from './screens/course/courses/Courses'
import CourseStudent from './components/courses/courseStudent.js'
import AddCourseForm from './screens/course/courses/AddCourseForm'
import EditCourseForm from './screens/course/courses/EditCourseForm'
import Addcourselevel from './screens/course/courseLevel/Addcourselevel'
import Courselevel from './screens/course/courseLevel/Courselevel'
import CommencementForm from './screens/course/commencement/CommencementForm'
import Commencent from './screens/course/commencement/Commencent'
import Student from './screens/application/Studentview'
import Applications from './screens/application/Applications'
import EditApplication from './screens/application/EditApplication'
import EmailTemplate from './screens/emailtemplate/EmailTemplate'
import FeeManagement from './screens/FeeManagement/FeeManagement'
import FeeStatus from './screens/FeeStatus/FeeStatus'
import Sidebar from './screens/MainDashBoard/Sidebar'
import Profile from './screens/Profile/Profile'
import CourseRole from './screens/rolesAndPermissions/permissions/CourseRole'
import EditPermissionsForm from './screens/rolesAndPermissions/permissions/EditPermissionsForm'
import AddRolesForm from './screens/rolesAndPermissions/roles/AddRolesForm'
import EditRoleForm from './screens/rolesAndPermissions/roles/EditRoleForm'
import RolesTable from './screens/rolesAndPermissions/roles/RolesTable'
import AddUserForm from './screens/rolesAndPermissions/users/AddUserForm'
import StudentLetters from './screens/StudentLetter/StudentLetters'
import UsersTable from './screens/rolesAndPermissions/users/UsersTable'
import Dashboard from './screens/MainDashBoard/Dashboard/Dashboard'
import StudentsList from './screens/StudentFolder/StudentsList.js'
import EditCommencement from './screens/course/commencement/Editcommencement.js'
import EditCourseLevel from './screens/course/courseLevel/EditCourseLevel.js'
import FeeDetails from './screens/FeeStatus/FeeDetails/FeeDetails.js'
import CheckForm from './CheckForm.js'
import StudentLetterDetails from './screens/StudentLetter/StudentLetter/StudentLetterDetails'
import StudentDetails from './screens/StudentFolder/StudentDetail/StudentDetails'
import FeeManagementDetails from './screens/FeeManagement/Fee-Management-Details/FeeManagementDetails'
import AddPermissions from './screens/rolesAndPermissions/permissions/AddPermissions.js'
import EditUserForm from './screens/rolesAndPermissions/users/EditUserForm.js'
import { RoleContext } from './context/RoleContext.js'
import { UnAuthRoute } from './Routing/PublicRoutes.js'
import { AdminRoute, UserRoute } from './Routing/PrivateRoutes.js'
import Loading from './components/Loading.js'
import { base_url } from './utils/Api.js'
import Applicationstudent from './components/Applicationl/Applicationstudent.js'
import Studentfeeslist from './components/Studentfees/Studentfeeslist.js'
import Studentfeesdetail from './components/Studentfees/Studentfeesdetail.js'
import Studentletterdetails from './components/StudentLetter/Studentletterdetail.js'
import StudentLettersdetail from './components/StudentLetter/Studentletterdetail.js'
import Studentletters from './components/StudentLetter/Studentletterlist.js'
import FeeManagementlist from './components/Feemanagement/Feemanagementlist.js'
import FeeManagementdetails from './components/Feemanagement/Feemangementdetals.js'
import Profilestudent from './components/profile/Profilestudent.js'
import StudentviewApllication from './components/Applicationl/Appllicationview.js'
import ResetPassword from './components/Resetpassword/RestPassword.js'
import Dashboarduser from './components/Dashboard/UserDashboard/Dashboarduser.js'

function App() {
  const dispatch = useDispatch()
  const [role, setRole] = useState(localStorage.getItem('role'))
  const userLogin = useSelector((state) => state.userLogin)
  const [authentication, setAuthState] = useState({
    authenticated: false,
    loading: true,
  })
  const usertoken = localStorage.getItem('UserToken')
  console.log('role is', role)
  const checkUser = async () => {
    try {
      axios
        .post(
          base_url + 'user/userData',
          {},
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === '201') {
            setRole(response?.data?.UserData?.type)

            dispatch({ type: USER_LOGIN_SUCCESS, payload: response.data })
          }
          setAuthState({
            ...authentication,
            authenticated: true,
            loading: false,
          })
        })
        .catch((error) => {
          console.error('the error is', error)
          setAuthState({
            ...authentication,
            authenticated: false,
            loading: false,
          })
        })
    } catch (error) {
      setAuthState({
        ...authentication,
        authenticated: false,
        loading: false,
      })
      console.error(error)
    }
  }

  useEffect(() => {
    if (usertoken) {
      checkUser()
      setAuthState({
        ...authentication,
        authenticated: true,
        loading: false,
      })
    } else {
      setAuthState({
        ...authentication,
        authenticated: false,
        loading: false,
      })
    }
  }, [usertoken])
  if (authentication.loading) {
    return <Loading />
  }

  return (
    <RoleContext.Provider value={role}>
      <BrowserRouter>
        {usertoken && authentication.authenticated && <Sidebar />}
        <Routes>
          {authentication.authenticated && (
            <Route path="/loading" element={<Loading />} />
          )}
          <Route
            path="/login"
            element={
              <UnAuthRoute auth={authentication.authenticated}>
                {' '}
                <Login />
              </UnAuthRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <UnAuthRoute auth={authentication.authenticated}>
                {' '}
                <Signup />
              </UnAuthRoute>
            }
          />
          <Route
            path="/resetpassword"
            element={
              <UnAuthRoute auth={authentication.authenticated}>
                {' '}
                <ResetPassword />
              </UnAuthRoute>
            }
          />
          {role === 'admin' ? (
            <>
              <Route
                path="/"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Dashboard />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/courses"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Courses />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/courses/create"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <AddCourseForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/course/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditCourseForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/users/:_id/edit"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditUserForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/foundationcourse/create"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Addcourselevel />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/feepay/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <FeeManagementDetails />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/studentmemo/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <StudentDetails />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/student-letter-report/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <StudentLetterDetails />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/foundationcourse"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Courselevel />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/commencement/create"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <CommencementForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/commencement"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Commencent />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/commencement/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditCommencement />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/foundationcourse/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditCourseLevel />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/applicationview/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Student />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/applications"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Applications />
                  </AdminRoute>
                }
              />
              <Route
                path="/student-applicationupdate/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditApplication />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/template"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EmailTemplate />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/fee-management"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <FeeManagement />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/fee-status"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <FeeStatus />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/editprofile"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <Profile />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/permissions"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <CourseRole />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/permissions/edit/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditPermissionsForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/roles/create"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <AddRolesForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/permissions/create"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <AddPermissions />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/roles/:_id/edit"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <EditRoleForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/feedetail/:_id"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <FeeDetails />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/roles"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <RolesTable />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/users/create"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <AddUserForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <UsersTable />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/student-letters"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <StudentLetters />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/studentslist"
                element={
                  <AdminRoute auth={authentication.authenticated}>
                    {' '}
                    <StudentsList />
                  </AdminRoute>
                }
              />
            </>
          ) : (
            <>
              {/* --------------------------These are user Routes----------------------- */}

              <Route
                path="/"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <Dashboarduser />
                  </UserRoute>
                }
              />
              <Route
                path="/appform/:_id"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <AppForm />
                  </UserRoute>
                }
              />
              <Route
                path="/student/courses"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <CourseStudent />
                  </UserRoute>
                }
              />
              <Route
                path="/student/application"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <Applicationstudent />
                  </UserRoute>
                }
              />
              <Route
                path="/student/applicationview/:_id"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <StudentviewApllication />
                  </UserRoute>
                }
              />
              <Route
                path="/student/fee"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <Studentfeeslist />
                  </UserRoute>
                }
              />
              <Route
                path="/student/fee/:_id"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <Studentfeesdetail />
                  </UserRoute>
                }
              />
              <Route
                path="/student/student-letters"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <Studentletters />
                  </UserRoute>
                }
              />
              <Route
                path="/student/student-letters/:_id"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    <Studentletterdetails />
                  </UserRoute>
                }
              />
              <Route
                path="/Student/fee-management"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    {' '}
                    <FeeManagementlist />
                  </UserRoute>
                }
              />
              <Route
                path="/student/fee-management/:_id"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    {' '}
                    <FeeManagementdetails />
                  </UserRoute>
                }
              />
              <Route
                path="/student/profile"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    {' '}
                    <Profilestudent />
                  </UserRoute>
                }
              />
              <Route
                path="/user/student-form"
                element={
                  <UserRoute auth={authentication.authenticated}>
                    {' '}
                    <AppForm />
                  </UserRoute>
                }
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </RoleContext.Provider>
  )
}

export default App
