import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FEE_Details } from '../../constants/feeMangementstatusconstant'
import { base_url } from '../../utils/Api'
const Studentfeeslist = () => {
  // const [studentsData, setStudentsData] = useState([
  //   {
  //     id: 1,
  //     name: 'John Hills',
  //     dob: '2003-06-12',
  //     city: 'Gujrat',
  //     courseName: 'Diploma in Web development',
  //     startDate: '2023-01-06',
  //     feeStatus: 'Fully Paid',
  //     totalPaidAmount: '£90 / £0',
  //   },
  //   // Add more student data as needed
  // ])

  // // const [studentsData, setStudentsData] = useState([])
  // const handlePayFee = (studentId) => {
  //   // Simulate fee payment by updating the fee status and total paid amount
  //   setStudentsData((prevData) =>
  //     prevData.map((student) =>
  //       student.id === studentId
  //         ? {
  //             ...student,
  //             feeStatus: 'Not Paid',
  //             totalPaidAmount: '£90 / 0', // Assuming the student has fully paid the fee
  //           }
  //         : student
  //     )
  //   )
  // }
  const [data, setData] = useState([])
  const [currentUser, setCurrentUser] = useState('')
  const [showData, setShowData] = useState([])
  const [courses, setCourses] = useState([])
  const [PerPageEntry, setPerPageEntry] = useState(10)
  const [searchQuery, setSearchQuery] = useState('') 
   const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
   const [totalEntries, setTotalEntries] = useState(0)
   const [totalLength, setTotalLength] = useState(0)
   const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const usertoken = localStorage.getItem('UserToken')
   const startIndex = (currentPage - 1) * PerPageEntry
   const endIndex = startIndex + PerPageEntry
  useEffect(() => {
    checkUser()
  }, [])
  const checkUser = async () => {
    try {
      axios
        .post(
          base_url + 'user/userData',
          {},
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === '201') {
            // setRole(response?.data?.UserData?.type)
            console.log('the respsdsdsd is👩👩👩', response)
            setCurrentUser(response?.data?.UserData?.email)
            // localStorage.setItem('role', response?.data?.UserData?.type)
          }
        })
        .catch((error) => {
          console.error('the error is', error)
        })
    } catch (error) {
      console.error(error)
    }
  }
    const handlePreviousClick = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
      }
    }

    const handleNextClick = () => {
      const totalPages = Math.ceil(totalEntries / PerPageEntry)
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1)
      }
    }

  const getAllCourses = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        setCourses(res?.data?.data)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    //  getAllList()
    getAllCourses()
  }, [])
  const getAllForms = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/applications', config)
      .then((res) => {
        let applicationsdata = res?.data?.applications
        const filteredData = applicationsdata.filter(
          (eachapplication) =>
            eachapplication.Personal_Details.Email == currentUser
        )
        console.log('teh sidfjs', filteredData)
        setData(filteredData)
      })
      // console.log('teh sidfjs', res)
      // setData(res?.data?.applications)
      // })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    console.log('currentUser', currentUser)
    if (currentUser) {
      getAllForms()
    }
  }, [currentUser])

  console.log('the student data isssssss', data)
  console.log('the course data isssssss', courses)
  const gatherData = () => {
    let arr = []
    data.forEach((item) => {
      if (item?.Course?.course_name) {
        courses.forEach((course_item) => {
          if (item?.Course?.course_name === course_item.course_Name) {
            arr.push({
              Student_Name: item?.Personal_Details?.First_Name_In_Passport,
              Family_Name_In_Passport:
                item?.Personal_Details?.Family_Name_In_Passport,
              Modile_No: item?.Personal_Details?.Modile_No,
              DOB: item?.Personal_Details?.Date_Of_Birth,
              City: item?.Personal_Details?.City,
              Email: item?.Personal_Details?.Email,
              _id: item?._id,

              Course_Name: course_item?.course_Name,
              totalamount: item.totalamount,
              feestatus: item?.feestatus,
              course_Level: course_item?.course_Level,

              Start_Date: course_item?.start_Date,
              Fee: course_item?.fee,
              End_Date: course_item?.end_Date,
              Installment:
                course_item?.allow_Installment.length > 0 ? 'Installments' : '',
            })
          }
        })
      }
    })
     setTotalEntries(arr.length)
     setTotalLength(arr.length)
     const filteredData = arr.filter((item) =>
       item?.Student_Name.toLowerCase().includes(searchQuery.toLowerCase())
     )
     if (filteredData) {
       setTotalEntries(filteredData.length)
     }
     setShowData(filteredData)
     setFilteredTotalEntries(filteredData.length) // Set showData after processing
  }
  useEffect(() => {
    gatherData()
  }, [data, courses,searchQuery])

  // Use useEffect to log the updated state when it changes
  useEffect(() => {
    console.log('showData:', showData)
    dispatch({ type: FEE_Details, payload: showData })
  }, [showData])
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-wrappe">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Fee-Status</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Fee-Status</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 ">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <p style={{ display: 'none' }}>1</p>
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div class="col-sm-12 col-md-6 mb-2">
                              <div
                                class="dataTables_length"
                                id="DataTables_Table_0_length"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <label>Show </label>
                                <select
                                  name="DataTables_Table_0_length"
                                  aria-controls="DataTables_Table_0"
                                  className="custom-select custom-select-sm form-control form-control-sm"
                                  style={{
                                    width: 60,
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                  value={PerPageEntry}
                                  onChange={(e) =>
                                    setPerPageEntry(parseInt(e.target.value))
                                  }
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>{' '}
                                <label>entries</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-2">
                            <div
                              id="DataTables_Table_0_filter"
                              className="dataTables_filter "
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="DataTables_Table_0"
                                value={searchQuery} // Bind the searchQuery to the input value
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ width: 200, marginLeft: 8 }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card card-table">
                              <div className="card-body">
                                {/* <div className="table-responsive"> */}
                                <table className="table table-hover table-center mb-0 datatable">
                                  <thead>
                                    <tr>
                                      <th>Sr.No</th>
                                      <th>Student Name</th>
                                      <th>DOB</th>

                                      <th>Course Name</th>
                                      <th>Start Date</th>
                                      <th>Fee Status</th>
                                      <th>Total Paid Amount</th>
                                      <th className="text-right">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {showData.length > 0 ? (
                                      showData
                                        .slice(startIndex, endIndex)
                                        .map((student, index) => (
                                          <tr role="row" className="odd">
                                            {/* <td>{student.id}</td> */}
                                            {/* <tr role="row" className="odd"> */}
                                            <td>
                                              {' '}
                                              {parseInt(startIndex) + index + 1}
                                            </td>
                                            <td>{student?.Student_Name}</td>
                                            <td>{student?.DOB}</td>
                                            <td>{student.Course_Name}</td>
                                            <td>{student.Start_Date}</td>
                                            {/* <td>{student.feeStatus}</td>
                                          <td>{student.totalPaidAmount}</td> */}
                                            {/* <td>{'Andriod Development'}</td>
                                          <td>{'2023 - 04 - 06'}</td> */}
                                            <td>
                                              {' '}
                                              <span
                                                className="badge badge-secondary p-2"
                                                style={{
                                                  backgroundColor: '#6c757d',
                                                }}
                                              >
                                                {student.feestatus}
                                              </span>
                                            </td>
                                            <td>
                                              {' '}
                                              {'£' +
                                                ' ' +
                                                student?.Fee +
                                                ' ' +
                                                '/' +
                                                ' ' +
                                                '£' +
                                                ' ' +
                                                student.totalamount}
                                            </td>
                                            <td className="text-right">
                                              <div className="actions">
                                                <Link
                                                  to={`/student/fee/${student._id}`}
                                                  className="btn btn-sm bg-success-light mr-2"
                                                >
                                                  View
                                                </Link>
                                                {/* Add more action buttons as needed */}
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="10"
                                          style={{ textAlign: 'center' }}
                                        >
                                          {searchQuery
                                            ? 'No matching records found'
                                            : 'Loading...'}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div
                                className="dataTables_info"
                                id="DataTables_Table_0_info"
                                role="status"
                                aria-live="polite"
                              >
                                {filteredTotalEntries === 0
                                  ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                                  : `Showing ${Math.min(
                                      startIndex + 1,
                                      endIndex
                                    )} to ${Math.min(
                                      endIndex,
                                      filteredTotalEntries
                                    )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="DataTables_Table_0_paginate"
                              >
                                <ul className="pagination justify-content-end">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="DataTables_Table_0_previous"
                                  >
                                    <a
                                      href="#"
                                      aria-controls="DataTables_Table_0"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                      onClick={handlePreviousClick}
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  {totalEntries > 0
                                    ? Array.from({
                                        length: Math.max(
                                          1,
                                          Math.ceil(totalEntries / PerPageEntry)
                                        ),
                                      }).map((_, index) => (
                                        <li
                                          key={index}
                                          className={`paginate_button page-item ${
                                            currentPage === index + 1
                                              ? 'active'
                                              : ''
                                          }`}
                                        >
                                          <button
                                            onClick={() =>
                                              setCurrentPage(index + 1)
                                            }
                                            className="page-link"
                                          >
                                            {index + 1}
                                          </button>
                                        </li>
                                      ))
                                    : null}
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="DataTables_Table_0_next"
                                  >
                                    <a
                                      href="#"
                                      aria-controls="DataTables_Table_0"
                                      data-dt-idx="2"
                                      tabIndex="0"
                                      className="page-link"
                                      onClick={handleNextClick}
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © {new Date().getFullYear()}.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Studentfeeslist
