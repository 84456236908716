import axios from 'axios'

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { base_url } from '../../../utils/Api'

const EditRoleForm = () => {
  const [roleName, setRoleName] = useState('')
  const [permissions, setPermissions] = useState([])
  const [data, setData] = useState({})

  const handleCheckboxChange = (index) => {
    // const { value, checked } = event.target
    // if (checked) {
    //   setPermissions((prevPermissions) => [...prevPermissions, value])
    // } else {
    //   setPermissions((prevPermissions) =>
    //     prevPermissions.filter((permission) => permission !== value)
    //   )
    // }
    setData((prevData) => {
      const newData = { ...prevData }
      newData.Permission[index].checked = !newData.Permission[index].checked
      return newData
    })
  }

  const handleRoleNameChange = (text) => {
    setData((prevData) => ({
      ...prevData,
      Role_Name: text,
    }))
  }
  const { _id } = useParams()
  useEffect(() => {
    getCourseNameById()
  }, [_id])
  const getCourseNameById = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+`admin/getRoleNameByid/${_id}`, config)
      .then((res) => {
        console.log('the res is❤❤❤❤❤', res)

        setData(res?.data?.data)
        //setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }
  console.log('the data is😊😊😊', data)

  const handleSubmit = (event) => {
    event.preventDefault()
    // Perform form submission here
    console.log('Role Name:', roleName)
    console.log('Permissions:', permissions)
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    axios
      .put(
        base_url+`admin/updateRoleName/${_id}`,
        data,
        config
      )
      .then((res) => console.log('resp is', res))
      .catch((err) => console.log('error is'.err))
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Add Roles</h3>
                  <ul className="breadcrumb mt-3">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Edit Role</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Admission Form */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm">
                        <form
                          onSubmit={handleSubmit}
                          className="needs-validation"
                          noValidate
                        >
                          <input
                            type="hidden"
                            name="_token"
                            value="X90I0NPAgthq4dcz6ArwbaXK1S7CKB4MEOxnPLCS"
                          />
                          <input type="hidden" name="_method" value="put" />
                          <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom01">
                                Role Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder=""
                                name="name"
                                value={data?.Role_Name}
                                onChange={(e) =>
                                  handleRoleNameChange(e.target.value)
                                }
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            </div>
                          </div>
                          <div className="card-header">
                            <h5 className="card-title mb-2 font-weight-bold">
                              Permissions
                            </h5>
                          </div>
                          <div className="form-row mt-3 mb-3">
                            {/* Map through permissions array and render checkboxes */}
                            {data?.Permission?.map((permission, index) => (
                              <div
                                className="col-lg-3 box"
                                key={permission._id}
                              >
                                <input
                                  className="larger"
                                  type="checkbox"
                                  // id={`checkbox${permission._id}`}
                                  name="permissions[]"
                                  value={permission._id}
                                  checked={permission.checked}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                                <label
                                  className="mt-1 ml-1"
                                  // htmlFor={`checkbox${permission._id}`}
                                >
                                  {permission.label}
                                </label>
                              </div>
                            ))}
                          </div>

                          <button className="btn btn-primary" type="submit">
                            Submit form
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <p>Copyright © 2020.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default EditRoleForm
