import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { base_url } from '../../../utils/Api'

const printDiv = () => {
  // Function to print the content in the 'printArea' div
  const printContents = document.getElementById('elementId').innerHTML
  const originalContents = document.body.innerHTML
  document.body.innerHTML = printContents
  window.print()
  document.body.innerHTML = originalContents
}

const StudentLetterDetails = () => {
  const [data, setData] = useState('')
  const [showData, setShowData] = useState([])
  const [courses, setCourses] = useState([])
  // const { _id } = useParams()
  const [studentData, setStudentData] = useState(null)
  const params = useParams()
  let _id = params._id

  const getAllCourses = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        setCourses(res?.data?.data)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    //  getAllList()
    getAllCourses()
  }, [])
  const getAllForms = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        console.log('teh sidfjs', res)
        setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    getAllForms()
  }, [])

  console.log('the student data isssssss', data)
  console.log('the course data isssssss', courses)
  const gatherData = () => {
    let arr = []
    // data.forEach((item) => {
    // console.log("the data is",item)
    // if (item?.Course?.course_name) {
    courses.forEach((course_item) => {
      if (data?.Course?.course_name === course_item.course_Name) {
        arr.push({
          Student_Name: data?.Personal_Details?.First_Name_In_Passport,
          Family_Name_In_Passport:
            data?.Personal_Details?.Family_Name_In_Passport,
          Modile_No: data?.Personal_Details?.Modile_No,
          DOB: data?.Personal_Details?.Date_Of_Birth,
          City: data?.Personal_Details?.City,
          Email: data?.Personal_Details?.Email,
          _id: data?._id,
          Home_Tel_No: data?.Personal_Details?.Home_Tel_No,

          Course_Name: course_item?.course_Name,
          course_Level: course_item?.course_Level,

          Start_Date: course_item?.start_Date,
          Fee: course_item?.fee,
          End_Date: course_item?.end_Date,
          Installment:
            course_item?.allow_Installment.length > 0 ? 'Installments' : '',
        })
      }
    })
    // }
    // })
    setShowData(arr) // Set showData after processing
  }
  useEffect(() => {
    gatherData()
  }, [data, courses])
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  console.log('showData:', showData)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" id="elementId">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Student Letter Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="http://clc-london.uk/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Student Letter Details
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="messages" />
              </div>
            </div>
          </div>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">COURSE DETAILS</h5>
              <div className="row">
                <div className="col-md-12">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      <div
                        className="fw-bold"
                        style={{ fontWeight: 'bold', fontSize: '19px' }}
                      >
                        Course title
                      </div>
                      {showData[0]?.Course_Name}
                    </div>
                  </li>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course Start Date
                        </div>
                        {showData[0]?.Start_Date}
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course End Date
                        </div>
                        {showData[0]?.End_Date}
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">PERSONAL DETAILS</h5>
              <div className="row">
                <div className="col-md-12">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      <div
                        className="fw-bold"
                        style={{ fontWeight: 'bold', fontSize: '19px' }}
                      >
                        Name:
                      </div>
                      {showData[0]?.Student_Name}
                      <input
                        id
                        defaultValue="shahid"
                        name="FamilyName"
                        hidden
                      />
                    </div>
                  </li>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Previous Family Name:
                        </div>
                        {showData[0]?.Family_Name_In_Passport}
                        <input
                          id
                          defaultValue="rasool"
                          name="PreviousName"
                          hidden
                        />
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Home Tel no:
                        </div>
                        {showData[0]?.Home_Tel_No}
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="col-md-6">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Email:
                        </div>
                        {showData[0]?.Email}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Mobile:
                        </div>
                        {showData[0]?.Modile_No}
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ width: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">Reports</h5>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  margin: '10px 0px 0px 0px',
                  gap: '10px',
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary btn"
                  style={{ padding: '8px 40px' }}
                  onclick={printDiv}
                >
                  Student Status Letter
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn"
                  style={{ padding: '8px 40px' }}
                  onclick="printDiv()"
                >
                  Acceptance Letter
                </button>
              </div>
            </div>
          </div>
          <div
            className="card"
            id="print"
            style={{ width: '100%', display: 'none' }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default StudentLetterDetails
