import axios from 'axios'
import { useSelector } from 'react-redux'
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_FAILED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from '../constants/userConstants'
import { base_url } from '../utils/Api'

export const login = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    const { data } = await axios.post(
      base_url + 'user/login',
      {
        email,
        password,
      },
      config
    )
    console.log('the data is👩👩👩👩', data)
    console.log('the data is👩👩👩👩', data?.status)

    if (data?.status === '201') {
      console.log('fsdjfl')
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data })
      localStorage.setItem('UserToken', data?.UserData?.token)
    }

    // const {
    //     userLogin: {userInfo},
    // }=getState();

    // for role work
    // localStorage.setItem("RoleWork",data.user.Role)
    // for role work

    // sessionStorage.setItem("UserToken", JSON.stringify(userInfo.token));
    // const token=localStorage.getItem("UserToken")
  } catch (error) {
    console.log('the eroro is', error)
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error?.message,
      // error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message
    })
  }
}

export const logout = async (dsipatch) => {
  localStorage.removeItem('UserToken')
  dsipatch({ type: USER_LOGOUT })
}

export const register = (data) => async (dispatch) => {
  console.log(data)
  try {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    console.log('apiii posting data')
    const result = await axios.post(
      base_url + 'user/register',
      //'https://student-registration-system.vercel.app/api/user/register',
      {
        username: data?.username,
        password: data?.password,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        telephone: data?.telephone,
        selectedCountry: data?.selectedCountry,
      },
      config
    )
    console.log(result)
    dispatch({ type: USER_REGISTER_SUCCESS, payload: result?.data })
  } catch (error) {
    console.log(error)
    dispatch({ type: USER_REGISTER_FAIL, error: error })
  }
}

export const resetpassword = (email) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST })
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    const { data } = await axios.post(
      base_url + 'user/checkRestpassword',
      {
        email
      },
      config
    )
    console.log('the data is👩👩👩👩', data)
    console.log('the data is👩👩👩👩', data?.status)

    // if (data?.status === '201') {
      console.log('fsdjfl')
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data })
      return Promise.resolve(data)
      // localStorage.setItem('UserToken', data?.UserData?.token)
    // }

    // const {
    //     userLogin: {userInfo},
    // }=getState();

    // for role work
    // localStorage.setItem("RoleWork",data.user.Role)
    // for role work

    // sessionStorage.setItem("UserToken", JSON.stringify(userInfo.token));
    // const token=localStorage.getItem("UserToken")
  } catch (error) {
    console.log('the eroro is', error)
    dispatch({
      type: RESET_PASSWORD_FAILED,
      payload: error,
      // error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message
    })
    return Promise.reject(error)
  }
}

export const verifyotp = (email, enteredOTP) => async (dispatch, getState) => {
  try {
    dispatch({ type: VERIFY_OTP_REQUEST })
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    const { data } = await axios.post(
      base_url + 'user/checkotp',
      {
        email,
        enteredOTP,
      },
      config
    )
    console.log('the data is👩👩👩👩', data)
    console.log('the data is👩👩👩👩', data?.status)

    // if (data?.status === '201') {
      console.log('fsdjfl')
      dispatch({ type: VERIFY_OTP_SUCCESS, payload: data })
     return Promise.resolve(data);
      // localStorage.setItem('UserToken', data?.UserData?.token)
    // }

    // const {
    //     userLogin: {userInfo},
    // }=getState();

    // for role work
    // localStorage.setItem("RoleWork",data.user.Role)
    // for role work

    // sessionStorage.setItem("UserToken", JSON.stringify(userInfo.token));
    // const token=localStorage.getItem("UserToken")
  } catch (error) {
    console.log('the eroro is', error)
    dispatch({
      type: VERIFY_OTP_FAILED,
      payload: error,
      // error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message
    })
    return Promise.reject(error)
  }
}

export const changepassword = (email, newPassword, resetToken) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST })
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    const { data } = await axios.post(
      base_url + 'user/resetPassword',
      {
        email,
        newPassword,
        resetToken,
      },
      config
    )
    console.log('the data is👩👩👩👩', data)
    console.log('the data is👩👩👩👩', data?.status)

    // if (data?.status === '201') {
    console.log('fsdjfl')
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data })
    return Promise.resolve(data)
    // localStorage.setItem('UserToken', data?.UserData?.token)
    // }

    // const {
    //     userLogin: {userInfo},
    // }=getState();

    // for role work
    // localStorage.setItem("RoleWork",data.user.Role)
    // for role work

    // sessionStorage.setItem("UserToken", JSON.stringify(userInfo.token));
    // const token=localStorage.getItem("UserToken")
  } catch (error) {
    console.log('the eroro is', error)
    dispatch({
      type: CHANGE_PASSWORD_FAILED,
      payload: error,
      // error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message
    })
    return Promise.reject(error)
  }
}