import axios from 'axios'
import { USER_LOGIN_SUCCESS } from './../../constants/userConstants.js'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { base_url, base_url1 } from '../../utils/Api'
import { Button } from 'bootstrap'
import { error } from 'pdf-lib'
const Profilestudent = () => {
  const dispatch=useDispatch()
    const userLogin = useSelector((state) => state.userLogin)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
   const [profile, setProfile] = useState(null)
   const [profileImage, setProfileImage] = useState(null)
   const [, forceUpdate] = useState()
  // const [profilePictureSrc, setProfilePictureSrc] = useState(
  //   'assets/images/profile-images/7a506246e9e1ea96287ac0e44774f7c9.jpg'
  // )
  const navigate = useNavigate()
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  useEffect(() => {
    if (userLogin) {
      console.log('Redux State:', userLogin)
      let userInfo = userLogin.userInfo.UserData
      setName(userInfo.name)
      setEmail(userInfo.email)
       setProfile(userInfo.profile)
      // setPhone(userInfo.name)
      // setPassword("")
      console.log('user', userLogin.userInfo.UserData)
    }
    // console.log('user',user)
  }, [userLogin])
 
  const handleProfilePicChange = (event) => {
    console.log('file', event.target)
    const file = event.target.files[0]
    setProfile(file)
     
    setProfileImage(URL.createObjectURL(file));
  };
    // const file = event.target.files[0]
    // console.log('file1', file)
    // if (file) {
    //   setProfilePictureSrc(URL.createObjectURL(file))
      
    // }
  
  const handleProfile = async (e) => {
    e.preventDefault()

    try {
      console.log('email', email)
      console.log('name', name)
      // console.log('profilePictureSrc', profilePictureSrc)
      // Attempt to login
      // await dsipatch(login(email, password))
      // If successful, the success toast will be shown in the useEffect
    } catch (error) {
      // If there's an error (e.g., incorrect login or password), show an error toast
      // toast.error('Invalid login credentials. Please try again.')
    }
  }
 const updateInfo =  (_id) => {
   console.log('Before update - User Info:', userLogin.userInfo.UserData)
   const formData = new FormData()
   if (profile) {
     console.log('the profile is', profile)
     formData.append('profile', profile)
     formData.append('data', JSON.stringify({ _id: _id }))

     const config = {
       headers: {
         'Content-Type': 'multipart/form-data',
       },
     }

    //  try {
       axios
         .post(base_url + 'user/updateProfile', formData, config)
         .then((res) => {
           console.log('res')
           console.log('response', res.data.result)
           const updatedProfile = res.data.result
           console.log('Updated Profile:', updatedProfile)
           const updatedProfileImage = updatedProfile.profileImage
           setName(updatedProfile.name)
           setEmail(updatedProfile.email)
           setProfile(updatedProfile.profile)
dispatch({ type: USER_LOGIN_SUCCESS, payload: { UserData: updatedProfile } })
           setProfileImage(updatedProfileImage)
         })
         .catch((error) => {
           console.log('error', error)
         })
      

    //    return Promise.resolve()
    //  } catch (error) {
    //    console.error('Error updating profile:', error)

    //    // Return a rejected promise to indicate failure
    //    return Promise.reject(error)
    //  }
   }
 }

  

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Profile</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Profile</li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="main" className="main container">
              <div className="form mt-3">
                <form

               

                encType="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="L3SZJJ8iq8356nAXdsBDSBAgu66YJoHpmk69Cafd"
                  />
                  <input
                    className="form-control"
                    placeholder="Buisness Development"
                    name="AspNetUserID"
                    value="4ab86223-8064-4ca0-83a7-faa4d46f6fe1"
                    required
                    hidden
                  />
                  <input
                    className="form-control"
                    placeholder="Buisness Development"
                    name="UserTypeID"
                    value="1"
                    required
                    hidden
                  />
                  <div className="row pl-4 pl-4-remove-mobile">
                    <div className="col-md-5 m-0 p-0 d-inline-block half">
                      <div>
                        <img
                          src={
                            profileImage
                              ? profileImage
                              : base_url1 + `files/${profile}`
                          }
                          id="ProfilePicture"
                          style={{ width: '95%' }}
                          className="img-fluid"
                          alt="Profile Picture"
                        />
                        <label
                          className="next cursor-pointer btn btn-primary"
                          htmlFor="ProfilePic"
                          style={{
                            textAlign: 'center',
                            padding: '5px',
                            textAlign: 'center',
                            padding: '8px',
                            marginTop: '10px',
                          }}
                        >
                          Change Picture
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="ProfilePic"
                          name="profile"
                          className="d-none"
                          onChange={handleProfilePicChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 m-0 p-0 d-inline-block half form-mobile-padding-right">
                      <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Name
                          </label>
                          <input
                            className="form-control"
                            placeholder="First Name"
                            name="name"
                            value={name}
                            required
                            id="FirstName"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Phone
                          </label>
                          <input
                            className="form-control"
                            placeholder="Last Name"
                            name="phone"
                            value={phone}
                            required
                            id="LastName"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="example@example.com"
                            name="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            id="Email"
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={password}
                            id="Password"
                            minLength="8"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 m-0 p-0 text-right mb-3 mt-3">
                        <Link
                          type="submit"
                          onClick={() =>
                           updateInfo(userLogin?.userInfo?.UserData?._id)
                            
                          }
                          className="btn btn-primary"
                        >
                          Update Info
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2022.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Profilestudent
