import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Profile = () => {
  const [name, setName] = useState('Admin')
  const [phone, setPhone] = useState('3018640237')
  const [password, setPassword] = useState('')
  const [profilePictureSrc, setProfilePictureSrc] = useState(
    'assets/images/profile-images/7a506246e9e1ea96287ac0e44774f7c9.jpg'
  )
// useEffect(()=>{
// // console.log('user',user)
// },[])
  const handleProfilePicChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setProfilePictureSrc(URL.createObjectURL(file))
    }
  }
const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Profile</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Profile</li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="main" className="main container">
              <div className="form mt-3">
                <form
                  action="http://clc-london.uk/updateprofile"
                  method="post"
                  id="form1"
                  encType="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="L3SZJJ8iq8356nAXdsBDSBAgu66YJoHpmk69Cafd"
                  />
                  <input
                    className="form-control"
                    placeholder="Buisness Development"
                    name="AspNetUserID"
                    value="4ab86223-8064-4ca0-83a7-faa4d46f6fe1"
                    required
                    hidden
                  />
                  <input
                    className="form-control"
                    placeholder="Buisness Development"
                    name="UserTypeID"
                    value="1"
                    required
                    hidden
                  />
                  <div className="row pl-4 pl-4-remove-mobile">
                    <div className="col-md-5 m-0 p-0 d-inline-block half">
                      <div>
                        <img
                          src={profilePictureSrc}
                          id="ProfilePicture"
                          style={{ width: '95%' }}
                          className="img-fluid"
                          alt="Profile Picture"
                        />
                        <label
                          className="next cursor-pointer btn btn-primary"
                          htmlFor="ProfilePic"
                          style={{
                            textAlign: 'center',
                            padding: '5px',
                            textAlign: 'center',
                            padding: '8px',
                            marginTop: '10px',
                          }}
                        >
                          Change Picture
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="ProfilePic"
                          name="profile"
                          className="d-none"
                          onChange={handleProfilePicChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 m-0 p-0 d-inline-block half form-mobile-padding-right">
                      <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Name
                          </label>
                          <input
                            className="form-control"
                            placeholder="First Name"
                            name="name"
                            value={name}
                            required
                            id="FirstName"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Phone
                          </label>
                          <input
                            className="form-control"
                            placeholder="Last Name"
                            name="phone"
                            value={phone}
                            required
                            id="LastName"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="example@example.com"
                            name="Email"
                            value="admin@admin.com"
                            required
                            id="Email"
                            readOnly
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label htmlFor="usr" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={password}
                            id="Password"
                            minLength="8"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 m-0 p-0 text-right mb-3 mt-3">
                        <button type="submit" className="btn btn-primary">
                          Update Info
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2022.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Profile
