import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { ToastContainer, toast } from 'react-toastify'
import { base_url } from '../../../src/utils/Api'
import img1 from '../../screens/assets/logo.jpg'
import img2 from '../../screens/assets/acca-1.png'
import img3 from '../../screens/assets/aw20090717121750439464-1.jpg'
import img4 from '../../screens/assets/download (1).jpg'
import img5 from '../../screens/assets/download.png'
import img6 from '../../screens/assets/images.png'
import { Button } from 'reactstrap'

const Studentletterdetails = () => {
  // const printDiv = (elementId) => {
  //   const elementToPrint = document.getElementById(elementId)
  //   if (elementToPrint) {
  //     const printContents = elementToPrint.innerHTML
  //     const originalContents = document.body.innerHTML

  //     document.body.innerHTML = printContents
  //     window.print()
  //     document.body.innerHTML = originalContents
  //     window.location.reload()
  //   }
  // }
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => {
      if (data?.status === 'Approve') {
        return componentRef.current
      } else {
        // Display a toast message when printing is not allowed
        toast.error('Printing is not allowed still you data have not approve.')
        return null // Returning null since there's no content to print
      }
    },
    documentTitle: 'Visitor Pass',
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  })
  const [data, setData] = useState('')
  const [showData, setShowData] = useState([])
  const [courses, setCourses] = useState([])
  // const { _id } = useParams()
  const [studentData, setStudentData] = useState(null)
  const params = useParams()
  let _id = params._id

  const getAllCourses = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        setCourses(res?.data?.data)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    //  getAllList()
    getAllCourses()
  }, [])
  const getAllForms = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + `admin/applicationview/${_id}`, config)
      .then((res) => {
        console.log('teh sidfjs', res)
        setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }
  useEffect(() => {
    getAllForms()
  }, [])

  console.log('the student data isssssss', data.status)
  console.log('the course data isssssss', courses)
  const gatherData = () => {
    let arr = []
    // data.forEach((item) => {
    // console.log("the data is",item)
    // if (item?.Course?.course_name) {
    courses.forEach((course_item) => {
      if (data?.Course?.course_name === course_item.course_Name) {
        arr.push({
          Student_Name: data?.Personal_Details?.First_Name_In_Passport,
          Family_Name_In_Passport:
            data?.Personal_Details?.Family_Name_In_Passport,
          Modile_No: data?.Personal_Details?.Modile_No,
          DOB: data?.Personal_Details?.Date_Of_Birth,
          City: data?.Personal_Details?.City,
          Email: data?.Personal_Details?.Email,
          _id: data?._id,
          Home_Tel_No: data?.Personal_Details?.Home_Tel_No,

          Course_Name: course_item?.course_Name,
          course_Level: course_item?.course_Level,

          Start_Date: course_item?.start_Date,
          Fee: course_item?.fee,
          End_Date: course_item?.end_Date,
          Installment:
            course_item?.allow_Installment.length > 0 ? 'Installments' : '',
        })
      }
    })
    // }
    // })
    setShowData(arr) // Set showData after processing
  }
  useEffect(() => {
    gatherData()
  }, [data, courses])
  const imageStyles = {
    maxWidth: '100%',
    height: 'auto',
    width: '256px', // Set a fixed width
    height: '163px', // Set a fixed height
    display: 'block', // Make the image a block element
    margin: '0 auto', // Center the image horizontally
    //  position: 'absolute',
    bottom: '6px', // Align the image to the bottom with 6px margin
    left: '50%', // Move to the center horizontally
    //  transform: 'translateX(-50%)',
    // Add any additional styles here
  }
  const images = {
    //  maxWidth: '100%',
    height: 'auto',
    width: '140px', // Set a fixed width
    height: '110px', // Set a fixed height
    display: 'block', // Make the image a block element
    margin: '10px',
    // Center the image horizontally
  }

  const textStyle = {
    textAlign: 'right',
    margin: '0',
    paddingRight: '15%',
  }

  const containerAndTextSty = {
    paddingLeft: '12%',
    paddingRight: '15%',
  }

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  }

  const thTdStyle = {
    border: '1px solid #333',
    textAlign: 'left',
    padding: '8px',
  }

  const imagesContainerStyle = {
    position: 'absolute',
    right: '0',
    top: '0',
    marginTop: '35%', // Top margin
    marginBottom: '35%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  }
  const thStyle = {
    padding: '8px',
    textAlign: 'left',
    //  backgroundColor: '#f2f2f2',
  }

  const tdStyle = {
    padding: '8px',
    textAlign: 'left',
  }

  const paragraphStyle = {
    margin: '3px 0',
    textAlign: 'end',
    fontSize: '16px',
    position: 'relative',
    paddingLeft: '25px',
    // borderLeft: '65px solid #333',
  }
  const tableData = [
    {
      label: 'Course Title',
      value: 'BTEC Higher National Certificate in Business',
    },
    { label: 'Duration', value: '0 month' },
    { label: 'Academic Level', value: 'Level 4' },
    { label: 'Awarding Body', value: 'Pearson' },
    { label: 'Start Date', value: '30 October 2023' },
    { label: 'End Date', value: '26 July 2024' },
    { label: 'Results Date', value: '26 August 2022' },
    { label: 'Study Mode', value: 'Full Time' },
    { label: 'Total Tuition fee', value: '£6,125.00' },
  ]

  const containerStyles = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 -15px', // Negative margin to offset column padding
  }

  const columnStyles = {
    flex: '0 0 50%', // Each column takes up 50% of the width
    boxSizing: 'border-box',
    padding: '0 15px', // Column padding
  }
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10%',
    borderLeft: '2px solid #000000',
    paddingRight: '65%',
    //  marginTop:"500px",
    //  marginBottom:'500x',
  }

  const currentDate = new Date()

  const textStyles = {
    color: 'blue', // Adjust the color based on your preference
    // Add more styles as needed
  }
  const lineStyle = {
    content: '""',
    position: 'absolute',
    left: '100%', // Start the line at the end of the text
    top: 0,
    bottom: 0,
    width: '5px',
    backgroundColor: '#333', // Dark color for the line
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  console.log('showData:', showData)
  return (
    <>
      <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Student Letter Details</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Student Letter Details
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="messages" />
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">COURSE DETAILS</h5>
                <div className="row">
                  <div className="col-md-12">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Course title
                        </div>
                        {showData[0]?.Course_Name}
                      </div>
                    </li>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Course Start Date
                          </div>
                          {showData[0]?.Start_Date}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Course End Date
                          </div>
                          {showData[0]?.End_Date}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">PERSONAL DETAILS</h5>
                <div className="row">
                  <div className="col-md-12">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div
                          className="fw-bold"
                          style={{ fontWeight: 'bold', fontSize: '19px' }}
                        >
                          Name:
                        </div>
                        {showData[0]?.Student_Name}
                        <input
                          id
                          defaultValue="shahid"
                          name="FamilyName"
                          hidden
                        />
                      </div>
                    </li>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Previous Family Name:
                          </div>
                          {showData[0]?.Family_Name_In_Passport}
                          <input
                            id
                            defaultValue="rasool"
                            name="PreviousName"
                            hidden
                          />
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Home Tel no:
                          </div>
                          {showData[0]?.Home_Tel_No}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Email:
                          </div>
                          {showData[0]?.Email}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div
                            className="fw-bold"
                            style={{ fontWeight: 'bold', fontSize: '19px' }}
                          >
                            Mobile:
                          </div>
                          {showData[0]?.Modile_No}
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body">
                <h5 className="card-title">Reports</h5>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    margin: '10px 0px 0px 0px',
                    gap: '10px',
                  }}
                >
                  <Button
                    type="button"
                    className="btn btn-primary btn"
                    style={{ padding: '8px 40px' }}
                    disabled={data?.status !== 'Approve'?true:false}
                    onClick={handlePrint}
                  >
                    Student Status Letter
                  </Button>
                  {/* <button
                  type="button"
                  className="btn btn-primary btn"
                  style={{ padding: '8px 40px' }}
                  onclick="printDiv()"
                >
                  Acceptance Letter
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', display: 'none' }}>
        <div ref={componentRef} className="card">
          <div style={{ position: 'relative', marginBottom: 500 }}>
            <img
              src={img1}
              alt="title"
              width={256}
              height={163}
              loading="lazy"
              style={imageStyles}
            />
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London E17 6X</p>
              <h3>Re: Learning and Funding Information Letter</h3>
              <p>
                We are pleased to offer you an Unconditional Offer on the
                following course:
              </p>
              <h4>Your course details are as follows:</h4>
              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <th style={thTdStyle}>Course Title</th>
                    <td style={thTdStyle}>
                      BTEC Higher National Certificate in Business
                    </td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Duration</th>
                    <td style={thTdStyle}>One Year</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Academic Level</th>
                    <td style={thTdStyle}>Level 4</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Awarding Body</th>
                    <td style={thTdStyle}>Pearson</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Registration Body</th>
                    <td style={thTdStyle}>Tyne Coast College</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Start Date</th>
                    <td style={thTdStyle}>30 October 2023</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>End Date</th>
                    <td style={thTdStyle}>26 July 2024</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Results Date</th>
                    <td style={thTdStyle}>26 August 2022</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Study Mode</th>
                    <td style={thTdStyle}>Full Time</td>
                  </tr>
                  <tr>
                    <th style={thTdStyle}>Total Tuition fee</th>
                    <td style={thTdStyle}>£6,125.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Images at the end of the first table */}
            <div style={imagesContainerStyle}>
              <img src={img4} alt="Image 1" style={images} />
              <img src={img6} alt="Image 2" style={images} />
              <img src={img3} alt="Image 3" style={images} />
              <img src={img2} alt="Image 4" style={images} />
              <img src={img5} alt="Image 4" style={images} />
            </div>
            <div style={containerAndTextSty}>
              <p>
                This letter provides you with information about your course and
                the cost of your studies.
                <strong>
                  Please keep it safe; you may need to refer to it again.
                </strong>
              </p>
              <h4>Paying for your studies:</h4>
              <p>
                You can pay the College directly for the course. Your family
                member or an employer may be willing to contribute.
                Alternatively, you may apply for the Student Loan through the
                Student Loans Company{' '}
                <a
                  href="https://www.gov.uk/government/organisations/student-loans-company"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Student Loans Company
                </a>
                . Please speak to us on{' '}
                <a href="tel:+442072472177">020 7247 2177</a> or send an email
                to{' '}
                <a href="mailto:studentservices@clc-london.ac.uk">
                  studentservices@clc-london.ac.uk
                </a>
                .
              </p>
              <p>
                Should you decide to apply to the Students Loan Company, the
                following details should be mentioned when making an
                application:
              </p>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thTdStyle}>
                      Name of College Where You Are Registere
                    </th>
                    <th style={thTdStyle}>
                      Name of College Delivering The Course
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={thTdStyle}>
                      Tyne Coast College, St George’s Avenue, South Shields,
                      Tyne & Wear, NE34 6ET UK Provider Reference Number:
                      10005999
                    </td>
                    <td style={thTdStyle}>
                      City of London College, 3 Boyd Street, London, E1 1FQ
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div style={containerStyle}> */}
              {/* <div style={lineStyle}> */}
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <p>Yours sincerely,</p>
              <p>Registry Office:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRight: '2px solid black',
                  paddingRight: 10,
                }}
              >
                <p style={paragraphStyle}>
                  3 Boyd Street, Aldgate East, London, E1 1FQ
                  {/* <span style={lineStyle}></span> */}
                </p>
                <p style={paragraphStyle}>
                  Tel: +44 (0)20 7247 2177 Fax: +44 (0)20 7247 1226 Email:
                  info@clc-london.ac.uk
                  {/* <span style={lineStyle}></span> */}
                </p>
                <a
                  href="https://www.clc-london.ac.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={paragraphStyle}
                >
                  www.clc-london.ac.uk
                  {/* <span style={lineStyle}></span> */}
                </a>
                <p style={paragraphStyle}>
                  principal: Maldwyn Buckland Patron: Lord Redesdale Hon.
                  President: A.L. Williams OBE
                  {/* <span style={lineStyle}></span> */}
                </p>
                <p style={paragraphStyle}>
                  REGISTERED IN ENGLAND AND WALES NO. 11818935
                  {/* <span style={lineStyle}></span> */}
                </p>
              </div>
            </div>
          </div>

          <div style={{ position: 'relative' }}>
            <img
              src={img1}
              alt="title"
              width={256}
              height={163}
              loading="lazy"
              style={imageStyles}
            />
            <div className="col-md-4 text-center mx-auto">
              <h2>LETTER OF ACCEPTANCE (Unconditional offer)</h2>
              <h4>Self-Funded Students Center</h4>
            </div>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London E17 6X </p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>
              <p>Re: BTEC Higher National Certificate in Business</p>
              <p>
                We are pleased to make an Unconditional Offer of a place on the
                course stated below. The offer is subject to the payment of the
                tuition fee.
              </p>
              <p>
                A considerable number of applications from potential students
                are received in each intake and we make unconditional offers to
                students most likely to benefit from the course. This offer has
                been made to you after considering your academic background,
                experience and successful interview and is valid for a period of
                seven days.
              </p>
              <h4>Your course details are as follows:</h4>
              <table style={tableStyle}>
                {/* <tbody> */}
                {/* {tableData.map((row, index) => (
                <tr key={index}>
                  <th style={thStyle}>{row.label}</th>
                  <td style={tdStyle}>{row.value}</td>
                </tr>
              ))} */}
                <tbody>
                  <tr>
                    <th style={thStyle}>Course Title</th>
                    <td style={tdStyle}>
                      BTEC Higher National Certificate in Business
                    </td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Duration</th>
                    <td style={tdStyle}>One Year</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Academic Level</th>
                    <td style={tdStyle}>Level 4</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Awarding Body</th>
                    <td style={tdStyle}>Pearson</td>
                  </tr>

                  <tr>
                    <th style={thStyle}>Start Date</th>
                    <td style={tdStyle}>30 October 2023</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>End Date</th>
                    <td style={tdStyle}>26 July 2024</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Results Date</th>
                    <td style={tdStyle}>26 August 2022</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Study Mode</th>
                    <td style={tdStyle}>Full Time</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Total Tuition fee</th>
                    <td style={tdStyle}>£6,125.00</td>
                  </tr>
                </tbody>
                {/* </tbody>?? */}
              </table>
            </div>

            {/* Images at the end of the first table */}
            <div style={imagesContainerStyle}>
              <img src={img4} alt="Image 1" style={images} />
              <img src={img6} alt="Image 2" style={images} />
              <img src={img3} alt="Image 3" style={images} />
              <img src={img2} alt="Image 4" style={images} />
              <img src={img5} alt="Image 4" style={images} />
            </div>
            <div style={containerAndTextSty}>
              <p>
                Please note that the College follows a strict progression and
                attendance policy. It is our policy to monitor and track your
                attendance and progression right through your studies.
                Attendance at lectures, tutorials and seminars is compulsory and
                you are required to adhere strictly with the attendance policy
                of the College, available on the Virtual Learning Environment.
                You will be attending lectures, tutorials and seminars for the
                above-mentioned duration.
              </p>
              <p>
                No tuition fee refunds or credits are made for non-attendance or
                withdrawal from the course.
              </p>
              <p>
                Please confirm your intention to accept this offer of admission
                by returning the signed copy of the attached Acceptance of Offer
                of a Place' letter
              </p>
              <p>We look forward to welcoming you to the College</p>

              {/* <div style={containerStyle}> */}
              {/* <div style={lineStyle}> */}
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <p>Registry Office:</p>
              <p style={paragraphStyle}>
                3 Boyd Street, Aldgate East, London, E1 1FQ
                <span style={lineStyle}></span>
              </p>
              <p style={paragraphStyle}>
                Tel: +44 (0)20 7247 2177 Fax: +44 (0)20 7247 1226 Email:
                info@clc-london.ac.uk
                <span style={lineStyle}></span>
              </p>
              <a
                href="https://www.clc-london.ac.uk"
                target="_blank"
                rel="noopener noreferrer"
                style={paragraphStyle}
              >
                www.clc-london.ac.uk
                <span style={lineStyle}></span>
              </a>
              <p style={paragraphStyle}>
                principal: Maldwyn Buckland Patron: Lord Redesdale Hon.
                President: A.L. Williams OBE
                <span style={lineStyle}></span>
              </p>
              <p style={paragraphStyle}>
                REGISTERED IN ENGLAND AND WALES NO. 11818935
                <span style={lineStyle}></span>
              </p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <img
              src={img1}
              alt="title"
              width={256}
              height={163}
              loading="lazy"
              style={imageStyles}
            />
            <div className=" col-md-4 mx-auto text-center">
              <h2>ACCEPTANCE OF OFFER OF A PLACE</h2>
            </div>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London E17 6X </p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                Your application for admission has been considered and I am
                pleased to offer you an unconditional offer on the course
                detailed below. This offer is subject to you satisfying all
                conditions specified, before commencing on the course
              </p>
              <h4>Your course details are as follows:</h4>
              <table style={tableStyle}>
                {/* <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <th style={thStyle}>{row.label}</th>
                  <td style={tdStyle}>{row.value}</td>
                </tr>
              ))}
            </tbody> */}
                <tbody>
                  <tr>
                    <th style={thStyle}>Course Title</th>
                    <td style={tdStyle}>
                      : BTEC Higher National Certificate in Business
                    </td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Duration</th>
                    <td style={tdStyle}>:One Year</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Academic Level</th>
                    <td style={tdStyle}>:Level 4</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Awarding Body</th>
                    <td style={tdStyle}>:Pearson</td>
                  </tr>

                  <tr>
                    <th style={thStyle}>Start Date</th>
                    <td style={tdStyle}>:30 October 2023</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>End Date</th>
                    <td style={tdStyle}>:26 July 2024</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Study hours per week</th>
                    <td style={tdStyle}>:: 14 - 16 Hours</td>
                  </tr>
                  <tr>
                    <th style={thStyle}>Total Tuition fee</th>
                    <td style={tdStyle}>:£6,125.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Images at the end of the first table */}
            <div style={imagesContainerStyle}>
              <img src={img4} alt="Image 1" style={images} />
              <img src={img6} alt="Image 2" style={images} />
              <img src={img3} alt="Image 3" style={images} />
              <img src={img2} alt="Image 4" style={images} />
              <img src={img5} alt="Image 4" style={images} />
            </div>
            <div style={containerAndTextSty}>
              <p>Subject to: Remittance of specified tuition fee</p>
              <p>I wish to pay my tuition fee in (Tick as appropriate):</p>
              <div style={containerStyles}>
                <div style={columnStyles}>
                  <p>
                    Please confirm your intention to accept this offer of
                    admission by returning the signed copy of the attached
                    'Acceptance of Offer of a Place' letter.
                  </p>
                </div>
                <div style={columnStyles}>
                  <p>We look forward to welcoming you to the College</p>
                </div>
              </div>

              {/* <div style={containerStyle}> */}
              {/* <div style={lineStyle}> */}
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <p>Registry Office:</p>
              <p style={paragraphStyle}>
                3 Boyd Street, Aldgate East, London, E1 1FQ
                <span style={lineStyle}></span>
              </p>
              <p style={paragraphStyle}>
                Tel: +44 (0)20 7247 2177 Fax: +44 (0)20 7247 1226 Email:
                info@clc-london.ac.uk
                <span style={lineStyle}></span>
              </p>
              <a
                href="https://www.clc-london.ac.uk"
                target="_blank"
                rel="noopener noreferrer"
                style={paragraphStyle}
              >
                www.clc-london.ac.uk
                <span style={lineStyle}></span>
              </a>
              <p style={paragraphStyle}>
                principal: Maldwyn Buckland Patron: Lord Redesdale Hon.
                President: A.L. Williams OBE
                <span style={lineStyle}></span>
              </p>
              <p style={paragraphStyle}>
                REGISTERED IN ENGLAND AND WALES NO. 11818935
                <span style={lineStyle}></span>
              </p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                We are writing to inform you that your course has now ended and
                we are closing your records at the College. In future should you
                require any reference or documentation to support your work or
                further studies please do let us know. The College has an active
                alumni network which we encourage you to join by clicking the
                alumni tab at the website
              </p>
            </div>

            <div style={containerAndTextSty}>
              <p>
                We take this opportunity to wish you well in your future plans
              </p>
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <h5 className="mb-4">Monica Kowalik (MA)</h5>
              <p>Admissions Officer</p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                According to College records your attendance has not been
                satisfactory. We are very concerned about this situation. Not
                only does it affect your prospects of success in your studies,
                but it is also likely to jeopardise your registration status.
                You must remember that you are enrolled on a full time programme
                of study which requires attendance as per your issued timetable.
                Attendance at lectures, tutorials and seminars is compulsory and
                you are required to maintain a near 100% attendance at all
                times. A medical certificate is required if there is absence on
                more than three consecutive days, owing to illness. You may, of
                course, be facing some difficulties of which the College is
                unaware. Should you require any help or counselling please do
                not hesitate to contact the College.
              </p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                A week ago the College wrote to you concerning your
                non-attendance. The College offered to assist you to continue
                with your studies. This matter is now very urgent. Please
                contact the College immediately to discuss the reasons for your
                non-attendance. Failure to contact us will be taken as
                indicating withdrawal from your course of studies. Within the
                next seven days the College will be taking steps to inform the
                concerned authorities - Student Loan Company (SLC), awarding
                body, Transport for London and your Borough Council - that you
                have been suspended from the College and that your enrolment is
                no longer valid. You will then not be allowed to use your
                College Identity Card as a proof of your status as a student
              </p>
            </div>

            <div style={containerAndTextSty}>
              <p>
                f you have contacted the College within the last THREE days
                please disregard this letter.
              </p>
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <h5 className="mb-4">Monica Kowalik (MA)</h5>
              <p>Admissions Officer</p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                We appear to have received no response to our previous letters
                about your non-attendance.Â It is now necessary to explain to
                you our position on this matter and the next steps which will be
                taken by the College. Please be advised that you have now been
                suspended from the College and that your enrolment is no longer
                valid.Â The College is currently in the process of informing the
                Student Loan Company(SLC), awarding body Transport for London
                and your Borough Council - that you are no longer enrolled with
                us
              </p>
            </div>

            <div style={containerAndTextSty}>
              <p>
                If you have contacted the College within the last THREE days
                please disregard this letter.Â
              </p>
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <h5 className="mb-4">Monica Kowalik (MA)</h5>
              <p>Admissions Officer</p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                We are writing to inform you that your course has now ended and
                we are closing your records at the College. In future should you
                require any reference or documentation to support your work or
                further studies please do let us know. The College has an active
                alumni network which we encourage you to join by clicking the
                alumni tab at the website
              </p>
            </div>

            <div style={containerAndTextSty}>
              <p>
                We take this opportunity to wish you well in your future plans.
              </p>
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <h5 className="mb-4">Monica Kowalik (MA)</h5>
              <p>Admissions Officer</p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                <div style={{ position: 'relative', marginTop: '10%' }}>
                  <h2 style={textStyle}>
                    {currentDate.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </h2>
                  <p style={textStyle}>Student ID: 203101121</p>

                  <div style={containerAndTextSty}>
                    <p>Mr. Bogumil SADOWSKI</p>
                    <p>48 St Andrews Court</p>
                    <p>London</p>
                    <p>E17 6X</p>

                    <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

                    <p>
                      We are writing to inform you that your course has now
                      ended and we are closing your records at the College. In
                      future should you require any reference or documentation
                      to support your work or further studies please do let us
                      know. The College has an active alumni network which we
                      encourage you to join by clicking the alumni tab at the
                      website
                    </p>
                  </div>

                  <div style={containerAndTextSty}>
                    <p>
                      You should be aware that Pearson will not refund your
                      registration fee, but it is valid for 5 years from the
                      date of your initial registration. Should you choose to
                      study at another College, your Pearson registration is
                      transferrable without further cost, subject to the other
                      College accepting your enrolment
                    </p>
                  </div>

                  {/* Remaining content */}
                  <div style={containerStyle}>
                    <div></div>
                    <p>Yours sincerely,</p>
                    <h5 className="mb-4">Monica Kowalik (MA)</h5>
                    <p>Admissions Officer</p>
                  </div>
                </div>
              </p>
            </div>

            <div style={containerAndTextSty}>
              <p>
                We take this opportunity to wish you well in your future plans.
              </p>
            </div>

            {/* Remaining content */}
            <div style={containerStyle}>
              <div></div>
              <p>Yours sincerely,</p>
              <p>image</p>
              <h5 className="mb-4">David Nixon BA (Hons), PGCE</h5>
              <p>Principal</p>
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '10%' }}>
            <h2 style={textStyle}>
              {currentDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </h2>
            <p style={textStyle}>Student ID: 203101121</p>

            <div style={containerAndTextSty}>
              <p>Mr. Bogumil SADOWSKI</p>
              <p>48 St Andrews Court</p>
              <p>London</p>
              <p>E17 6X</p>

              <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

              <p>
                <div style={{ position: 'relative', marginTop: '10%' }}>
                  <h2 style={textStyle}>
                    {currentDate.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </h2>
                  <p style={textStyle}>Student ID: 203101121</p>

                  <div style={containerAndTextSty}>
                    <p>Mr. Bogumil SADOWSKI</p>
                    <p>48 St Andrews Court</p>
                    <p>London</p>
                    <p>E17 6X</p>

                    <p className="mt-2 mb-5">Dear Mr. SADOWSKI</p>

                    <p>
                      My colleagues and I are pleased to congratulate you on the
                      successful completion of your programme of study at City
                      of London College. Your transcript, which shows your
                      result is enclosed. The certificate will be available
                      within four to eight weeks for your collection and we will
                      notify you upon receipt of it. Please retain these safely
                      as you would need them to continue with your studies or to
                      secure employment. You may nominate your tutor, Course
                      Leader or Director of Studies as your referee when
                      applying to do further studies or when applying for
                      employment
                    </p>
                  </div>

                  <div style={containerAndTextSty}>
                    <p>
                      Please keep the College informed of your future plans, as
                      we are interested in the welfare of our alumni. Your
                      details will be included on the mailing list of our free
                      termly newsletter
                    </p>
                  </div>
                </div>
              </p>
            </div>

           
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Studentletterdetails
