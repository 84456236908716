import React, { useEffect, useState } from 'react'

import '../SideBar.css'
import './Dashboard.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { base_url } from '../../../utils/Api'

function Dashboard() {
  const [data, setData] = useState([])

  const getAllForms = async () => {
    try {
      const response = await axios.get(base_url + 'admin/applications')
      setData(response?.data?.applications || [])
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    getAllForms()
  }, [])

  const calculateStatusCounts = () => {
    const counts = {
      pending: 0,
      approve: 0,
      reject: 0,
      revision: 0,
    }

    data.forEach((application) => {
      const status = application.status.toLowerCase()
      if (counts.hasOwnProperty(status)) {
        counts[status]++
      }
    })

    return counts
  }

  const statusCounts = calculateStatusCounts()
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      {/* <div style={{ width: '100%' }}> */}
      <div className="page-wrapper" style={{ minHeight: '845px' }}>
        <div className="content ">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Welcome Admin!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Status cards */}
            {Object.entries(statusCounts).map(([status, count]) => (
              <div key={status} className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className={`card bg-${status} w-100`}>
                  <div className="card-body">
                    <div className="db-widgets d-flex justify-content-between align-items-center">
                      <div className="db-icon">
                        <i
                          className={`fas fa-file${
                            status === 'revision' ? '-invoice-dollar' : ''
                          }`}
                        />
                      </div>
                      <div className="db-info">
                        <h3>{count}</h3>
                        <h6>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <footer>
          <p>Copyright © {new Date().getFullYear()}.</p>
        </footer>
      </div>
      {/* </div> */}
    </div>
  )
}

export default Dashboard
