import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'

const AddRolesForm = () => {
  const [roleName, setRoleName] = useState('')
  const [permissions, setPermissions] = useState([])
   const [PerPageEntry, setPerPageEntry] = useState(10)
   const [searchQuery, setSearchQuery] = useState('') // New state for search query
   const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
   const [totalEntries, setTotalEntries] = useState(0)
   const [totalLength, setTotalLength] = useState(0)
   const [currentPage, setCurrentPage] = useState(1)
  // const [data, setData] = useState({
  //   Role_Name: '',
  //   Permission: [
  //     { label: 'Course access', checked: false },
  //     { label: 'Course edit', checked: false },
  //     { label: 'Course create', checked: false },
  //     { label: 'Course delete', checked: false },
  //     { label: 'Role access', checked: false },
  //     { label: 'Role edit', checked: false },
  //     { label: 'Role create', checked: false },
  //     { label: 'Role delete', checked: false },
  //     { label: 'User access', checked: false },
  //     { label: 'User edit', checked: false },
  //     { label: 'User create', checked: false },
  //     { label: 'CUser delete', checked: false },
  //     { label: 'Permission access', checked: false },
  //     { label: 'Permission edit', checked: false },
  //     { label: 'Permission create', checked: false },
  //     { label: 'Permission delete', checked: false },
  //     { label: 'Application access', checked: false },
  //     { label: 'fee management', checked: false },
  //   ],
  // })
  const [data, setData] = useState({
    Role_Name: '',
    Permission: [
      // ... your initial permission data ...
    ],
  })

  const getAllPermissions = async () => {
    try {
      const response = await axios.get(
        base_url+`admin/getAllPermissions`,
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      // console.log(" hyuoiuoiuouioiouoiuoi",response)
      const permissionsData = response?.data?.data||[];
      console.log('permissionsData', permissionsData)
       const mappedPermissionsData = permissionsData.map((item) => ({
         label: item.Premission_Name, // Assuming this is the correct property name
         checked: false, // Initialize as unchecked
       }))

      // Update the state with the fetched data
      setData((prevData) => ({
      ...prevData,
      Permission: mappedPermissionsData,
    })); // Assuming permissionsData is an array
      

      
    } catch (err) {
      console.log('error is---', err)
    }
  }

  useEffect(() => {
    getAllPermissions()
  }, [])

  console.log('the data is', data)



  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value)
  }

  const handlePermissionChange = (e) => {
    const permissionValue = e.target.value
    if (e.target.checked) {
      setPermissions([...permissions, permissionValue])
    } else {
      setPermissions(
        permissions.filter((permission) => permission !== permissionValue)
      )
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Perform logic to submit form data, including roleName and permissions
    console.log('Form submitted with role name:', roleName)
    console.log('Selected permissions:', permissions)
    console.log("first",data)
    try {
     const config = {
       headers: {
         'Content-type': 'application/json',
       },
     }
     axios
       .post(base_url+'admin/addRoleName', data , config)
       .then((res) => console.log('the res is', res))
       .catch((err) => console.log('the error is', err))
    } catch (error) {
      console.log("catch error is",error)
    }
    
   
  }
  
  const handleChange=()=>{
    // console.log("the vlaue is",e.target.value)
    // const { name, value } = event.target

  }
  

  // const handleCheckboxChange = (index) => {
  //   setData((prevData) => {
  //     const updatedPermission = [...prevData.Permission]
  //     updatedPermission[index].checked = !updatedPermission[index].checked
  //     return {
  //       ...prevData,
  //       Permission: updatedPermission,
  //     }
  //   })
  // }
  const handleCheckboxChange = (index) => {
    setData((prevData) => ({
      ...prevData,
      Permission: prevData.Permission.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      ),
    }))
  }
const isSidebarOpen = useSelector((state) => state.checkCloseOpen)

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Add Roles</h3>
                  <ul className="breadcrumb mt-3">
                    <li className="breadcrumb-item">
                      <a href="http://clc-london.uk/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Add roles</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* //**admission form** // */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm">
                        <form
                          // method="POST"
                          // action="http://clc-london.uk/admin/roles"

                          className="needs-validation"
                          onSubmit={handleSubmit}
                          noValidate
                        >
                          {/* <input
                          type="hidden"
                          name="_token"
                          defaultValue="ITrEzfjOxgxVC1QvhDNuxqSARNOfiSgJFhIPFYIZ"
                        />{' '}
                        <input
                          type="hidden"
                          name="_method"
                          defaultValue="post"
                        /> */}
                          <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom01">
                                Role Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder
                                name="Role_Name"
                                value={data.Role_Name}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    Role_Name: e.target.value,
                                  })
                                }
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            </div>
                          </div>
                          <div className="card-header">
                            <h5 className="card-title mb-2 font-weight-bold">
                              Permissons
                            </h5>
                          </div>
                          <div className="form-row mt-3 mb-3">
                            {data?.Permission?.map((item, index) => (
                              <div className="col-lg-3 box" key={index}>
                                <input
                                  className="larger"
                                  type="checkbox"
                                  id={`checkbox${index._id}`}
                                  checked={item.checked}
                                  name="permissions[]"
                                  defaultValue={1}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                                <label
                                  className="mt-1 ml-1"
                                  htmlFor={`checkbox${index._id}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            ))}
                          </div>
                      
                          <button className="btn btn-primary" type="submit">
                            Submit form
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <p>Copyright © 2020.</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default AddRolesForm
