import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { base_url } from '../../../utils/Api'

function EditUserForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
    role: '',
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleRoleChange = (roleId) => {
    // if (formData.roles.includes(roleId)) {
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     roles: prevData.roles.filter((role) => role !== roleId),
    //   }))
    // } else {
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     roles: [...prevData.roles, roleId],
    //   }))
    // }
  }
  const { _id } = useParams()
  useEffect(() => {
    getUserbyId()
  }, [_id])
  const getUserbyId = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url+`admin/getUserbyId/${_id}`, config)
      .then((res) => {
        console.log('the res is', res)
        

        setFormData({
          name: res?.data?.data?.Name, // Update with the correct field names
          email: res?.data?.data?.Email,
          phone: res?.data?.data?.Phone,
          Password: res?.data?.data?.Password,
          // password_confirmation: res?.data?.data?.password_confirmation,
          role: res?.data?.data?.Roles,
        })
        //setData(res?.data?.applications)
      })
      .catch((err) => console.log('error is---', err))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      // Simulate API call to update user data
      const response = await axios.put(
        'http://clc-london.uk/admin/users/1',
        formData
      )
      console.log('Response:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* ...page header and breadcrumb... */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm">
                    <form
                      onSubmit={handleSubmit}
                      className="needs-validation"
                      noValidate
                    >
                      <input
                        type="hidden"
                        name="_token"
                        value="ZqQxRjAi1AjzGEZabcfVtc7EQBjSneYAksl5iLgH"
                      />
                      <input type="hidden" name="_method" value="put" />

                      <div className="form-row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom01">Username:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder=""
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="valid-feedback">Looks good!</div>
                          <div className="invalid-feedback">
                            This field is required.
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom02">Email:</label>
                          <input
                            type="email"
                            className="form-control"
                            id="validationCustom02"
                            placeholder=""
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="valid-feedback">Looks good!</div>
                          <div className="invalid-feedback">
                            This field is required.
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom03">Phone:</label>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom03"
                            placeholder=""
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="valid-feedback">Looks good!</div>
                          <div className="invalid-feedback">
                            This field is required.
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="validationCustom04">Password:</label>
                          <input
                            type="password"
                            className="form-control"
                            id="validationCustom04"
                            placeholder=""
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                          />
                          <div className="valid-feedback">Looks good.</div>
                          <div className="invalid-feedback">
                            This field is required.
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="validationCustom05">
                            Confirm Password:
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="validationCustom05"
                            placeholder=""
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleInputChange}
                          />
                          <div className="valid-feedback">Looks good.</div>
                          <div className="invalid-feedback">
                            This field is required.
                          </div>
                        </div>
                      </div>

                      <div className="card-header">
                        <h5 className="card-title mb-2 font-weight-bold">
                          Roles
                        </h5>
                      </div>

                      <div className="form-row mt-3 mb-3">
                        <div className="col-lg-3 box">
                          <input
                            className="larger"
                            type="checkbox"
                            id="adminCheckbox"
                            name="role"
                            value="admin"
                            checked={formData.role === 'admin'}
                            onChange={handleInputChange}
                          />
                          <label className="mt-1 ml-1" htmlFor="adminCheckbox">
                            admin
                          </label>
                        </div>

                        <div className="col-lg-3 box">
                          <input
                            className="larger"
                            type="checkbox"
                            id="writerCheckbox"
                            name="role"
                            value="2"
                            checked={formData.role === 'writer'}
                            onChange={handleInputChange}
                          />
                          <label className="mt-1 ml-1" htmlFor="writerCheckbox">
                            writer
                          </label>
                        </div>

                        {/* Add more role checkboxes as needed */}
                      </div>

                      <button className="btn btn-primary" type="submit">
                        Submit form
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <p>Copyright © {new Date().getFullYear()}.</p>
      </footer>
    </div>
  )
}

export default EditUserForm
