import React, { useEffect, useRef, useState } from 'react'
import './AppForm.css'
import { useFormik } from 'formik'
import colllage from '../../../assets/images/collage1.jpg'
import * as Yup from 'yup'
// import { base_url } from '../../student/ApplicationForm/A'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img3 from '../../../assets/images/uploadimg.png'
import generic_file_icon from '../../../assets/images/generic_file.png'
import Pdf_file_icon from '../../../assets/images/PDF_file_icon.png'
import word_icon from '../../../assets/images/word_icon.png'

import {
  commencement,
  course_duration,
  data,
  equal_oppertunity,
  foundation_course,
  further_info,
  marketing_info,
  mode_of_study,
  pay_fees,
  reference,
  tabel_achieved_data,
  tabel_data,
  tabel_pending_data,
  tabel_work_experience,
  undergraduate_course,
} from '../../../config/AppFormData'
import { Link, useNavigate } from 'react-router-dom'
import { countries } from 'countries-list'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { base_url } from '../../../utils/Api'
import { toast } from 'react-toastify'
// For User Title
const user_title = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Ms', label: 'Ms' },
]
const Course_Level = [
  { value: 'Level 4', label: 'Level 4' },
  { value: 'Level 5', label: 'Level 5' },
]
const Course_title = [
  {
    value: 'BTEC L5 in Education & Training',
    label: 'BTEC L5 in Education & Training',
  },
  { value: 'HNC L4 in Business', label: 'HNC L4 in Business' },
  { value: 'HNC L4 in Computing', label: 'HNC L4 in Computing' },
  { value: 'HNC L5 in Hospitality', label: 'HNC L5 in Hospitality' },
  { value: 'HND L5 in Business', label: 'HND L5 in Business' },
  { value: 'HND L5 in Computing', label: 'HND L5 in Computing' },
  {
    value: 'VTCT L4 in Education & Training',
    label: 'VTCT L4 in Education & Training',
  },
  // { value: 'VTCT L4 in Education & Training', label: 'VTCT L4 in Education & Training' },
]
const country = [
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'USA', label: 'USA' },
]
const job_mode = [
  { value: 'Full-Time', label: 'Full-Time' },
  { value: 'Part-Time', label: 'Part-Time' },
]

const gender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
]
const Interviewed = [
  { value: 'Yes', label: 'YES' },
  { value: 'No', label: 'NO' },
]
const Language_proficiency_test = [
  {
    text: 'Have you taken any English Language Proficiency test?',
    value: 'Yes',
    label: 'Yes.',
  },
  { text: 'If YES please attach evidence', value: 'No', label: 'No.' },
]
const Language_attend_course = [
  {
    text: 'Have you attended any courses in English Language?',
    value: 'Yes',
    label: 'Yes.',
  },
  { text: 'If YES please attach evidence', value: 'No', label: 'No.' },
]
const Is_English = [{ value: 'is_english' }]

function AppForm() {
  const navigate = useNavigate()
  const [myfile, setmyfile] = useState()
  const [filePreviews, setFilePreviews] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [courses, setCourses] = useState({
    foundationCourse: [],
    underGraduateCourse: [],
  })
  const [selectedGenders, setSelectedGenders] = useState([])
  const [ModeOfStudy, setModeOfStudy] = useState([])
  const [Commencement, setCommencement] = useState([])
  const [CourseDuration, setCourseDuration] = useState([])
  const [PayFees, setPayFees] = useState([])
  const [age, setAge] = useState('')
  const [selectedyes, setSelectyes] = useState([])
  const [selectedattend, setSelectedattend] = useState([])
  const [already, setAlready] = useState(true)
  const userLogin = useSelector((state) => state.userLogin)
  const [ReligionHandling, setReligionHandling] = useState([
    { key: 'Christian', checked: false },
    { key: 'Muslim', checked: false },
    { key: 'No religion', checked: false },
    { key: 'Buddhist', checked: false },
    { key: 'Sikh', checked: false },
    { key: 'Any other religion or belief', checked: false },
    { key: 'Hindu', checked: false },
    { key: 'Bahá’í Faith', checked: false },
    { key: 'Prefer not to say', checked: false },
    { key: 'Jewish', checked: false },
    { key: 'Pagan', checked: false },
    { key: 'Other', checked: false },
  ])
  const handleReligionHandling = (index) => {
    const newData = ReligionHandling.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setReligionHandling(newData)
  }
  const [EthnicOrigin, setEthnicOrigin] = useState([
    { key: 'White', checked: false },
    { key: 'Asian or Asian British - Pakistani', checked: false },
    { key: 'Mixed – white and Asian', checked: false },
    { key: 'Gypsy or traveller', checked: false },
    { key: 'Asian or Asian British - Bangladeshi', checked: false },
    { key: 'Other mixed background', checked: false },
    { key: 'Black or black British - Caribbean', checked: false },
    { key: 'Chinese', checked: false },
    { key: 'Arab', checked: false },
    { key: 'Black or black British - African', checked: false },
    { key: 'Other Asian background', checked: false },
    { key: 'Other ethnic background', checked: false },
    { key: 'Other black background', checked: false },
    { key: 'Mixed – white and black Caribbean', checked: false },
    { key: 'Prefer not to say', checked: false },
    { key: 'Asian or Asian British - Indian', checked: false },
    { key: 'Mixed – white and black African', checked: false },
  ])
  const handleEthnicOrigin = (index) => {
    const newData = EthnicOrigin.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })

    setEthnicOrigin(newData)
  }
  // console.log('the userinfo is', userLogin?.userInfo?.UserData?.email)
  let email = userLogin?.userInfo?.UserData?.email
  // const CheckEmailAlreadyRegister = async () => {
  //   console.log('email', userLogin?.userInfo?.UserData?.email)
  //   const config = {
  //     headers: {
  //       'Content-type': 'application/json',
  //     },
  //   }
  //   const result = await axios.post(
  //     base_url + 'form/checkemail',
  //     {
  //       email: email,
  //     },
  //     config
  //   )
  //   // console.log("the app form res isss",result?.data?.result)
  //   if (result?.data?.result === 'User Already Register') {
  //     setAlready(true)
  //     toast.success('User already register')
  //   } else {
  //     setAlready(false)
  //   }
  // }
  // useEffect(() => {
  //   CheckEmailAlreadyRegister()
  // }, [])

  const getFoundationCourse = async () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    await axios
      .get(base_url + 'admin/courses', config)
      .then((res) => {
        console.log('the resp is------------', res)
        let underCourse = []
        let foundCourse = []
        res?.data?.data?.map((item) => {
          console.log('item is', item)
          if (item.course_Level === 'Undergraduate Course') {
            underCourse.push({ item: item.course_Name, checked: false })
          } else {
            foundCourse.push({ item: item.course_Name, checked: false })
          }
        })
        setCourses({
          underGraduateCourse: underCourse,
          foundationCourse: foundCourse,
        })
      })
      .catch((err) => console.log('error is---', err))
  }

  console.log('the courses is', courses)

  // for course name
  const [course_name, setCourseName] = useState('') // Initialize course_name as an empty string

  const handleCourseNameChange = (courseType, index) => {
    // Create a copy of the course state
    const updatedCourse = { ...courses }
    updatedCourse[courseType].forEach((courseItem, i) => {
      if (i === index) {
        courseItem.checked = !courseItem.checked
      } else {
        courseItem.checked = false // Uncheck other checkboxes in the same group
      }
    })
    const otherGroup =
      courseType === 'foundationCourse'
        ? 'underGraduateCourse'
        : 'foundationCourse'
    updatedCourse[otherGroup].forEach((courseItem) => {
      courseItem.checked = false
    })

    const selectedCourse = updatedCourse[courseType].find(
      (courseItem) => courseItem.checked
    )

    setCourses(updatedCourse)
    setCourseName(selectedCourse ? selectedCourse.item : '')
  }

  useEffect(() => {
    getFoundationCourse()
    // getUndergraduateCourse()
    // getModeOfStudy()
    // getCommencement()
  }, [])
  //for file
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    setmyfile(files)
    console.log('files', files)

    setSelectedFiles([...selectedFiles, ...event.target.files])
    // const previews = files.map((file) => URL.createObjectURL(file))
    // setFilePreviews([...filePreviews, ...previews])
    const previews = files.map((file) => {
      return {
        file: file,
        preview: file.type.includes('image') ? URL.createObjectURL(file) : null,
      }
    })
    setFilePreviews([...filePreviews, ...previews])
  }
  // for Modal
  const [modal, setModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [profileURL, setProfileURL] = useState('')

  // const toggle = () => setModal(!modal);
  const [userName, setUserName] = useState('')
  // for Modal
  const [selectedImage, setSelectedImage] = useState(null)
  // const [formData, setFormData] = useState({})

  const [sources, setSources] = useState([
    {
      label: 'Newspaper/Magazine',
      value: 'Newspaper/Magazine',
      checked: false,
    },
    {
      label: 'Name of Newspaper/Magazine',
      value: 'Name of Newspaper/Magazine',
      checked: false,
    },
    { label: 'Relative/Friend', value: 'Relative/Friend', checked: false },
    { label: 'Internet', value: 'Internet', checked: false },
    { label: 'Social Media', value: 'Social Media', checked: false },
    { label: 'Other sources', value: 'Other sources', checked: false },
  ])

  const handleCheckboxChange = (event, index) => {
    // const updatedSources = [...sources]
    // updatedSources[index].checked = event.target.checked
    // setSources(updatedSources)
    const newData = sources.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setSources(newData)
  }
  const formData = new FormData()
  const [profileImage, setProfileImage] = useState('')
  const handleImageUpload = async (event) => {
    setProfileImage(event.target.files[0])
    const file = event.target.files[0]
    const profileUrl = URL.createObjectURL(event.target.files[0])
    setSelectedImage(profileUrl)
    setProfileURL(profileUrl)
  }

  const countryOptions = Object.keys(countries)
    .map((countryCode) => {
      const country = countries[countryCode]
      return {
        value: countryCode,
        label: country.name.charAt(0).toUpperCase() + country.name.slice(1),
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const validation = useFormik({
    // enableReinitialize: true,    it will initilaze only when we update the values of the form

    initialValues: {
      family_name: '',
      first_name: '',
      title: '',
      // optional_family_name: '',
      dob: '',
      gender: selectedGenders,
      home_address: '',
      city: '',
      post_code: '',
      home_tel_no: '',
      mobile: '',
      // email: '',
      country_birth: '',
      nationality: '',
      // entry_date_uk: '',
      course: {
        // foundation_courses: [],
        course_name: course_name,
        // undergraduate_courses: [],
        mode_study: ModeOfStudy,
        commencement: Commencement.length > 0 && Commencement[0],
        course_duration: '',
        fee_pay: '',
      },
      academic_qualification: [
        {
          dateAwarded: '',
          board_institute: '',
          subject: '',
          qualification: '',
          results_achieved: '',
        },
      ],
      academic_qualification_examinations: [
        {
          dateAwarded: '',
          board_institute: '',
          subject: '',
          qualification: '',
        },
      ],
      english_your_first_language: false,
      english_language_proficiency: selectedyes,
      language_attend_course: selectedattend,
      Work_Experience_sub: [
        {
          date_from: '',
          fT_PT: '',
          post_held: '',
          rOrganisation_nam: '',
          brief_description: '',
        },
      ],
      Work_Experience: [
        {
          Date_from: '',
          Date_to: '',
          FT_PT: '',
          Post_held: '',
          ROrganisation_nam: '',
          Brief_description: '',
        },
      ],
      refer_name: '',
      refer_sign: '',
      refer_date: '',
      app_sign: '',
      equal_oppertunity_date: '',
      reference_applicant_capacity: '',
      reference_applicant_opinion: '',
      reference_any_other_info: '',
      further_information: {
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q6: '',
        q7: '',
      },

      // For Registry user validation
    },
    validationSchema: Yup.object({
      family_name: Yup.string('Enter family name').required('Name is required'),
      first_name: Yup.string().required('Enter First Name'),
      title: Yup.string().required('Select title'),
      dob: Yup.string().required('Date of Birth is required'),
      gender: Yup.array()
        .max(1, 'you can check only one option')
        .required('Please select a gender'),
      english_language_proficiency: Yup.array()
        .max(1, 'you can check only one option')
        .required('Please select this field'),
      language_attend_course: Yup.array()
        .max(1, 'you can check only one option')
        .required('Please select this field'),
      home_address: Yup.string().required('Enter home/permanent address'),
      city: Yup.string().required('Enter your city'),
      post_code: Yup.string().required('Enter your post code'),
      home_tel_no: Yup.string().required('Home Telephone Number is required'),
      mobile: Yup.string().required('Mobile Number is required'),
      country_birth: Yup.string().required('Enter country of birth'),
      further_information: Yup.object().shape({
        q1: Yup.string().required('This field is required'),
        q2: Yup.string().required('This field is required'),
        q3: Yup.string().required('This field is required'),
        q4: Yup.string().required('This field is required'),
        q5: Yup.string().required('This field is required'),
        q6: Yup.string().required('This field is required'),
      }),
      nationality: Yup.string().required('Enter your nationality'),
      academic_qualification: Yup.array().of(
        Yup.object().shape({
          dateAwarded: Yup.string(),
          board_institute: Yup.string(),
          subject: Yup.string(),
          qualification: Yup.string(),
          results_achieved: Yup.string(),
        })
      ),
      english_your_first_language: Yup.bool().oneOf(
        [true],
        'Please tick box as applicable'
      ),
      Work_Experience: Yup.array().of(
        Yup.object().shape({
          date_from: Yup.string(),
          fT_PT: Yup.string(),
          post_held: Yup.string(),
          rOrganisation_nam: Yup.string(),
          brief_descriptione: Yup.string(),
        })
      ),
      reference_applicant_capacity: Yup.string().required(
        'This field is required'
      ),
      reference_applicant_opinion: Yup.string().required(
        'This field is required'
      ),
      reference_any_other_info: Yup.string().required('This field is required'),
      refer_name: Yup.string().required('name required'),
      refer_sign: Yup.string().required('sign required'),
      refer_date: Yup.string().required('date required'),
      app_sign: Yup.string().required('required'),
      equal_oppertunity_date: Yup.string().required('required'),
    }),

    onSubmit: async (values, { resetForm }) => {
      console.log('the vlaueri', values)
      let academic_qualification_pending = []
      let academic_qualificaiton_achieved = []
      let work_Experience = []

      // set user name
      setUserName(values.first_name)

      // for Academic Qualification Examination Achieved
      values.academic_qualification.map((item) => {
        if (
          item.board_institute &&
          item.dateAwarded &&
          item.qualification &&
          item.results_achieved &&
          item.subject
        ) {
          academic_qualificaiton_achieved.push({
            board_institute: item.board_institute,
            dateAwarded: item.dateAwarded,
            qualification: item.qualification,
            results_achieved: item.results_achieved,
            subject: item.subject,
          })
        }
      })

      // for Academic Qualification Examination Pending
      values.academic_qualification_examinations.map((item) => {
        if (
          item.board_institute &&
          item.dateAwarded &&
          item.qualification &&
          item.subject
        ) {
          academic_qualification_pending.push({
            board_institute: item.board_institute,
            dateAwarded: item.dateAwarded,
            qualification: item.qualification,
            subject: item.subject,
          })
        }
      })

      // for Work Experience
      values.Work_Experience.map((item) => {
        if (
          item.Brief_description &&
          item.Date_from &&
          item.Date_to &&
          item.FT_PT &&
          item.Post_held &&
          item.ROrganisation_nam
        ) {
          work_Experience.push({
            Brief_description: item.Brief_description,
            Date_from: item.Date_from,
            Date_to: item.Date_to,
            FT_PT: item.FT_PT,
            Post_held: item.Post_held,
            ROrganisation_nam: item.ROrganisation_nam,
          })
        }
      })

      const data = {
        totalamount: 0,
        feestatus: 'pending',
        status: 'Pending',
        Personal_Details: {
          Family_Name_In_Passport: values.family_name,
          First_Name_In_Passport: values.first_name,
          Title: values.title,
          // Pervious_Family_Name: values.optional_family_name,
          Date_Of_Birth: values.dob,
          Gender: selectedGenders.length > 0 ? selectedGenders[0] : null,
          Home_Permanent_Address: values.home_address,
          City: values.city,
          Post_Code: values.post_code,
          Home_Tel_No: values.home_tel_no,
          Modile_No: values.mobile,
          Email: userLogin?.userInfo?.UserData?.email,
          Coutry_Of_Birth: values.country_birth,
          Nationality: values.nationality,
        },
        Course: {
          commencement: Commencement[0],
          course_duration: CourseDuration[0],
          fee_pay: PayFees[0],
          mode_study: ModeOfStudy[0],
          course_name: course_name,
        },
        Academic_Qua_Exam_Achieved: academic_qualificaiton_achieved,
        Academic_Qua_Exam_Pending: academic_qualification_pending,
        English_Language: {
          English_Your_First_Language: values.english_your_first_language,
          English_Language_Attend_Course:
            selectedattend.length > 0 ? selectedattend[0] : null,
          English_Language_Proficiency:
            selectedyes.length > 0 ? selectedyes[0] : null,
        },
        Work_Experience: {
          work_experience: work_Experience,
          currently_employed: values.Work_Experience_sub,
        },
        Further_Information: values.further_information,
        Reference: {
          Note_To_Refree: {
            Applicant_Capacity: values.reference_applicant_capacity,
            Applicant_Opinion: values.reference_applicant_opinion,
          },
          Reference_Tabel: {
            //values.reference_percentage_table
            Ability_to_work_hard: Abilitytoworkhard?.filter(
              (item) => item.checked === true
            )[0]?.value,
            Concern_for_others: Concernforothers?.filter(
              (item) => item.checked === true
            )[0]?.value,
            Creativity: Creativity?.filter((item) => item.checked === true)[0]
              ?.value,
            English_communication: {
              oral: sharedData?.filter((item) => item.oralChecked === true)[0]
                ?.value,
              written: sharedData?.filter(
                (item) => item.writtenChecked === true
              )[0]?.value,
            },
            Intellectual_Academic_ability: dataIntelAcadAbility?.filter(
              (item) => item.checked === true
            )[0]?.value,
            Leadership: Leadership?.filter((item) => item.checked === true)[0]
              ?.value,
            Perseverance: Perseverance?.filter(
              (item) => item.checked === true
            )[0]?.value,
          },
          Any_Other_Info: values.reference_any_other_info,
          Refrees_Name: values.refer_name,
          Refrees_Sign: values.refer_sign,
          Date: values.refer_date,
        },
        Marketing_Information: sources,
        Equal_Oppertunity_Monitoring: {
          Ethnic_Origin:
            EthnicOrigin.find((item) => item.checked)?.key || 'Not selected',
          Religion:
            ReligionHandling.find((item) => item.checked)?.key ||
            'Not selected',
          Declaration: {
            Applicant_Signature: values.app_sign,
            Date: values.equal_oppertunity_date,
          },
        },
      }
      formData.append('data', JSON.stringify(data))
      formData.append('Profile', profileImage)
      for (const file of selectedFiles) {
        formData.append('allfiles', file)
      }
      if (data) {
        try {
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }

          const res = await axios.post(
            base_url + 'form/appForm',
            formData,
            config
          )

          // console.log('res is', res)
          if (res.data.success === 'ok') {
            setModal(!modal)
            resetForm()
          }
        } catch (error) {
          console.log('errorrrr', error)
        }
      }
    },
  })
  const handleAddRow = () => {
    validation.setFieldValue('Work_Experience', [
      ...validation.values.Work_Experience,
      {
        date_from: '',
        fT_PT: '',
        post_held: '',
        rOrganisation_nam: '',
        brief_description: '',
      },
    ])
  }
  const handleDeleteRow = (index) => {
    const updatedRows = [...validation.values.Work_Experience]
    updatedRows.splice(index, 1)
    validation.setFieldValue('Work_Experience', updatedRows)
  }
  const handleAddRowacedemic = () => {
    validation.setFieldValue('academic_qualification', [
      ...validation.values.academic_qualification,
      {
        dateAwarded: '',
        board_institute: '',
        subject: '',
        qualification: '',
        results_achieved: '',
      },
    ])
  }
  const handleAddRowWork_Experience_sub = () => {
    validation.setFieldValue('Work_Experience_sub', [
      ...validation.values.Work_Experience_sub,
      {
        date_from: '',
        fT_PT: '',
        post_held: '',
        rOrganisation_nam: '',
        brief_description: '',
      },
    ])
  }

  const handleDeleteRowWork_Experience_sub = (index) => {
    const updatedRows = [...validation.values.Work_Experience_sub]
    updatedRows.splice(index, 1)
    validation.setFieldValue('Work_Experience_sub', updatedRows)
  }
  const handleDeleteRowacedemic = (index) => {
    const updatedRows = [...validation.values.academic_qualification]
    updatedRows.splice(index, 1)
    validation.setFieldValue('academic_qualification', updatedRows)
  }
  const handleAddRowcademic_qualificationexam = () => {
    validation.setFieldValue('academic_qualification_examinations', [
      ...validation.values.academic_qualification_examinations,
      {
        dateAwarded: '',
        board_institute: '',
        subject: '',
        qualification: '',
      },
    ])
  }
  const handleDeleteRowacademic_qualificationexam = (index) => {
    const updatedRows = [
      ...validation.values.academic_qualification_examinations,
    ]
    updatedRows.splice(index, 1)
    validation.setFieldValue('academic_qualification_examinations', updatedRows)
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  const steps = [
    { label: 'First' },
    { label: 'Second' },
    { label: 'Third' },
    { label: 'Fourth' },
    { label: 'Fifth' },
    { label: 'Sixth' },
    { label: 'Seventh' },
   
  ]
  const [state, setState] = useState({
    value: 0,
  })
  const totalPages = 7
  const [currentPage, setCurrentPage] = useState(1)
  const [activeSteps, setActiveSteps] = useState([0])


  const handlePrevious = () => {
    handlePageChange(-1)
    setActiveSteps((prevActiveSteps) =>
      prevActiveSteps.length > 1
        ? prevActiveSteps.slice(0, prevActiveSteps.length - 1)
        : prevActiveSteps
    )
    setState({ ...state, value: state.value - 1 })
  }
  let error = true
  const [errorMessage, setErrorMessage] = useState('')
  const handleNext = (values) => {
    console.log('the values are', values)

    let qualificationsFilled = false
    if (currentPage === 1) {
      if (parseInt(age) < 20) {
        setErrorMessage('Your age is less than 20')
      } else if (
        values.family_name !== '' &&
        values.first_name !== '' &&
        values.title !== '' &&
        // values.optional_family_name !== '' &&
        values.dob !== '' &&
        selectedGenders.length > 0 &&
        values.home_address !== '' &&
        values.city !== '' &&
        values.post_code !== '' &&
        values.home_tel_no !== '' &&
        values.mobile !== '' &&
        userLogin?.userInfo?.UserData?.email !== '' &&
        values.country_birth !== '' &&
        values.nationality !== '' &&
        selectedImage !== null
        // values.entry_date_uk !== ''
      ) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    if (currentPage === 2) {
      if (
        course_name !== '' &&
        Commencement.length > 0 &&
        PayFees.length > 0 &&
        CourseDuration.length > 0 &&
        ModeOfStudy.length > 0
      ) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    if (currentPage === 3) {
      //     const isAnyObjectFilled = Array.from(values.academic_qualification).some(obj => areAllFieldsFilled(obj));
      //     console.log("the fhsdfhs",isAnyObjectFilled)
      // if (isAnyObjectFilled) {
      //   console.log('At least one object is completely filled.');
      // } else {
      //   console.log('No object is completely filled.');
      // }
      // const filledInputsss = Array.from(values.academic_qualification).some(input =>
      //   (Object.values(input).every(value => value.trim() !== '')));
      //   console.log("the some are",filledInputsss)
      // let qualificationsFilled = false;
      let nofor = false
      const filledInputs = Array.from(values.academic_qualification).filter(
        (input) => {
          if (Object.values(input).every((value) => value.trim() !== '')) {
            return Object.values(input).every((value) => value.trim() !== '')
          } else {
            if (Object.values(input).some((str) => str !== '')) {
              nofor = true
            }
            return Object.values(input).every((value) => value.trim() !== '')
          }
        }
      )
      // const filledInputss = Array.from(
      //   values.academic_qualification_examinations
      // ).filter((input) => {
      //   if (Object.values(input).every((value) => value.trim() !== '')) {
      //     return Object.values(input).every((value) => value.trim() !== '')
      //   } else {
      //     if (Object.values(input).some((str) => str !== '')) {
      //       nofor = true
      //     }
      //     return Object.values(input).every((value) => value.trim() !== '')
      //   }
      // })
      console.log('nofor', nofor)
      if (filledInputs.length > 0 && filledInputs.length > 0 && !nofor) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    if (currentPage === 4) {
      const WorkExp = Array.from(values.Work_Experience).filter((input) =>
        Object.values(input).every((value) => value.trim() !== '')
      )
      // const WorkExpSub = Array.from(values.Work_Experience_sub).filter(
      //   (input) => Object.values(input).every((value) => value.trim() !== '')
      // )
      if (
        selectedyes.length > 0 &&
        values?.english_your_first_language === true &&
        selectedattend.length > 0 &&
        WorkExp.length > 0
        // &&
        // WorkExpSub.length > 0
      ) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    if (currentPage === 5) {
      const allFilled = Object.values(values.further_information).every(
        (value) => value.trim() !== ''
      )
      console.log('the allfilled is', allFilled)
      if (allFilled) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    if (currentPage === 6) {
      if (
        values.reference_applicant_opinion !== '' &&
        values.reference_applicant_capacity !== '' &&
        values.reference_any_other_info !== '' &&
        values.refer_date !== '' &&
        values.refer_sign !== '' &&
        values.refer_name !== '' &&
        Concernforothers.length !== 0 &&
        Creativity.length !== 0 &&
        Leadership.length !== 0 &&
        Perseverance.length !== 0 &&
        Abilitytoworkhard.length !== 0 &&
        dataIntelAcadAbility.length !== 0 &&
        sharedData.filter((item) => item.oralChecked === true).length !== 0 &&
        sharedData.filter((item) => item.writtenChecked === true).length !== 0
        // Concernforothers.length!==0 &&
      ) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    if (currentPage === 7) {
      if (
        values.equal_oppertunity_Religion.length > 0 &&
        values.equal_oppertunity_ethnic_origin.length > 0 &&
        values.equal_oppertunity_date !== '' &&
        values.app_sign !== '' &&
        sources.length > 0
      ) {
        error = false
        setErrorMessage('')
      } else {
        setErrorMessage('kindly fill all the values')
      }
    }
    // let error = false
    if (!error) {
      console.log('the values isssss', values)
      handlePageChange(1)
      setActiveSteps((prevActiveSteps) =>
        prevActiveSteps[prevActiveSteps.length - 1] < steps.length - 1
          ? [
              ...prevActiveSteps,
              prevActiveSteps[prevActiveSteps.length - 1] + 1,
            ]
          : prevActiveSteps
      )
      setState({ ...state, value: state.value + 1 })
    }
  }
  const handlePageChange = (increment) => {
    const nextPage = currentPage + increment
    if (nextPage >= 0 && nextPage <= totalPages) {
      setCurrentPage(nextPage)
    }
  }
  const updateDateFromInputs = (value, fieldName) => {
    const newDate = new Date(
      validation.values.dobYear || new Date().getFullYear(),
      validation.values.dobMonth - 1 || 0,
      validation.values.dobDay || 1
    )

    switch (fieldName) {
      case 'dobDay':
        newDate.setDate(value)
        break
      case 'dobMonth':
        newDate.setMonth(value - 1)
        break
      case 'dobYear':
        newDate.setFullYear(value)
        break
      default:
        break
    }

    validation.setValues({
      ...validation.values,
      dobDay: newDate.getDate(),
      dobMonth: newDate.getMonth() + 1,
      dobYear: newDate.getFullYear(),
    })
  }

  const updateDatePicker = () => {
    const date = new Date(
      validation.values.dobYear || new Date().getFullYear(),
      validation.values.dobMonth - 1 || 0,
      validation.values.dobDay || 1
    )

    
    if (!isNaN(date.getTime())) {
      validation.setValues({
        ...validation.values,
        dob: date.toISOString().split('T')[0],
      })
    } else {
      
      console.error('Invalid date')
   
    }
  }

  const updateDateFromDatePicker = (dateValue) => {
    if (!dateValue) {
      validation.setValues({
        ...validation.values,
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        dob: '',
      })
      // Handle the case where dateValue is not provided
      return
    }

    const date = new Date(dateValue)

    if (!isNaN(date.getTime())) {
      validation.setValues({
        ...validation.values,
        dobDay: date.getDate(),
        dobMonth: date.getMonth() + 1,
        dobYear: date.getFullYear(),
        dob: date.toISOString().split('T')[0],
      })
    } else {
      // Handle the case where the date is not valid
      console.error('Invalid date string provided:', dateValue)
      // You can set a default date or show an error message here
    }
  }

  // The handleGender function
  const handleGender = (value) => {
    if (selectedGenders.length === 1 && selectedGenders[0] === value) {
      return
    }

    // Update the state to contain only the selected value
    setSelectedGenders([value])
  }
  const handleyes = (value) => {
    if (selectedyes.length === 1 && selectedyes[0] === value) {
      return
    }

    // Update the state to contain only the selected value
    setSelectyes([value])
  }
  const handleattend = (value) => {
    if (selectedattend.length === 1 && selectedattend[0] === value) {
      return
    }

    // Update the state to contain only the selected value
    setSelectedattend([value])
  }
  const handleModeOfStudy = (value) => {
    if (ModeOfStudy.length === 1 && ModeOfStudy[0] === value) {
      return
    }
    //  const updatedMode = [...ModeOfStudy]
    // const index = updatedMode.indexOf(value)
    // if (index !== -1) {
    //   updatedMode.splice(index, 1)
    // } else {
    //   updatedMode.push(value)
    // }
    // setModeOfStudy(updatedMode)

    // Update the state to contain only the selected value
    setModeOfStudy([value])
  }
  const handleCommencement = (value) => {
    if (Commencement.length === 1 && Commencement[0] === value) {
      return
    }
    // const updatedCommencement = [...Commencement]
    // const index = updatedCommencement.indexOf(value)
    // if (index !== -1) {
    //   updatedCommencement.splice(index, 1)
    // } else {
    //   updatedCommencement.push(value)
    // }
    // setCommencement(updatedCommencement)
    setCommencement([value])
  }
  const handleCourseDuration = (value) => {
    if (CourseDuration.length === 1 && CourseDuration[0] === value) {
      return
    }
    // const updatedCourseDuration = [...CourseDuration]
    // const index = updatedCourseDuration.indexOf(value)
    // if (index !== -1) {
    //   updatedCourseDuration.splice(index, 1)
    // } else {
    //   updatedCourseDuration.push(value)
    // }
    // setCourseDuration(updatedCourseDuration)
    setCourseDuration([value])
  }
  const handlePayFees = (value) => {
    if (PayFees.length === 1 && PayFees[0] === value) {
      return
    }
    // const updatedPayFee = [...PayFees]
    // const index = updatedPayFee.indexOf(value)
    // if (index !== -1) {
    //   updatedPayFee.splice(index, 1)
    // } else {
    //   updatedPayFee.push(value)
    // }
    // setPayFees(updatedPayFee)
    setPayFees([value])
  }

  const [dataIntelAcadAbility, setDataIntelAcadAbility] = useState([
    { value: 'Outstanding', checked: false },
    { value: 'Excellent 90-94%', checked: false },
    { value: 'Very Good 75-89%', checked: false },
    { value: 'Good 60-74%', checked: false },
    { value: 'Average', checked: false },
    { value: 'Below Average', checked: false },
    { value: 'Not know', checked: false },
  ])
  const [Abilitytoworkhard, setAbilitytoworkhard] = useState([
    { value: 'Outstanding', checked: false },
    { value: 'Excellent 90-94%', checked: false },
    { value: 'Very Good 75-89%', checked: false },
    { value: 'Good 60-74%', checked: false },
    { value: 'Average', checked: false },
    { value: 'Below Average', checked: false },
    { value: 'Not know', checked: false },
  ])
  const [Perseverance, setPerseverance] = useState([
    { value: 'Outstanding', checked: false },
    { value: 'Excellent 90-94%', checked: false },
    { value: 'Very Good 75-89%', checked: false },
    { value: 'Good 60-74%', checked: false },
    { value: 'Average', checked: false },
    { value: 'Below Average', checked: false },
    { value: 'Not know', checked: false },
  ])
  const [Leadership, setLeadership] = useState([
    { value: 'Outstanding', checked: false },
    { value: 'Excellent 90-94%', checked: false },
    { value: 'Very Good 75-89%', checked: false },
    { value: 'Good 60-74%', checked: false },
    { value: 'Average', checked: false },
    { value: 'Below Average', checked: false },
    { value: 'Not know', checked: false },
  ])
  const [Creativity, setCreativity] = useState([
    { value: 'Outstanding', checked: false },
    { value: 'Excellent 90-94%', checked: false },
    { value: 'Very Good 75-89%', checked: false },
    { value: 'Good 60-74%', checked: false },
    { value: 'Average', checked: false },
    { value: 'Below Average', checked: false },
    { value: 'Not know', checked: false },
  ])
  const [Concernforothers, setConcernforothers] = useState([
    { value: 'Outstanding', checked: false },
    { value: 'Excellent 90-94%', checked: false },
    { value: 'Very Good 75-89%', checked: false },
    { value: 'Good 60-74%', checked: false },
    { value: 'Average', checked: false },
    { value: 'Below Average', checked: false },
    { value: 'Not know', checked: false },
  ])
  const handleCheckboxesChange = (index) => {
    const newData = dataIntelAcadAbility.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setDataIntelAcadAbility(newData)
  }
  const handleAbilitytoworkhard = (index) => {
    const newData = Abilitytoworkhard.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setAbilitytoworkhard(newData)
  }
  const handlePerseverance = (index) => {
    const newData = Perseverance.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setPerseverance(newData)
  }
  const handleLeadership = (index) => {
    const newData = Leadership.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setLeadership(newData)
  }
  const handleCreativity = (index) => {
    const newData = Creativity.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setCreativity(newData)
  }
  const handleConcernforothers = (index) => {
    const newData = Concernforothers.map((item, i) => {
      if (i === index) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    setConcernforothers(newData)
  }
  const [sharedData, setSharedData] = useState([
    { value: 'Outstanding', oralChecked: false, writtenChecked: false },
    { value: 'Excellent 90-94%', oralChecked: false, writtenChecked: false },
    { value: 'Very Good 75-89%', oralChecked: false, writtenChecked: false },
    { value: 'Good 60-74%', oralChecked: false, writtenChecked: false },
    { value: 'Average', oralChecked: false, writtenChecked: false },
    { value: 'Below Average', oralChecked: false, writtenChecked: false },
    { value: 'Not know', oralChecked: false, writtenChecked: false },
  ])
  const handleOralChange = (index) => {
    const newData = sharedData.map((item, i) => {
      if (i === index) {
        return { ...item, oralChecked: true }
      } else {
        return { ...item, oralChecked: false }
      }
    })
    setSharedData(newData)
  }

  const handleWrittenChange = (index) => {
    const newData = sharedData.map((item, i) => {
      if (i === index) {
        return { ...item, writtenChecked: true }
      } else {
        return { ...item, writtenChecked: false }
      }
    })
    setSharedData(newData)
  }
  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="main_wrapper">
        <div className="page-wrapper" id="elementId">
          <div className="content container-fluid">
            <div className="stepper-wrapper">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`stepper-item ${
                    activeSteps.includes(index)
                      ? 'active'
                      : index < activeSteps[0]
                      ? 'completed'
                      : ''
                  }`}
                >
                  <div
                    className={`step ${
                      activeSteps.includes(index) ? 'active-circle' : ''
                    }`}
                  >
                    {index + 1}
                  </div>
                  <div className="step-name">{step.label}</div>
                </div>
              ))}
            </div>

            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">
                    APPLICATION FOR ADMISSION
                    <small className="fs-5" style={{ fontSize: '15px' }}>
                      (Higher Education Courses)
                    </small>
                  </h3>
                  <small className="fs-5" style={{ fontSize: '15px' }}>
                    (Please read the collage prospectus and consult the website
                    before completing this form)
                    <br />
                    Please complete this form in BLOCK capitals using black ink
                  </small>
                </div>
              </div>
            </div>
            <div
              className="main"
              // style={{ display: already ? 'none' : 'block' }}
            >
              <div className="border border-3 border-dark p-4">
                <div className="mb-3">
                  <div>
                    <form
                      enableReinitialize={false}
                      encType="multipart/form-data"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {currentPage === 1 && (
                        <div>
                          <div className="d-flex flex-sm-column flex-column flex-md-row flex-lg-row justify-content-sm-center justify-content-lg-between justify-content-md-between align-items-center flex-column-reverse">
                            <div>
                              <div
                                className=""
                                style={{
                                  width: '300px',
                                  height: '300px',
                                  border: '1px solid black',
                                  display: 'flex',
                                  alignItems: 'center',
                                  margin: '10px',
                                  justifyContent: 'center',
                                  overflow: 'hidden',
                                  position: 'relative',
                                }}
                              >
                                {selectedImage ? (
                                  <img
                                    src={selectedImage}
                                    alt="Selected"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="text-center"
                                    style={{
                                      position: 'absolute',
                                      fontSize: '20px',
                                    }}
                                  >
                                    Attach <b>ONE</b> passport size photograph
                                    here
                                  </div>
                                )}
                                <input
                                  type="file"
                                  name="Profile"
                                  accept="image/*"
                                  onChange={handleImageUpload}
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                  }}
                                />
                              </div>
                            </div>

                            <div className="text-center d-flex justify-content-center align-items-center card border-0">
                              <img
                                src={colllage}
                                width="300px"
                                height="200px"
                                alt="college Logo"
                              />
                              <div className="card-body">
                                <p
                                  className="card-text"
                                  style={{ maxWidth: '28rem' }}
                                >
                                  3 Boyd Street, Aldgate East, London, E1 1FQ,
                                  United Kingdom Tel: +44 (0)20 7247 2177 Email:
                                  info@clc-london.ac.uk
                                </p>
                                <h5 className="card-title">
                                  <Link to="#">www.clc-london.ac.uk</Link>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <h3>1. Personal details</h3>
                          <div className="row">
                            <div className="row col-12  col-lg-6 col-md-6">
                              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                                <label className="label_text">
                                  Family name as appears in passport:
                                </label>
                              </div>
                              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="family_name"
                                  id="family_name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.family_name || ''}
                                  invalid={
                                    validation.touched.family_name &&
                                    validation.errors.family_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.family_name &&
                                validation.errors.family_name ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    type="invalid"
                                  >
                                    {validation.errors.family_name}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="row col-12  col-lg-6 col-md-6">
                              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                                <label className="label_text">
                                  First name(s) as appears in passport:
                                </label>
                              </div>
                              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  id="first_name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name || ''}
                                  invalid={
                                    validation.touched.first_name &&
                                    validation.errors.first_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.first_name &&
                                validation.errors.first_name ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    type="invalid"
                                  >
                                    {validation.errors.first_name}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-1">
                            <div className="col-6">
                              <label className="col-form-label label_text pe-0">
                                Title(Mr Ms Miss Mrs):
                              </label>
                            </div>
                            <div className="col-6">
                              <label className="col-form-label label_text  pe-0">
                                Date of birth:
                              </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <select
                                name="title"
                                id="title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ''}
                                invalid={
                                  validation.touched.family_name &&
                                  validation.errors.family_name
                                    ? true
                                    : false
                                }
                                className="form-select"
                              >
                                <option defaultValue>Select your title</option>
                                {user_title.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.title &&
                              validation.errors.title ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.title}
                                </span>
                              ) : null}
                            </div>{' '}
                            <div className="col-md-6">
                              {/* <div className="col-6">
                              <input
                                type="text"
                                name="optional_family_name"
                                id="optional_family_name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.optional_family_name || ''
                                }
                                className="form-control"
                              />
                            </div> */}
                              <div className="row g-3 align-items-center">
                                <div className="col-md-3">
                                  <input
                                    type="number"
                                    name="dobDay"
                                    id="dobDay"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromInputs(
                                        e.target.value,
                                        'dobDay'
                                      )
                                      updateDatePicker()
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dobDay || ''}
                                    placeholder="Day"
                                    className="form-control"
                                  />
                                  {validation.touched.dobDay &&
                                  validation.errors.dobDay ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dobDay}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="number"
                                    name="dobMonth"
                                    id="dobMonth"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromInputs(
                                        e.target.value,
                                        'dobMonth'
                                      )
                                      updateDatePicker()
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dobMonth || ''}
                                    placeholder="Month"
                                    className="form-control"
                                  />
                                  {validation.touched.dobMonth &&
                                  validation.errors.dobMonth ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dobMonth}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="number"
                                    name="dobYear"
                                    id="dobYear"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromInputs(
                                        e.target.value,
                                        'dobYear'
                                      )
                                      updateDatePicker()
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dobYear}
                                    placeholder="Year"
                                    className="form-control"
                                  />
                                  {validation.touched.dobYear &&
                                  validation.errors.dobYear ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dobYear}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    type="date"
                                    name="dob"
                                    id="dob"
                                    onChange={(e) => {
                                      console.log('e', e)
                                      validation.handleChange(e)
                                      const birthDate = new Date(e.target.value)
                                      const currentDate = new Date()

                                      // Calculate the difference in years
                                      const ageDifference =
                                        currentDate.getFullYear() -
                                        birthDate.getFullYear()

                                      // Check if the birthday has occurred this year
                                      if (
                                        currentDate.getMonth() <
                                          birthDate.getMonth() ||
                                        (currentDate.getMonth() ===
                                          birthDate.getMonth() &&
                                          currentDate.getDate() <
                                            birthDate.getDate())
                                      ) {
                                        console.log(
                                          'ageDifference',
                                          ageDifference - 1
                                        )
                                        setAge(ageDifference - 1)
                                      } else {
                                        console.log(
                                          'ageDifference',
                                          ageDifference
                                        )
                                        setAge(ageDifference)
                                      }

                                      updateDateFromDatePicker(e.target.value)
                                    }}
                                    style={{ color: 'transparent', width: 46 }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dob || ''}
                                    className="form-control"
                                  />
                                  {validation.touched.dob &&
                                  validation.errors.dob ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dob}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            {/* <div className="col-md-6">
                              <div className="row g-3 align-items-center">
                                <div className="col-md-4">
                                  <label className="label_text">
                                    Date of birth:
                                  </label>
                                </div>
                                <div className="col-md-2">
                                  <input
                                    type="number"
                                    name="dobDay"
                                    id="dobDay"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromInputs(
                                        e.target.value,
                                        'dobDay'
                                      )
                                      updateDatePicker()
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dobDay || ''}
                                    placeholder="Day"
                                    className="form-control"
                                  />
                                  {validation.touched.dobDay &&
                                  validation.errors.dobDay ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dobDay}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    type="number"
                                    name="dobMonth"
                                    id="dobMonth"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromInputs(
                                        e.target.value,
                                        'dobMonth'
                                      )
                                      updateDatePicker()
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dobMonth || ''}
                                    placeholder="Month"
                                    className="form-control"
                                  />
                                  {validation.touched.dobMonth &&
                                  validation.errors.dobMonth ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dobMonth}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    type="number"
                                    name="dobYear"
                                    id="dobYear"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromInputs(
                                        e.target.value,
                                        'dobYear'
                                      )
                                      updateDatePicker()
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dobYear || ''}
                                    placeholder="Year"
                                    className="form-control"
                                  />
                                  {validation.touched.dobYear &&
                                  validation.errors.dobYear ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dobYear}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    type="date"
                                    name="dob"
                                    id="dob"
                                    onChange={(e) => {
                                      validation.handleChange(e)
                                      updateDateFromDatePicker(e.target.value)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dob || ''}
                                    className="form-control"
                                  />
                                  {validation.touched.dob &&
                                  validation.errors.dob ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {validation.errors.dob}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div> */}

                            <div className="col-md-4">
                              <div className="row g-3 align-items-center">
                                <div className="col-md-3">
                                  <label className="label_text">Gender:</label>
                                </div>
                                <div className="col-md-5 d-flex">
                                  {gender.map((checks, index) => (
                                    <div className="ms-3 d-flex align-items-center">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          name={`gender[${index}]`}
                                          type="checkbox"
                                          value={checks.value}
                                          checked={selectedGenders.includes(
                                            checks.value
                                          )}
                                          onChange={() =>
                                            handleGender(checks.value)
                                          }
                                          invalid={selectedGenders.length > 1}
                                        />
                                      </div>
                                      <label className="form-check-label ms-2 label_text">
                                        {checks.label}
                                      </label>
                                    </div>
                                  ))}
                                  {selectedGenders.length > 1 ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                      className="ms-2 mt-1"
                                    >
                                      Select only one
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-1">
                            <div className="col-md-12">
                              <label className="col-form-label label_text">
                                Home/permanent address:
                              </label>
                              <textarea
                                type="text"
                                name="home_address"
                                id="home_address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.home_address || ''}
                                invalid={
                                  validation.touched.home_address &&
                                  validation.errors.home_address
                                    ? true
                                    : false
                                }
                                className="form-control"
                              />
                              {validation.touched.home_address &&
                              validation.errors.home_address ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.home_address}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="col-form-label label_text">
                                City:
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label label_text">
                                Postcode:
                              </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                name="city"
                                id="city"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ''}
                                invalid={
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? true
                                    : false
                                }
                                className="form-control"
                              />
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.city}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                name="post_code"
                                id="post_code"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.post_code || ''}
                                invalid={
                                  validation.touched.post_code &&
                                  validation.errors.post_code
                                    ? true
                                    : false
                                }
                                className="form-control"
                              />
                              {validation.touched.post_code &&
                              validation.errors.post_code ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.post_code}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label className="col-form-label label_text">
                                Email:
                              </label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                // value={userLogin?.userInfo?.UserData?.email}
                                value={userLogin?.userInfo?.UserData?.email}
                                // invalid={
                                //   validation.touched.email &&
                                //   validation.errors.email
                                //     ? true
                                //     : false
                                // }
                                disabled
                                className="form-control"
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.email}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="col-form-label label_text">
                                Home Tel no.:
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label label_text">
                                Mobile:
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="number"
                                name="home_tel_no"
                                id="home_tel_no"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.home_tel_no || ''}
                                invalid={
                                  validation.touched.home_tel_no &&
                                  validation.errors.home_tel_no
                                    ? true
                                    : false
                                }
                                className="form-control"
                              />
                              {validation.touched.home_tel_no &&
                              validation.errors.home_tel_no ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.home_tel_no}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <input
                                type="number"
                                name="mobile"
                                id="mobile"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile || ''}
                                invalid={
                                  validation.touched.mobile &&
                                  validation.errors.mobile
                                    ? true
                                    : false
                                }
                                className="form-control"
                              />
                              {validation.touched.mobile &&
                              validation.errors.mobile ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.mobile}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label className="col-form-label label_text">
                                Country of birth
                              </label>
                              <select
                                name="country_birth"
                                id="country_birth"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country_birth || ''}
                                invalid={
                                  validation.touched.country_birth &&
                                  validation.errors.country_birth
                                    ? true
                                    : false
                                }
                                className="form-select"
                              >
                                <option defaultValue>
                                  Select Country of Birth
                                </option>
                                {countryOptions.map((option) => (
                                  <option
                                    style={{ textTransform: 'capitalize' }}
                                    key={option.value}
                                    value={option.label}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.country_birth &&
                              validation.errors.country_birth ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.country_birth}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label label_text">
                                Nationality:
                              </label>
                              <select
                                name="nationality"
                                id="title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nationality || ''}
                                invalid={
                                  validation.touched.nationality &&
                                  validation.errors.nationality
                                    ? true
                                    : false
                                }
                                className="form-select"
                              >
                                <option defaultValue>
                                  Select your Nationlaity
                                </option>
                                {countryOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.label}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.nationality &&
                              validation.errors.nationality ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.nationality}
                                </span>
                              ) : null}
                            </div>
                            {/* <div className="col-md-4">
                              <label className="col-form-label label_text">
                                Entry date to UK:
                              </label>
                              <input
                                type="date"
                                name="entry_date_uk"
                                id="entry_date_uk"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.entry_date_uk || ''}
                                invalid={
                                  validation.touched.entry_date_uk &&
                                  validation.errors.entry_date_uk
                                    ? true
                                    : false
                                }
                                className="form-control"
                              />
                              {validation.touched.entry_date_uk &&
                              validation.errors.entry_date_uk ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  type="invalid"
                                >
                                  {validation.errors.entry_date_uk}
                                </span>
                              ) : null}
                            </div> */}
                          </div>
                        </div>
                      )}
                      {currentPage === 2 && (
                        <>
                          <h3 className="mb-3">
                            2. Course:{' '}
                            <span style={{ fontSize: '16px' }}>
                              <i>
                                (Please indicate the course you wish to apply)
                              </i>
                            </span>
                          </h3>
                          {/* Foundation Course */}

                          <div className="mb-3">
                            <h5>{foundation_course.heading}</h5>
                            {/* {validation.touched?.course?.foundation_courses && */}
                            {validation.touched?.course?.course_name &&
                            // validation.errors?.course?.foundation_courses ? (
                            validation.errors?.course?.course_name ? (
                              <span
                                style={{ color: 'red', fontSize: '12px' }}
                                type="invalid"
                              >
                                {/* {validation.errors?.course?.foundation_courses} */}
                                {validation.errors?.course?.course_name}
                              </span>
                            ) : null}
                            <div className="row">
                              {courses?.foundationCourse?.map(
                                (checks, index) => (
                                  <div className="col-12 col-sm-3 mb-2 d-flex align-items-center">
                                    {/* {console.log('the check is', checks)}
                                     */}
                                    <div>
                                      <input
                                        type="checkbox"
                                        // name="course.foundation_courses"
                                        checked={checks.checked}
                                        name="course.course_name"
                                        value={checks.course_Name}
                                        // onChange={validation.handleChange}
                                        onChange={(e) =>
                                          handleCourseNameChange(
                                            'foundationCourse',
                                            index
                                          )
                                        }
                                        invalid={
                                          // ?.foundation_courses &&
                                          validation.touched?.course
                                            ?.course_name &&
                                          // ?.foundation_courses
                                          validation.errors?.course?.course_name
                                        }
                                        className="form-check-input"
                                      />
                                    </div>
                                    <label className="ms-2 form-check-label label_text">
                                      {checks.item}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          {/* Undergraduate Courses */}

                          <div className="mb-3">
                            <h5>{undergraduate_course.heading}</h5>
                            {/* {validation.touched?.course?.undergraduate_courses && */}
                            {validation.touched?.course?.course_name &&
                            // validation.errors?.course?.undergraduate_courses ? (
                            validation.errors?.course?.course_name ? (
                              <span
                                style={{ color: 'red', fontSize: '12px' }}
                                type="invalid"
                              >
                                {/* {validation.errors?.course?.undergraduate_courses} */}
                                {validation.errors?.course?.course_name}
                              </span>
                            ) : null}
                            <div className="row">
                              {courses.underGraduateCourse.map(
                                (checks, index) => (
                                  <div className="col-12 col-sm-3 mb-2 d-flex align-items-center">
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="course.course_name"
                                        checked={checks.checked}
                                        value={checks.course_Name}
                                        onChange={(e) =>
                                          handleCourseNameChange(
                                            'underGraduateCourse',
                                            index
                                          )
                                        }
                                        invalid={
                                          // ?.undergraduate_courses &&
                                          validation.touched?.course
                                            ?.course_name &&
                                          // ?.undergraduate_courses
                                          validation.errors?.course?.course_name
                                        }
                                        className="form-check-input"
                                      />
                                    </div>
                                    <label className="ms-2 form-check-label label_text">
                                      {checks.item}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          {/* Mode of Study */}

                          <div className="mb-3">
                            <h5>{mode_of_study.heading}</h5>
                            {ModeOfStudy.length > 1 ? (
                              <span
                                style={{ color: 'red', fontSize: '12px' }}
                                type="invalid"
                              >
                                Select Only One
                              </span>
                            ) : null}
                            <div className="row">
                              {mode_of_study.item.map((checks, index) => (
                                <div
                                  className="col-12 col-sm-3 mb-2 d-flex align-items-center"
                                  key={checks.label}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      name={`course.mode_study[${index}]`}
                                      value={checks.label}
                                      checked={ModeOfStudy.includes(
                                        checks.label
                                      )}
                                      // onChange={validation.handleChange}
                                      onChange={() =>
                                        handleModeOfStudy(checks.label)
                                      }
                                      // invalid={
                                      //   validation.touched?.course?.mode_study &&
                                      //   validation.errors?.course?.mode_study
                                      // }
                                      className="form-check-input"
                                    />
                                  </div>
                                  <label className="ms-2 form-check-label label_text">
                                    {checks.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* Commencement */}

                          <div className="mb-3">
                            <h5>
                              {commencement.heading}
                              <span style={{ fontSize: '16px' }}>
                                <i>{commencement.small_text}</i>
                              </span>
                            </h5>
                            {Commencement.length > 1 ? (
                              // { validation.touched?.course?.commencement &&
                              //              validation.errors?.course?.commencement ? (
                              <span
                                style={{ color: 'red', fontSize: '12px' }}
                                type="invalid"
                              >
                                Select Only One
                              </span>
                            ) : null}
                            <div className="row">
                              {commencement.item.map((checks, index) => (
                                <div
                                  className="col-12 col-sm-3 mb-2 d-flex align-items-center"
                                  key={checks.label}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      name={`course.commencement[${index}]`}
                                      // name="course.commencement"
                                      value={checks.label}
                                      // onChange={validation.handleChange}
                                      checked={Commencement.includes(
                                        checks.label
                                      )}
                                      onChange={() =>
                                        handleCommencement(checks.label)
                                      }
                                      invalid={
                                        validation.touched?.course
                                          ?.commencement &&
                                        validation.errors?.course?.commencement
                                      }
                                      className="form-check-input"
                                    />
                                  </div>
                                  <label className="ms-2 form-check-label label_text">
                                    {checks.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* Course Duration */}

                          <div className="mb-3">
                            <h5>
                              {course_duration.heading}
                              <span style={{ fontSize: '16px' }}>
                                <i>{course_duration.small_text}</i>
                              </span>
                            </h5>
                            {/* {validation.touched?.course?.course_duration &&
                          validation.errors?.course?.course_duration ? ( */}
                            {CourseDuration.length > 1 ? (
                              <span
                                style={{ color: 'red', fontSize: '12px' }}
                                type="invalid"
                              >
                                {/* {validation.errors?.course?.course_duration} */}
                                Select Only One
                              </span>
                            ) : null}
                            <div className="row">
                              {course_duration.item.map((checks, index) => (
                                <div
                                  className="col-12 col-sm-3 mb-2 d-flex align-items-center"
                                  key={checks.label}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      // name="course.course_duration"
                                      name={`course.course_duration[${index}]`}
                                      value={checks.label}
                                      // onChange={validation.handleChange}
                                      onChange={() =>
                                        handleCourseDuration(checks.label)
                                      }
                                      checked={CourseDuration.includes(
                                        checks.label
                                      )}
                                      // invalid={
                                      //   validation.touched?.course
                                      //     ?.course_duration &&
                                      //   validation.errors?.course?.course_duration
                                      // }
                                      className="form-check-input"
                                    />
                                  </div>
                                  <label className="ms-2 form-check-label label_text">
                                    {checks.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* Pay Fees */}

                          <div className="mb-3">
                            <h5>
                              {pay_fees.heading}
                              <span style={{ fontSize: '16px' }}>
                                <i>{pay_fees.small_text}</i>
                              </span>
                            </h5>
                            {PayFees.length > 1 ? (
                              // validation.touched?.course?.fee_pay &&
                              //   validation.errors?.course?.fee_pay
                              <span
                                style={{ color: 'red', fontSize: '12px' }}
                                type="invalid"
                              >
                                Select Only One
                              </span>
                            ) : null}
                            <div className="row">
                              {pay_fees.item.map((checks, index) => (
                                <div
                                  className="col-12 col-sm-3 mb-2 d-flex align-items-center"
                                  key={checks.label}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      // name="course.fee_pay"
                                      name={`course.fee_pay[${index}]`}
                                      value={checks.label}
                                      // onChange={validation.handleChange}
                                      onChange={() =>
                                        handlePayFees(checks.label)
                                      }
                                      checked={PayFees.includes(checks.label)}
                                      // invalid={
                                      //   validation.touched?.course?.fee_pay &&
                                      //   validation.errors?.course?.fee_pay
                                      // }
                                      className="form-check-input"
                                    />
                                  </div>
                                  <label className="ms-2 form-check-label label_text">
                                    {checks.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {currentPage === 3 && (
                        <>
                          <div >
                            {tabel_achieved_data.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h3>{item.heading}</h3>
                                  <p>{item.paragraph}</p>
                                </div>
                              )
                            })}
                          <div style={{overflowX:'auto'}}>
                            <table className="table border text-start" >
                              <thead>
                                <tr>
                                  {tabel_achieved_data.map((item) =>
                                    item.tabel_title.map((sub_item, index) => (
                                      <th key={index} scope="col">
                                        {sub_item.tabel_heading}
                                      </th>
                                    ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {validation.values.academic_qualification.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td className="col-12 col-lg-3">
                                        <input
                                          className="table_input form-control"
                                          type="date"
                                          name={`academic_qualification[${index}].dateAwarded`}
                                          id={`academic_qualification[${index}].dateAwarded`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.dateAwarded || ''}
                                        />
                                      </td>
                                      <td className="col-12 col-lg-3">
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification[${index}].board_institute`}
                                          id={`academic_qualification[${index}].board_institute`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.board_institute || ''}
                                        />
                                      </td>
                                      <td className="col-12 col-lg-2">
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification[${index}].subject`}
                                          id={`academic_qualification[${index}].subject`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.subject || ''}
                                        />
                                      </td>
                                      <td className="col-12 col-lg-2">
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification[${index}].qualification`}
                                          id={`academic_qualification[${index}].qualification`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.qualification || ''}
                                        />
                                      </td>
                                      <td className="col-12 col-lg-2">
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification[${index}].results_achieved`}
                                          id={`academic_qualification[${index}].results_achieved`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.results_achieved || ''}
                                        />
                                      </td>
                                      <td className="col-12 col-lg-3">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleDeleteRowacedemic(index)
                                          }
                                          className="btn btn-danger "
                                          style={{
                                            backgroundColor:
                                              '#0b7b83' /* other styles */,
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </button>
                                      </td>
                                      <td className="col-12 col-lg-2">
                                        <button
                                          type="button"
                                          value="Add Row"
                                          onClick={handleAddRowacedemic}
                                          className="btn btn-primary "
                                          style={{
                                            backgroundColor: '#0b7b83',
                                            color: '#fff',
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-plus-square-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            </div>
                          </div>
                          <div >
                            {tabel_pending_data.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h3>{item.heading}</h3>
                                  <p>{item.paragraph}</p>
                                </div>
                              )
                            })}
                             <div style={{overflowX:'auto'}}>
                            <table className="table border text-start ">
                              <thead>
                                <tr>
                                  {tabel_pending_data.map((item) =>
                                    item.tabel_title.map((sub_item, index) => (
                                      <th key={index} scope="col">
                                        {sub_item.tabel_heading}
                                      </th>
                                    ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {validation.values.academic_qualification_examinations.map(
                                  (item, index) => (
                                    <tr>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="date"
                                          name={`academic_qualification_examinations[${index}].dateAwarded`}
                                          id={`academic_qualification_examinations[${index}].dateAwarded`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.dateAwarded || ''}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification_examinations[${index}].board_institute`}
                                          id={`academic_qualification_examinations[${index}].board_institute`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.board_institute || ''}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification_examinations[${index}].subject`}
                                          id={`academic_qualification_examinations[${index}].subject`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.subject || ''}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`academic_qualification_examinations[${index}].qualification`}
                                          id={`academic_qualification_examinations[${index}].qualification`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.qualification || ''}
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleDeleteRowacademic_qualificationexam(
                                              index
                                            )
                                          }
                                          className="btn btn-danger "
                                          style={{
                                            backgroundColor:
                                              '#0b7b83' /* other styles */,
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          value="Add Row"
                                          onClick={
                                            handleAddRowcademic_qualificationexam
                                          }
                                          className="btn btn-primary "
                                          style={{
                                            backgroundColor: '#0b7b83',
                                            color: '#fff',
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-plus-square-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </>
                      )}
                      {currentPage === 4 && (
                        <>
                          <div>
                            <h3>5. English language:</h3>
                            <p>
                              You must submit proof of your English language
                              proficiency with this application
                            </p>
                            <div>
                              <div className="d-flex">
                                <p>
                                  Is English your first language? (Please tick
                                  box as applicable)
                                </p>
                                <input
                                  name="english_your_first_language"
                                  className="form-check-input ms-4"
                                  onChange={validation.handleChange}
                                  type="checkbox"
                                  checked={
                                    validation.values
                                      .english_your_first_language
                                  }
                                />
                                {validation.errors
                                  .english_your_first_language ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    className="mt-1 ms-2"
                                  >
                                    {
                                      validation.errors
                                        .english_your_first_language
                                    }
                                  </span>
                                ) : null}
                              </div>

                              <div className="d-flex">
                                {Language_attend_course.map((item, index) => (
                                  <div key={index} className="d-flex">
                                    <p>{item.text}</p>
                                    <input
                                      className="form-check-input ms-3"
                                      type="checkbox"
                                      name={`language_attend_course[${index}]`}
                                      value={item.value}
                                      checked={selectedattend.includes(
                                        item.value
                                      )}
                                      onChange={() => handleattend(item.value)}
                                      invalid={selectedattend.length > 1}
                                      // invalid={
                                      //   validation.touched
                                      //     .language_attend_course ||
                                      //     validation.errors.language_attend_course
                                      //     ? true
                                      //     : false
                                      // }
                                    />
                                    <p className="ms-2">{item.label}</p>
                                  </div>
                                ))}
                                {validation.touched.language_attend_course ||
                                validation.errors.language_attend_course ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    className="mt-1 ms-2"
                                  >
                                    {validation.errors.language_attend_course}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div>
                              <div className="d-flex">
                                {Language_proficiency_test.map(
                                  (item, index) => (
                                    <div key={index} className="d-flex">
                                      <p>{item.text}</p>
                                      <input
                                        className="form-check-input ms-3"
                                        type="checkbox"
                                        name={`english_language_proficiency[${index}]`}
                                        value={item.value}
                                        checked={selectedyes.includes(
                                          item.value
                                        )}
                                        onChange={() => handleyes(item.value)}
                                        invalid={selectedyes.length > 1}
                                      />
                                      <p className="ms-2">{item.label}</p>
                                    </div>
                                  )
                                )}
                                {validation.errors
                                  .english_language_proficiency ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    className="mt-1 ms-2"
                                  >
                                    {
                                      validation.errors
                                        .english_language_proficiency
                                    }
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div>
                            {tabel_work_experience.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h3>{item.heading}</h3>
                                  <p>{item.paragraph}</p>
                                </div>
                              )
                            })}
                             <div style={{overflowX: 'auto'}}>
                            <table className="table border text-start ">
                              <thead>
                                <tr>
                                  {tabel_work_experience.map((item) =>
                                    item.tabel_title.map((sub_item, index) => (
                                      <th key={index} scope="col">
                                        {sub_item.tabel_heading}
                                      </th>
                                    ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {validation.values.Work_Experience.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="date"
                                          name={`Work_Experience[${index}].Date_from`}
                                          id={`Work_Experience[${index}].Date_from`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.Date_from || ''}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="date"
                                          name={`Work_Experience[${index}].Date_to`}
                                          id={`Work_Experience[${index}].Date_to`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.Date_to || ''}
                                        />
                                      </td>
                                      <td>
                                        <select
                                          className="form-select"
                                          name={`Work_Experience[${index}].FT_PT`}
                                          id={`Work_Experience[${index}].FT_PT`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.FT_PT || ''}
                                        >
                                          <option defaultValue>
                                            Select your job Mode
                                          </option>
                                          {job_mode.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      </td>

                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`Work_Experience[${index}].Post_held`}
                                          id={`Work_Experience[${index}].Post_held`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.Post_held || ''}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`Work_Experience[${index}].ROrganisation_nam`}
                                          id={`Work_Experience[${index}].ROrganisation_nam`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.ROrganisation_nam || ''}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`Work_Experience[${index}].Brief_description`}
                                          id={`Work_Experience[${index}].Brief_description`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.Brief_description || ''}
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() => handleDeleteRow(index)}
                                          className="btn btn-danger "
                                          style={{
                                            backgroundColor:
                                              '#0b7b83' /* other styles */,
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          value="Add Row"
                                          onClick={handleAddRow}
                                          className="btn btn-primary "
                                          style={{
                                            backgroundColor: '#0b7b83',
                                            color: '#fff',
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-plus-square-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            </div>
                            {tabel_work_experience.map((item, index) => (
                              <p key={index}>{item.sub_paragraph}</p>
                            ))}
                            <div style={{overflowX: 'auto'}}>
                            <table className="table border text-start ">
                              <thead>
                                <tr>
                                  {tabel_work_experience.map((item) =>
                                    item.sub_tabel_title.map(
                                      (sub_item, index) => (
                                        <th key={index} scope="col">
                                          {sub_item.tabel_heading}
                                        </th>
                                      )
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {validation.values.Work_Experience_sub.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="date"
                                          name={`Work_Experience_sub[${index}].date_from`}
                                          id={`Work_Experience_sub[${index}].date_from`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.date_from || ''}
                                        />
                                      </td>
                                      <td>
                                        <select
                                          className="form-select"
                                          name={`Work_Experience_sub[${index}].fT_PT`}
                                          id={`Work_Experience_sub[${index}].fT_PT`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          //invalid={validation.touched?.Work_Experience_sub[`${index}`]?.fT_PT && validation.errors?.Work_Experience_sub[`${index}`]?.fT_PT ? true : false}
                                          value={item.fT_PT || ''}
                                        >
                                          <option defaultValue>
                                            Select job Mode
                                          </option>
                                          {job_mode.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      </td>

                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`Work_Experience_sub[${index}].post_held`}
                                          id={`Work_Experience_sub[${index}].post_held`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.post_held || ''}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`Work_Experience_sub[${index}].rOrganisation_nam`}
                                          id={`Work_Experience_sub[${index}].rOrganisation_nam`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.rOrganisation_nam || ''}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table_input form-control"
                                          type="text"
                                          name={`Work_Experience_sub[${index}].brief_description`}
                                          id={`Work_Experience_sub[${index}].brief_description`}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={item.brief_description || ''}
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleDeleteRowWork_Experience_sub(
                                              index
                                            )
                                          }
                                          className="btn btn-danger "
                                          style={{
                                            backgroundColor:
                                              '#0b7b83' /* other styles */,
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          value="Add Row"
                                          onClick={
                                            handleAddRowWork_Experience_sub
                                          }
                                          className="btn btn-primary "
                                          style={{
                                            backgroundColor: '#0b7b83',
                                            color: '#fff',
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-plus-square-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </>
                      )}
                      {currentPage === 5 && (
                        <>
                          {further_info.map((data, index) => (
                            <div key={index}>
                              <h3>{data.title}</h3>
                              <p>{data.paragraph}</p>
                              {data.questions.map((questions, index) => (
                                <div key={index}>
                                  <div className="">
                                    <label className="label_text">
                                      {questions?.ques}
                                    </label>
                                  </div>
                                  <div className="mb-3">
                                    <input
                                      type="text"
                                      name={`further_information.q${index + 1}`}
                                      value={
                                        index === 0
                                          ? validation.values
                                              .further_information.q1
                                          : index === 1
                                          ? validation.values
                                              .further_information.q2
                                          : index === 2
                                          ? validation.values
                                              .further_information.q3
                                          : index === 3
                                          ? validation.values
                                              .further_information.q4
                                          : index === 4
                                          ? validation.values
                                              .further_information.q5
                                          : index === 5
                                          ? validation.values
                                              .further_information.q6
                                          : validation.values
                                              .further_information.q7
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        (index === 0
                                          ? validation.touched
                                              ?.further_information?.q1
                                          : index === 1
                                          ? validation.touched
                                              ?.further_information?.q2
                                          : index === 2
                                          ? validation.touched
                                              ?.further_information?.q3
                                          : index === 3
                                          ? validation.touched
                                              ?.further_information?.q4
                                          : index === 4
                                          ? validation.touched
                                              ?.further_information?.q5
                                          : index === 5
                                          ? validation.touched
                                              ?.further_information?.q6
                                          : validation.touched
                                              ?.further_information?.q7) &&
                                        (index === 0
                                          ? validation.errors
                                              ?.further_information?.q1
                                          : index === 1
                                          ? validation.errors
                                              ?.further_information?.q2
                                          : index === 2
                                          ? validation.errors
                                              ?.further_information?.q3
                                          : index === 3
                                          ? validation.errors
                                              ?.further_information?.q4
                                          : index === 4
                                          ? validation.errors
                                              ?.further_information?.q5
                                          : index === 5
                                          ? validation.errors
                                              ?.further_information?.q6
                                          : validation.errors
                                              ?.further_information?.q7)
                                          ? true
                                          : false
                                      }
                                      className="form-control"
                                    />
                                    {(index === 0
                                      ? validation.touched?.further_information
                                          ?.q1
                                      : index === 1
                                      ? validation.touched?.further_information
                                          ?.q2
                                      : index === 2
                                      ? validation.touched?.further_information
                                          ?.q3
                                      : index === 3
                                      ? validation.touched?.further_information
                                          ?.q4
                                      : index === 4
                                      ? validation.touched?.further_information
                                          ?.q5
                                      : index === 5
                                      ? validation.touched?.further_information
                                          ?.q6
                                      : validation.touched?.further_information
                                          ?.q7) &&
                                    (index === 0
                                      ? validation.errors?.further_information
                                          ?.q1
                                      : index === 1
                                      ? validation.errors?.further_information
                                          ?.q2
                                      : index === 2
                                      ? validation.errors?.further_information
                                          ?.q3
                                      : index === 3
                                      ? validation.errors?.further_information
                                          ?.q4
                                      : index === 4
                                      ? validation.errors?.further_information
                                          ?.q5
                                      : index === 5
                                      ? validation.errors?.further_information
                                          ?.q6
                                      : validation.errors?.further_information
                                          ?.q7) ? (
                                      <span
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                        }}
                                      >
                                        {index === 0
                                          ? validation.errors
                                              ?.further_information?.q1
                                          : index === 1
                                          ? validation.errors
                                              ?.further_information?.q2
                                          : index === 2
                                          ? validation.errors
                                              ?.further_information?.q3
                                          : index === 3
                                          ? validation.errors
                                              ?.further_information?.q4
                                          : index === 4
                                          ? validation.errors
                                              ?.further_information?.q5
                                          : index === 5
                                          ? validation.errors
                                              ?.further_information?.q6
                                          : validation.errors
                                              ?.further_information?.q7}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      )}
                      {currentPage === 6 && (
                        <>
                          {reference.map((data, index) => (
                            <div key={index}>
                              <h3>{data.title}</h3>
                              <p>{data.paragraph}</p>
                              <h4>{data.sub_title}</h4>
                              <p>{data.sub_paragraph}</p>
                              {/* {data.questions.map((questions) => ( */}
                              <>
                                <div className="">
                                  <label className="label_text">
                                    How long have you known the applicant and in
                                    what capacity?
                                  </label>
                                </div>
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    name="reference_applicant_capacity"
                                    value={
                                      validation.values
                                        .reference_applicant_capacity
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    className="form-control"
                                    invalid={
                                      validation.touched
                                        .reference_applicant_capacity &&
                                      validation.errors
                                        .reference_applicant_capacity
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched
                                    .reference_applicant_capacity &&
                                  validation.errors
                                    .reference_applicant_capacity ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {
                                        validation.errors
                                          .reference_applicant_capacity
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="">
                                  <label className="label_text">
                                    What is your opinion of the Applicants
                                    suitability for the course chosen?
                                  </label>
                                </div>
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    name="reference_applicant_opinion"
                                    value={
                                      validation.values
                                        .reference_applicant_opinion
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    className="form-control"
                                    invalid={
                                      validation.touched
                                        .reference_applicant_opinion &&
                                      validation.errors
                                        .reference_applicant_opinion
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched
                                    .reference_applicant_opinion &&
                                  validation.errors
                                    .reference_applicant_opinion ? (
                                    <span
                                      style={{ color: 'red', fontSize: '12px' }}
                                    >
                                      {
                                        validation.errors
                                          .reference_applicant_opinion
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </>
                              {/* ))} */}
                            </div>
                          ))}
                          <div>
                            <p className="label_text">
                              Please tick appropriately – ONE tick per row:
                            </p>
                          </div>
                          <div className="mb-3">
                            <table className="table border  ">
                              <thead>
                                <tr>
                                  {reference.map((item) =>
                                    item.tabel_title.map((sub_item, index) => (
                                      <th
                                        key={sub_item.title}
                                        scope="col"
                                        className="text-center font-weight-bold "
                                      >
                                        {sub_item.title}
                                      </th>
                                    ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Intellectual / Academic ability</td>
                                  {dataIntelAcadAbility.map((item, index) => (
                                    <td style={{ textAlign: 'center' }}>
                                      <input
                                        className="form-check-input "
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() =>
                                          handleCheckboxesChange(index)
                                        }
                                      />
                                      {/* <label>{item.value}</label> */}
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex ">
                                      <div className="d-flex align-items-center">
                                        Fluent and logical communication in
                                        English
                                      </div>
                                      <div className="text-center ms-4">
                                        {/* {sub_item.divider?.map(
                                                  (text, idx) => ( */}
                                        <div>
                                          <div>Oral</div>
                                          <div>Written</div>
                                        </div>
                                        {/* )
                                                )} */}
                                        {/* <div>{sub_item.written?.text}</div> */}
                                      </div>
                                    </div>
                                  </td>
                                  {sharedData?.map((item, index) => (
                                    <td key={index} className="text-center">
                                      <div>
                                        <div>
                                          <input
                                            className="form-check-input "
                                            type="checkbox"
                                            checked={item.oralChecked}
                                            onChange={() =>
                                              handleOralChange(index)
                                            }
                                          />
                                        </div>
                                        <div>
                                          <input
                                            className="form-check-input "
                                            type="checkbox"
                                            checked={item.writtenChecked}
                                            onChange={() =>
                                              handleWrittenChange(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>Ability to work hard</td>
                                  {Abilitytoworkhard.map((item, index) => (
                                    <td style={{ textAlign: 'center' }}>
                                      <input
                                        className="form-check-input "
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() =>
                                          handleAbilitytoworkhard(index)
                                        }
                                      />
                                      {/* <label>{item.value}</label> */}
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>Perseverance</td>
                                  {Perseverance.map((item, index) => (
                                    <td style={{ textAlign: 'center' }}>
                                      <input
                                        className="form-check-input "
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() =>
                                          handlePerseverance(index)
                                        }
                                      />
                                      {/* <label>{item.value}</label> */}
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>Leadership</td>
                                  {Leadership.map((item, index) => (
                                    <td style={{ textAlign: 'center' }}>
                                      <input
                                        className="form-check-input "
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() => handleLeadership(index)}
                                      />
                                      {/* <label>{item.value}</label> */}
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>Creativity</td>
                                  {Creativity.map((item, index) => (
                                    <td style={{ textAlign: 'center' }}>
                                      <input
                                        className="form-check-input "
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() => handleCreativity(index)}
                                      />
                                      {/* <label>{item.value}</label> */}
                                    </td>
                                  ))}
                                </tr>
                                <tr>
                                  <td>Concern for others</td>
                                  {Concernforothers.map((item, index) => (
                                    <td style={{ textAlign: 'center' }}>
                                      <input
                                        className="form-check-input "
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={() =>
                                          handleConcernforothers(index)
                                        }
                                      />
                                      {/* <label>{item.value}</label> */}
                                    </td>
                                  ))}
                                </tr>
                              </tbody>
                            </table>

                            <div className="">
                              <div>
                                <label className="label_text">
                                  Any other information that you feel is
                                  relevant.{' '}
                                </label>
                              </div>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  name="reference_any_other_info"
                                  value={
                                    validation.values.reference_any_other_info
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  className="form-control"
                                  invalid={
                                    validation.touched
                                      .reference_any_other_info &&
                                    validation.errors.reference_any_other_info
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.reference_any_other_info &&
                                validation.errors.reference_any_other_info ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                  >
                                    {validation.errors.reference_any_other_info}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4">
                              <label className=" label_text">
                                Referee’s name:
                              </label>
                            </div>
                            <div className="col-4">
                              <label className=" label_text">
                                Referee’s signatures:
                              </label>
                            </div>
                            <div className="col-4">
                              <label className=" label_text">Date:</label>
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-4">
                              <input
                                type="text"
                                name="refer_name"
                                value={validation.values.refer_name || ''}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.refer_name &&
                                  validation.errors.refer_name
                                    ? true
                                    : false
                                }
                                className="form-control "
                              />
                              {validation.touched.refer_name &&
                                validation.errors.refer_name && (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    className="ms-4"
                                  >
                                    {validation.errors.refer_name}
                                  </span>
                                )}
                            </div>

                            <div className="col-4">
                              <input
                                type="text"
                                name="refer_sign"
                                value={validation.values.refer_sign || ''}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.refer_sign &&
                                  validation.errors.refer_sign
                                    ? true
                                    : false
                                }
                                className="form-control "
                              />
                              {validation.touched.refer_sign &&
                                validation.errors.refer_sign && (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                    className="ms-4"
                                  >
                                    {validation.errors.refer_sign}
                                  </span>
                                )}
                            </div>
                            <div className="col-4">
                              {/* Input for Date */}
                              <input
                                type="date"
                                className="form-control"
                                name="refer_date"
                                value={validation.values.refer_date || ''}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.refer_date &&
                                  validation.errors.refer_date
                                    ? true
                                    : false
                                }
                              />
                              {/* Validation error message for Date */}
                              {validation.touched.refer_date &&
                              validation.errors.refer_date ? (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                >
                                  {validation.errors.refer_date}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {currentPage === 7 && (
                        <>
                          <div className="mb-3">
                            {marketing_info.map((value, idx) => (
                              <div key={value.title}>
                                <h3>{value.title}</h3>
                                <p>{value.paragraph}</p>
                                <div className="row">
                                  {sources.map((source, index) => {
                                    return (
                                      <div
                                        key={source.value}
                                        className="d-flex col-3 align-items-center"
                                      >
                                        <div>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="marketing_inf"
                                            value={source.value}
                                            id={`checkbox${source.id}`}
                                            checked={source.checked}
                                            onChange={(event) =>
                                              handleCheckboxChange(event, index)
                                            }
                                          />
                                        </div>
                                        <label className="form-check-label ms-2 label_text">
                                          {source.label}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div>
                            {equal_oppertunity.map((value, idx) => (
                              <div key={idx}>
                                <h3>{value.title}</h3>
                                <p>{value.paragraph}</p>
                                <h5>{value.sub_title_1}</h5>
                                {validation.touched
                                  .equal_oppertunity_ethnic_origin &&
                                validation.errors
                                  .equal_oppertunity_ethnic_origin ? (
                                  <span
                                    style={{ color: 'red', fontSize: '12px' }}
                                  >
                                    {
                                      validation.errors
                                        .equal_oppertunity_ethnic_origin
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                                <div className="row mb-3">
                                  {EthnicOrigin.map((item, idx) => {
                                    return (
                                      <div
                                        key={idx}
                                        className="d-flex col-3 align-items-center"
                                      >
                                        <div>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.checked}
                                            value={item.key}
                                            onChange={() => {
                                              handleEthnicOrigin(idx)
                                            }}
                                          />
                                        </div>
                                        <label className="form-check-label ms-2 label_text">
                                          {item.key}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <h5>{value.sub_title_2}</h5>
                                <div className="row mb-3">
                                  {ReligionHandling.map((item, idx) => {
                                    return (
                                      <div
                                        key={idx}
                                        className="d-flex col-3 align-items-center"
                                      >
                                        <div>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.checked}
                                            value={item.key}
                                            onChange={() => {
                                              handleReligionHandling(idx)
                                            }}
                                          />
                                        </div>
                                        <label className="form-check-label ms-2 label_text">
                                          {item.key}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <h4>{value.declaration_heading}</h4>
                                <p>{value.declaration_paragraph}</p>
                                <div className="row mb-3">
                                  <div className="col-6">
                                    <label className="label_text">
                                      Applicant’s signature:
                                    </label>
                                  </div>
                                  <div className="col-6">
                                    <label className=" label_text ">
                                      Date:
                                    </label>
                                  </div>
                                </div>

                                <div className="row mb-2 ">
                                  <div className="col-6">
                                    <input
                                      type="text"
                                      name="app_sign"
                                      value={validation.values.app_sign}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      className="form-control"
                                      invalid={
                                        validation.touched.app_sign &&
                                        validation.errors.app_sign
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.app_sign &&
                                    validation.errors.app_sign ? (
                                      <span
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                        }}
                                      >
                                        {validation.errors.app_sign}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className="col-6">
                                    <input
                                      type="date"
                                      name="equal_oppertunity_date"
                                      value={
                                        validation.values.equal_oppertunity_date
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      className="form-control"
                                      invalid={
                                        validation.touched
                                          .equal_oppertunity_date &&
                                        validation.errors.equal_oppertunity_date
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched
                                      .equal_oppertunity_date &&
                                    validation.errors.equal_oppertunity_date ? (
                                      <span
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                        }}
                                      >
                                        {
                                          validation.errors
                                            .equal_oppertunity_date
                                        }
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <h4>{value.enrolment_procedure_title}</h4>
                                <p>
                                  1. Complete the Application for Admission
                                  form, and send it with
                                </p>
                                <p className="ms-4">
                                  a{')'} certified copies of relevant academic
                                  certificates & work related documents
                                </p>
                                <p className="ms-4">b{')'} 1 colour photo</p>
                                <p className="ms-4">
                                  c{')'} copy of passport page
                                </p>
                                <p className="ms-4">
                                  d{')'} copy of National Insurance number
                                  document
                                </p>
                                <p className="ms-4">
                                  e{')'} proof of address (driving license or
                                  bank statement) send to the{' '}
                                  <i>
                                    <b>
                                      Registrar, City of London College, 3 Boyd
                                      Street, London E1 1FQ.
                                    </b>
                                  </i>
                                </p>
                                <p>
                                  2. When your application has been processed,
                                  the College will issue a Letter of Acceptance
                                  (LOA).
                                </p>
                              </div>
                            ))}
                          </div>
                          <div className="form-row">
                            <div className="file-upload">
                              <div className="image-upload-wrap">
                                <div className="drag-text">
                                  <img
                                    src={img3}
                                    alt="uploadimage"
                                    style={{
                                      marginTop: '40px',
                                      width: '160px',
                                      height: '100px',
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '5px',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        padding: '15px 15px 0px 15px',
                                      }}
                                    >
                                      Drag And Drop Files OR Choose your Files
                                    </h3>
                                    <h6>
                                      Only .pdf, .png, .jpg, .jpeg, .docx
                                      Allowed
                                    </h6>
                                  </div>
                                  <input
                                    multiple
                                    name="allfiles"
                                    type="file"
                                    className="file-upload-btn"
                                    id="allfiles"
                                    accept="image/png, image/jpeg, image/jpg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf "
                                    // multiple={(e) =>
                                    //   handleFileSelect(e, 'selectedFilesD')
                                    // }
                                    onChange={handleFileChange}
                                  />
                                </div>
                                <output
                                  id="selectedFilesDadd"
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '40px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {filePreviews.map((previewObj, index) => (
                                    <div key={index}>
                                      {/* {console.log("file preview is",preview)}
                         <img key={index} src={preview} alt={`Preview ${index}`} style={{ maxWidth: '100px', maxHeight: '100px' }} /> */}
                                      {previewObj.preview ? (
                                        <img
                                          src={previewObj.preview}
                                          alt={`Preview ${index}`}
                                          style={{
                                            maxWidth: '100px',
                                            maxHeight: '100px',
                                          }}
                                        />
                                      ) : (
                                        <div>
                                          {/* Display an icon or thumbnail based on file type */}
                                          {previewObj.file.type.includes(
                                            'pdf'
                                          ) ? (
                                            <img
                                              src={Pdf_file_icon}
                                              alt={`PDF Preview ${index}`}
                                              style={{
                                                maxWidth: '100px',
                                                maxHeight: '100px',
                                              }}
                                            />
                                          ) : previewObj.file.type.includes(
                                              'word'
                                            ) ? (
                                            <img
                                              src={word_icon}
                                              alt={`Word Preview ${index}`}
                                              style={{
                                                maxWidth: '100px',
                                                maxHeight: '100px',
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={generic_file_icon}
                                              alt={`File Preview ${index}`}
                                              style={{
                                                maxWidth: '100px',
                                                maxHeight: '100px',
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    // <div key={index}>
                                    //   {/* ... Display selected files ... */}
                                    //   <img
                                    //     key={index}
                                    //     src={preview}
                                    //     alt={`Preview ${index}`}
                                    //     style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    //   />
                                    //   {/* <button
                                    //                   type="button"
                                    //                   className="btn btn-danger"
                                    //                   onClick={() =>
                                    //                     deleteFile(index, 'selectedFilesD')
                                    //                   }
                                    //                 >
                                    //                   Delete
                                    //                 </button> */}
                                    // </div>
                                  ))}
                                </output>
                              </div>
                            </div>
                          </div>
                          <div
                            className="ms-5 mt-5"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }}
                          >
                            <button
                              type="submit"
                              style={{
                                width: '30%',
                                background: '#002060',
                                color: '#ffff',
                                fontSize: '2em',
                                fontWeight: 'bold',
                              }}
                              className="btn"
                              // onClick={toggle}
                            >
                              Submit via Email
                            </button>
                          </div>
                        </>
                      )}
                      {/* For Register Use Only */}
                      <div>
                        {/* <Button color="danger" onClick={toggle}></Button>
          Click Me
        </Button> */}
                        <Modal isOpen={modal}>
                          <ModalHeader>Hi! {userName}</ModalHeader>
                          <ModalBody>
                            Kindly check your email address to confirm that form
                            is submitted or not.
                          </ModalBody>
                          <ModalFooter>
                            {/* <Button color="primary" onClick={toggle}>
              Do Something
            </Button>{' '} */}
                            <Button
                              color="secondary"
                              onClick={() => {
                                setModal(!modal)
                                navigate('/')
                              }}
                            >
                              OK
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="ms-5 mt-4 "
                style={{ overflow: 'auto', marginBottom: '10px' }}
              >
                <div style={{ float: 'right' }}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="prevBtn"
                    hidden={activeSteps.length === 1 ? true : false}
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    type="button"
                    id="nextBtn"
                    hidden={
                      activeSteps[activeSteps.length - 1] === steps.length - 1
                        ? true
                        : false
                    }
                    onClick={() => handleNext(validation.values)}
                  >
                    Next
                  </button>
                  {/* <button
                                  type="submit"
                                  style={{
                                    width: '100%',
                                    background: '#002060',
                                    color: '#ffff',
                                    fontSize: '2em',
                                    fontWeight: 'bold',
                                  }}
                                  className="btn"
                                // onClick={toggle}
                                >
                                  Submit via Email
                                </button> */}
                </div>
              </div>
              {/* for model */}
              {errorMessage !== '' && (
                <div
                  className="mb-3"
                  style={{ float: 'inline-end', color: 'red' }}
                >
                  <span>{errorMessage}</span>
                </div>
              )}

              {/* for model */}
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  )
}

export default AppForm
