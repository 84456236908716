import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { base_url } from '../../../utils/Api'
import '../../style.css'

const Commencent = () => {
  const [data, setData] = useState([])

  const [PerPageEntry, setPerPageEntry] = useState(10)
  const [load, setLoad] = useState(false)
  const [searchQuery, setSearchQuery] = useState('') // New state for search query
  const [filteredTotalEntries, setFilteredTotalEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    const totalPages = Math.ceil(totalEntries / PerPageEntry)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const startIndex = (currentPage - 1) * PerPageEntry
  const endIndex = startIndex + PerPageEntry
  console.log(startIndex, endIndex)
  const renderTable = () => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan="10" style={{ textAlign: 'center' }}>
            No matching records found
          </td>
        </tr>
      )
    }

    return data?.map((item, index) => (
      <tr
        key={item._id}
        role="row"
        className={index % 2 === 0 ? 'even' : 'odd'}
      >
        {/* Render table rows as before */}
      </tr>
    ))
  }

  const handleDelete = async (_id) => {
    console.log(_id)
    // Show a confirmation dialog
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this item?'
    )

    if (confirmDelete) {
      try {
        // Make an API request to delete the item
        const response = await axios.delete(
          base_url + `admin/Course/deleteSessionNames/${_id}`
        )

        if (response.data.data === 'Name Removed') {
          // If the course was successfully removed, update the state or perform any necessary actions
          setLoad(!load) // Update your state as needed
          alert('courseName removed successfully') // Provide feedback to the user
        } else {
          // Handle the case where the API response indicates an error
          alert('Failed to remove courseName')
        }
      } catch (error) {
        // Handle any errors that occur during the DELETE request
        console.error('Error deleting course:', error)
        alert('An error occurred while deleting the course')
      }
    } else {
      // The user canceled the deletion, no further action needed
      // You can provide feedback to the user if necessary
      alert('Deletion canceled')
    }
  }
  useEffect(() => {
    getSessionNames()
  }, [load])

  const getSessionNames = async () => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const res = await axios.get(base_url + 'admin/getSessionNames', config)
      const data = res?.data?.data
      // const total_length=data.le
      setTotalEntries(data.length)
      setTotalLength(data.length)
      const filteredData = res?.data?.data.filter(
        (item, index) =>
          item.session_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          index.toString().includes(searchQuery)
      )

      if (filteredData) {
        setTotalEntries(filteredData.length)
      }
      setData(filteredData)
      setFilteredTotalEntries(filteredData.length)
    } catch (error) {
      console.log('catch error is', error)
    }
  }
  const isSidebarOpen = useSelector((state) => state.checkCloseOpen)
  useEffect(() => {
    getSessionNames()
  }, [searchQuery])

  return (
    <div className={isSidebarOpen.checkOpenClose ? `mini-sidebar` : ''}>
      <div className="page-wrapper" style={{ minHeight: '844px' }}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Commencement</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="http://clc-london.uk/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Commencement</li>
                </ul>
              </div>
              <div className="col-auto text-right float-right ml-auto">
                <Link
                  to="/admin/commencement/create"
                  className="btn btn-primary"
                >
                  <i className="fas fa-plus"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <p style={{ display: 'none' }}>1</p>
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      {/* Table */}
                      <div className="row">
                        <div className="col-sm-12 col-md-6 mb-1">
                          <div className="col-sm-12 col-md-6 mb-1">
                            <div
                              className="dataTables_length"
                              id="DataTables_Table_0_length"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <label>Show </label>
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                                style={{
                                  width: 60,
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                                value={PerPageEntry}
                                onChange={(e) =>
                                  setPerPageEntry(parseInt(e.target.value))
                                }
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              <label>entries</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="DataTables_Table_0_filter"
                            className="dataTables_filter "
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                            }}
                          >
                            <label>Search:</label>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="DataTables_Table_0"
                              value={searchQuery} // Bind the searchQuery to the input value
                              onChange={(e) => setSearchQuery(e.target.value)}
                              style={{ width: 200, marginLeft: 8 }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Table */}
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-hover table-center mb-0 datatable dataTable no-footer"
                            id="DataTables_Table_0"
                            role="grid"
                            aria-describedby="DataTables_Table_0_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                  style={{ width: '191.578px' }}
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Session Name: activate to sort column ascending"
                                  style={{ width: '507.875px' }}
                                >
                                  Session Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="DataTables_Table_0"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Action: activate to sort column ascending"
                                  style={{ width: '336.547px' }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderTable()}
                              {data
                                ?.slice(startIndex, endIndex)
                                .map((item, index) => (
                                  <tr role="row" className="odd" key={item._id}>
                                    <td className="sorting_1">
                                      {' '}
                                      {parseInt(startIndex) + index + 1}
                                    </td>
                                    <td>{item.session_Name}</td>
                                    <td className="text-right">
                                      <div className="actions d-flex text-right">
                                        <Link
                                          to={`/admin/commencement/${item._id}`}
                                          className="btn btn-sm bg-success-light mr-2"
                                          style={{
                                            width: 30,
                                            marginLeft: 10,
                                            marginRight: 10,
                                          }}
                                        >
                                          <i className="fas fa-pen"></i>
                                        </Link>
                                        <a href="#">
                                          <form
                                          // method="POST"
                                          // action="http://clc-london.uk/admin/commencement/1"
                                          // onsubmit="return confirm('Are you sure?');"
                                          >
                                            {/* <input
                                              type="hidden"
                                              name="_token"
                                              value="XmFdYM18BVXS254W4xnlG45nJoKFJwtoYojzrpo4"
                                            />
                                            <input
                                              type="hidden"
                                              name="_method"
                                              value="DELETE"
                                            /> */}
                                            <button
                                              type="submit"
                                              style={{
                                                border: 'none',
                                                padding: '0%',
                                                backgroundColor: 'none',
                                              }}
                                              onClick={() =>
                                                handleDelete(item._id)
                                              }
                                            >
                                              <i className="fas fa-trash  btn-sm bg-danger-light"></i>
                                            </button>
                                          </form>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* Pagination */}
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {filteredTotalEntries === 0
                              ? `Showing 0 to 0 of 0 entries (filtered from ${totalLength} total entries)`
                              : `Showing ${Math.min(
                                  startIndex + 1,
                                  endIndex
                                )} to ${Math.min(
                                  endIndex,
                                  filteredTotalEntries
                                )} of ${filteredTotalEntries} entries (filtered from ${totalLength} total entries)`}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination justify-content-end">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="DataTables_Table_0_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  onClick={handlePreviousClick}
                                  className="page-link"
                                >
                                  Previous
                                </a>
                              </li>
                              {totalEntries > 0
                                ? Array.from({
                                    length: Math.max(
                                      1,
                                      Math.ceil(totalEntries / PerPageEntry)
                                    ),
                                  }).map((_, index) => (
                                    <li
                                      key={index}
                                      className={`paginate_button page-item ${
                                        currentPage === index + 1
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      <button
                                        onClick={() =>
                                          setCurrentPage(index + 1)
                                        }
                                        className="page-link"
                                      >
                                        {index + 1}
                                      </button>
                                    </li>
                                  ))
                                : null}
                              <li
                                className={`paginate_button page-item next ${
                                  currentPage ===
                                  Math.ceil(totalEntries / PerPageEntry)
                                    ? 'disabled'
                                    : ''
                                }`}
                                id="DataTables_Table_0_next"
                              >
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  onClick={handleNextClick}
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright © {new Date().getFullYear()}.</p>
        </footer>
      </div>
    </div>
  )
}

export default Commencent
