import { SIDEBAR_HANDLING } from "../constants/handlingSidebarConstant"


export const handlingSidebar = (state = {}, action) => {
  switch (action.type) {
 
    case SIDEBAR_HANDLING:
      return { checkOpenClose: action.payload }
    default:
      return state
  }
}